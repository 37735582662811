<div class="title-chart text-center">
    <b [innerHTML]="title"></b>
</div>
<div echarts [options]="chartOption" class="demo-chart"></div>
<div class="table-responsive">
    <table class="table ">
        <thead>
            <tr>
                <th scope="col">-</th>
                <th scope="col" *ngFor="let e of xAxis">{{ e }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">{{chartOption.legend.data[3]}}</th>
                <td *ngFor="let value of mapArray('valor_promedio')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[1]}}</th>
                <td *ngFor="let value of mapArray('hidrologia_alta')">{{ value | number:'1.0-0' }}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[2]}}</th>
                <td *ngFor="let value of mapArray('hidrologia_baja')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[0]}}</th>
                <td *ngFor="let value of mapArray('valor_esperado')">{{ value | number:'1.0-0'}}</td>
            </tr>
        </tbody>
    </table>

</div>