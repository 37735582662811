export class NuestrosProyectos {
  nombre: string;
  descripcion: string;
  archivo: string;
  logo?: string;
  nombreParticipantes: string;
  areaDesempeno: string;
  cantidad: number;
  posicion: string;
  color: string;
  id?: number;
  titulo?: string;
}
