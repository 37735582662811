<div>
    <div class="content">
        <video *ngIf="bannerCampos.tipo == 'video'" height=" auto " controlsList="nodownload" data-autoplay onloadedmetadata="this.muted = true" oncanplay="this.play()" loop="true" class="w-100 imgbanner">
      <source [src]="urlS3+ '/' + bannerCampos.archivo " type="video/mp4 ">
    </video>
        <img *ngIf="bannerCampos.tipo == 'imagen'" [src]="urlS3+ '/' + bannerCampos.archivo" alt="" class="w-100 imgbanner">
    </div>

    <img src="assets/bannertransparente.png" alt="" class="w-100 ">
    <div class="text-banner">
<!--      <button (click)="openWindow()">Versiones</button>-->
        <h1 class="tituloppal-banner animate__animated animate__zoomIn animate__delay-2s">{{bannerCampos.titulo}}</h1>
        <div class="second-text descripcion-banner animate__animated animate__zoomIn animate__delay-2s">
            <p [innerHTML]="bannerCampos.descripcion | dompurify">{{bannerCampos.descripcion}} </p>
        </div>
    </div>
</div>
