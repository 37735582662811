import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  private urlApi = environment.url;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient
  ) {
  }

  getEndpointByEnv(env: string): string {
    const currentEnv = (env === 'test') ? 'production' : 'production';
    return `https://${currentEnv}.wompi.co/v1`;
  }

  getTransactionById(idTransaction: string, env: string): Observable<any> {
    const endpoint = this.getEndpointByEnv(env);
    return this.http.get(`${endpoint}/transactions/${idTransaction}`, this.httpOptions);
  }

  createPayment(data: object): Observable<any> {
    return this.http.post(`${this.urlApi}pagos/create`, data, this.httpOptions);
  }
}
