import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpClientJsonpModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {HomeComponent} from './components/home/home.component';
import {BannerComponent} from './components/banner/banner.component';
import {SliderComponent} from './components/slider/slider.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NosotrosComponent} from './components/nosotros/nosotros.component';
import {ServiciosNoDigitalComponent} from './components/servicios-no-digital/servicios-no-digital.component';
import {FooterComponent} from './components/footer/footer.component';
import {TestimoniosComponent} from './components/testimonios/testimonios.component';
import {MapaComponent} from './components/mapa/mapa.component';
import {ProyectosComponent} from './components/proyectos/proyectos.component';
import {InicioComponent} from './components/phc-interno/inicio/inicio.component';
import {SidebarComponent} from './components/phc-interno/sidebar/sidebar.component';
import {ProyeccionesMercadoComponent} from './components/phc-interno/proyecciones-mercado/proyecciones-mercado.component';
import {HeaderGeneralComponent} from './components/phc-interno/header-general/header-general.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ModalesComponent} from './components/phc-interno/shared/modales/modales.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RequestInterceptor} from './interceptor/request.interceptor';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {MatDialogModule} from '@angular/material/dialog';


import {ScrollingModule} from '@angular/cdk/scrolling';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translate/', '.json');
}

import {DatePipe, registerLocaleData} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';


import {ModalMapaComponent} from './components/modal-mapa/modal-mapa.component';
import {LanguageInterceptor} from './interceptor/language.interceptor';
import {SobreNosotrosComponent} from './components/internas/sobre-nosotros/sobre-nosotros.component';
import {TrabajaconNosotrosComponent} from './components/internas/trabajacon-nosotros/trabajacon-nosotros.component';
import {NuestrosProyectosPHCComponent} from './components/internas/nuestros-proyectos-phc/nuestros-proyectos-phc.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {Grafica1Component} from './components/graficas-proyecciones-mercado/grafica1/grafica1.component';

import {Grafica3Component} from './components/graficas-proyecciones-mercado/grafica3/grafica3.component';
import {VersionesWindowComponent} from './components/windows/versiones-window/versiones-window.component';
import {Tabla1Component} from './components/graficas-proyecciones-mercado/tabla1/tabla1.component';
import {Tabla2Component} from './components/graficas-proyecciones-mercado/tabla2/tabla2.component';
import {Tabla3Component} from './components/graficas-proyecciones-mercado/tabla3/tabla3.component';
import {Tabla6Component} from './components/graficas-proyecciones-mercado/tabla6/tabla6.component';
import {Tabla7Component} from './components/graficas-proyecciones-mercado/tabla7/tabla7.component';
import {ComponenteInformeComponent} from './components/graficas-proyecciones-mercado/componente_informe/componente-informe.component';

import {ContactoComponent} from './components/internas/contacto/contacto.component';
import {PoliticasComponent} from './components/internas/politicas/politicas.component';
import {MomentosPHCComponent} from './components/internas/momentos-phc/momentos-phc.component';
import {Grafica4Component} from './components/graficas-proyecciones-mercado/grafica4/grafica4.component';
import {PhcMundoComponent} from './components/internas/phc-mundo/phc-mundo.component';
import {Tabla4Component} from './components/graficas-proyecciones-mercado/tabla4/tabla4.component';
import {Tabla5Component} from './components/graficas-proyecciones-mercado/tabla5/tabla5.component';
import {Chart6Component} from './components/graficas-proyecciones-mercado/chart6/chart.component';
import {Chart7Component} from './components/graficas-proyecciones-mercado/chart7/chart.component';
import {Chart9Component} from './components/graficas-proyecciones-mercado/chart9/chart.component';
import {Chart10Component} from './components/graficas-proyecciones-mercado/chart10/chart.component';
import {Chart11Component} from './components/graficas-proyecciones-mercado/chart11/chart.component';
import {Chart12Component} from './components/graficas-proyecciones-mercado/chart12/chart.component';
import {Chart13Component} from './components/graficas-proyecciones-mercado/chart13/chart.component';
import {Chart14Component} from './components/graficas-proyecciones-mercado/chart14/chart.component';
import {Chart15Component} from './components/graficas-proyecciones-mercado/chart15/chart.component';
import {Chart16Component} from './components/graficas-proyecciones-mercado/chart16/chart.component';
import {Chart17Component} from './components/graficas-proyecciones-mercado/chart17/chart.component';
import {Chart18Component} from './components/graficas-proyecciones-mercado/chart18/chart.component';
import {Grafica2Component} from './components/graficas-proyecciones-mercado/grafica2/grafica2.component';
import {InicioSesionComponent} from './components/inicio-sesion/inicio-sesion.component';
import {RegistrarseComponent} from './components/registrarse/registrarse.component';
import localeESCO from '@angular/common/locales/es-CO';
import localeEn from '@angular/common/locales/en';
import {CutHtmlStringPipe} from './pipes/cut-html-string.pipe';
import {DetallePagoComponent} from './components/detalle-pago/detalle-pago.component';
import {TerminosYCondicionesComponent} from './components/terminos-y-condiciones/terminos-y-condiciones.component';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {CustomDecimalPipe} from './pipes/custom-decimal.pipe';
import {ChangePasswordComponent} from './modals/change-password/change-password.component';
import {ConsultaUsuarioComponent} from './components/phc-interno/consulta-usuario/consulta-usuario.component';
import {RecaptchaModule, RecaptchaFormsModule} from "ng-recaptcha";
import {InfoSuscripcionComponent} from './components/phc-interno/info-suscripcion/info-suscripcion.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerInterceptor} from './interceptor/spinner.interceptor';
import {EstudiosConexionComponent} from './components/phc-interno/estudios-conexion/estudios-conexion.component';
import {InformePropuestaComponent} from './components/informe-propuesta/informe-propuesta.component';
import {ConozcoSectorComponent} from './components/phc-interno/conozco-sector/conozco-sector.component';
import {MapaConozcoSectorComponent} from './components/phc-interno/mapa-conozco-sector/mapa-conozco-sector.component';
import {ModalRegistroDatosComponent} from './components/modal-registro-datos/modal-registro-datos.component';
import {AsesoriaLineaComponent} from './components/phc-interno/asesoria-linea/asesoria-linea.component';
import { ModalEstudiosConexionComponent } from './components/modal-estudios-conexion/modal-estudios-conexion.component';
import { TrazabilidadProyectosComponent } from './components/phc-interno/trazabilidad-proyectos/trazabilidad-proyectos.component';
import { ProyectosDetalleComponent } from './components/phc-interno/proyectos-detalle/proyectos-detalle.component';
import { ModalProyectosComponent } from './components/phc-interno/modal-proyectos/modal-proyectos.component';
import { LogActividadesComponent } from './components/phc-interno/log-actividades/log-actividades.component';
import { FormularioEncuestaComponent } from './components/phc-interno/formulario-encuesta/formulario-encuesta.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

// Se agrega esto para el adaptador de datepicker que permita usar DD/MM/YYYY
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {CustomDateAdapter} from './components/phc-interno/conozco-sector/custom-adapter-datepicker';
import {DateAdapter} from '@angular/material/core';
import { ModalAsesoriaComponent } from './components/phc-interno/modal-asesoria/modal-asesoria.component';
import {NgDompurifyModule} from '@tinkoff/ng-dompurify';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { DetalleMomentoPhcComponent } from './components/internas/momentos-phc/detalle-momento-phc/detalle-momento-phc.component';
import { DetalleNuestroProyectoPhcComponent } from './components/internas/nuestros-proyectos-phc/detalle-nuestro-proyecto-phc/detalle-nuestro-proyecto-phc.component';
import { BnNgIdleService } from 'bn-ng-idle';

const language = localStorage.getItem('language');

if (language === 'es') {
  registerLocaleData(localeESCO);
} else {
  registerLocaleData(localeEn);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    BannerComponent,
    SliderComponent,
    NosotrosComponent,
    ServiciosNoDigitalComponent,
    FooterComponent,
    TestimoniosComponent,
    MapaComponent,
    ProyectosComponent,
    CutHtmlStringPipe,
    InicioComponent,
    SidebarComponent,
    ProyeccionesMercadoComponent,
    HeaderGeneralComponent,
    ModalesComponent,
    ModalMapaComponent,
    SobreNosotrosComponent,
    TrabajaconNosotrosComponent,
    NuestrosProyectosPHCComponent,
    Grafica1Component,
    Grafica2Component,
    Grafica3Component,
    VersionesWindowComponent,
    Tabla1Component,
    Tabla2Component,
    Tabla3Component,
    Tabla6Component,
    Tabla7Component,
    ContactoComponent,
    PoliticasComponent,
    MomentosPHCComponent,
    Grafica4Component,
    PhcMundoComponent,
    Tabla4Component,
    Tabla5Component,
    Chart6Component,
    Chart7Component,
    Chart9Component,
    Chart10Component,
    Chart11Component,
    Chart12Component,
    Chart13Component,
    Chart14Component,
    Chart15Component,
    Chart16Component,
    Chart17Component,
    Chart18Component,
    ComponenteInformeComponent,
    InicioSesionComponent,
    RegistrarseComponent,
    DetallePagoComponent,
    TerminosYCondicionesComponent,
    SafeUrlPipe,
    CustomDecimalPipe,
    ChangePasswordComponent,
    ConsultaUsuarioComponent,
    InfoSuscripcionComponent,
    EstudiosConexionComponent,
    InformePropuestaComponent,
    ConozcoSectorComponent,
    MapaConozcoSectorComponent,
    ModalRegistroDatosComponent,
    AsesoriaLineaComponent,
    ModalEstudiosConexionComponent,
    TrazabilidadProyectosComponent,
    ProyectosDetalleComponent,
    ModalProyectosComponent,
    LogActividadesComponent,
    FormularioEncuestaComponent,
    ModalAsesoriaComponent,
    DetalleMomentoPhcComponent,
    DetalleNuestroProyectoPhcComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatTabsModule,
    MatDialogModule,
    MatNativeDateModule,
    NgbModule,
    MatInputModule,
    MatSliderModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule,
    MatButtonModule,
    ScrollingModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatProgressBarModule,
    NgDompurifyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgxSpinnerModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyBootstrapModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: true,
    }),
  ],
  entryComponents: [
    ModalMapaComponent,
    ChangePasswordComponent,
    ConsultaUsuarioComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    DatePipe,
    // Se agrega esto para el adaptador de datepicker que permita usar DD/MM/YYYY
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: DateAdapter, useClass: CustomDateAdapter },

    {provide: LOCALE_ID, useValue: language === 'es' ? 'es-CO' : 'en'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
