import {Injectable} from '@angular/core';
import countriesJson from '../countries.json';
import statesJson from '../states.json';
import citiesJson from '../cities.json';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getCountries(): any {
    const countries = [];

    for (const country of countriesJson) {
      const obj: any = {};
      obj.id = country.id;
      obj.name = country.name;
      obj.latitude = country.latitude;
      obj.longitude = country.longitude;
      countries.push(obj);
    }

    return countries;
  }

  getStatesForCountryId(idCountry: number): any {
    return statesJson.filter(state => state.country_id == idCountry);
  }

  getCitiesForStateId(idState: number): any {
    return citiesJson.filter(city => city.state_id == idState);
  }

  getInfoCity(id: number): any {
    return citiesJson.filter(city => city.id == id);
  }

  getInfoState(id: number): any {
    return statesJson.filter(state => state.id == id);
  }

  getLatitudeAndLongitudeByAddress(address: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${this.mapKey}`;
    return this.httpClient.get(url).pipe(map((response: any) => {
      if ('results' in response && response.results.length && response.status === 'OK') {
        return response.results[0].geometry.location;
      }
    }));
  }

  getAddressByLatitudeAndLongitude(latitudeAndLongitude: string): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitudeAndLongitude}&key=${this.mapKey}`;
    return this.httpClient.get(url).pipe(map((response: any) => {
      if ('results' in response && response.results.length && response.status === 'OK') {
        return response.results[0].formatted_address;
      }
    }));
  }

  get mapKey(): string {
    const apiKeyMap = JSON.parse(localStorage.getItem('credenciales'));
    const keyDecript = AES.decrypt(apiKeyMap.mapKey, 'abcd@$--12');
    const apiKeyMapa = keyDecript.toString(CryptoJS.enc.Utf8);
    return apiKeyMapa.toString();
  }
}

