import {Component, OnInit, Renderer2} from '@angular/core';
import {OwlOptions, SlidesOutputData} from 'ngx-owl-carousel-o';
import {ServiciosNoDigitalService} from '../../services/servicios-no-digital.service'
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ModalDismissReasons, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { InicioSesionComponent } from '../inicio-sesion/inicio-sesion.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})

export class SliderComponent implements OnInit {
  datosServicio: any;
  iconos: any = [];
  carouselData: any = [];
  activeSlides: SlidesOutputData;
  claseEtiqueta: any;
  claseEtiquetaP: any;
  modalReference: NgbModalRef;
  closeResult = '';
  logged: any;
  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    items: 3,
    dotsSpeed: 500,
    dots: true,
    smartSpeed: 600,
    dragEndSpeed: 600,
    center: true,
    autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      640: {
        items: 1,
      },
      900: {
        items: 3
      }
    },
    // stagePadding: 40,
    nav: false
  }


  constructor(private translate: TranslateService, private renderer: Renderer2,
              private _ServiciosNoDigitalService: ServiciosNoDigitalService, private router: Router, private modalService: NgbModal,private authService:AuthService) {
    this.consultaServiciosDigitales();
  }

  ngOnInit(): void {

  }

  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
    const arrayKeys = this.activeSlides.slides.keys();
    for (let key of arrayKeys) {
      if (key == 0) {
        this.claseEtiqueta = 'anteriorEtiqueta';
      } else if (key == 2) {
        this.claseEtiquetaP = 'posteriorEtiqueta';
      }
    }
  }

  consultaServiciosDigitales() {
    let objeto = {
      nombre: "",
      digital: "si",
      estado: "activo"
    }
    this._ServiciosNoDigitalService.consultarServiciosNoDigitales(objeto).subscribe((data: any) => {
      this.datosServicio = data;
      for (const value of data) {
        const dataIconos = {
          id: value.id,
          url: value.url,
          title: value.nombre,
          img: this.urlS3 + "/" + value.icono,
          imgHover: this.urlS3 + "/" + value.icono
        };
        this.iconos.push(dataIconos);
        const dataCarousel = {
          id: value.id,
          url: value.url,
          descripcion: value.descripcion_corta,
          text: value.nombre,
          src: this.urlS3 + "/" + value.imagen
        };
        this.carouselData.push(dataCarousel);
      }
    });
  }

  routerOrPopup(url) {
    const arrayUrls = [];
    arrayUrls['proyecciones-de-mercado'] = 'proyecciones-de-mercado';
    arrayUrls['estudios-de-conexion'] = 'estudios-de-conexion';
    arrayUrls['asesoria-en-linea'] = 'asesoria-en-linea';
    arrayUrls['market-projections'] = 'proyecciones-de-mercado';
    arrayUrls['connection-studies'] = 'estudios-de-conexion';
    arrayUrls['online-counseling'] = 'asesoria-en-linea';
    arrayUrls['trazabilidad-de-proyectos'] = 'trazabilidad-de-proyectos';

    const urlNext = arrayUrls[url];

    if(urlNext == 'trazabilidad-de-proyectos'){
      this.logged = this.authService.isLoggedIn();
      if (!this.logged) {
        this.login(url)
      } else {
        this.router.navigate(['/inicio', urlNext]);
      }
    }else{
      if (urlNext) {
        this.router.navigate(['/inicio', urlNext]);
      } else {
        return;
      }
      }
  }

  login(url?) {
    this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.logged = this.authService.isLoggedIn();
      if (this.logged) {
        this.router.navigate(['/inicio', url]);
     }
    });
  }

  validateLoggin(){
    if (!this.isLoggedIn) {
      this.login()
    }else{
        this.router.navigate(['/inicio/trazabilidad-de-proyectos']);
      }
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  private getDismissReason(reason: any): string {
    this.ngOnInit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  get urlS3() {
    return environment.urlS3;
  }


}
