<div id="myModal" class="modal">
    <div class="row modal-rps">
        <div class="col-lg-6 col-sm-12">
            <div class="modal-content" *ngIf="!loading && !successPassword">
                <form *ngIf="getPassword == false" [formGroup]="firtsTimePasswordForm" (ngSubmit)="changePasswordFirtTime(formDir)" #formDir="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="exampleInputUser" class="labelForm"> {{"modificarContrasenaForm.opcion1" | translate }}</label>
                        <br>
                        <label for="exampleInputUser" class="labelForm" style="margin-top: 12px;"> {{"modificarContrasenaForm.opcion2" | translate }}</label>
                        <div class="position-relative">
                            <input [type]="fieldTextType ? 'password' : 'text'" class="form-control" value="" id="login-name" formControlName="digitarContrasena" minlength="8">
                            <mat-icon (click)="toggleFieldTextType()" class="iconoPassword cursor-pointer" *ngIf="fieldTextType">visibility_off</mat-icon>
                            <mat-icon (click)="toggleFieldTextType()" class="iconoPassword cursor-pointer" *ngIf="!fieldTextType">visibility</mat-icon>
                        </div>
                        <label for="login-name"></label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('required', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion1" | translate }}</label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('minlength', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion7" | translate }}</label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('pattern', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion8" | translate }}</label>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPass" class="labelForm">{{"modificarContrasenaForm.opcion3" | translate }}</label>
                        <div class="position-relative">
                            <input [type]="fieldTextType2 ? 'password' : 'text'" class="form-control" value="" id="login-pass" formControlName="confirmarContrasena" minlength="8">
                            <mat-icon (click)="toggleFieldTextType2()" class="iconoPassword cursor-pointer" *ngIf="fieldTextType2">visibility_off</mat-icon>
                            <mat-icon (click)="toggleFieldTextType2()" class="iconoPassword cursor-pointer" *ngIf="!fieldTextType2">visibility</mat-icon>
                        </div>
                        <label for="login-pass"></label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('required', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion1" | translate }}</label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('minlength', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion7" | translate }}</label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="firtsTimePasswordForm.hasError('pattern', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion8" | translate }}</label>
                        <br>
                        <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{contrasenaError}}</label>
                        <!--            <label class="text-danger p-messaje errorMessaje"-->
                        <!--                   style="font-size: 16px;">{{contrasenaExpresionRegular}}</label>-->
                    </div>
                    <div class="row btn-container">
                        <button type="submit" class="btn btn-form">{{"modificarContrasenaForm.opcion4" | translate }}</button>
                    </div>
                </form>
            </div>

            <div *ngIf="successPassword == true">
                <div class="modal-content" style="justify-content: center;align-items: center;text-align: center;">
                    <h3>{{"modificarContrasenaForm.opcion5" | translate }}</h3>
                    <button type="submit" class="btn btn-form" style="color: white;" (click)="changePasswordFirtTime('CerrarModal')">{{"modificarContrasenaForm.opcion7" | translate }}</button>
                </div>
                <div class="row btn-container">
                </div>
            </div>

            <div *ngIf="loading == true">
                <div class="modal-content" style="justify-content: center;align-items: center;text-align: center;">
                    <mat-spinner></mat-spinner>
                    <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
                </div>
                <div class="row btn-container">
                </div>
            </div>

        </div>
        <div class="col-lg-6 align-self-center text-center desaparecer-image">
            <img src="/assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
        </div>
    </div>
</div>