<div class="header-proyectos position-relative">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="new-proyectcss">
                    <h1 class="text-proyecto"> {{ "proyectosHeader.opcion1" | translate }} </h1>
                </div>
            </div>
        </div>
    </div>
    <div class="linea-custom pull-right"></div>
</div>
<div *ngIf="arrayProyectos && arrayProyectos.length > 0" class="container proyectos-section">
    <div class="row">
        <div class="col">
            <div class="all-slider">
                <!-- <div class="all-slider" data-aos="zoom-in"> -->
                <owl-carousel-o [options]="customOptions" #proyectos id="proyectos">

                    <ng-container *ngFor="let item of arrayProyectos" class="proyectos-dots">
                        <ng-template carouselSlide [id]="item.id" [width]="item.width">
                            <div class="slider item-proyecto">
                                <div class="row align-items-center">
                                    <div class="imagen-proyecto col-lg-6 col-sm-12">
                                        <img [src]="urlS3+'/'+item.archivo" alt="item.nombre" class="foto-proyecto">
                                        <!-- <img [src]="item.src" alt="item.text" class="foto-proyecto"> -->
                                    </div>
                                    <div class="texto-proyecto col-lg-6 col-sm-12">
                                        <p class="title">{{item.nombre}}</p>
                                        <div class="description" [innerHTML]="item.descripcion | cutHtmlString:350:true | dompurify"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="boton-center">
                                <button [routerLink]="['nuestrosProyectosPHC']" class="button-link btn-custom"> {{ "proyectosHeader.opcion2" | translate }} </button>
                            </div>
                        </ng-template>
                    </ng-container>

                </owl-carousel-o>
            </div>

        </div>
        <div class="position-absolute absolute-card">
            <img src="assets/fondo-proyectos.png" alt="">
        </div>
    </div>
    <div class="background">
        <div class="row">
            <!-- <div class="col absolute-card">
                <img src="assets/fondo-proyectos.png" alt="">
            </div> -->

            <!-- <div class="col maintittle">
                <h1 class="text-right text-proyecto">PROYECTOS</h1>

            </div> -->
        </div>


    </div>
    <!-- <div class="linea-custom"></div> -->
</div>


<!-- Modal -->
<!--<div class="modal fade modal-custom" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="cerrar-moda">
                <button type="button" class="close botonCerrar" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row align-items-center p-5 modal-middle">
                <div class="titulo-modal col-lg-6">
                    <h5 class="modal-title text-center" id="exampleModalLabel" data-aos="zoom-in" [(ngModel)]="proyectosDetalleModal.titulo">{{hola}}</h5>
                </div>
                <div class=" descripcion-modal col-lg-6">
                    <div class="modal-body text-justify">
                        <p [innerHTML]="proyectosDetalleModal.descripcion" data-aos="zoom-in"></p>
                        <br>
                        <label>Área de desempeño :</label>
                        <p [innerHTML]="proyectosDetalleModal.areaDesempeno" data-aos="zoom-in"></p>
                        <br>
                        <label>Nombre de participantes :</label>
                        <p [innerHTML]="proyectosDetalleModal.nombreParticipantes" data-aos="zoom-in"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

<ng-template #confirmar let-modal>

    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body p-3">
            <div class="row">
                <div class="col-6" style="margin-top: auto;
                margin-bottom: auto">
                    <p class="text-center">{{proyectosDetalleModal.titulo}}</p>
                </div>
                <div class="col-6">
                    <div class="modal-body text-justify">
                        <p [innerHTML]="proyectosDetalleModal.descripcion | dompurify" data-aos="zoom-in"></p>
                        <br>
                        <label>Área de desempeño :</label>
                        <p [innerHTML]="proyectosDetalleModal.areaDesempeno" data-aos="zoom-in"></p>
                        <br>
                        <label>Nombre de participantes :</label>
                        <p [innerHTML]="proyectosDetalleModal.nombreParticipantes" data-aos="zoom-in"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
