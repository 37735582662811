import {Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { HomeService } from 'src/app/services/home.service';
import { ServiciosNoDigitalService } from 'src/app/services/servicios-no-digital.service';
import { environment } from '../../../../environments/environment';
import { ModalAsesoriaComponent } from '../modal-asesoria/modal-asesoria.component';


@Component({
  selector: 'app-asesoria-linea',
  templateUrl: './asesoria-linea.component.html',
  styleUrls: ['./asesoria-linea.component.css']
})
export class AsesoriaLineaComponent implements OnInit {

  datosServicio: any;
  iconos: any = [];
  showSeconds: boolean;
  carouselData: any = [];
  activeSlides :  SlidesOutputData;
  modalReference: NgbModalRef;
  asesoria: string;
  customOptions: OwlOptions
  customOptions2: OwlOptions = {
    autoWidth: true,
    loop: true,
    items: 6,
    margin: 30,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    smartSpeed: 600,
    dragEndSpeed: 600,
    responsiveRefreshRate: 1000,
    center: true,
    // autoHeight: true,
    responsive:{
      0:{
          items: 1
      },
      400:{
          items: 2
      },
      600:{
          items: 4
      },
      1000:{
          items: 6
      }
  },
    // stagePadding: 40,
    nav: false
  }
  constructor(private _ServiciosNoDigitalService: ServiciosNoDigitalService,
     private router: Router, private modalService: NgbModal,
     private homeService: HomeService,
     private myVideo: ElementRef, private elementRef: ElementRef)
  {
    this.consultaServiciosDigitales()
      }

      ngOnInit(): void {
        setTimeout(() => {
          this.botonAsesoria();
          this.showSeconds = true;
        }, 500);
      }


      pararVideos(videoID){
        let video: any = this.myVideo.nativeElement.querySelector(`#${videoID}`);
        video.pause();
      }

      funcionNext(elemento){
        elemento.next();
        this.myVideo.nativeElement.querySelector(".owl-item.active.center video").pause();
      }

      funcionPrev(elemento){
        elemento.prev();
        this.myVideo.nativeElement.querySelector(".owl-item.active.center video").pause();
      }

  consultaServiciosDigitales(){
    this._ServiciosNoDigitalService.consultarAsesoria().subscribe((data: any)=>{
      this.datosServicio=data;

      this.customOptions = {
        autoWidth: false,
        loop: true,
        rewind: true,
        items: this.datosServicio.length,
        //dotsSpeed: 500,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        lazyLoad: false,
        responsiveRefreshRate: 1000,
        smartSpeed: 600,
        dragEndSpeed: 600,
        center: true,
        autoHeight: true,
        startPosition: -1,
        rtl: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          640: {
            items: 1,
          },
          900: {
            items: this.datosServicio.length -1
          }
        },
        // stagePadding: 40,
        nav: false
      }

      this.customOptions2 = {
        autoWidth: true,
        loop: true,
        items: this.datosServicio.length,
        margin: 30,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        lazyLoad: true,
        smartSpeed: 600,
        dragEndSpeed: 600,
        responsiveRefreshRate: 1000,
        center: true,
        // autoHeight: true,
        responsive:{
          0:{
              items: 1
          },
          400:{
              items: 2
          },
          600:{
              items: 4
          },
          1000:{
              items: this.datosServicio.length - 1
          }
      },
        // stagePadding: 40,
        nav: false
      }

    });
  }

  botonAsesoria(){
    this.homeService.botonAsesoria().subscribe((data:any)=>{
      this.asesoria = data.message;
    });
  }

  asesoriaLinea(servicio){
    const modalRef = this.modalService.open(ModalAsesoriaComponent);
    modalRef.componentInstance.servicio = servicio;
  }

  private getDismissReason(reason: any): string {
    this.ngOnInit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get urlS3() {
    return environment.urlS3;
}

}
