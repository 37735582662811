<div class="container-custom background-snd">

    <h1 class="text-center text-main">{{"serviciosNoDigital.titulo" | translate }}</h1>
    <div class="linea"></div>

    <div class="row img-servicios">
        <div class="col-lg-4 col-md-6 no-padding cursor-pointer" *ngFor="let dato of datosServicio, let i = index" (click)='modal(dato)' data-toggle="modal" data-target="#exampleModal">

            <div class="container-img" data-aos="zoom-in">
                <img [src]="urlS3+ '/' + dato.icono" alt="" class="w-100  pt-1 image">
                <div [ngClass]="i % 2 == 0 ? 'overlay' : 'overlay2' ">
                    <div class="text">
                        <h3>{{dato.nombre}}</h3>
                        <p>{{dato.descripcion_corta}}</p>
                    </div>
                </div>
            </div>

        </div>

        <!-- Modal -->
        <div class="modal fade modal-custom" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="cerrar-moda">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="row align-items-center p-5">
                        <div class="titulo-modal col-lg-6">
                            <h5 class="modal-title text-center" id="exampleModalLabel" data-aos="zoom-in">{{ nosotrosDetalleModal.titulo }}</h5>
                        </div>
                        <div class=" descripcion-modal col-lg-6">
                            <div class="modal-body text-justify">
                                <p [innerHTML]="nosotrosDetalleModal.descripcion_larga | dompurify" data-aos="zoom-in"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
