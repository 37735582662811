<app-header></app-header>

<div class="section-vacantes">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-vacantes">
                <h1 class="text-center text-main"> {{ "informacionTrabajaNosotros.titulo" | translate }} </h1>
                <div class="linea linea-azul"></div>
                <h3 *ngIf="trabajaConNosotrosDescripcion" class="pt-3 text-center description" [innerHTML]="trabajaConNosotrosDescripcion.descripcion | dompurify">
                </h3>
            </div>
        </div>
        <div class="row pd-contenido">
            <div class="col-lg-6">
                <form [formGroup]="vacantesForm" (ngSubmit)="onSubmit(formDir, formDirective)" #formDir="ngForm" #formDirective="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="nombres" class="labelForm"> {{ "trabajaNosotrosForm.opcion1" | translate }} </label>
                        <input type="text" class="form-control" id="nombres" formControlName="nombres">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('required', 'nombres') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('whitespace', 'nombres')">Ingrese un nombre válido</p>
                    </div>
                    <div class="form-group">
                        <label for="apellidos" class="labelForm">{{ "trabajaNosotrosForm.opcion2" | translate }}</label>
                        <input type="text" class="form-control" id="apellidos" formControlName="apellidos">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('required', 'apellidos') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('whitespace', 'apellidos')">Ingrese un apellido válido</p>
                    </div>
                    <div class="form-group">
                        <label for="correo" class="labelForm">{{ "trabajaNosotrosForm.opcion3" | translate }}</label>
                        <input type="email" class="form-control" id="correo" formControlName="correo">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('required', 'correo') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.get('correo').invalid && formDir.submitted ">{{ "formularioAvisos.opcion3" | translate }}</p>
                    </div>
                    <div class="form-group">
                        <label for="telefono" class="labelForm"> {{ "trabajaNosotrosForm.opcion9" | translate }}</label>
                        <input type="text" class="form-control" id="telefono" formControlName="telefono">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('required', 'telefono') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('pattern', 'telefono') && formDir.submitted">{{ "formularioAvisos.opcion2" | translate }}</p>
                    </div>
                    <div class="form-group">
                        <label for="cargo" class="labelForm"> {{ "trabajaNosotrosForm.opcion4" | translate }}</label>
                        <input type="text" class="form-control" id="cargo" formControlName="cargo">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('required', 'cargo') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="vacantesForm.hasError('whitespace', 'cargo')">Ingrese un cargo válido</p>

                    </div>
                    <div class="custom-file">
                        <label for="HV" class="labelForm">{{ "trabajaNosotrosForm.opcion5" | translate }}</label>
                        <input type="file" id="HV" formControlName="HV" #valorArchivo aria-describedby="HV" (change)="fileInputChange($event.target.files)" class="cursor-pointer custom-file-input" accept=".docx,.doc,.pdf" />
                        <label class="custom-file-label" [attr.data-after-content]="[buscarPalabra]">{{ "trabajaNosotrosForm.opcion6" | translate }}</label>
                        <p class="text-danger p-messaje errorMessaje mensaje-top" *ngIf="vacantesForm.hasError('required', 'HV') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje mt-2" *ngIf="uploadFileEvent">{{"errores.extensiones" | translate}}</p>
                    </div>
                    <div class="pt-4 col-lg-12 col-12 form-check">
                        <input class="form-check-input" type="checkbox" (change)="checkHabeasData($event.target.checked)" value="" formControlName="terminos" id="terminos">
                        <label class="form-check-label" for="terminos" *ngIf="habeasData">
                            <a *ngIf="politicasTratamiento" class="link-terminos" (click)="navigate($event,1)" target="_blank" > {{politicasTratamiento.titulo}} </a>
                        </label>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="this.checkedHabeasData == false || (vacantesForm.hasError('required', 'terminos') && formDir.submitted)">
                            {{"formularioAvisos.opcion1" | translate }}</p>
                    </div>
                    <!-- <p class="text-danger p-messaje errorMessaje mensaje-top message-archivo" *ngIf="tipoErrorForm"> {{mensajeErrorForm}} </p> -->
                    <div class="row btn-container">
                        <button type="submit" class="btn btn-form">{{ "trabajaNosotrosForm.opcion8" | translate }}</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-6">
                <div class="title-vacantes">
                    <h2 class="text-center"> {{ "vacantesInformacion.titulo" | translate }} </h2>
                </div>
                <cdk-virtual-scroll-viewport class="lista-vacantes" itemSize="50">
                    <div class="vacantes-disponibles" *ngFor="let vacante of arrayVacantes">
                        <div class="cajon">
                            <h2> {{ vacante.titulo }} </h2>
                            <span> {{ vacante.cargo }} </span>
                            <div class="descripcion">
                                <p> <span>{{ "vacantesInformacion.descripcion" | translate }} </span>
                                    <strong [innerHTML]="vacante.descripcion | dompurify"></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>

    </div>

</div>

<app-footer #footer></app-footer>