import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {graphicsColor} from '../../app.config';

const url = `${environment.url}grafica9/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#6FAC45', '#396BC1', '#ED7D30', '#FABA04', '#5698D5', '#224175', '#A04C13', '#636363', '#8C6300', graphicsColor.glp],
          tooltip: {
            trigger: 'axis',
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) => el.year),
          },
          grid: {
            top: 80,
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            padding: [0, 0, 0, 0],
            top: '0%',
            data: this.lenguaje == 'en' ? [
              'Guajira Coal',
              'Gecelca Coal',
              'Internal Coal',
              'LNG Coast',
              'LNG Interior',
              'Coast Gas',
              'Coast Gas',
              'Internal Gas',
              'Diesel',
              'Fuel Oil',
              'LGP',
            ] : [              
            'Carbón Guajira',
            'Carbón Gecelca',
            'Carbón Interior',
            'GNL Costa',
            'GNL Interior',
            'Gas Costa',
            'Gas Interior',
            'Diesel',
            'Fuel Oil',
            'GLP',
          ],
          },
          series: [
            {
              name: this.lenguaje == 'en' ? 'Guajira Coal' : 'Carbón Guajira',
              data: data.map((el) => parseFloat(el.guajira_coal.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#6FAC45',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Gecelca Coal' : 'Carbón Gecelca',
              data: data.map((el) => parseFloat(el.gecelca_coal.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#396BC1',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Internal Coal' : 'Carbón Interior',
              data: data.map((el) => parseFloat(el.internal_coal.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#ED7D30',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'LNG Coast' : 'GNL Costa',
              data: data.map((el) => parseFloat(el.lng_costa.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#FABA04',
              },
            },

            {
              name: this.lenguaje == 'en' ? 'LNG Interior' : 'GNL Interior',
              data: data.map((el) => parseFloat(el.lng_interior.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#5698D5',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Coast Gas' : 'Gas Costa',
              data: data.map((el) => parseFloat(el.coast_gas.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#224175',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Internal Gas' : 'Gas Interior',
              data: data.map((el) => parseFloat(el.internal_gas.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#A04C13',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Diesel' : 'Diesel',
              data: data.map((el) => parseFloat(el.diesel.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#636363',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'Fuel Oil' : 'Fuel Oil',
              data: data.map((el) => parseFloat(el.fuel_oil.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#8C6300',
              },
            },
            {
              name: this.lenguaje == 'en' ? 'LGP' : 'GLP',
              data: data.map((el) => parseFloat(el.glp.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: graphicsColor.glp,
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
