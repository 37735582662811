import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GraficasService } from "../../../services/graficas.service";

@Component({
    selector: 'app-tabla6',
    templateUrl: './tabla6.component.html',
    styleUrls: ['./tabla6.component.scss'],
    providers: []
})
export class Tabla6Component implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  escenario;
  @Input()
  historico = false;
    // Parametros para buscador
    response: any;
    constructor(
        private graficasService: GraficasService
    ) { }


    ngOnInit() {

        this.getTabla3();
    }

    ngAfterViewInit() { }

    ngOnDestroy() { }

    getTabla3() {

        this.graficasService.consultatablas(this.escenario, 'tabla_6').subscribe(data => {
            if (data) {
                this.response = data
            } else {
                alert("Consulta sin resultados");
            }
        });


    }
}
