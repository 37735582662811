import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {EChartOption} from 'echarts';
import {GraficasService} from '../../../services/graficas.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CutHtmlStringPipe} from 'src/app/pipes/cut-html-string.pipe';

@Component({
  selector: 'app-grafica3',
  templateUrl: './grafica3.component.html',
  styleUrls: ['./grafica3.component.css']
})
export class Grafica3Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  data = [];
  title: any;
  leyenda1: any;
  leyenda2: any;
  chartOption: EChartOption;
  lenguaje = ""

  constructor(private graficasService: GraficasService, private _sanitizer: DomSanitizer, private renderer: Renderer2) {
    this.lenguaje = localStorage.getItem('language');
  }

  descriptionCut(text: string): string {
    const cutHtmlStringPipe = new CutHtmlStringPipe(this.renderer);
    const cutText = cutHtmlStringPipe.transform(text, 0);
    //
    return cutText;
  }

  ngOnInit() {
    this.title = this.parsearLocal.nombre_grafica_3;
    this.leyenda1 = this.parsearLocal.leyenda_1_grafico_3;
    this.leyenda2 = this.parsearLocal.leyenda_2_grafico_3;
    const markLine = [];

    markLine.push([
      {
        name: this.descriptionCut(this.parsearLocal.leyenda_1_grafico_3),
        // coord: [210, 1395],
        coord: this.coordenada1Grafico3 ? this.coordenada1Grafico3.split(',').map(coordenada => parseFloat(coordenada)) : null,
        label: {
          color: 'red',
          padding: -27 // baja el texto leyenda 1 grafica 3
        },
      },
      {
        // coord: [184, 150],
        coord: this.coordenada2Grafico3 ? this.coordenada2Grafico3.split(',').map(coordenada => parseFloat(coordenada)) : null,
      },
    ]);
    this.graficasService.consultarGrafica3(this.escenario).subscribe((Response: any) => {
      const coordenadaLeyenda2Grafico3 = this.coordenadaLeyenda2Grafico3.split(',');

      this.chartOption = {
        // title: {
        //     text: 'Precio promedio de Contratos COP/kWh'
        // },

        color: ['#2c7eb0', '#F82E03', '#1EE80A'],

        tooltip: {
          trigger: 'axis',

        },
        legend: {
          icon: 'rectangle',
          // center: ['10%', '10%'],
          // orient: 'vertical',
          // textStyle: {
          //     fontSize: '12',
          // },
          // x: 'right',
          y: '0px',
          // left: '10%',
          // margin: '10%',
          data: this.lenguaje == "es" ? ['PB', 'Fenomeno del Niño', 'Fenomeno del Niña'] : ['SP', '"Niño" phenomenon', '"Niña" phenomenon']
        },
        xAxis: {
          type: 'category',
          rotate: 90,
          splitLine: {
            show: false
          },
          data: Response.headers
        },
        yAxis: {
          type: 'value',
          name: this.lenguaje == "es" ? 'Precio de bolsa [COP/kWh]' : 'Spot Price [COP/kWh]',
          nameLocation: 'middle',
          nameTextStyle: {
            padding: 30
          },
        },
        series: [
          {
            name: this.lenguaje == "es" ? 'PB' : 'SP',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: Response.pb,
            markArea: {
              data: markLine,
              itemStyle: {
                color: 'white',
                borderColor: 'red',
                borderWidth: 1,
              },
            },
          },
          {
            data: Response.fenomeno_nino,
            name: this.lenguaje == "es" ? 'Fenomeno del Niño' : '"Niño" phenomenon',
            type: 'line',
            symbol: 'circle',
            hoverAnimation: false,
            lineStyle: {
              width: 5,
              type: 'dotted',
              color: '#F82E03',
            },
            markPoint: {
              symbol: false,
              symbolSize: 1,
              label: {
                formatter: this.descriptionCut(this.parsearLocal.leyenda_2_grafico_3),
                color: 'red',
                type: 'solid',
              },
              itemStyle: {
                color: 'red',
                type: 'solid',
              },
              data: [
                {x: coordenadaLeyenda2Grafico3[0], y: coordenadaLeyenda2Grafico3[1]},
              ],
            },
          }
          ,
          {
            data: Response.fenomeno_nina,
            name: this.lenguaje == "es" ? 'Fenomeno del Niña' : '"Niña" phenomenon',
            type: 'line',

            hoverAnimation: false,

            lineStyle: {
              width: 5,
              type: 'dotted',
              color: '#1EE80A',
            }
          }

        ],
        dataZoom: [
          {
            start: 0,
            type: 'inside'
          },
          {
            start: 0
          }
        ],
        grid: {
          left: '15%',
        },
      };
    });
  }

  randomData(now, oneDay, value) {
    let now2 = new Date(+now + oneDay);
    let value2 = value + Math.random() * 21 - 10;
    return {
      value: [
        [now2.getFullYear(), now2.getMonth() + 1, now2.getDate()].join('/'),
        Math.round(value2)
      ]
    };
  }

  get parsearLocal(): any {
    return JSON.parse(localStorage.getItem('datainforme'));
  }

  get coordenada1Grafico3(): string {
    return this.parsearLocal.coordenada_1_grafico_3 ? this.parsearLocal.coordenada_1_grafico_3.replace(/\s/, '') : '';
  }

  get coordenada2Grafico3(): string {
    return this.parsearLocal.coordenada_2_grafico_3 ? this.parsearLocal.coordenada_2_grafico_3.replace(/\s/, '') : '';
  }

  get coordenadaLeyenda2Grafico3(): string {
    return this.parsearLocal.coord_leyenda_2_grafico_3 ? this.parsearLocal.coord_leyenda_2_grafico_3.replace(/\s/, '') : '';
  }
}
