import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica17/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#4472C4', '#EC7524', '#28AD4A'],
          values: data,
          tooltip: {
            trigger: 'axis',
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) => el.anio),
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            data: this.lenguaje == 'es' ? [
              'PB promedio (100 Series)',
              'P. Contratos MR',
              'P. Contratos MNR',
            ] :  [
              'Average SP (100 Series)',
              'P. MR Contracts',
              'P. MNR Contracts',
            ],
          },
          series: [
            {
              name: this.lenguaje == 'es' ? 'PB promedio (100 Series)' : 'Average SP (100 Series)',
              data: data.map((el) => parseFloat(el.valor_promedio.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#4472C4',
              },
            },
            {
              name: this.lenguaje == 'es' ? 'P. Contratos MR' : 'P. MR Contracts',
              data: data.map((el) => parseFloat(el.contratos_sup.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#EC7524',
              },
            },
            {
              name: this.lenguaje == 'es' ? 'P. Contratos MNR' : 'P. MNR Contracts',
              data: data.map((el) => parseFloat(el.contratos_inf.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#28AD4A',
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
