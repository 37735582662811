
    <div id="myModal" class="modal modal-datalocal">
        <div class="row mt-3 mr-3">
            <div class="col-12">
                <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal()" >
            </div>
        </div>
        <div class="row modal-rps">
            <div class="col-lg-6 col-sm-12">
                <div class="modal-content">
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">
                        <div class="form-group">
                            <label for="nombre" class="labelForm"> {{"proyeccionesForm.opcion1" | translate }} </label>
                            <input type="text" class="form-control" id="nombre" formControlName="nombre">
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                        </div>
                        <div class="form-group">
                            <label for="apellido" class="labelForm">{{"proyeccionesForm.opcion2" | translate }} </label>
                            <input type="text" class="form-control" id="apellido" formControlName="apellido">
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'apellido') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                        </div>
                        <div class="form-group">
                            <label for="empresa" class="labelForm">{{"proyeccionesForm.opcion3" | translate }} </label>
                            <input type="text" class="form-control" id="empresa" formControlName="empresa">
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'empresa') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                        </div>
                        <div class="form-group">
                            <label for="telefono" class="labelForm">{{"proyeccionesForm.opcion4" | translate }} </label>
                            <input type="text" class="form-control" id="telefono" formControlName="telefono">
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'telefono') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('pattern', 'telefono') && formDir.submitted">{{"formularioAvisos.opcion2" | translate }}</p>
                        </div>
                        <div class="form-group">
                            <label for="correo" class="labelForm">{{"proyeccionesForm.opcion5" | translate }} </label>
                            <input type="email" class="form-control" id="correo" formControlName="correo">
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'correo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                            <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.get('correo').invalid && formDir.submitted ">{{"formularioAvisos.opcion3" | translate }}</p>
                        </div>
                        <div class="row btn-container">
                            <button type="submit" class="btn btn-form">{{"proyeccionesForm.opcion6" | translate }} </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 align-self-center text-center desaparecer-image">
                <img src="assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
            </div>
        </div>

    </div>
