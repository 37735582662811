<div class="informe-generalizado" *ngIf="response">
  <div id="vision" class="first-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
    <div *ngIf="nameEmpresa" class="marca_agua-emprese">
      {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
    </div>
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>
   
      <a class="btn float-right" id="downloadButton" [attr.href]="urlPDF" target="_blank" (click)="descargarPDF()"
         *ngIf="fullView && isLoggedIn">Descargar PDF</a>
	 <a class="btn float-right" id="downloadButton" (click)="descargarExcel()"  target="_blank" 
        *ngIf="fullView && isLoggedIn">Descargar Excel </a>
      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-main">
      <div class="alerta-responsivaa alert ml-5 mr-5 mt-5 p-5" role="alert "
           style="background-color: midnightblue; color: white; ">
        <p [innerHTML]="response.parrafo_1"></p>
      </div>

      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4">
          <div class="col-lg-12 center-block">
            <p style="border-left:forestgreen; border-left-style: solid;margin-bottom: 15px;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.titulo_graficas_1"></span>
            </p>
          </div>
        </div>
        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12 center-block">
            <div class="pl-4">
              <p>
                <app-grafica1 [escenario]="escenario">
                </app-grafica1>
              </p>
              <p>
                <app-tabla1 [escenario]="escenario">
                </app-tabla1>
              </p>
              <p>
                <app-grafica3 [escenario]="escenario">
                </app-grafica3>
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 center-block ">
            <p>
              <app-grafica2 [escenario]="escenario">
              </app-grafica2>
            </p>
            <div class="alert" role="alert " style="background-color: darkgray; color: white;margin-top: 57px; ">
              <h4 class="alert-heading " [innerHTML]="response.parrafo_2"></h4>
            </div>
            <p>
              <app-grafica4 [escenario]="escenario">
              </app-grafica4>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="preciosBolsa" class="second-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4 ">
          <div class="col-lg-6 col-md-12 center-block">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_1"> </span>
            </p>
`            <p class="pl-4" [innerHTML]="response.parrafo_2_1">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_2"></span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_3">
              </p>

              <p [innerHTML]="response.parrafo_4">
              </p>

              <p [innerHTML]="response.parrafo_5">
              </p>

              <p [innerHTML]="response.parrafo_6">
              </p>
            </div>

            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_3"></span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_7">
            </p>

          </div>

          <div class="col-lg-6 col-md-12 center-block ">

            <p [innerHTML]="response.parrafo_8">
            </p>

            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_4"></span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_9">
              </p>

              <p [innerHTML]="response.parrafo_10">
              </p>

              <div style=" margin-left: 10px;">
                <div class="text-center">
                  <b [innerHTML]="response.fuente_grafica_5"> </b>
                </div>
                <img [src]="urlS3+ '/static/' + response.grafica_5" alt="grafica_5" class="w-100">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="third-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div>
          <app-chart6 [escenario]="escenario">
          </app-chart6>
          <div class="text-center" style="margin-top:25px;padding-bottom: 30px;">
            <b [innerHTML]="response.fuente_grafica_6"></b>
          </div>
        </div>

        <div class="row ml-4 mr-4 ">
          <div class="col-lg-6 col-md-12 center-block">
            <p [innerHTML]="response.parrafo_11">
            </p>
            <p [innerHTML]="response.parrafo_12">
            </p>
          </div>
          <div class="col-lg-6 col-md-12 center-block ">
            <div style=" margin-left: 10px;">

              <app-chart7 [escenario]="escenario">
              </app-chart7>
            </div>
            <div style=" margin-left: 10px;"></div>
          </div>
        </div>
        <div class="row ml-4 mr-4 ">
          <div class="col-lg-12">
            <div class="text-center" style="padding-bottom: 10px;">
              <b [innerHTML]="response.titulo_tabla_2"> </b>
            </div>
            <app-tabla2 [escenario]="escenario">
            </app-tabla2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fourth-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4 ">
          <div class="col-lg-12 pb-3">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style="padding-left: 10px;" [innerHTML]="response.subtitulo_5"></span>
            </p>
          </div>
        </div>
        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12 center-block">
            <div class="pl-4">
              <div class="alert" role="alert " style="background-color: darkgray; color: white; ">
                <h4 class="alert-heading " [innerHTML]="response.parrafo_13"></h4>
              </div>
              <div style=" margin-left: 10px;">
                <div class="text-center">
                  <b [innerHTML]="response.fuente_grafica_8"></b>
                </div>
                <img [src]="urlS3+ '/static/' + response.grafica_8" alt="grafica_8" class="w-100">
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 center-block ">
            <p>
              <app-tabla3 [escenario]="escenario"></app-tabla3>
            </p>
            <p>
              <app-chart9 [escenario]="escenario">
              </app-chart9>
            </p>
          </div>
        </div>
        <div class="container-fluid">
          <div class="lineazul-informe"></div>
        </div>

        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12  center-block">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style="padding-left: 10px;" [innerHTML]="response.titulo_grafico_10"></span>
            </p>
            <p class="pl-4">
              <app-chart10 [escenario]="escenario"></app-chart10>
            </p>
          </div>

          <div class="col-lg-6 col-md-12 align-self-center center-block ">
            <div class="alert" role="alert " style="background-color: darkgray; color: white; margin-top: 15px;">
              <h4 class="alert-heading " [innerHTML]="response.parrafo_14">
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="supuestos" class="five-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4 ">
          <div class="col-lg-12">
            <app-chart11 [escenario]="escenario">
            </app-chart11>
          </div>
        </div>

        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12 center-block">
            <p>
              <app-chart12 [escenario]="escenario"></app-chart12>
            </p>
            <div class="alert" role="alert " style="background-color: darkgray; color: white; ">
              <h4 class="alert-heading " [innerHTML]="response.parrafo_15">
              </h4>
            </div>

            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style="padding-left: 10px;" [innerHTML]="response.subtitulo_6"></span>
            </p>
            <p class="pl-4">
              <app-tabla4 [escenario]="escenario"></app-tabla4>
            </p>
          </div>

          <div class="col-lg-6 col-md-12 center-block ">
            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style="padding-left: 10px;" [innerHTML]="response.subtitulo_7"></span>
            </p>
            <div class="pl-4">
              <p>
                <app-tabla5 [escenario]="escenario"></app-tabla5>
              </p>

              <div class="alert" role="alert " style="background-color: darkgray; color: white; ">
                <h4 class="alert-heading " [innerHTML]="response.parrafo_16">
                </h4>
              </div>
            </div>
            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style="padding-left: 10px;" [innerHTML]="response.subtitulo_8"></span>
            </p>

            <p class="pl-4">
              <app-tabla6 [escenario]="escenario"></app-tabla6>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullView" class="six-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12 center-block">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_9"></span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_17">
              </p>
              <p [innerHTML]="response.parrafo_18">
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 center-block ">
            <p>
              <span style=" margin-left: 10px;"></span>
            </p>
            <p [innerHTML]="response.parrafo_19">
            </p>

            <div class="text-center">
              <b [innerHTML]="response.titulo_tabla_7"></b>
            </div>
            <app-tabla7 [escenario]="escenario">
            </app-tabla7>
            <app-chart13 [escenario]="escenario">
            </app-chart13>
            <div class="text-center">
              <b [innerHTML]="response.texto_grafica_13"></b>
            </div>

          </div>
        </div>

        <div class="container-fluid">
          <div class="lineazul-informe"></div>
        </div>

        <div class="row ml-4 mr-4 ">
          <div class="col-lg-6 col-md-12 center-block">
            <p style="border-left:blue; border-left-style: solid;" class="palillo-azul">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_10"></span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_20">
            </p>
          </div>
          <div class="col-lg-6 col-md-12 center-block ">
            <div>
              <app-chart14 [escenario]="escenario">
              </app-chart14>
              <div class="text-center">
                <b [innerHTML]="response.texto_grafica_14"></b>
              </div>
            </div>
          </div>
          <div class="col-lg-12 center-block">
            <div style="margin: 30px 0px; text-align: justify;" [innerHTML]="response.parrafo_21">
            </div>
          </div>
        </div>

        <div class="row ml-4 mr-4 ">
          <div class="col-lg-12 center-block">
            <p [innerHTML]="response.parrafo_22">
            </p>
          </div>
          <div class="col-lg-12 center-block ">
            <app-chart15 [escenario]="escenario">
            </app-chart15>
            <div class="text-center">
              <b [innerHTML]="response.texto_grafica_15"></b>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="fullView" id="preciosContrato" class="seven-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4 ">
          <div class="col-lg-12 center-block">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_11">
                        </span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_23">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_12"></span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_24">
              </p>

              <div>
                <app-chart16 [escenario]="escenario">
                </app-chart16>
                <div class="text-center">
                  <b [innerHTML]="response.texto_grafica_16"></b>
                </div>

              </div>

              <p [innerHTML]="response.parrafo_25">
              </p>
            </div>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_20">
                        </span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_26">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_13"></span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_27">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_14"></span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_28">
            </p>

          </div>

          <div class="col-lg-12 center-block ">
            <app-chart17 [escenario]="escenario"></app-chart17>
            <div class="text-center">
              <b [innerHTML]="response.texto_grafica_17"></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullView" id="preciosEscasez" class="eigth-page pages-border" [ngClass]="{'margin-fullView': fullView}">
    <div class="menu-repeat">
      <div class="d-flex flex-row bd-highlight">
        <div class="p-2 bd-highlight"></div>
        <div class="subtitle-ppal p-4 ml-5 bd-highlight font-weight-bold" style="color: darkgrey;"
             [innerHTML]="response.titulo_general"></div>
        <div class="p-2 bd-highlight"></div>
      </div>

      <div class="container-fluid">
        <div class="row titulo-cinfor mr-4">
          <div class="d-inline p-3 text-white " style="background-color: midnightblue; ">
            <p></p>
          </div>
          <div class="p-1 ml-4 col-6 titulo-ppalinforme">
            <h2 [innerHTML]="response.subtitulo_general"></h2>
          </div>
          <div class="p-2 col-6 col-sm-12"></div>
        </div>
      </div>
      <br>

      <div class="container container-rpinfo">
        <div class="row ml-5 mr-5 titulosSecciones">
          <div class="col-sm center-block titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'vision'" [innerHTML]="response.titulo_seccion_1"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'supuestos'"
               [innerHTML]="response.titulo_seccion_2"> </a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" [routerLink]="" [fragment]="'preciosBolsa'"
               [innerHTML]="response.titulo_seccion_3"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosContrato')" [routerLink]=""
               [fragment]="'preciosContrato'" [innerHTML]="response.titulo_seccion_4"></a>
          </div>
          <div class="col-sm center-block  titles">
            <a class="cursor-pointer" (click)="revisar(fullView,'preciosEscasez')" [routerLink]=""
               [fragment]="'preciosEscasez'" [innerHTML]="response.titulo_seccion_5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-main">
      <img class="marca_agua-img" src="assets/logo phc@2x.png" alt="logo_marca">
      <div *ngIf="nameEmpresa" class="marca_agua-emprese">
        {{"proyeccionesTabulador.titulo5" | translate }} {{ nameEmpresa }}
      </div>
      <div class="container-fluid pt-5 pb-5">
        <div class="row ml-4 mr-4">
          <div class="col-lg-6 col-md-12 center-block">
            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_15">
                    </span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_29">
              </p>

              <p>
                <b [innerHTML]="response.titulo_figura_1"></b>
              </p>
              <p>
                <img [src]="urlS3+ '/static/' + response.figura_1" alt="figura_1" class="w-100">
              </p>
              <p [innerHTML]="response.parrafo_30">
              </p>
            </div>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_16">
                    </span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_31">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_17">
                    </span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_32">
              </p>

              <div class="text-center">
                <b [innerHTML]="response.texto_figura_2"></b>
              </div>
              <img [src]="urlS3+ '/static/' + response.figura_2" alt="figura_2" class="w-100">
            </div>
          </div>

          <div class="col-lg-6 col-md-12 center-block">

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
                        <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_18">
                        </span>
            </p>
            <p class="pl-4" [innerHTML]="response.parrafo_33">
            </p>

            <p style="border-left:forestgreen; border-left-style: solid;" class="palillo-verde">
              <span style=" margin-left: 10px;" [innerHTML]="response.subtitulo_19"></span>
            </p>
            <div class="pl-4">
              <p [innerHTML]="response.parrafo_34">
              </p>
              <app-chart18 [escenario]="escenario">
              </app-chart18>
              <div class="text-center">
                <b [innerHTML]="response.texto_grafica_18"></b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
