import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {SobreNosotrosComponent} from './components/internas/sobre-nosotros/sobre-nosotros.component';
import {ContactoComponent} from './components/internas/contacto/contacto.component';
import {TrabajaconNosotrosComponent} from './components/internas/trabajacon-nosotros/trabajacon-nosotros.component';
import {InicioComponent} from './components/phc-interno/inicio/inicio.component';
import {NuestrosProyectosPHCComponent} from './components/internas/nuestros-proyectos-phc/nuestros-proyectos-phc.component';
import {VersionesWindowComponent} from './components/windows/versiones-window/versiones-window.component';
import {ComponenteInformeComponent} from './components/graficas-proyecciones-mercado/componente_informe/componente-informe.component';
import {PoliticasComponent} from './components/internas/politicas/politicas.component';
import {PhcMundoComponent} from './components/internas/phc-mundo/phc-mundo.component';
import {MomentosPHCComponent} from './components/internas/momentos-phc/momentos-phc.component';
import {InicioSesionComponent} from './components/inicio-sesion/inicio-sesion.component';
import {RegistrarseComponent} from './components/registrarse/registrarse.component';
import {DetallePagoComponent} from './components/detalle-pago/detalle-pago.component';
import { TerminosYCondicionesComponent } from './components/terminos-y-condiciones/terminos-y-condiciones.component';
import { ModalRegistroDatosComponent } from './components/modal-registro-datos/modal-registro-datos.component';
import {InformePropuestaComponent} from './components/informe-propuesta/informe-propuesta.component';
import {MapaConozcoSectorComponent} from './components/phc-interno/mapa-conozco-sector/mapa-conozco-sector.component';
import {DetalleMomentoPhcComponent} from './components/internas/momentos-phc/detalle-momento-phc/detalle-momento-phc.component';
import {DetalleNuestroProyectoPhcComponent} from './components/internas/nuestros-proyectos-phc/detalle-nuestro-proyecto-phc/detalle-nuestro-proyecto-phc.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'registrarse', component: RegistrarseComponent},
  {path: 'iniciar-sesion', component: InicioSesionComponent},
  {path: 'terminos-y-condiciones/:id', component: TerminosYCondicionesComponent},
  {path: 'nuestrosProyectosPHC', component: NuestrosProyectosPHCComponent},
  {path: 'nuestrosProyectosPHC/:title/:id', component: DetalleNuestroProyectoPhcComponent},
  {path: 'sobre-nosotros', component: SobreNosotrosComponent},
  {path: 'PHC-en-el-mundo', component: PhcMundoComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'trabaja-con-nosotros', component: TrabajaconNosotrosComponent},
  {path: 'componente-informe', component: ComponenteInformeComponent},
  {path: 'politicas', component: PoliticasComponent},
  {path: 'momentosPHC', component: MomentosPHCComponent},
  {path: 'momentosPHC/:title/:id', component: DetalleMomentoPhcComponent},
  {path: 'detalle-pago', component: DetallePagoComponent},
  {path: 'informe-propuesta', component: InformePropuestaComponent},
  {path: 'mapa-propuesta', component: MapaConozcoSectorComponent},
  {path: 'modal-propuesta', component: ModalRegistroDatosComponent},
  {
    path: 'inicio',
    children: [
      {
        path: '',
        component: InicioComponent,
        loadChildren: () => import('./components/phc-interno/inicio/inicio.module').then(m => m.InicioModule)
      },
    ],
  },
  {path: '**', redirectTo: ''},
  {path: 'versiones', component: VersionesWindowComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
