import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from 'src/app/services/auth.service';
import {InternasServiceService} from 'src/app/services/internas-service.service';
//alertas
import Swal from 'sweetalert2'

@Component({
  selector: 'app-modal-proyectos',
  templateUrl: './modal-proyectos.component.html',
  styleUrls: ['./modal-proyectos.component.css']
})
export class ModalProyectosComponent implements OnInit {

  buscarPalabra: any;
  uploadFileEvent: any;
  urlFileDownload: any;
  fileParaSubir: any;
  popupInformacion: FormGroup;
  modalReference: NgbModalRef;
  mostrarTareasCampos: boolean;
  deshabilitarSubmit: boolean = false;
  tareaCerrada: boolean = false;
  trueLenguage: any;
  falseLenguage: any;
  estadosArray = [];
  comentarios: any = [];
  facturas: any = [];
  adjuntos: any = [];
  editarComment: boolean = false;
  comentarioEditar: any;
  recientesComentos: boolean = false;
  @Input() popUp: any;
  @Input() fases: any;
  @Input() objetoCompleto: any;
  constructor(private ngbActiveModal: NgbActiveModal, private _builder: FormBuilder,
    private modalService: NgbModal, private _snackBar: MatSnackBar,
    private internaService: InternasServiceService, private authService: AuthService) { }

  ngOnInit(): void {

    const id = this.objetoCompleto.detalle.estado.id;

    this.internaService.obtenerEstadosPorEstadoActualProyecto(id)
        .subscribe( (respuesta: any) => {
          this.estadosArray  = respuesta;
        });

    this.popUp == 'tareas' ? this.mostrarTareasCampos = true : this.mostrarTareasCampos = false;

    this.popupInformacion = this._builder.group({
      id_tarea_detalle: new FormControl(this.objetoCompleto != undefined && this.popUp == 'tareas' ? this.objetoCompleto.detalle.id : ''),
      id_etapa_detalle: new FormControl(this.objetoCompleto != undefined && this.popUp == 'etapas' ? this.objetoCompleto.detalle.id : ''),
      comentarios: new FormControl(''),
      archivo: new FormControl(''),
      horasEjecutadas: new FormControl('', [Validators.min(0), Validators.pattern('^[0-9]*$')]),
      estadoTarea: new FormControl(this.objetoCompleto != undefined && this.popUp == 'tareas' ? this.objetoCompleto.detalle.estado['id'] : ''),
    });

    //Revisar si tiene estado cancelado o finalizado
    this.verificarEstados();

    if (localStorage.getItem('language') == 'en') {
      this.buscarPalabra = 'Search';
      this.trueLenguage = 'YES'
      this.falseLenguage = 'NO'
    } else {
      this.trueLenguage = 'SI'
      this.falseLenguage = 'NO'
      this.buscarPalabra = 'Buscar';
    }

    this.getComentariosAndAdjuntos();
    this.mostrarFacturas();
  }

  verificarEstados(){

    if(this.popUp == 'tareas'){

      let idTarea = this.objetoCompleto.detalle.etapa_detalle;
      let estadoEtapa = this.fases.find( (detalle) => detalle.detalle.id == idTarea);

      if(this.objetoCompleto.detalle.estado['id'] == 3 || this.objetoCompleto.detalle.estado['id'] == 4 ){
        this.popupInformacion.controls['horasEjecutadas'].disable();
        this.popupInformacion.controls['estadoTarea'].disable();
        this.tareaCerrada = true;
      }else {
        this.tareaCerrada = false;
        this.popupInformacion.controls['horasEjecutadas'].enable();
        this.popupInformacion.controls['archivo'].enable();
      }
    }

  }

  closeModal(instancia){
    this.ngbActiveModal.close(instancia);
  }

  fileInputChange(evento) {
    this.fileParaSubir = evento[0];
    document.querySelector('.label-new').innerHTML = this.fileParaSubir.name;

    let validExts = new Array('.xlsx', '.xls');
    if (!evento) {
      return;
    }

    let fileExt = evento[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.uploadFileEvent = true;
    } else {
      this.uploadFileEvent = false;
    }
  }

  mostrarFacturas(){
    let objFactura;
    if(this.popUp == 'tareas'){
      objFactura = {
        tarea_detalle_id: this.objetoCompleto.detalle.id
      }
    }else {
      objFactura = {
        etapa_detalle_id: this.objetoCompleto.detalle.id
      }
    }
    this.internaService.obtenerFacturas( objFactura )
        .subscribe( (respuesta) =>  {
          this.facturas = respuesta;
        });
  }

  onSubmit(valores){

    if(this.popupInformacion.valid){

        //Añadir nuevo valor sumando las horas anteriores con la nueva
        if((this.popupInformacion.controls['estadoTarea'].value == 3 || this.popupInformacion.controls['estadoTarea'].value == 4) && !this.tareaCerrada){
            let finalizarText = (this.popupInformacion.controls['estadoTarea'].value == 3 && localStorage.getItem('language') == 'en') ? 'If you finish the task, you will not be able to continue managing it.' : 'Si finaliza la tarea no podrá seguir gestionando esta.';
            let finalizarTitle = (this.popupInformacion.controls['estadoTarea'].value == 3 && localStorage.getItem('language') == 'en') ? 'Do you want to finish the task?' : '¿Desea finalizar la tarea?';
            let cancelarText = (this.popupInformacion.controls['estadoTarea'].value == 4 && localStorage.getItem('language') == 'en') ? 'If you cancel the task, you will not be able to continue managing it.' : 'Si cancela la tarea no podrá seguir gestionando esta.';
            let cancelarTitle = (this.popupInformacion.controls['estadoTarea'].value == 3 && localStorage.getItem('language') == 'en') ? 'Do you want to cancel the task?' : '¿Desea cancelar la tarea?';
            Swal.fire({
              title: (this.popupInformacion.controls['estadoTarea'].value == 3 && localStorage.getItem('language')) ? finalizarTitle : cancelarTitle,
              text: (this.popupInformacion.controls['estadoTarea'].value == 3 && localStorage.getItem('language')) ? finalizarText : cancelarText,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: localStorage.getItem('language') == 'en' ? 'Continue' : 'Continuar',
              cancelButtonText: localStorage.getItem('language') == 'en' ? 'Cancel' : 'Cancelar',
              confirmButtonColor: '#60E266',
              cancelButtonColor: '#d33',
            }).then((result) => {
              if (result.isConfirmed) {
                
                let horasActualizadas = this.popupInformacion.controls['horasEjecutadas'].value ? parseFloat(this.objetoCompleto.detalle.horas_ejecutadas) + parseFloat(this.popupInformacion.controls['horasEjecutadas'].value) : this.objetoCompleto.detalle.horas_ejecutadas;
                let formData = new FormData();
                formData.append('id_tarea_detalle',  this.popupInformacion.controls['id_tarea_detalle'].value);
                formData.append('id_etapa_detalle', this.popupInformacion.controls['id_etapa_detalle'].value);
                formData.append('comentarios', this.popupInformacion.controls['comentarios'].value);
                formData.append('archivo', this.fileParaSubir);
                formData.append('horasEjecutadas', ( horasActualizadas ? horasActualizadas.toString() : '0' ));
                formData.append('estadoTarea', this.popupInformacion.controls['estadoTarea'].value);
        
                this.internaService.updateFaseTarea(formData).subscribe((respuesta) => {
                  console.log(respuesta);
        
                  if( respuesta.type == "danger" || respuesta.type == "warning" ){
                    this._snackBar.open( respuesta.message, '' , { duration: 3000,
                      panelClass: ['alert', 'alert-danger'] }
                   );
                   return;
                  }
        
                  this._snackBar.open( respuesta.message , '' , { duration: 3000,
                    panelClass: ['alert', 'alert-success'] }
                  );
        
                  this.popupInformacion.controls['comentarios'].reset();
                  this.popupInformacion.controls['archivo'].reset();
                  this.popupInformacion.controls['horasEjecutadas'].reset();
                  this.popupInformacion.controls['estadoTarea'].reset();
                  document.querySelector('.custom-file-label').innerHTML = '';
                  this.fileParaSubir = '';
                  this.getComentariosAndAdjuntos();
                  this.mostrarFacturas();
                  this.closeModal(true);
        
                } );

              }
            })
        } else {
          
          let horasActualizadas = this.popupInformacion.controls['horasEjecutadas'].value ? parseFloat(this.objetoCompleto.detalle.horas_ejecutadas) + parseFloat(this.popupInformacion.controls['horasEjecutadas'].value) : this.objetoCompleto.detalle.horas_ejecutadas;
  
          let formData = new FormData();
          formData.append('id_tarea_detalle',  this.popupInformacion.controls['id_tarea_detalle'].value);
          formData.append('id_etapa_detalle', this.popupInformacion.controls['id_etapa_detalle'].value);
          formData.append('comentarios', this.popupInformacion.controls['comentarios'].value);
          formData.append('archivo', this.fileParaSubir);
          formData.append('horasEjecutadas', ( horasActualizadas.toString() ));
          formData.append('estadoTarea', this.popupInformacion.controls['estadoTarea'].value);
  
          this.internaService.updateFaseTarea(formData).subscribe((respuesta) => {
  
            if( respuesta.type == "danger" || respuesta.type == "warning" ){
              this._snackBar.open( respuesta.message, '' , { duration: 3000,
                panelClass: ['alert', 'alert-danger'] }
             );
             return;
            }
  
            this._snackBar.open( respuesta.message , '' , { duration: 3000,
              panelClass: ['alert', 'alert-success'] }
            );
  
            this.popupInformacion.controls['comentarios'].reset();
            this.popupInformacion.controls['archivo'].reset();
            this.popupInformacion.controls['horasEjecutadas'].reset();
            this.popupInformacion.controls['estadoTarea'].reset();
            document.querySelector('.custom-file-label').innerHTML = '';
            this.fileParaSubir = '';
            this.getComentariosAndAdjuntos();
            this.mostrarFacturas();
            this.closeModal(true);
  
          } );
        }
          

    }

  }

  hasPermission(permissionCodename: string) {
    return this.authService.hasPermission(permissionCodename);
  }

  eliminarArchivo(adjunto){
    let objetoEliminar;

    if(this.objetoCompleto != undefined && this.popUp == 'tareas'){
      objetoEliminar = {
        "id_adjunto": adjunto.id,
        "id_proyecto":"",
        "id_tarea_detalle": this.objetoCompleto.detalle.id,
        "id_etapa_detalle":""
      }
    } else if (this.objetoCompleto != undefined && this.popUp == 'etapas'){
      objetoEliminar = {
        "id_adjunto": adjunto.id,
        "id_proyecto":"",
        "id_tarea_detalle": "",
        "id_etapa_detalle": this.objetoCompleto.detalle.id
      }
    }

    this.internaService.borrarArchivosPorProyecto( objetoEliminar )
        .subscribe( (respuesta)  => {


          if( respuesta.type == "danger" || respuesta.type == "warning" ){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
              panelClass: ['alert', 'alert-danger'] }
           );
           return;
          }

          this.getComentariosAndAdjuntos();

          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );


        });

  }

  eliminarComentario(comentario){
    let objetoEliminar;

    if(this.objetoCompleto != undefined && this.popUp == 'tareas'){
      objetoEliminar = {
        id_comentario_tarea: comentario.id,
      }
    } else if (this.objetoCompleto != undefined && this.popUp == 'etapas'){
      objetoEliminar = {
        id_comentario_fase: comentario.id,
      }
    }

    this.internaService.borrarComentariosProyecto( objetoEliminar )
        .subscribe( (respuesta)  => {


          if( respuesta.type == "danger" || respuesta.type == "warning" ){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
              panelClass: ['alert', 'alert-danger'] }
           );
           return;
          }

          this.getComentariosAndAdjuntos();

          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );


        });

  }

  getComentariosEtapasDetalle(): void {
    const etapaDetalleId = this.detalleId;
    this.internaService.getComentariosEtapasDetalle(etapaDetalleId).subscribe(comentarios => {
      this.comentarios = comentarios;
      if(this.recientesComentos){
        this.comentarios.sort( (a,b) => {
          if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
            || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
              || b.fecha_modificacion != '')){
                return new Date(a.fecha_modificacion).getTime() - new Date(b.fecha_modificacion).getTime()
              } else {
                return new Date(a.fecha_creacion).getTime() - new Date(b.fecha_creacion).getTime()
              }
        });
      } else {
        this.comentarios.sort( (a,b) => {
          if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
            || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
              || b.fecha_modificacion != '')){
                return new Date(b.fecha_modificacion).getTime() - new Date(a.fecha_modificacion).getTime()
              } else {
                return new Date(b.fecha_creacion).getTime() - new Date(a.fecha_creacion).getTime()
              }
        });
      }
      this.recientesComentos = !this.recientesComentos;
    });
  }

  getComentariosTareasDetalle(): void {
    const tareaDetalleId = this.detalleId;
    this.internaService.getComentariosTareasDetalle(tareaDetalleId).subscribe(comentarios => {
      this.comentarios = comentarios;
      if(this.recientesComentos){
        this.comentarios.sort( (a,b) => {
          if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
            || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
              || b.fecha_modificacion != '')){
                return new Date(a.fecha_modificacion).getTime() - new Date(b.fecha_modificacion).getTime()
              } else {
                return new Date(a.fecha_creacion).getTime() - new Date(b.fecha_creacion).getTime()
              }
        });
      } else {
        this.comentarios.sort( (a,b) => {
          if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
            || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
              || b.fecha_modificacion != '')){
                return new Date(b.fecha_modificacion).getTime() - new Date(a.fecha_modificacion).getTime()
              } else {
                return new Date(b.fecha_creacion).getTime() - new Date(a.fecha_creacion).getTime()
              }
        });
      }
      this.recientesComentos = !this.recientesComentos;
    });
  }

  getComentariosAndAdjuntos(): void {
    if ('fase' in this.objetoCompleto) {
      this.getComentariosEtapasDetalle();
      this.getAdjuntos({id_etapa_detalle: this.detalleId});
    } else {
      this.getAdjuntos({id_tarea_detalle: this.detalleId});
      this.getComentariosTareasDetalle();
    }
  }

  modificarComentario(comentario){
    this.editarComment = true;
    this.comentarioEditar = comentario;
    this.popupInformacion.controls['comentarios'].setValue(this.comentarioEditar.comentarios);
  }

  cancelarComentario(){
    this.popupInformacion.controls['comentarios'].setValue('');
    this.editarComment = false;
    this.comentarioEditar = '';
  }

  actualizarComentario(){

    let comentarioEditar;
    if( this.comentarioEditar && this.popUp == 'tareas' ){
      comentarioEditar = { id_comentario_tarea: this.comentarioEditar.id, comentarios: this.popupInformacion.controls['comentarios'].value }
    } else {
      comentarioEditar = { id_comentario_fase: this.comentarioEditar.id, comentarios: this.popupInformacion.controls['comentarios'].value }
    }

    this.internaService.editarComentario(comentarioEditar)
           .subscribe( (respuesta) => {
             if( respuesta.type == "warning" ){
               this._snackBar.open( respuesta.message, '' , { duration: 3000,
                panelClass: ['alert', 'alert-danger'] }
                );
                return;
            }

              this._snackBar.open( respuesta.message , '' , { duration: 3000,
                panelClass: ['alert', 'alert-success'] }
             );
              this.popupInformacion.controls['comentarios'].setValue('');
              this.editarComment = false;
              this.comentarioEditar = '';
              this.getComentariosAndAdjuntos();
           })

  }

  get detalleId(): number {
    return this.objetoCompleto.detalle.id;
  }

  getAdjuntos(obj): void {
    this.internaService.getAdjuntosProyectos(obj).subscribe(adjuntos => this.adjuntos = adjuntos);
  }


}
