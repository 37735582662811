<app-header></app-header>

<div class="banner">
  <div class="container" *ngIf="webContent">
    <div class="row row-cols-1">
      <div class="col text-center align-self-center">
        <h1>{{webContent.titulo}}</h1>
      </div>
      <div class="col text-left align-self-start">
        <h2>{{webContent.subtitulo}}</h2>
      </div>
      <div class="col text-left align-self-start">
        <h2>{{webContent.nombre_proyecto}}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <dl class="row" *ngIf="webContent.area_desempeno">
          <dt class="col-3">{{"proyectosInformacion.area" | translate }}</dt>
          <dd class="col-9">{{webContent.area_desempeno}}</dd>
        </dl>
        <dl class="row" *ngIf="formatParticipants()">
          <dt class="col-3">{{"proyectosInformacion.nombreParticipantes" | translate }}</dt>
          <dd class="col-9" [innerHTML]="formatParticipants()"></dd>
        </dl>
      </div>
      <div class="col-2">
        <img
          *ngIf="webContent.logo"
          [src]="urlS3+ '/' + webContent.logo" alt="" class="w-100">
      </div>
    </div>

    <div class="row align-items-center detalle-estilos">
      <div class="col-lg-6 col-md-6 col-xs-12 cajon-estilos">
        <div>
          <p [innerHTML]="webContent.descripcion"></p>
        </div>
        <div class="text-center">
          <button routerLink="/nuestrosProyectosPHC" class="ver-mas">{{'nuestrosProyectosPHC.botonRegresar' | translate}}</button>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 cajon-estilos2">
        <img
          *ngIf="webContent.tipo_momento == 'Imagen' || webContent.tipo_momento == 'Infografia' || (!webContent.tipo_momento && webContent.archivo)"
          [src]="urlS3+ '/' + webContent.archivo" alt="" class="w-100">
        <video *ngIf="webContent.tipo_momento == 'Video'" width=" 100% " height=" auto " controls
               controlsList="nodownload">
          <source [src]="urlS3+ '/' + webContent.archivo "
                  type="video/mp4 ">
        </video>
        <div *ngIf="webContent.tipo_momento == 'PDF'">
          <iframe [src]="urlS3+ '/' + webContent.archivo | safe"></iframe>
          <a [attr.href]="urlS3+ '/' + webContent.archivo" target="_blank">
            {{'momentosPHC.botonPdf' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
