import {Component, Input, OnInit} from '@angular/core';
import { GraficasService } from "../../../services/graficas.service";
@Component({
  selector: 'app-tabla5',
  templateUrl: './tabla5.component.html',
  styleUrls: ['./tabla5.component.css']
})
export class Tabla5Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  response:any
  constructor(private graficasService:GraficasService) { }

  ngOnInit(): void {
    this.graficasService.consultatablas(this.escenario,'tabla_5').subscribe(data =>{
      this.response=data
    });
  }

}
