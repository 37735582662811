import {Pipe, PipeTransform, Renderer2} from '@angular/core';

@Pipe({
  name: 'cutHtmlString'
})
export class CutHtmlStringPipe implements PipeTransform {

  constructor(
    private renderer: Renderer2
  ) {
  }

  transform(value: string, limit: number, cut = false): any {
    let cutText: string;
    if (value) {
      value = value.trim();
      const renderedText = this.renderer.createElement('div');
      renderedText.innerHTML = value;
      cutText = renderedText.textContent || renderedText.innerText;
      if (cut) {
        if (cutText.length > limit) {
          cutText = cutText.slice(0, limit);
          cutText += '...';
        }
        return `${cutText}`;
      }
    }
    return cutText;
  }

}
