import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GraficasService } from "../../../services/graficas.service";

@Component({
    selector: 'app-tabla1',
    templateUrl: './tabla1.component.html',
    styleUrls: ['./tabla1.component.scss'],
    providers: []
})
export class Tabla1Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
    @Input() tabla = 'grafica_1';
    response:any
    total_capacidad = 0
    total_participacion = 0
  constructor(private graficasService:GraficasService) { }

    ngOnInit(){

    this.graficasService.consultatablas(this.escenario,this.tabla).subscribe((data:any) =>{
      this.response=data
      data.forEach(element => {
        this.total_capacidad += element.capacidad_instalada_mw
        this.total_participacion += element.porcentaje_participacion
      });

    });
  }

}
