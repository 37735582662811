import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  lenguaje: string;
  imageLenguaje: string;
  urlTwitter: string;
  urlLinkedin: string;
  urlYoutube: string;
  urlFacebook: string;
  urlInstagram: string;
  urlTiktok: string;
  datosContacto: any;
  constructor(private translate: TranslateService, private homeService: HomeService) { }


  changeLenguaje() {
    this.lenguaje = this.translate.getDefaultLang().valueOf();
    if (this.lenguaje == 'es') {
      this.translate.setDefaultLang('en');
      localStorage.setItem('language', 'en');
      this.imageLenguaje = 'bandera-espanol.png';
    } else {
      this.translate.setDefaultLang('es');
      localStorage.setItem('language', 'es');
      this.imageLenguaje = 'bandera-ingles.png';
    }
    window.location.reload();
  }

  ngOnInit(): void {

    this.homeService.obtenerRedesSociales()
        .subscribe( respuesta => { 
          respuesta.forEach( item => {
            if ( item.name == "twitter" ){
              this.urlTwitter = item.link;
           } else if ( item.name == "linkedin" ){
              this.urlLinkedin = item.link;
           } else if ( item.name == "youtube" ) {
              this.urlYoutube = item.link;
           } else if ( item.name == "facebook" ) {
              this.urlFacebook = item.link;
           } else if ( item.name == "instagram" ){
              this.urlInstagram = item.link;
           } else if ( item.name == "tiktok" ){
              this.urlTiktok = item.link;
           }
          } );
            
         } );

         this.homeService.consultaContacto()
         .subscribe( (resp: any) => {
           this.datosContacto = resp[0];
         } );

  }

}
