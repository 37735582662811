import {Component, Input, OnInit} from '@angular/core';
import { GraficasService } from "../../../services/graficas.service";
@Component({
  selector: 'app-tabla4',
  templateUrl: './tabla4.component.html',
  styleUrls: ['./tabla4.component.css']
})
export class Tabla4Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  response:any
  constructor(private graficasService:GraficasService) { }

    ngOnInit(): void {
    this.graficasService.consultatablas(this.escenario,'tabla_4').subscribe(data =>{
      this.response=data
    });
  }

}
