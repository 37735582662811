<div class="table-responsive">
    <table class="table inform-cabecera table-striped">
        <thead>
            <tr>
                <th scope="col">{{"tabla1.tipo_planta" | translate}}</th>
                <th scope="col">{{"tabla1.capacidad_instalada" | translate}}</th>
                <th scope="col">{{"tabla1.porcentaje_participacion" | translate}}</th>
            </tr>
        </thead>
        <tbody>


            <tr *ngFor="let data of response;">
                <td>{{data.tipo_de_planta}}</td>
                <td class="text-center">{{data.capacidad_instalada_mw | customDecimal}}</td>
                <td class="text-center">{{data.porcentaje_participacion | percent}}</td>
            </tr>

            <!-- <tr>
            <th scope="row"></th>
            <td>Total</td>
            <td>{{total_capacidad}}</td>
            <td>{{total_participacion}}</td>
        </tr> -->



        </tbody>
    </table>
</div>
