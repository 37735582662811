<!--Se instanacia el menú del header-->
<div *ngIf="arrayProyectos.length > 0">
    <app-header></app-header>
    <div  class="pantalla proyectos-imgs">
        <div class="container">
            <div class="row">
                <div class="col-12" style="margin-top: 200px; text-align: center;">
                    <h1 class="colorTitulo" id="titulo">{{"nuestrosProyectosPHC.titulo" | translate }}</h1>
                    <!--Titulo principal-->
                    <label style="color: white;" *ngIf="proyectosDescripcion" [innerHTML]="proyectosDescripcion.descripcion | dompurify"></label>
                    <!--Parrafo principal-->
                </div>
                <!--Progress spinner-->
                <div class="text-center col-12" *ngIf="loading == true" style="margin-top: 50px;">
                    <!--<mat-spinner></mat-spinner>-->
                    <mat-spinner style="margin:0 auto;"></mat-spinner>
                    <label style="color: white;">{{"proyectosInformacion.cargador" | translate }}</label>
                </div>
            </div>
            <!--Cartas de forma horizontal-->
            <div class="row" style="margin-top: 50px;;">
                <!--Se carga la información-->
                <div [ngStyle]="{'visibility': this.calcularDimensiones != 'rgb(96, 226, 103)' ? true : false }" class="col-md-6" *ngFor="let item of arrayProyectos" [ngClass]="{'cardPrimero': item.cantidad == 1}">
                    <!--Pinta las primeras cartas-->
                    <div class="row carta-complet" *ngIf="item.cantidad <= 2" style="height: 100%;">
                        <div class="imagen-projecpal col-4" style="padding: 0px;">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.archivo" alt="item.nombre">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.logo" *ngIf="item.logo" class="logo-post" alt="item.nombre">
                        </div>
                        <div class="col-8" [ngClass]="{'backgroungBlanco' : item.color == 'blanco','backgroungAzul' : item.color == 'azulClaro'} ">
                            <h5 style="padding-top: 15px; padding-left: 15px;">{{item.nombre}}</h5>
                            <div style="padding-top: 15px; padding-left: 15px;" [innerHTML]="item.descripcion | cutHtmlString:150:true | dompurify"></div>
                          <div class="row">
                            <button [routerLink]="['/nuestrosProyectosPHC', item.titulo, item.id]" class="ver-mas">{{"nuestrosProyectosPHC.verMas" | translate }}</button>
                          </div>
                        </div>
                    </div>
                    <!--Pinta las cartas con orientación a la derecha-->
                    <div class="row carta-complet" *ngIf="item.posicion == 'derecha'" style="height: 100%;">
                        <div class="col-8" [ngClass]="{'backgroungBlanco' : item.color == 'blanco','backgroungAzul' : item.color == 'azulClaro'} ">
                            <h5 style="padding-top: 15px; padding-left: 15px;">{{item.nombre}}</h5>
                            <div style="padding-top: 15px; padding-left: 15px;" [innerHTML]="item.descripcion | cutHtmlString:150:true | dompurify"></div>
                          <div class="row">
                            <button [routerLink]="['/nuestrosProyectosPHC', item.titulo, item.id]" class="ver-mas">{{"nuestrosProyectosPHC.verMas" | translate }}</button>
                          </div>
                        </div>
                        <div class="imagen-projecpal col-4" style="padding: 0px;">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.archivo" alt="item.nombre">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.logo" *ngIf="item.logo" class="logo-post" alt="item.nombre">
                        </div>
                    </div>
                    <!--Pinta las cartas con orientación a la izquierda-->
                    <div class="row carta-complet" *ngIf="item.posicion == 'izquierda'" style="height: 100%;">
                        <div class="imagen-projecpal col-4" style="padding: 0px;">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.archivo" alt="item.nombre">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.logo" *ngIf="item.logo" class="logo-post" alt="item.nombre">
                        </div>
                        <div class="col-8" [ngClass]="{'backgroungBlanco' : item.color == 'blanco','backgroungAzul' : item.color == 'azulClaro'} ">
                            <h5 style="padding-top: 15px; padding-left: 15px;">{{item.nombre}}</h5>
                            <div style="padding-top: 15px; padding-left: 15px;" [innerHTML]="item.descripcion | cutHtmlString:150:true | dompurify"></div>
                          <div class="row">
                            <button [routerLink]="['/nuestrosProyectosPHC', item.titulo, item.id]" class="ver-mas">{{"nuestrosProyectosPHC.verMas" | translate }}</button>
                          </div>
                        </div>
                    </div>
                </div>
    
                <div [ngStyle]="{'visibility': this.calcularDimensiones == 'rgb(96, 226, 103)' ? true : false }" class="col-12" *ngFor="let item of arrayProyectosMovil">
    
                    <!--Pinta las cartas con orientación a la derecha-->
                    <div class="row carta-complet" *ngIf="item.posicion == 'derecha'" style="height: 100%;">
                        <div class="col-8 backgroungBlanco">
                            <h5 style="padding-top: 15px; padding-left: 15px;">{{item.nombre}}</h5>
                            <div style="padding-top: 15px; padding-left: 15px;" [innerHTML]="item.descripcion | cutHtmlString:150:true | dompurify"></div>
                          <div class="row">
                            <button [routerLink]="['/nuestrosProyectosPHC', item.titulo, item.id]" class="ver-mas">{{"nuestrosProyectosPHC.verMas" | translate }}</button>
                          </div>
                        </div>
                        <div class="imagen-projecpal col-4" style="padding: 0px;">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.archivo" alt="item.nombre">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.logo" *ngIf="item.logo" class="logo-post" alt="item.nombre">
                        </div>
                    </div>
                    <!--Pinta las cartas con orientación a la izquierda-->
                    <div class="row carta-complet" *ngIf="item.posicion == 'izquierda'" style="height: 100%;">
                        <div class="imagen-projecpal col-4" style="padding: 0px;">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.archivo" alt="item.nombre">
                            <img width="100%" height="320px" [src]="urlS3+'/'+item.logo" *ngIf="item.logo" class="logo-post" alt="item.nombre">
                        </div>
                        <div class="col-8 backgroungBlanco">
                            <h5 style="padding-top: 15px; padding-left: 15px;">{{item.nombre}}</h5>
                            <div style="padding-top: 15px; padding-left: 15px;" [innerHTML]="item.descripcion | cutHtmlString:150:true | dompurify"></div>
                          <div class="row">
                            <button [routerLink]="['/nuestrosProyectosPHC', item.titulo, item.id]" class="ver-mas">{{"nuestrosProyectosPHC.verMas" | translate }}</button>
                          </div>
                        </div>
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
    
    
    
    <!-- Modal -->
    <div class="modal fade modal-custom" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="cerrar-moda">
                    <button type="button" class="close botonCerrar" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row align-items-center p-5 modal-middle">
                    <div class="titulo-modal col-lg-6">
                        <h5 class="modal-title text-center" id="exampleModalLabel" data-aos="zoom-in">
                            {{ nosotrosDetalleModal.titulo }}</h5>
                    </div>
                    <div class=" descripcion-modal col-lg-6">
                        <div class="modal-body text-justify">
                            <div class="descripcion-proyect" [innerHTML]="nosotrosDetalleModal.descripcion | dompurify"></div>
                            <br>
                            <label>{{"proyectosInformacion.area" | translate }}</label>
                            <p [innerHTML]="nosotrosDetalleModal.areaDesempeno" data-aos="zoom-in"></p>
                            <br>
                            <label>{{"proyectosInformacion.nombreParticipantes" | translate }}</label>
                            <p [innerHTML]="nosotrosDetalleModal.nombreParticipantes" data-aos="zoom-in"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--Se instancia el footer-->
    <app-footer style="margin-top: 0px;"></app-footer>
    
</div>

