<app-header></app-header>

<div class="banner-momentos">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 text-center align-self-center">
                <h1> {{ "momentosPHC.titulo" | translate }} </h1>
                <h3 *ngIf="momentosDescripcion" [innerHTML]="momentosDescripcion.descripcion | dompurify"> </h3>
            </div>
        </div>
    </div>
</div>

<div class="tabuladores-momentos">
    <div class="container">


        <!-- Tabuladores para prueba -->

        <div class="tabuladores-internos">
            <div class="container">
                <div class="row">
                    <div class="d-flex pass-block">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills text-center" orientation="horizontal">
                            <li ngbNavItem="1">
                                <a ngbNavLink> {{"momentosTabulador.titulo1" | translate }} </a>
                                <ng-template ngbNavContent>

                                    <div *ngIf="loading" class="cargador text-center">
                                        <mat-spinner class="text-center"></mat-spinner>
                                        <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
                                    </div>

                                    <div *ngIf="!loading" class="row align-items-start">
                                        <div class="d-flex flex-wrap cajones-momentosphc">
                                            <div class="col-lg-6 cajon-phc caja-momento{{i}}" [class.caja-destacada]="i <= 1" [class.cajano-destacada]="i > 1" *ngFor="let momento of arrayMomentos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index">
                                                <div class="d-flex flex-wrap moment-caja">
                                                    <div [ngClass]="{ 'col-lg-12 media-momentos' : (i == 0 || i == 1) , 'col-lg-6 media-momentos' : i > 1 }">
                                                        <img *ngIf="momento.tipo == 'Imagen' || momento.tipo == 'Infografia'" [src]="urlS3+ '/' + momento.archivo" alt="" class="w-100">
                                                        <video *ngIf="momento.tipo == 'Video'" width=" 100% " height=" auto " controls controlsList="nodownload">
                                                            <source [src]="urlS3+ '/' + momento.archivo "
                                                                type="video/mp4 ">
                                                        </video>
                                                        <a [attr.href]="urlS3+ '/' + momento.archivo" target="_blank">
                                                            <iframe *ngIf="momento.tipo == 'PDF'" [src]="urlS3+ '/' + momento.archivo | safe" class="iframepdf" frameborder="0"></iframe>
                                                        </a>
                                                    </div>
                                                    <div [ngClass]="{ 'col-lg-12 informacion-momentos' : (i == 0 || i == 1) , 'col-lg-6 informacion-momentos' : i > 1 }">
                                                        <h2> {{ momento.subtitulo }} </h2>
                                                        <p [innerHTML]="truncateHTML(momento.descripcion) | dompurify"></p>
                                                    </div>
                                                    <div class="row">
                                                      <div class="col">
                                                        <button class="ver-mas" [routerLink]="['/momentosPHC', momento.titulo, momento.id]">{{"linkVerMas.link" | translate}}</button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 text-center paginador-momentos">
                                                <ngb-pagination [(page)]="page" [directionLinks]="true" [pageSize]="pageSize" [size]="tamanoPaginador()" [collectionSize]="20">
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>
                            </li>
                            <!-- se hace x mas 2 ya que el item recientes tiene el elemento 1 -->
                            <li *ngFor="let cat of categorias; let x=index" ngbNavItem="{{x+2}}">
                                <a ngbNavLink (click)="console(cat.nombre)"> {{cat.nombre}}</a>
                                <ng-template ngbNavContent>
                                    <div *ngIf="loading" class="cargador text-center">
                                        <mat-spinner class="text-center"></mat-spinner>
                                        <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
                                    </div>
                                    <div *ngIf="!loading" class="row align-items-start">
                                        <div class="d-flex flex-wrap cajones-momentosphc">
                                            <div class="col-lg-6 cajon-phc caja-momento{{i}}" [class.caja-destacada]="i <= 1" [class.cajano-destacada]="i > 1" *ngFor="let momento of arrayMomentosFiltro | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index">
                                                <div class="d-flex flex-wrap moment-caja">
                                                    <div [ngClass]="{ 'col-lg-12 media-momentos' : (i == 0 || i == 1) , 'col-lg-6 media-momentos' : i > 1 }">
                                                        <img *ngIf="momento.tipo == 'Imagen' || momento.tipo == 'Infografia'" [src]="urlS3+ '/' + momento.archivo" alt="" class="w-100">
                                                        <video *ngIf="momento.tipo == 'Video'" width=" 100% " height=" auto " controls controlsList="nodownload">
                                                            <source [src]="urlS3+ '/' + momento.archivo "
                                                                type="video/mp4 ">
                                                        </video>
                                                        <a [attr.href]="urlS3+ '/' + momento.archivo" target="_blank">
                                                            <iframe *ngIf="momento.tipo == 'PDF'" [src]="urlS3+ '/' + momento.archivo | safe" class="iframepdf" frameborder="0"></iframe>
                                                        </a>
                                                    </div>
                                                    <div [ngClass]="{ 'col-lg-12 informacion-momentos' : (i == 0 || i == 1) , 'col-lg-6 informacion-momentos' : i > 1 }">
                                                        <h2> {{ momento.subtitulo }} </h2>
                                                        <p [innerHTML]="truncateHTML(momento.descripcion) | dompurify"></p>
                                                    </div>
                                                    <div class="row">
                                                      <div class="col">
                                                        <button class="ver-mas" [routerLink]="['/momentosPHC', momento.titulo, momento.id]">{{"linkVerMas.link" | translate}}</button>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 text-center paginador-momentos">
                                                <ngb-pagination [(page)]="page" [directionLinks]="true" [pageSize]="pageSize" [size]="tamanoPaginador()" [collectionSize]="arrayMomentosFiltro.length">
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                    </div>


                                </ng-template>
                            </li>
                        </ul>

                        <div [ngbNavOutlet]="nav" class="contenido-tnos"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>
