import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addLanguage(request));
  }

  addLanguage(request: HttpRequest<any>): HttpRequest<any> {
    const language = this.getLanguage();
    if (!language) {
      return request;
    }
    return request.clone({
      params: request.params.set('language', language),
      setHeaders: {'Accept-Language': language}
    });
  }

  getLanguage(): string {
    return localStorage.getItem('language');
  }
}
