import {Component, Input, OnInit} from '@angular/core';
import {EChartOption} from 'echarts';
import {GraficasService} from '../../../services/graficas.service';

@Component({
  selector: 'app-grafica2',
  templateUrl: './grafica2.component.html',
  styleUrls: ['./grafica2.component.css']
})
export class Grafica2Component implements OnInit {

  @Input()
  escenario;
  @Input()
  historico = false;
  title: any;
  chartOption: EChartOption;

  constructor(private graficasService: GraficasService) {
  }

  ngOnInit(): void {
    let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.nombre_grafica_2;
    const historico = this.historico;
    const data = {escenario: this.escenario, historico};
    this.graficasService.consultarGrafica2(data).subscribe(option => {
      this.chartOption = option;
    });
  }

}
