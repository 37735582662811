<div id="myModal" class="modal modal-datalocal">
    <div class="row mt-3 mr-3">
        <div class="col-12">
            <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal(false)">
        </div>
    </div>
    <div class="row modal-rps">
        <div class="col-12">
            <div class="modal-content">
                <div class="header-modal">
                    <div class="title-comments pl-3 pr-3">
                        <h3> {{"asesoriaEnLinea.asesoria" | translate }} </h3>
                    </div>
                </div>

                <div class="modal-body">
                    <form [formGroup]="usuarioAsesoria" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">
                        <div class="form-group">
                            <label class="labelForm">{{"asesoriaEnLinea.asesor" | translate }}:  </label>
                            <select id="inputState" (change)="obtenerUserHubspot($event.target.value)" class="form-control" formControlName="usuario">
                            <option value="" disabled="true">{{"formularioAvisos.opcion6" | translate }}</option>
                          <option *ngFor="let asesor of asesores" value="{{asesor.user_hubspot}}">{{asesor.asesor__first_name}} {{asesor.asesor__last_name}}</option>
                        </select>
                            <p class="text-danger p-messaje errorMessaje" *ngIf="usuarioAsesoria.hasError('required', 'usuario') && formDir.touched">{{ "formularioAvisos.opcion1" | translate }}</p>
                        </div>
                    </form>


                <div *ngIf="urlAsesoria">
                    <object class="objectAsesoria" style="width: 100%; height: 500px" [data]="urlAsesoria"></object>
                </div>
            </div>
        </div>
    </div>

</div>