import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ProgressBarMode} from '@angular/material/progress-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute,Router} from '@angular/router';
import {NgbButtonsModule, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from 'src/app/services/auth.service';
import {InternasServiceService} from 'src/app/services/internas-service.service';
import {ModalProyectosComponent} from '../modal-proyectos/modal-proyectos.component';


@Component({
  selector: 'app-proyectos-detalle',
  templateUrl: './proyectos-detalle.component.html',
  styleUrls: ['./proyectos-detalle.component.css']
})
export class ProyectosDetalleComponent implements OnInit {

  buscarPalabra: any;
  uploadFileEvent: any;
  urlFileDownload: any;
  fileParaSubir: any;
  valueProgress: number;
  flechaArriba: boolean;
  proyectoDetalle: any;
  tareasTamano: number;
  tareasTerminadas: number;
  estadosArray:any = [];
  comentarios : any = [];
  adjuntos : any = [];
  formProyecto: FormGroup;
  mode: ProgressBarMode = 'determinate';
  proyectoId: any;
  editarComment: boolean = false;
  comentarioEditar: any;
  notificacionNumero: number;
  recientesComentos: boolean = false;
  constructor(private elRef:ElementRef, private modalService: NgbModal, private _builder: FormBuilder,
              private internaService: InternasServiceService, private route: ActivatedRoute,
              private _snackBar: MatSnackBar,private authService: AuthService,private router: Router) { }

  ngOnInit(): void {

    //Obtener data de proyecto
    this.reCalcularData()

    if (localStorage.getItem('language') == 'en') {
      this.buscarPalabra = 'Search';
    } else {
      this.buscarPalabra = 'Buscar';
    }

    this.getComentariosProyectos();
    this.getAdjuntosProyectos();
    //Obtener notificaciones (numero) de log actividades
    this.notificacionesLog();
  }

  notificacionesLog(){
    this.internaService.getNotificacionesProyectos(this.proyectoId)
        .subscribe( (respuesta) => {
            this.notificacionNumero = respuesta.new_project_logs;
        });
  }

  reCalcularData(){

    this.internaService.obtenerEstadosProyecto()
        .subscribe( (respuesta: any) => {
          this.estadosArray  = respuesta;
        });

    this.route.queryParams.subscribe(params => {

      this.proyectoId= params.id;
    });
    let id = this.proyectoId;
    this.internaService.proyectoDetalle({ id })
    .subscribe( (respuesta) => {

      this.proyectoDetalle = respuesta;

      //Contar solucion
      this.obtenerTotalTareasYSolucion();

      //Construcción del formulario
      this.formProyecto = this._builder.group({
        id_proyecto: new FormControl( this.proyectoDetalle != undefined ? this.proyectoDetalle.proyecto.id : ''),
        comentarios: new FormControl(''),
        archivo: new FormControl('')
      });
    });
  }

  obtenerTotalTareasYSolucion(){
    //Total de tareas
    let lenguaje;
    localStorage.getItem('language') == 'en' ? lenguaje = 'closed' : lenguaje = 'cerrado';
    let tareasNumero = 0;
    let tareasCerradas = 0;
    this.proyectoDetalle.fases.forEach(fase => {
        tareasNumero =  tareasNumero + fase.tareas.length;
        fase.tareas.forEach(tarea => {
          if(tarea.detalle.estado.id === 3){
            tareasCerradas += 1;
          }
        });
    });
    this.tareasTamano = tareasNumero;
    this.tareasTerminadas = tareasCerradas;
  }

  fileInputChange(evento) {
    this.fileParaSubir = evento[0];
    document.querySelector('.custom-file-label').innerHTML = this.fileParaSubir.name;
  }

  // cambiarIcono(index){
  //   let elemento =  this.elRef.nativeElement.querySelector(`#collapse${index}`);
  //
  //   if(elemento.classList.contains('collapse') && elemento.classList.contains('show')){
  //     this.flechaArriba = true;
  //   } else {
  //     this.flechaArriba = false;
  //   }

  // }

  popupEtapa(etapa){
    const modalRef = this.modalService.open(ModalProyectosComponent);
    modalRef.componentInstance.popUp = 'etapas';
    modalRef.componentInstance.objetoCompleto = etapa;
    modalRef.result.then( (result) => {
      if(result){
        this.reCalcularData();
        this.notificacionesLog();
      }
    });
  }

  popupTarea(tarea){
    const modalRef = this.modalService.open(ModalProyectosComponent);
    modalRef.componentInstance.popUp = 'tareas';
    modalRef.componentInstance.fases = this.proyectoDetalle.fases;
    modalRef.componentInstance.objetoCompleto = tarea;
    modalRef.result.then( (result) => {
      if(result){
        this.reCalcularData();
        this.notificacionesLog();
      }
    });
  }

  eliminarComentario(comentario){
    this.internaService.borrarComentariosProyecto( {id_comentario_proyecto: comentario.id} )
        .subscribe( (respuesta)  => {


          if( respuesta.type == "danger" || respuesta.type == "warning" ){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
              panelClass: ['alert', 'alert-danger'] }
           );
           return;
          }

          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );
         this.getComentariosProyectos();

        });
  }

  onSubmit(valores){
    if( !this.formProyecto.valid ){
      return;
    }

    let formData = new FormData();
    formData.append('id_proyecto', this.formProyecto.controls['id_proyecto'].value);
    formData.append('comentarios', this.formProyecto.controls['comentarios'].value);
    formData.append('archivo', this.fileParaSubir);

    this.internaService.actualizarComentsProyecto(formData)
        .subscribe( (respuesta: any) => {
          if( respuesta.type == "danger" || respuesta.type == "warning" ){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
              panelClass: ['alert', 'alert-danger'] }
           );
           return;
          }

          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );
          this.formProyecto.controls['comentarios'].reset();
          this.formProyecto.controls['archivo'].reset();
          document.querySelector('.custom-file-label').innerHTML = '';
          this.fileParaSubir = '';
          this.getComentariosProyectos();
          this.getAdjuntosProyectos();
          this.notificacionesLog();
        });

  }

  hasPermission(permissionCodename: string) {
    return this.authService.hasPermission(permissionCodename);
  }

  eliminarArchivo(adjunto){

    this.internaService.borrarArchivosPorProyecto( {  "id_adjunto": adjunto.id, "id_proyecto": this.proyectoId , "id_tarea_detalle":"", "id_etapa_detalle":"" } )
        .subscribe( (respuesta)  => {


          if( respuesta.type == "danger" || respuesta.type == "warning" ){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
              panelClass: ['alert', 'alert-danger'] }
           );
           return;
          }

          this.getAdjuntosProyectos();
          this.notificacionesLog();

          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );

        });

  }

  modificarComentario(comentario){
    this.editarComment = true;
    this.comentarioEditar = comentario;
    this.formProyecto.controls['comentarios'].setValue(this.comentarioEditar.comentarios);
  }

  actualizarComentario(){

    if( this.comentarioEditar ){
       this.internaService.editarComentario( { id_comentario_proyecto: this.comentarioEditar.id, comentarios: this.formProyecto.controls['comentarios'].value } )
           .subscribe( (respuesta) => {
             if( respuesta.type == "warning" ){
               this._snackBar.open( respuesta.message, '' , { duration: 3000,
                panelClass: ['alert', 'alert-danger'] }
                );
                return;
            }

              this._snackBar.open( respuesta.message , '' , { duration: 3000,
                panelClass: ['alert', 'alert-success'] }
             );
              this.formProyecto.controls['comentarios'].setValue('');
              this.editarComment = false;
              this.comentarioEditar = '';
              this.getComentariosProyectos();
           })
    }

  }

  cancelarComentario(){
    this.formProyecto.controls['comentarios'].setValue('');
    this.editarComment = false;
    this.comentarioEditar = '';
  }

  getComentariosProyectos(): void {
    if (this.proyectoId) {
      this.internaService.getComentariosProyectos(this.proyectoId).subscribe(comentarios => {
        this.comentarios = comentarios;
        if(this.recientesComentos){
          this.comentarios.sort( (a,b) => {
            if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
              || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
                || b.fecha_modificacion != '')){
                  return new Date(a.fecha_modificacion).getTime() - new Date(b.fecha_modificacion).getTime()
                } else {
                  return new Date(a.fecha_creacion).getTime() - new Date(b.fecha_creacion).getTime()
                }
          });
        } else {
          this.comentarios.sort( (a,b) => {
            if((a.fecha_modificacion != null || a.fecha_modificacion != undefined
              || a.fecha_modificacion != '')  && (b.fecha_modificacion != null || b.fecha_modificacion != undefined
                || b.fecha_modificacion != '')){
                  return new Date(b.fecha_modificacion).getTime() - new Date(a.fecha_modificacion).getTime()
                } else {
                  return new Date(b.fecha_creacion).getTime() - new Date(a.fecha_creacion).getTime()
                }
          });
        }
        this.recientesComentos = !this.recientesComentos;
      });
    }
  }

  getAdjuntosProyectos(): void {
    if (this.proyectoId) {
      this.internaService.getAdjuntosProyectos({id_proyecto: this.proyectoId}).subscribe(adjuntos => this.adjuntos = adjuntos);
    }
  }

  log(){
    this.router.navigate(['/inicio/log-actividades'],{queryParams:{id:this.proyectoId}})
  }

}
