<!--
    Modal en donde se muestra el inicio de sesión del usuario
-->
<div>
    <div id="myModal" class="modal login-md">
        <div class="row mt-3 mr-3">
            <!--<div class="col-12">
              <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close"
                  (click)="modal.dismiss('Cross click')">
          </div>-->
        </div>
        <div class="row modal-rps">
            <div class="col-lg-12 center col-sm-12 desaparecer" style="margin: auto;">
                <!--<img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close boton-cerrar" (click)="modal.dismiss('Cross click')">-->
                <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close login-cerrar boton-cerrar" (click)="closeModal()">
                <br>

                <img src="assets/logo-sobre-azul.png" class="img-logo-proyect desaparecer-image" alt="">

            </div>
            <div class="col-lg-12 center col-sm-12" style="margin: auto;">

                <!-- <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close boton-cerrar desaparecer-image"> -->
                <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close boton-cerrar  login-cerrar desaparecer-image" (click)="closeModal()">
                <br>

                <img src="assets/logo-sobre-azul.png" class="img-logo-proyect desaparecer-image" alt="">

            </div>

            <div *ngIf="tituloInforme" class="col-12 tituloInformeCss">
                <p> {{ tituloInforme }} </p>
            </div>

            <div class="col-lg-12 anchoLogueo col-sm-12">
                <div class="modal-content logueo">
                    <form *ngIf="getPassword == false" [formGroup]="loginForm" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">
                        <div class="form-group">
                            <!--<div *ngIf="localstorage.getItem('Validador') == 'false'">
                      <mat-spinner></mat-spinner>
                  </div>-->
                            <label for="exampleInputUser" class="labelForm">{{"loginForm.opcion1" | translate }}</label>
                            <input type="text" class="form-control" value="" id="login-name" formControlName="username">
                            <label for="login-name"></label>
                            <br>
                            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="loginForm.hasError('required', 'username') && errorMensaje == true">{{"formularioAvisos.opcion1" | translate }}</label>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputPass" class="labelForm">{{"loginForm.opcion2" | translate }}</label>
                            <div class="position-relative">
                                <input [type]="fieldTextType ? 'password' : 'text'" minlength="8" class="form-control" value="" id="login-pass" formControlName="password">
                                <mat-icon (click)="toggleFieldTextType()" class="iconoPassword cursor-pointer" *ngIf="fieldTextType">visibility_off</mat-icon>
                                <mat-icon (click)="toggleFieldTextType()" class="iconoPassword cursor-pointer" *ngIf="!fieldTextType">visibility</mat-icon>
                            </div>
                            <label for="login-pass"></label>
                            <br>
                            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="loginForm.hasError('required', 'password') && errorMensaje == true">{{"formularioAvisos.opcion1" | translate }}</label>
                            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="loginForm.hasError('minlength', 'password') && errorMensaje == true">{{"formularioAvisos.opcion4" | translate }}</label>
                            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{mensajeEstado}}</label>
                        </div>
                        <label class="contra-label cursor-pointer" (click)="changePassword(true)">{{"loginForm.opcion3" | translate }}</label>
                        <label class="contra-label cursor-pointer subrayado-registro" (click)="openRegistro(registro)"> {{"loginForm.opcion4" | translate }} </label>
                        <!--<label class="contra-label cursor-pointer subrayado-registro" (click)="modal.dismiss('Cross click');openRegistro(registro)"> {{"loginForm.opcion4" | translate }} </label>-->
                        <p class="text-danger text-center errorMessaje" *ngIf="errorMensaje == true">{{messajeError}}</p>
                        <div class="row btn-container">
                            <button type="submit" class="btn btn-form">{{"loginForm.opcion5" | translate }} </button>
                        </div>
                    </form>

                    <!--
                  Sección de
                  ¿olvidó su contraseña?
              -->
                    <form *ngIf="getPassword == true" [formGroup]="passwordForm" (ngSubmit)="submitChangePassword(passwordForm.value)" #formDir="ngForm" autocomplete="off">
                        <div class="form-group">
                            <label for="exampleInputUser" class="labelForm">{{"olvidoContra.opcion1" | translate }}</label>
                            <input type="text" class="form-control" value="" id="login-name" formControlName="email">
                            <label for="login-name"></label>
                            <div *ngIf="passwordForm.hasError('required', 'email') && formDir.submitted && messajeErrorPassword == ''">
                                <span class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{"formularioAvisos.opcion1" | translate }}</span>
                                <br>
                            </div>
                            <div *ngIf="passwordForm.hasError('email', 'email') && formDir.submitted">
                                <span class="labelForm text-danger p-messaje errorMessaje" style="font-size: 16px;">{{"formularioAvisos.opcion5" | translate }} </span>
                                <br>
                            </div>
                            <span class="labelForm p-messaje errorMessaje" style="font-size: 16px;" *ngIf="this.messageError.message" class="text-{{this.messageError.type}}">
                            {{ this.messageError.message }}
                          </span>
                        </div>
                        <label (click)="changePassword(false)">{{"olvidoContra.opcion2" | translate }}</label>
                        <div class="row btn-container">
                            <button type="submit" class="btn btn-form">{{"olvidoContra.opcion3" | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>

<!--
    Modal que se muestra el registro para un nuevo usuario
-->
<ng-template #registro let-modal>
    <div id="myModal" class="modal md-registro">
        <div class="marginNoValid" *ngIf="!documentoOrNIT"></div>
        <div class="row mt-5 mr-3 ">
            <div class="col-12">
                <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click'); resetDatosRegistro()">
            </div>
        </div>
        <div class="row modal-rps">
            <div class="col-lg-12 col-sm-12 ">
                <div class="modal-content">
                    <form *ngIf="!mensajeAfterRegistro" [formGroup]="registerFormulario" (ngSubmit)="onSubmitRegistro(formDir)" #formDir="ngForm" autocomplete="off">
                        <div class="row formularioRegistro">
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="nombre" class="labelForm"> {{"registroForm.opcion1" | translate }} </label>
                                <input type="text" class="form-control" id="nombre" formControlName="nombre">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="apellido" class="labelForm">{{"registroForm.opcion2" | translate }}</label>
                                <input type="text" class="form-control" id="apellido" formControlName="apellido">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'apellido') && formDir.submitted">
                  {{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="correo" class="labelForm"> {{"registroForm.opcion3" | translate }}</label>
                                <input type="email" class="form-control" id="correo" formControlName="correo">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'correo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.get('correo').invalid && formDir.submitted ">{{"formularioAvisos.opcion3" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label class="labelForm"><b>{{"registroForm.opcion4" | translate }}</b></label>
                                <select id="inputState" class="form-control" (change)="cambiarTexto($event.target.value)" formControlName="tipoPersona">
                  <option value="null" disabled>{{"formularioAvisos.opcion6" | translate }}</option>
                  <option *ngFor="let tipoPersona of tiposPersona"
                          value="{{tipoPersona.id}}">{{tipoPersona.tipo}}</option>
                </select>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'tipoPersona') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="numeroDocumento" *ngIf="documentoOrNIT" class="labelForm">{{"registroForm.opcion5" | translate }}</label>
                                <label for="numeroDocumento" *ngIf="!documentoOrNIT" class="labelForm">{{"registroForm.opcion6" | translate }}</label>
                                <input type="text" class="form-control" id="numeroDocumento" formControlName="numeroDocumento">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'numeroDocumento') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'numeroDocumento') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="celular" class="labelForm">{{"registroForm.opcion7" | translate }}</label>
                                <input type="text" class="form-control" id="celular" formControlName="celular" maxlength="10">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'celular') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'celular') && formDir.submitted">{{"formularioAvisos.opcion2" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.controls['celular'].hasError('maxlength') && !registerFormulario.hasError('pattern', 'celular') && !registerFormulario.hasError('required', 'celular')">{{"formularioAvisos.opcion20" | translate }}</label>

                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="telefono" class="labelForm">{{"registroForm.opcion8" | translate }}</label>
                                <input type="text" class="form-control" id="telefono" formControlName="telefono">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'telefono') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
                                <label for="cargo" class="labelForm">{{"registroForm.opcion9" | translate }}</label>
                                <input type="text" class="form-control" id="cargo" formControlName="cargo">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'cargo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
                                <label for="empresa" class="labelForm">{{"registroForm.opcion10" | translate }}</label>
                                <input type="text" class="form-control" id="empresa" formControlName="empresa">

                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
                                <label for="nitEmpresa" class="labelForm"> {{"registroForm.opcion11" | translate }}</label>
                                <input *ngIf="documentoOrNIT" type="text" class="form-control" id="nitEmpresa" formControlName="nitEmpresa">
                                <input *ngIf="!documentoOrNIT" type="text" class="form-control" id="nitEmpresa" formControlName="nitEmpresa" [value]="registerFormulario.controls['numeroDocumento'].value">

                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'nitEmpresa') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
                                <label for="archivo" class="labelForm">{{"registroForm.opcion17" | translate }}</label>
                                <input type="file" class="form-control-file" id="archivo" #imageInput (change)="processFile(imageInput,$event,'rut')">
                                <!-- <span class="text-danger" *ngIf="!rutFile && formDir.submitted && !fileRutCurrent">Campo
                    obligatorio</span> -->
                                <span class="text-danger errorMessaje" *ngIf="formatFilerunt && !fileRutCurrent">{{"formularioAvisos.opcion15" | translate }}</span>
                                <!-- <a [attr.href]="urlS3 + '/' + fileRutCurrent" target="_blank">{{fileRutCurrent}asdasdasd</a> -->
                            </div>

                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
                                <label for="archivo" class="labelForm">{{"registroForm.opcion18" | translate }}</label>
                                <input type="file" class="form-control-file" id="archivo" #imageInput (change)="processFile(imageInput,$event,'camara')">
                                <!-- <span class="text-danger" *ngIf="!camaraFile && formDir.submitted && !fileCameraCurrent">Campo
                    obligatorio</span> -->
                                <span class="text-danger errorMessaje" *ngIf="formatFilecamera && !fileCameraCurrent">{{"formularioAvisos.opcion15" | translate }}</span>
                                <!-- <p><a [attr.href]="urlS3 + '/' + fileCameraCurrent" target="_blank">{{fileCameraCurrent}}</a></p> -->
                            </div>

                            <div class="col-lg-12 col-12 form-check">
                                <input class="form-check-input" type="checkbox" (change)="checkHabeasData($event.target.checked)" value="" formControlName="habeasData" id="habeasData">
                                <label class="form-check-label" for="habeasData" *ngIf="politicasTratamiento">
                                <a class="link-terminos" (click)="navigate($event,politicasTratamiento.id)" id="text-terminos" target="_blank"> {{politicasTratamiento.titulo}} </a>
                                </label>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="this.checkedHabeasData == false || (registerFormulario.hasError('required', 'habeasData') && formDir.submitted)">
                                    {{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="col-lg-12 col-12 form-check">
                                <input class="form-check-input" type="checkbox" (change)="checkTerminos($event.target.checked)" value="" formControlName="terminos" id="terminos">
                                <label class="form-check-label" for="terminos">
                                <a *ngIf="terminosTitulo" class="link-terminos"  (click)="navigate($event,terminosTitulo.id)"
                                    target="_blank"> {{terminosTitulo.titulo}} </a>
                                </label>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="this.checkedTerminos == false || (registerFormulario.hasError('required', 'terminos') && formDir.submitted)">
                                    {{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="col-lg-12 text-xs-center">

                                <re-captcha class="g-recaptcha" (resolved)="resolved($event)" [siteKey]="keyCaptcha">

                                </re-captcha>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="!responseCaptcha && formDir.submitted">
                                    {{"formularioAvisos.opcion1" | translate }}</p>

                            </div>
                            <div class="col-lg-12">
                                <div class="btn-container-login">
                                    <button type="submit" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                  <div *ngIf="mensajeAfterRegistro" class="mensaje-registro">
                    <div *ngIf="!mensajeExitoRegistro && !mensaje_back && errorEmail" class="mensaje-existente">
                      <label style="font-size: 16px;">{{ "registroForm.opcion14" | translate }}</label>
                    </div>
                    <div *ngIf="!mensajeExitoRegistro && !mensaje_back && errorEmpresa" class="mensaje-existente">
                      <label style="font-size: 16px;">{{ "registroForm.errorEmpresa" | translate }}</label>
                    </div>
                    <div *ngIf="mensaje_back && !mensajeExitoRegistro" class="mensaje-existente">
                      <label style="font-size: 16px;">{{ mensaje_back }}</label>
                    </div>
                    <div *ngIf="mensajeExitoRegistro" class="mensaje-exitoso">
                      <label style="font-size: 16px;">{{"registroForm.opcion15" | translate }}</label>
                    </div>
                    <div class="col-lg-12">
                      <div class="btn-container">
                        <button type="button" (click)="modal.dismiss('Cross click')"
                                class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

    </div>
</ng-template>
