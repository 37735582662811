import {AfterContentInit, Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as Datamap from 'node_modules/datamaps/dist/datamaps.world.min.js';
import {HomeService} from '../../services/home.service';
import * as d3 from 'd3';
import {COUNTRY_NAME_ESPANISH} from '../../translate/datamap/topojson.es';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit, AfterContentInit {
  dataMAP1: any = {};
  dataMAP2: any = [];
  dataMAP3: any = {};
  D3event = (<d3.ZoomEvent> d3.event);
  datosPais: any;
  mapaDescripcion: any;
  constructor(private homeService: HomeService,private translate: TranslateService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    if( localStorage.getItem('language') == 'es' ){
      Datamap.prototype.worldTopo = COUNTRY_NAME_ESPANISH;
    }else {
      Datamap.prototype.worldTopo.objects.world.geometries
    }

    this.homeService.filtrarSecciones( { nombre: 'PHC en el mundo' } )
    .subscribe( (respuesta: any) => {
      this.mapaDescripcion = respuesta[0];
        } );

  }

  ngAfterContentInit(): void {
    let filtros = {
      'estado': 'Activo',
      'nombre': ''
    };
    this.homeService.consultarMapa(filtros).subscribe((response: any) => {
      let dataMap;
      let defaultFills;
      let fillDefault = {};
      fillDefault['defaultFill'] = '#606060';
      defaultFills = Object.assign(this.dataMAP3, fillDefault);
      response.forEach((element: any, index, geography) => {
        let nombre = element.pais_codigo;
        let data = {};
        let fill = {};
        data[nombre] = {fillKey: element.pais_codigo, numberOfcities: element.numero_ciudades, numberclients: element.numero_clientes};
        if( window.innerWidth < 700 ){
          fill[element.pais_codigo] = '#061E38';
        }
        //
        let marker = {name: 'foo', iconUrl: 'assets/icono-geo.png', radius: 20, latitude: element.latitud, longitude: element.longitud, nombrePais: element.pais_nombre};
        this.dataMAP2.push(marker);
        dataMap = Object.assign(this.dataMAP1, data);
        defaultFills = Object.assign(this.dataMAP3, fill);
      });
      var map = new Datamap({
        element: document.getElementById('container'),
        data: dataMap,
        projection: 'mercator',
        responsive: true,
        fills: defaultFills,
        geographyConfig: {
          highlightFillColor: function(code) {
            if(code.fillKey){
              return '#061E38';
            } else {
              return '#606060';
            }

        },
          // highlightFillColor: '#061E38',
          popupTemplate: function(geo, data) {
            let ciudades = 'Ciudades';
            let clientes = 'Clientes';
            if( localStorage.getItem('language') == 'es' ){
              ciudades = 'Ciudades';
              clientes = 'Clientes';
            }else {
              ciudades = 'Cities';
              clientes = 'Customers';
            }
            return ['<div style="padding: 8px !important; border-radius: 5px !important; background-color: #071e39; color:#ffffff; font-size: 15px;" class="hoverinfo"><strong><span style="color: #60E266" class="color-tooltipmap">' + geo.properties.name + '</span><br>',
              ' <span style="color: #60E266" class="color-tooltipmap">' + data.numberOfcities + '</span> ' +  ciudades  + '<br> <span style="color: #60E266" class="color-tooltipmap">' + data.numberclients,
              '</span> ' +  clientes + ' </strong></div>'].join('');
          }
        },
        done: function(datamap) {
          const datamapSvg = datamap.svg;
          datamapSvg.on('click', () => {
            datamapSvg.call(d3.behavior.zoom().on('zoom', redraw));
          });

          function redraw() {
              let e = (<d3.ZoomEvent> d3.event);
               datamap.svg.selectAll("g").attr("transform", "translate(" + e.translate + ")scale(" + e.scale + ")");
              //  rescaleMarker(datamap);
          }

          // datamap.svg.selectAll('.datamaps-subunit').on('click', function(geography) {
          //   alert(geography.properties.name);
          // })
        }
      });

      window.addEventListener('resize', function() {
        map.resize();
      }, {passive: false});

      // function rescaleMarker(datamap, datum?) {
      //   let devent = (<d3.ZoomEvent> d3.event);
      //   datamap.svg
      //     .selectAll('.datamaps-marker')
      //     .attr("x", "translate(" + devent.translate + ")scale(" + devent.scale + ")")
      //     .attr("y", "translate(" + devent.translate + ")scale(" + devent.scale + ")")
      // }

      function handleMarkers(layer, data, options, geography) {
        var self = this,
          fillData = this.options.fills,
          svg = this.svg;

        if (!data || (data && !data.slice)) {
          throw 'Datamaps Error - markers must be an array';
        }

        var markers = layer.selectAll('image.datamaps-marker').data(data, JSON.stringify);

        markers
          .enter()
          .append('image')
          .attr('class', 'datamaps-marker')
          .attr('xlink:href', function(datum) {
            return datum.iconUrl || options.defaultIcon;
          })
          .attr('height', 15)
          .attr('width', 15)
          .attr('x', function(datum) {
            var latLng;
            if (datumHasCoords(datum)) {
              latLng = self.latLngToXY(datum.latitude, datum.longitude);
            } else if (datum.centered) {
              latLng = self.path.centroid(svg.select('path.' + datum.centered).data()[0]);
            }
            if (latLng) {
              return latLng[0] - 10;
            }
          })
          .attr('y', function(datum) {
            var latLng;
            if (datumHasCoords(datum)) {
              latLng = self.latLngToXY(datum.latitude, datum.longitude);
            } else if (datum.centered) {
              latLng = self.path.centroid(svg.select('path.' + datum.centered).data()[0]);
            }
            if (latLng) {
              return latLng[1] - 20;
            }
          })
          .on('mouseover', function(data) {
            var $this = d3.select(this);
            d3.selectAll('.datamaps-hoverover').style('display', 'block');
            if (options.popupOnHover) {

              self.updatePopup($this, data, options, svg);
            }
          })
          .on('mouseout', function(data) {
          var $this = d3.select(this);
          if (options.highlightOnHover) {
            var previousAttributes = JSON.parse($this.attr('data-previousAttributes'));
            for (var attr in previousAttributes) {
              $this.style(attr, previousAttributes[attr]);
            }
            self.updatePopup($this, data, options, svg);
          }
          d3.selectAll('.datamaps-hoverover').style('display', 'none');
        });

        markers.exit()
          .transition()
          .attr('height', 0)
          .remove();

        function datumHasCoords(datum) {
          return typeof datum !== 'undefined' && typeof datum.latitude !== 'undefined' && typeof datum.longitude !== 'undefined';
        }
      }

      map.addPlugin('markers', handleMarkers);
      var options = {
        popupOnHover: true,
        popupTemplate: function(geo, data) {
          return ['<div class="hoverinfo"><strong><span class="color-tooltipmap">' + geo.properties.name + '</span><br>',
            ' <span class="color-tooltipmap">' + data.numberOfcities + '</span> Ciudades <br> <span class="color-tooltipmap">' + data.numberclients,
            '</span> Clientes </strong></div>'].join('');
        }
      };
      map.markers(this.dataMAP2, {options});
    });
  }
}
