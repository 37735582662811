import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient) {
  }

  consultarNosotros(objeto): any {
    return this._http.post(`${environment.url}servicio_consulta/`, objeto, this.httpOptions);
  }

  consultarMapa(objeto): any {
    return this._http.post(`${environment.url}map/filter/`, objeto, this.httpOptions);
  }

  consultaTerminosPolticas(): Observable<any> {
    return this._http.get(`${environment.url}habeas-data/`, this.httpOptions);
  }

  filtrarSecciones(datos: any) {
    return this._http.post(`${environment.url}seccion/filter/`, datos, this.httpOptions);
  }

  consultarProyecto(objeto): any {
    return this._http.post(`${environment.url}servicio_consulta/`, objeto, this.httpOptions);
  }

  consultaContacto() {
    return this._http.get(`${environment.url}contact-info/`, this.httpOptions);
  }

  consultaEquipoGet() {
    return this._http.get(`${environment.url}secciones-nuestro-equipo/data/`, this.httpOptions);
  }

  changePassword(objeto): any {
    return this._http.post(`${environment.url}users/reset_password/`, objeto, this.httpOptions);
  }

  firtsTimePassword(objeto): any {
    return this._http.post(`${environment.url}users/first_time/`, objeto, this.httpOptions);
  }

  registrarUsuario(objeto): any {
    return this._http.post(`${environment.url}auth/users/`, objeto, this.httpOptions);
  }

  userName(objeto): any {
    return this._http.post(`${environment.url}user-extern/validate/`, objeto, this.httpOptions);
  }

  getSuscripciones(objeto): any {
    return this._http.post(`${environment.url}suscripcion`, objeto, this.httpOptions);
  }

  traerTipoPersona(): any {
    return this._http.get(`${environment.url}tipo-persona/`);
  }

  tipoDeDocumento(objeto): any {
    return this._http.post(`${environment.url}tipo-documento/`, objeto, this.httpOptions);
  }

  crearEmpresa(objeto): any {
    return this._http.post(`${environment.url}empresa/create/`, objeto, this.httpOptions);
  }

  filtroMomento(objeto): any {
    return this._http.post(`${environment.url}momentos-filtro/`, objeto, this.httpOptions);
  }

  obtenerRedesSociales(): any {
    return this._http.get(`${environment.url}social-network/`);
  }

  obtenerCredenciales(): any {
    return this._http.get(`${environment.url}credentiales/servicios`);
  }

  obtenerOperadores(): any {
    return this._http.get(`${environment.url}operadores`);
  }

  obtenerAlternativas(dato): any {
    return this._http.post(`${environment.url}alternativas/list`, dato);
  }

  obtenerTecnologias(): any {
    return this._http.get(`${environment.url}tecnologias/list`);
  }

  obtenerParametrosGenerales(): any {
    return this._http.get(`${environment.url}parametros/generales`);
  }

  obtenerEstudiosConexion(): any {
    return this._http.get(`${environment.url}estudios/conexion`);
  }

  obtenerPotencias(objeto): any {
    return this._http.post(`${environment.url}potencias/`, objeto);
  }

  obtenerPotenciasPromise(objeto): any {
    return new Promise( (resolve,reject) => {
      this._http.post(`${environment.url}potencias/`, objeto).subscribe( (data) => resolve(data)
      , err => reject(err));
    });
  }

  obtenerMonedas(): any {
    return this._http.get(`${environment.url}moneda`);
  }

  solicitarCotizacion(objeto): any {
    return this._http.post(`${environment.url}solicitud_cotizacion`, objeto);
  }

  categoriaMomentos(): any {
    return this._http.get(`${environment.url}categoria-momentos/`);
  }

  crearEstudiosConexion(): any {
    return this._http.get(`${environment.url}estudios`);
  }

  cloneUser(email): Observable<any> {
    return this._http.post(`${environment.url}users/clone-user`, {email}, this.httpOptions);
  }

  guardarImg(file){
    const formData = new FormData();

    formData.append('file', file);
    let files = {
      "file":file
    }
    return this._http.post(`${environment.url}aws/`,formData);
  }

  tooltips(){
    return this._http.get(`${environment.url}tooltips`);
  }

  mensajeCargador(){
    return this._http.get(`${environment.url}phc-web/mensaje-cargando`);
  }

  detailWebContent(id: string): Observable<any> {
    return this._http.get(`${environment.url}detalle-contenido-web/${id}`);
  }
  botonAsesoria(){
    return this._http.get(`${environment.url}phc-web/boton-asesoria`);
  }
  botonProyeccionesMercado(){
    return this._http.get(`${environment.url}phc-web/mensaje-proyecciones`);
  }
}
