import {EventEmitter, Injectable, Output} from '@angular/core';
import {of, Subject, Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {mergeMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SecureStorageService} from './secure-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  public logoutSubject: any;
  public loginSubject: any;

  public accessToken: string;
  public refreshToken: string;
  public djangoUser: any;
  @Output() userEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private secureStorageService: SecureStorageService
  ) {
    this.accessToken = this.secureStorageService.getItem('accessToken');
    this.refreshToken = this.secureStorageService.getItem('refreshToken');
    this.djangoUser = this.secureStorageService.getItem('djangoUser');

    this.logoutSubject = new Subject();
    this.loginSubject = new Subject();

    if (this.getAccessToken()) {
        this.validateDjangoUser();
    }
   }

      login(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept-Language', 'es');
        let ruta = environment.url + 'auth/jwt/create/';
        return this.http.post<any>(
            ruta,
            JSON.stringify(data),
            {headers}
        ).pipe(
            mergeMap(async (result) => {
                if (result.access && result.refresh) {
                    this.accessToken = result.access;
                    this.refreshToken = result.refresh;
                    await this.secureStorageService.setItem('accessToken', this.accessToken);
                    await this.secureStorageService.setItem('refreshToken', this.refreshToken);
                    this.loginSubject.next(true);
                    return this.getCurrentUserData(true).subscribe();
                }
                return of(result);
            })
        );
    }

    getPropertyOfCurrentUserData(property) {

        let value;

        this.getCurrentUserData(false).subscribe(user => {
            if (user[property] !== undefined) {
                value = user[property];
            }
        });

        return value;
    }

    getCurrentUserData(force): Observable<any> {

      if (this.djangoUser && !force) {
          return of(this.djangoUser);
      } else if (this.isLoggedIn()) {
          let headers = new HttpHeaders();
          headers = headers.set('Content-Type', 'application/json');
          return this.http.get(
              environment.url + 'auth/users/me/',
              {headers}
          ).pipe(
              mergeMap(async result => {
                  await this.setUserData(result);
                  return of(result);
              })
          );
      } else {
          return throwError(new Error('Not Logged In'));
      }
    }

    isLoggedIn() {
      return !!this.accessToken;
    }

    getAccessToken() {
      return this.secureStorageService.getItem('accessToken');
    }

    async validateDjangoUser() {
      if (!this.djangoUser) {
          this.djangoUser = await this.getUserData();
      }
    }

    async getUserData() {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      const auth = await this.http.get(
          environment.url + 'auth/users/me/',
          {headers}
      ).toPromise();
      return auth;
    }

    getEmitter() {
        return this.userEmit;
    }

    hasPermission(permissionCodename) {
        if (this.djangoUser && this.djangoUser.permisos) {
            return this.validPermission(permissionCodename);
        }
    }
    validPermission(permissionCodename) {
        this.djangoUser.permisos = this.deleteDuplicates(this.djangoUser.permisos, 'id');
        for (let i = 0; i < this.djangoUser.permisos.length; i++) {
            const permiso = this.djangoUser.permisos[i];
            if (permiso.codename === permissionCodename) {
                return true;
            }
        }
        return false;
    }
    deleteDuplicates(arr, comp) {
        const unique = arr.map(e => e[comp])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }



    async setUserData(djangoUser) {
      this.djangoUser = djangoUser;
      try {
          await this.secureStorageService.setItem('djangoUser', this.djangoUser);
          // this.secureStorageService.setItem('Validador','true');
      } catch (Ex) {

      }
      this.userEmit.emit(this.djangoUser);
    }
    updateUser(values){
        return this.http.put(environment.url + 'auth/users/me/',values);
    }

    logout() {
      this.secureStorageService.removeItem('accessToken');
      this.secureStorageService.removeItem('refreshToken');
      this.secureStorageService.removeItem('djangoUser');
      this.accessToken = null;
      this.refreshToken = null;
      this.djangoUser = null;
      this.logoutSubject.next(true);
  }
  refreshAccessToken(): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    const refresh = {
        refresh: this.refreshToken,
    };

    return this.http.post<any>(
        environment.url + 'auth/jwt/refresh/',
        refresh,
        {headers}
    ).pipe(
        mergeMap(result => {
            if (result.access) {
                this.accessToken = result.access;
                this.secureStorageService.setItem('accessToken', this.accessToken);
            }
            return of(result.access);
        })
    );
}
}
