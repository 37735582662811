import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modales',
  templateUrl: './modales.component.html',
  styleUrls: ['./modales.component.css']
})
export class ModalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
