<div class="nostros">
    <div class="container-custom mt-custom">
        <h2 data-aos="zoom-in">{{ nosotrosCampos.titulo }}</h2>
        <div class="linea"></div>
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <p class="pt-4" data-aos="zoom-in" [innerHTML]='nosotrosCampos.descripcion | dompurify'></p>
            </div>
            <div class="col-lg-6 col-sm-12">
                <img data-aos="zoom-in" *ngIf="archivoTipo == 'Imagen' || archivoTipo == 'Infografia'" [src]="urlS3+ '/' + nosotrosCampos.archivo" alt="" class="w-100 pt-1">
                <video data-aos="zoom-in" *ngIf="archivoTipo == 'Video'" width=" 100% " height=" auto " controls controlsList="nodownload">
          <source [src]="urlS3+ '/' + nosotrosCampos.archivo " type="video/mp4 ">
        </video>
                <div class="margin-btn pt-5 ">
                    <a [routerLink]="['/sobre-nosotros']" class="btn btn-light " data-aos="zoom-in">{{"nosotros.titulo" | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>