<div class="cn-responsive" *ngIf="headerAndFooter">
    <div class="page" id="pagina1">
        <!-- <a class="btn btn-pdf float-right" id="downloadButton" target="_blank">Descargar PDF</a> -->
        <a class="btn btn-pdf float-right" id="downloadButton" href="javascript:;" (click)="generatePDF()">
      {{'estudios_conexion.solicitud.enviar_pdf' | translate }} </a>

        <img class="w-100" [src]="headerAndFooter.imagen_banner" alt="">
        <div class="info">
            <div>
                <h1>{{headerAndFooter.header}}</h1>
                <div class="linea"></div>
                <div class="datos">
                    <p>{{parametrosInforme.nombre_cliente}}</p>
                    <p>{{parametrosInforme.codigo_propuesta}}</p>
                    <p>{{parametrosInforme.fechaCreacion | date:'MMMM d, y' | uppercase}}</p>
                </div>

                <div class="row container-contacto half-contacto">
                    <div class="col imagen-contacto">
                        <img class="logo-info" [src]="headerAndFooter.imagen_logo_phc" alt="">
                    </div>
                    <div class="col contacto txt-contacto">
                        <p [innerHTML]="headerAndFooter.datos_contacto"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-page">
            <!--      <div class="linea-footer"></div>-->
            <div class="row">
                <div class="col">
                    <!--          <img class="footer-image" src="../../../assets/logo-phc.png" alt="">-->
                </div>
                <div class="col">
                    <!--          <p class="text-right page-number"> Pagina | <span class="number">1</span></p>-->
                </div>
            </div>
        </div>
    </div>

    <div class="page pt-4 " id="pagina2">
        <div class="footer-page">
            <div class="row">
                <div class="col">
                    <p class="text-right page-number">{{ headerAndFooter.header }}</p>
                </div>
            </div>
            <div class="linea-footer"></div>
        </div>
        <div class="contenedor-indice mt-5">
            <h1>CONTENIDO</h1>
        </div>
        <ul class="contenido-item list-nostyle">
            <li *ngFor="let content of contentTable" [ngStyle]="{'text-indent': calculateTextIndent(content.order) }">
                <a class="clickInforme" (click)="revisar(content.title)">{{content.title}}</a>
                <span class="indice-page">{{content.pageNumber}}</span></li>
        </ul>


        <div class="footer-page">
            <!--      <div class="linea-footer"></div>-->
            <div class="row">
                <div class="col">
                    <!--          <img class="footer-image" src="../../../assets/logo-phc.png" alt="">-->
                </div>
                <div class="col">
                    <!--          <p class="text-right page-number"> Pagina | <span class="number">2</span></p>-->
                </div>
            </div>
        </div>
    </div>

    <div class="page pt-4 " [id]="generateIdPage(i)" *ngFor="let page of pages; let i = index">

        <div class="footer-page">
            <div class="row">
                <div class="col">
                    <p class="text-right page-number">{{ headerAndFooter.header }}</p>
                </div>
            </div>
            <div class="linea-footer"></div>
        </div>

        <div class="content-informe">
            <div *ngFor="let currentPage of page;">
                <div [id]="currentPage.title" class="contenedor-titulo mt-5" [ngClass]="{'contenedor-titulo mt-5': isInteger(currentPage.order), 'contenedor-subtitulo mt-3': !isInteger(currentPage.order)}">
                    <h1>{{currentPage.title}}</h1>
                </div>
                <p class="text" [innerHTML]="currentPage.paragraph">
                </p>
            </div>
        </div>

        <div class="footer-page bottom-abs">
            <div class="linea-footer"></div>
            <div class="row align-items-center">
                <div class="col">
                    <img class="footer-image" [src]="headerAndFooter.footerImage" alt="">
                </div>
                <div class="col">
                    <p class="text-right page-number"> Pagina | <span class="number">{{generatePageNumber(i)}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>
