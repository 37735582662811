import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-estudios-conexion',
  templateUrl: './estudios-conexion.component.html',
  styleUrls: ['./estudios-conexion.component.css']
})
export class EstudiosConexionComponent implements OnInit {
  appear: boolean = false;
  estudiosComplete = [];
  constructor(private HomeService: HomeService){ }

  ngOnInit(): void {
    this.HomeService.crearEstudiosConexion()
        .subscribe( (respuesta) => {
            this.estudiosComplete = respuesta;
            this.appear = true;
        });
  }

  get urlS3() {
    return environment.urlS3;
    }

}
