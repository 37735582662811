import {Component, OnInit} from '@angular/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {InternasServiceService} from 'src/app/services/internas-service.service';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-trazabilidad-proyectos',
  templateUrl: './trazabilidad-proyectos.component.html',
  styleUrls: ['./trazabilidad-proyectos.component.css']
})
export class TrazabilidadProyectosComponent implements OnInit {

  proyectosGET: any = [];
  informacionMensaje: any;
  mode: ProgressBarMode = 'determinate';

  constructor(private internasService: InternasServiceService,
              private _snackBar: MatSnackBar,
              private authService: AuthService,private router: Router) {
  }

  ngOnInit(): void {

    if (this.email) {
      this.internasService.getProyectos({"correo": this.email})
        .subscribe((respuesta) => {

          if (respuesta.type == "danger") {
            this._snackBar.open(respuesta.message, '', {
                duration: 3000,
                panelClass: ['alert', 'alert-danger']
              }
            );
            return;
          }

          if (respuesta.type == "warning") {
              this.informacionMensaje = respuesta.message;
              return;
          }

          this.proyectosGET = respuesta.filter( (estado) => estado.estado_id !== 6);
        });
    }
  }

  get email(): string {
    return this.authService.getPropertyOfCurrentUserData('email');
  }
  detalleProyecto(proyecto){
    this.router.navigate(['/inicio/proyectos-progreso'],{queryParams:{id:proyecto}})

  }

}
