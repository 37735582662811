<nav class="navbar navbar-expand-lg navbar-light bg-light menuppal-phc">
    <a href="https://www.phc.com.co/"><img src="assets/logo phc@2x.png" alt="logo-headephc" class="logo-headephc"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse colapsar-menu" id="navbarSupportedContent">
        <div class="menu items-sidebar">
            <ul>
                <li *ngFor="let digitalService of digitalServices">
                  <div class="row align-items-center lineaverde-hover">
                    <div class="icono-rps">
                      <a (click)="validateLoggin(digitalService.url)"><img [src]="digitalService.img" alt=""
                                                                             style="filter: brightness(0) invert(1)"></a>
                    </div>
                    <div class="texto-menu">
                      <a (click)="validateLoggin(digitalService.url)">{{ digitalService.title }}</a>
                    </div>
                  </div>
                </li>
            </ul>
            <div class="user-informacion-responsive">
                <div class="user-texto" *ngIf="logged && dataUser">
                    <span class="mb-2">{{dataUser.username}}</span>
                    <span class="mb-2">{{dataUser.email}}</span>
                    <a class="d-block mb-2" type="button" (click)="consultaSuscripcion()"> {{"header.suscripcion" | translate }} </a>
                    <a class="d-block mb-2" type="button" (click)="consultaUser()"> {{"header.perfil" | translate }} </a>
                    <a class="d-block mb-2" type="button" (click)="cerrarSesion()"> {{"header.cerrarSeccion" | translate }} </a>
                </div>
                <div class="iniciar-s" *ngIf="!logged">

                    <a type="button" (click)="inicioSesion()"> {{"header.Inicio" | translate }} </a>
                </div>
            </div>
        </div>
    </div>
    <div class="user-informacion">


        <div class="dropdown" *ngIf="logged && dataUser">
            <button class="btn btn-secondary dropdown-toggle mb-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <b>
                  {{"header.micuenta" | translate }}
                </b>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a type="button" class="dropdown-item mb-2" (click)="consultaUser()"> {{"header.perfil" | translate }} </a>
                <a type="button" class="dropdown-item mb-2" (click)="consultaSuscripcion()">{{"header.suscripcion" | translate }}</a>
                <a type="button" class="dropdown-item mb-2" (click)="cerrarSesion()"> {{"header.cerrarSeccion" | translate }} </a>
            </div>
        </div>
        <div class="iniciar-s" *ngIf="!logged">
            <!-- <a type="button" (click)="inicioSesion()"> {{"header.Inicio" | translate }} </a> -->

            <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="inicioSesion()">
               <b>
                    {{"header.Inicio" | translate }}
                </b>
            </button>
        </div>
    </div>
</nav>

<ng-template #construccion let-modal>
    <div id="myModal" class="modal modal-construccion modal-dialog-centered">
        <div class="row mt-3 mr-3 w-100">
            <div class="col-12">
                <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click')">
            </div>
        </div>
        <div class="row modal-middle">
            <div class="col-lg-12">
                <div class="modal-content-modify">
                    <div class="cabecera-modal">
                        {{"paginaConstruccion.opcion1" | translate }}
                    </div>
                    <div class="cuerpo-modal">
                        <p> {{"paginaConstruccion.opcion2" | translate }}
                        </p>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 text-center">
                <div class="btn-container-modify">
                    <button type="button" (click)="modal.close('Close click')" class=" btn btn-form ">{{"paginaConstruccion.opcion3" | translate }}</button>
                </div>
            </div>
        </div>

    </div>
</ng-template>
