<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                    <h1 class="titulo-bannerinicio">
                        {{"proyeccionesBanner.tituloTrazabilidad" | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div *ngIf="informacionMensaje" class="mensaje mt-5">
            <h3> {{ informacionMensaje }} </h3>
        </div>
        <div *ngIf="proyectosGET.length > 0" class="table-responsive space-proyectos">
            <table class="table">
                <thead class="thead-light ">
                    <tr>
                        <th colspan="100%">{{"proyectosDetalle.trazaProyecto" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proyecto of proyectosGET">
                        <a (click)="detalleProyecto(proyecto.id)">
                            <td>
                                <div class="row m-0 align-items-center">
                                    <div class="col-12 col-sm-6 col-lg-6">
                                        <div style="cursor: pointer;"  class="organizated-proyect">
                                            <p > {{ proyecto.codigo }} - {{ proyecto.nombre }} </p>
                                            <p> {{"proyectosDetalle.InicioProyecto" | translate }}: <span class="subtitle-proyect">{{ proyecto.fecha_inicio | date: 'dd/MM/yyyy' }}</span> </p>
                                            <p> {{"proyectosDetalle.FinProyecto" | translate }}: <span class="subtitle-proyect">{{ proyecto.fecha_fin | date: 'dd/MM/yyyy' }}</span> </p>
                                            <p> {{"proyectosDetalle.cliente" | translate }}: <span class="subtitle-proyect">{{ proyecto.cliente }}</span> </p>
                                            <p class="subtitle-proyect"> {{ proyecto.estado_nombre }} </p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-6">
                                        <div style="cursor: pointer;" class="estados-proyectos">
                                            <span class="title-azules"><b>{{"proyectosDetalle.trazaProgreso" | translate }}:</b></span>
                                            <section class="example-section">
                                                <mat-progress-bar class="example-margin" [mode]="mode" [value]="proyecto.avance">
                                                </mat-progress-bar>
                                                <span>{{ proyecto.avance }} % </span>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </a>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
