import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {InternasServiceService} from 'src/app/services/internas-service.service';

@Component({
  selector: 'app-log-actividades',
  templateUrl: './log-actividades.component.html',
  styleUrls: ['./log-actividades.component.css']
})
export class LogActividadesComponent implements OnInit {

  proyectoID: any = '';
  pagina: number = 1;
  totalPaginas: number = 1;
  registrosLog: any;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  constructor(private internaService: InternasServiceService, private activatedRoute: ActivatedRoute,
              private translateService: TranslateService,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.recargarLogActividades();

    //Disparar el evento de usuario
    this.dispararEventoUsuario();

  }

  recargarLogActividades() {

    this.activatedRoute.queryParams.subscribe(params => {

      this.proyectoID= params.id;
      });

    this.internaService.getLogActividades(this.proyectoID, this.pagina)
      .subscribe((respuesta) => {


        this.totalPaginas = respuesta.total_pages;
        if (this.pagina > this.totalPaginas) {
          return;
        } else {
          this.pagina++;
        }
        this.registrosLog ? this.registrosLog = this.registrosLog.concat(respuesta.results) : this.registrosLog = respuesta.results;
      });
  }

  //Disparar log de actividades por usuario
  dispararEventoUsuario() {
    this.internaService.logActividadesUsuario(this.proyectoID)
      .subscribe((respuesta) => {

      });
  }

  onScroll(evento) {
    if (this.pagina > this.totalPaginas) {
      return;
    }

    if (evento >= this.registrosLog.length) {
      this.recargarLogActividades();
    }
  }

  crearMensaje(registro) {

    let usuario = registro.nombre;
    let mensajeParser = JSON.parse(registro.change_message);
    let concatMensaje = '';
    let aVariable = this.translateService.instant('general.a');
    let delVariable = this.translateService.instant('general.del');
    let enVariable = this.translateService.instant('general.en');
    const stateLabel = this.translateService.instant('proyectos.estado');
    let mensajeAccion;
    let tipoAccion;
    let mensaje;

    for (const index of mensajeParser) {


      const estados = [];
      estados[1] = 'creado';
      estados[2] = 'ejecucion';
      estados[3] = 'finalizado';
      estados[4] = 'cancelado';
      estados[5] = 'inactivo';

      if (tipoAccion) {
        if ('fields' in index[tipoAccion]) {
          (index[tipoAccion].fields).forEach((log, idx, array) => {
            if (log.name === 'estado_id') {
              const stateOldLabel = this.translateService.instant(`proyectos.estados.${estados[log.old_data]}`);
              const stateNewLabel = this.translateService.instant(`proyectos.estados.${estados[log.new_data]}`);
              concatMensaje += `${stateLabel} ${stateOldLabel} ${aVariable} ${stateNewLabel}${idx === array.length - 1 ? ' ' : ', '} `;
            } else {
              concatMensaje += `${log.name} ${log.old_data} ${aVariable} ${log.new_data}${idx === array.length - 1 ? ' ' : ', '} `;
            }
          });
        }
      }
    }

    switch (registro.action_flag) {
      case 1:
        mensajeAccion = this.translateService.instant('general.creado');
        break;
      case 2:
        mensajeAccion = this.translateService.instant('general.actualizado');
        break;
      case 3:
        mensajeAccion = this.translateService.instant('general.eliminado');
        break;
    }

    mensaje = `<span> <b> ${usuario} </b> ${mensajeAccion} <b class="actividad-nombre"> ${concatMensaje} </b> ${delVariable} "${registro.object_repr}"</span>`;

    if (tipoAccion == 'added_custom') {
      mensaje = `<span> <b> ${usuario} </b> ${mensajeAccion} "${registro.object_repr}"</span>`;
    }

    return mensaje;
  }

  redirectToProjectDetail(): void {
    this.router.navigate(['/inicio/proyectos-progreso'], {
      queryParams: {
        id: this.proyectoID
      }
    });
  }


}
