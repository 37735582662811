import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {graphicsColor} from '../../app.config';

const url = `${environment.url}grafica11/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params): any {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          values: data,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: this.lenguaje == "es" ? [
              'GLP',
              'Retiros',
              'GNL',
              'Carbón',
              'Solar',
              'Eólica',
              'Menores',
              'Hidráulica',
            ] : [
              'LGP',
              'Withdrawals',
              'LNG',
              'Coal',
              'Solar',
              'Wind',
              'Menores',
              'Hydraulics',
            ],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          yAxis: {
            type: 'value',
          },
          xAxis: {
            type: 'category',
            data: data.map((e) => e.fecha),
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'Hidráulica' : 'Hydraulics',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: graphicsColor.hidraulica,
              },
              data: data.map((e) => e.hidraulica != 0 ? parseFloat(e.hidraulica.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'Menores' : 'Menores',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: '#34b0f0',
              },
              data: data.map((e) => e.menores != 0 ? parseFloat(e.menores.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'Carbón' : 'Coal',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: graphicsColor.carbon,
              },
              data: data.map((e) => e.carbon != 0 ? parseFloat(e.carbon.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'Eólica' : 'Wind',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: graphicsColor.eolica,
              },
              data: data.map((e) => e.eolica != 0 ? parseFloat(e.eolica.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'Solar' : 'Solar',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: graphicsColor.solar,
              },
              data: data.map((e) => e.solar != 0 ? parseFloat(e.solar.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'GNL' : 'LNG',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: '#ED7E31',
              },
              data: data.map((e) => e.gnl != 0 ? parseFloat(e.gnl.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'GLP' : 'LGP',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: graphicsColor.glp,
              },
              data: data.map((e) => e.glp != 0 ? parseFloat(e.glp.toFixed(2)) : 0),
            },
            {
              name: this.lenguaje == "es" ? 'Retiros' : 'Withdrawals',
              type: 'bar',
              stack: 'column',
              itemStyle: {
                color: '#F71504',
              },
              data: data.map((e) => e.retiros != 0 ? parseFloat(e.retiros.toFixed(2)) : 0),
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
