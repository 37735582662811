<div class="title-chart text-center">
    <b [innerHTML]="title"></b>
</div>
<div echarts [options]="chartOption" class="demo-chart"></div>
<div class="table-responsive">
    <table class="table" *ngIf="chartOption.xAxis">
        <thead>
            <tr>
                <th scope="col">-</th>
                <th scope="col" *ngFor="let e of chartOption.xAxis.data">{{ e }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">{{chartOption.legend.data[0]}}</th>
                <td *ngFor="let value of mapArray('glp')">{{ value | number:'1.0-0' }}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[1]}}</th>
                <td *ngFor="let value of mapArray('retiros')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[2]}}</th>
                <td *ngFor="let value of mapArray('gnl')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[3]}}</th>
                <td *ngFor="let value of mapArray('carbon')">{{ value | number:'1.0-0' }}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[4]}}</th>
                <td *ngFor="let value of mapArray('solar')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[5]}}</th>
                <td *ngFor="let value of mapArray('eolica')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[6]}}</th>
                <td *ngFor="let value of mapArray('menores')">{{ value | number:'1.0-0'}}</td>
            </tr>
            <tr>
                <th scope="row">{{chartOption.legend.data[7]}}</th>
                <td *ngFor="let value of mapArray('hidraulica')">{{ value | number:'1.0-0'}}</td>
            </tr>
        </tbody>
    </table>

</div>