import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica7/`;


@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let charts = [];
        let chart = {
          color: ['#FABE00', '#EE8237', '#4FB044', '#17569B'],
          tooltip: {
            trigger: 'axis',

            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) => el.anio),
          },
          yAxis: {
            type: 'value',
            min: 'dataMin'
          },
          legend: { 
            data: this.lenguaje == 'es' ? [
              'Esc. Bajo',
              'Esc. Medio',
              'Esc. Alto',
              'Historico Demanda PMAX [MW]',
            ]: [
              'Low Sc.',
              'Medium Sc.',
              'High Sc.',
              'Historical PMAX Demand [MWh].',
            ] ,
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'Esc. Bajo' : 'Low Sc.',
              data: data.map((el) => el.esc_bajo != null ? parseFloat(el.esc_bajo.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#FABE00',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Esc. Medio' : 'Medium Sc.',
              data: data.map((el) => el.esc_medio != null ? parseFloat(el.esc_medio.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#EE8237',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Esc. Alto' : 'High Sc.',
              data: data.map((el) => el.esc_alto != null ? parseFloat(el.esc_alto.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#4FB044',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Historico Demanda PMAX [MW]' :  'Historical PMAX Demand [MWh].',
              data: data.map((el) => el.historico_pmax != null ? parseFloat(el.historico_pmax.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                color: '#17569B',
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        let chart2 = {
          color: ['#FABE00', '#EE8237', '#4FB044', '#17569B'],
          tooltip: {
            trigger: 'axis',

            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) => el.anio),
          },
          yAxis: {
            type: 'value',
            min: 'dataMin'
          },
          legend: { 
            data: this.lenguaje == 'es' ? [
              'Esc. Bajo',
              'Esc. Medio',
              'Esc. Alto',
              'Historico Demanda EE [GWh]',
            ]: [
              'Low Sc.',
              'Medium Sc.',
              'High Sc.',
              'Historical EE Demand [MWh].',
            ] ,
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'Esc. Bajo' : 'Low Sc.',
              data: data.map((el) => el.esc_bajo1 != null ? parseFloat(el.esc_bajo1.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#FABE00',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Esc. Medio' : 'Medium Sc.',
              data: data.map((el) => el.esc_medio1 != null ? parseFloat(el.esc_medio1.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#EE8237',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Esc. Alto' : 'High Sc.',
              data: data.map((el) => el.esc_alto1 != null ? parseFloat(el.esc_alto1.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#4FB044',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Historico Demanda EE [GWh]' :  'Historical EE Demand [MWh].',
              data: data.map((el) => el.historico_dee != null ? parseFloat(el.historico_dee.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                color: '#17569B',
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        charts.push(chart);
        charts.push(chart2);
        resolve(charts);
      });
    });
  }
}
