<div class="modal-header text-resumen">
    <h2 class="modal-title">{{ "detallePago.titulo" | translate }}</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="loading" class="cargador text-center">
        <mat-spinner class="text-center"></mat-spinner>
        <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
    </div>

    <div class="container">
        <div *ngIf="!loading" class="resumen-pago">
            <div class="row">
                <div class="col-lg-12 pt-3 text-center rs-pago ">
                    <span> {{ "detallePago.pagaste" | translate }}  </span>
                    <div class="pagos-pipes">
                        {{ transactionData.data.currency }} {{ (transactionData.data.amount_in_cents / 100) | currency:'COP':'':'1.0-0' }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 pt-4 text-center rs-pago2">
                    <span> {{ "detallePago.pagasteCon" | translate }}  </span>
                    <p> {{ "detallePago.metodosDePago." + transactionData.data.payment_method_type | translate }} </p>
                </div>

                <div class="table-responsive tabla-resumenes">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.estadoTransaccion" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ traduccionEstado }}</td>
                            </tr>
                            <tr *ngIf="transactionData.data.payment_method.extra.brand">
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.marca" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;">{{ transactionData.data.payment_method.extra.brand }}</td>
                            </tr>
                            <tr *ngIf="transactionData.data.payment_method.extra.last_four">
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.tarjetaDeCredito" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;">**** **** **** {{ transactionData.data.payment_method.extra.last_four }}</td>
                            </tr>
                            <tr *ngIf="transactionData.data.payment_method.installments">
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.cuotas" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;">{{ transactionData.data.payment_method.installments }}</td>
                            </tr>
                            <tr *ngIf="transactionData.data.payment_method.payment_description">
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.descripcionTransaccion" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;">{{ transactionData.data.payment_method.payment_description }}</td>
                            </tr>
                            <tr>
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.pagoEfectuado" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;" [innerHTML]="pagoEfectuadoA"></td>
                            </tr>
                            <tr>
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.fechaTransaccion" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;"> {{ fechaTransaccion }} </td>
                            </tr>
                            <tr *ngIf="transactionData.data.payment_method.extra.ticket_id">
                                <td class="text-right" style="width: 50%;vertical-align: middle;font-weight: 600;">{{ "detallePago.ticket" | translate }}</td>
                                <td class="text-left" style="width: 50%;vertical-align: middle;"> {{ transactionData.data.payment_method.extra.ticket_id }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.close(false)"> Cerrar </button>
</div>
