import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators, FormGroup} from '@angular/forms';
import {NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-modal-registro-datos',
  templateUrl: './modal-registro-datos.component.html',
  styleUrls: ['./modal-registro-datos.component.css']
})
export class ModalRegistroDatosComponent implements OnInit {
  registerForm: FormGroup;
  datosPersonalizado: any;
  modalReference: NgbModalRef;
  constructor(private _builder: FormBuilder 
    ,public activeModal: NgbActiveModal,private translate: TranslateService,private modalService: NgbModal) {

    this.registerForm = this._builder.group({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      empresa: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      correo: new FormControl('', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]),
    });

   }

  ngOnInit(): void {
  }

  
  onSubmit(formDir): void {
    if (!this.registerForm.valid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    this.datosPersonalizado = {
      nombre: this.registerForm.get('nombre').value,
      apellido: this.registerForm.get('apellido').value,
      empresa: this.registerForm.get('empresa').value,
      telefono: this.registerForm.get('telefono').value,
      correo: this.registerForm.get('correo').value,
    };

    localStorage.setItem('tomaDeDatos', JSON.stringify(this.datosPersonalizado));
    // this.tomaDeDatosON = true;
     this.activeModal.close('Succesful');
  }

  closeModal(){
    this.modalService.dismissAll();
  }

}
