import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor() { 
  }

  ngOnInit(): void {
  }

  addSidebar(){
    document.getElementById('dinamico-inicio').classList.add('sidebar-abierto');
  }
  
  removeSidebar(){
    document.getElementById('dinamico-inicio').classList.remove('sidebar-abierto');
  }

}
