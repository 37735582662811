import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GraficasService } from "../../../services/graficas.service";

@Component({
    selector: 'app-tabla7',
    templateUrl: './tabla7.component.html',
    styleUrls: ['./tabla7.component.scss'],
    providers: []
})
export class Tabla7Component implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  escenario;
  @Input()
  historico = false;
    // Parametros para buscador
    response: any;
    constructor(
        private graficasService: GraficasService
    ) { }


    ngOnInit() {

        this.getTabla7();
    }

    ngAfterViewInit() { }

    ngOnDestroy() { }

    getTabla7() {

        this.graficasService.consultatablas(this.escenario, 'tabla_7').subscribe(data => {

            if (data) {
                this.response = data
            } else {
                alert("Consulta sin resultados");
            }

        });


    }
}
