import { Component, OnInit } from '@angular/core';
import { tabulador } from 'src/app/models/tabulador.models';
import { Politicas } from 'src/app/models/politicas.models';
import { HomeService } from 'src/app/services/home.service';
import { environment } from '../../../../environments/environment';
import {SeoService} from '../../../services/seo.service';


@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css']
})
export class PoliticasComponent implements OnInit {

  misionParametros: any;
  politicasParametros: any;
  misionCampos = new tabulador;
  politicasCampos = new Politicas;
  idiomaTexto: any;
  politicasDescripcion: any;
  constructor(
    private homeService: HomeService,
    private seoService: SeoService
  ) {
    this.misionParametros = {seccion: 'Mision', estado: 'Activo'};
    this.politicasParametros = {seccion: 'Politicas de gestion', estado: 'Activo'};

    this.homeService.consultarNosotros(this.misionParametros)
        .subscribe((respuesta: any) => {
          if (respuesta.data.length === 0) {
            return;
          }
          respuesta.data[0].campos.forEach(campo => {
            if (campo.nombre == 'Titulo') {
              this.misionCampos.titulo = campo.valor;
            }
            if (campo.nombre == 'Texto') {
              this.misionCampos.descripcion = campo.valor;
            }
            if (campo.nombre == 'Archivo') {
              this.misionCampos.archivo = campo.valor;
            }
          });
        });

        this.homeService.consultarNosotros(this.politicasParametros)
        .subscribe((respuesta: any) => {

          if (respuesta.data.length === 0) {
            return;
          }
          respuesta.data[0].campos.forEach(campo => {
            if (campo.nombre == 'Titulo') {
              this.politicasCampos.titulo = campo.valor;
            }
            if (campo.nombre == 'Descripcion') {
              this.politicasCampos.descripcion = campo.valor;
            }
          });
        });

   }

  ngOnInit(): void {
    this.idiomaTexto = localStorage.getItem('language');

    this.homeService.filtrarSecciones( { nombre: 'Politicas de gestion' } )
    .subscribe( (respuesta: any) => {
      this.politicasDescripcion = respuesta[0];
        } );

    this.seoService.addMetaTagsByPage('Meta tags - página políticas');

  }

  get urlS3() {
    return environment.urlS3;
  }

}
