<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                    <h1 class="titulo-bannerinicio">
                        {{"proyeccionesBanner.tituloConozcoSector" | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-xl-6 col-lg-12 orderFormulario p-0 m-0">
                <div class="formulario-sector">

                    <div class="titulo-form pb-2">
                        <h3>{{"estudiosConexionFormulario.tituloppal" | translate }}</h3>
                    </div>

                    <div class="form-body">
                        <form [formGroup]="formEstudiosConexion" (ngSubmit)="onSubmit()" #formDir="ngForm" autocomplete="off">
                            <div class="padbottom-inputs proyecto">
                                <label class="d-block"><b>{{ 'estudiosConexionFormulario.placeholderProyecto' | translate}}</b> </label>
                                <input type="text" placeholder="{{ 'estudiosConexionFormulario.placeholderProyecto' | translate}}" formControlName="nombreProyecto">
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('nombreProyecto')" [title]="getTooltip('nombreProyecto')">
                                    i
                                </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'nombreProyecto') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="padbottom-inputs selects-form">
                                <label class="d-block"><b>{{ 'estudiosConexionFormulario.tipoProyecto' | translate}}</b> </label>
                                <select class="form-control" (change)="envioTipoProyecto($event.target.value)" formControlName="tipoProyecto">
                                    <option value="" disabled=true > {{"estudiosConexionFormulario.tipoProyecto" | translate }} </option>
                                    <option *ngFor="let estudio of estudios" [value]="estudio"> {{ estudio | titlecase }} </option>
                                </select>
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('tipoProyecto')" [title]="getTooltip('tipoProyecto')">
                                    i
                                  </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'tipoProyecto') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="padbottom-inputs selects-form">
                                <label class="d-block"><b> {{"estudiosConexionFormulario.unidadPotencia" | translate }} </b> </label>
                                <select class="form-control" #unidadPotenciaInfo (change)="obtenerRangos($event.target.value)" formControlName="unidadPotencia">
                                    <option value="" disabled="true"> {{"estudiosConexionFormulario.unidadPotencia" | translate }} </option>
                                    <option *ngFor="let unidad of unidadesPotencia" [value]="unidad.unidad_potencia"> {{ unidad.unidad_potencia }} </option>
                                </select>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'unidadPotencia') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="padbottom-inputs slider-bar">
                                <mat-slider [disabled]="activeCamposPotencia" (input)="onInputChange($event)" [value]="value" class="example-margin mat-slider-horizontal" [max]="max" [min]="min" [step]="step">
                                </mat-slider>
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('unidadPotencia')" [title]="getTooltip('unidadPotencia')">
                                    i
                                  </span>
                                <div class="indicadoresPotencia">
                                    <span class="minimo-left"> Min </span>
                                    <span class="maximo-right "> Max </span>
                                </div>
                            </div>
                            <div class="padbottom-inputs mt-3 slider-form">
                                <label class="d-block"><b> {{ 'estudiosConexionFormulario.placeholderPotencia' | translate}} </b> </label>
                                <input type="text" (keyup)="inputChangeSlider($event.target.value)" placeholder="{{ 'estudiosConexionFormulario.placeholderPotencia' | translate}}" formControlName="potencia">
                                <span *ngIf="unidadInformacion" class="unidad-info"> {{ unidadInformacion }} </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'potencia') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('pattern', 'potencia') && formDir.submitted">{{ "formularioAvisos.opcion2" | translate }}</p>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('min', 'potencia') && formDir.submitted">{{ "formularioAvisos.opcion17" | translate }}</p>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('max', 'potencia') && formDir.submitted">{{ "formularioAvisos.opcion18" | translate }}</p>

                            </div>
                            <div class="padbottom-inputs fecha-modulo">
                                <label class="d-block"><b> {{"estudiosConexionFormulario.placeholderFechaOperacion" | translate }} </b> </label>
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>DD/MM/AAAA</mat-label>
                                    <input onkeypress="return (event.charCode >= 47 && event.charCode <= 57)" [min]="fechaHoy" matInput [matDatepicker]="picker" formControlName="fechaInicioOperacion">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <mat-icon>calendar_today</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('fechaInicioOperacion')" [title]="getTooltip('fechaInicioOperacion')">
                                    i
                                  </span>
                            </div>

                          <div class="padbottom-inputs">
                            <label class="d-block"><b>{{ 'estudiosConexionFormulario.ubicacion' | translate}}</b> </label>
                            <input type="text" placeholder="{{ 'estudiosConexionFormulario.ubicacion' | translate}}" formControlName="ubicacion" #ubicacion>
                            <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('ubicacion')" [title]="getTooltip('ubicacion')">
                                    i
                                </span>
                            <button type="button" class="btn btn-secondary tooltip-information" (click)="getLatitudeAndLongitudeByAddress(ubicacion.value)">
                              <i class="bi bi-search"></i>
                            </button>
                            <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('maxlength', 'ubicacion') && formDir.submitted">{{ "formularioAvisos.opcion21" | translate }}</p>
                          </div>

                            <label class="d-block"><b> {{ 'estudiosConexionFormulario.placeholderCoordenadas' | translate}} </b> </label>
                            <div class="padbottom-inputs proyecto">
                                <input type="text" (keyup)="cleanUbicacion()" placeholder="{{ 'estudiosConexionFormulario.placeholderLatitud' | translate}}" formControlName="latitud">
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" [title]="getTooltip('latitud')">
                                    i
                                </span>
                            </div>
                            <div class="padbottom-inputs proyecto">
                                <input type="text" (keyup)="cleanUbicacion()" placeholder="{{ 'estudiosConexionFormulario.placeholderLongitud' | translate}}" formControlName="longitud">
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" [title]="getTooltip('longitud')">
                                    i
                                  </span>
                            </div>

                            <div class="padbottom-inputs selects-form">
                                <label class="d-block"><b>  {{ 'estudiosConexionFormulario.placeholderOperador' | translate}} </b> </label>
                                <select class="form-control" formControlName="operadorRed">
                                    <option value="" disabled="true"> {{ 'estudiosConexionFormulario.placeholderOperador' | translate}} </option>
                                    <option *ngFor="let operador of operadores" [value]="operador.id" > {{ operador.nombre }} </option>
                                    <!-- <option value="otros"> {{ 'estudiosConexionFormulario.placeholderOtro' | translate}} </option> -->
                                </select>
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('operadorRed')" [title]="getTooltip('operadorRed')">
                                    i
                                  </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'operadorRed') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                            </div>

                            <div *ngIf="activaCampoOperador" class="padbottom-inputs proyecto">
                                <label class="d-block"><b>  {{ 'estudiosConexionFormulario.placeholderOtro' | translate}} </b> </label>
                                <input type="text" placeholder="{{ 'estudiosConexionFormulario.placeholderOtro' | translate}}" formControlName="operadorRedOtros">
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('operadorRedOtros')" [title]="getTooltip('operadorRedOtros')">
                                    i
                                </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'operadorRedOtros') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                            </div>

                            <div class="padbottom-inputs selects-form">
                                <label class="d-block"><b>  {{ 'estudiosConexionFormulario.placeholderAlternativas' | translate}} </b> </label>
                                <select class="form-control" formControlName="alternativas">
                                    <option value="" disabled="true"> {{ 'estudiosConexionFormulario.placeholderAlternativas' | translate}} </option>
                                    <option *ngFor="let alternativa of alternativas" [value]="alternativa.id" > {{ alternativa.valor }} </option>
                                </select>
                                <span class="btn btn-secondary tooltip-information" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('alternativas')" [title]="getTooltip('alternativas')">
                                    i
                                  </span>
                            </div>

                            <div class="padbottom-inputs radios-form rds-monedas">
                                <h2 class="tittle-moneda"><b>{{ 'estudiosConexionFormulario.placeholderMonedas' | translate}}</b></h2>
                                <div class="btn-group btn-group-toggle" *ngFor="let moneda of monedas; let i = index">
                                    <label class="btn btn-secondary button-radioEstConexion" [ngClass]="[moneda.isChecked ? 'checkedMoneda' : 'noCheckedMoneda']">
                                        <input type="radio" (change)="checked(i)" [value]="moneda.id" formControlName="moneda" name="moneda" [id]="moneda.moneda" autocomplete="off" checked="true"> {{moneda.nombre_moneda}}
                                      </label>
                                </div>
                                <span class="btn btn-secondary tooltip-information tooltip-radios-custom mt-2" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('moneda')" [title]="getTooltip('moneda')">
                                    i
                                  </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'moneda') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>

                            </div>

                            <div class="padbottom-inputs radios-form rds-tecnologias">
                                <h2 class="tittle-moneda"><b>{{ 'estudiosConexionFormulario.placeholderTech' | translate}}</b></h2>
                                <div class="btn-group btn-group-toggle" *ngFor="let tecnologia of tecnologias; let i = index">
                                    <label class="btn btn-secondary btnTamanio button-radioEstConexion" [ngClass]="[tecnologia.isChecked ? 'checkedMoneda' : 'noCheckedMoneda']">
                                        <input type="radio" (change)="checkedTecnologia(i)" [value]="tecnologia.id" formControlName="tecnologia" name="tecnologia" [id]="tecnologia.nombre" autocomplete="off" checked="true"> <img class="img-tecnologias" [src]="urlS3+ '/static/' + tecnologia.icono" [alt]="tecnologia.nombre"> {{tecnologia.nombre}}
                                      </label>
                                </div>
                                <span class="btn btn-secondary tooltip-information tooltip-radios-custom mt-2" data-toggle="tooltip" data-placement="top" *ngIf="getTooltip('tecnologia')" [title]="getTooltip('tecnologia')">
                                    i
                                  </span>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'tecnologia') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>

                            </div>

                            <div class="terminos-check">
                                <input class="custom-radio-checkbox__input" type="checkbox" formControlName="terminosCondiciones" id="terminosCondiciones">
                                <label class="custom-radio-checkbox__show custom-radio-checkbox__show--radio form-check-label" for="terminosCondiciones" *ngIf="politicasTratamiento">
                                <a class="custom-radio-checkbox__text link-terminos" (click)="navigate($event,politicasTratamiento.id)"
                                    target="_blank"> {{politicasTratamiento.titulo}} </a>
                                </label>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="formEstudiosConexion.hasError('required', 'terminosCondiciones') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>

                            </div>

                            <div class="padbottom-inputs terminos-check selects-form">
                                <button type="submit" class="generar">Generar cotización</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 orderFormulario2 order-lg-1 p-0 m-0">
                <app-mapa-conozco-sector [latitud]="this.formEstudiosConexion.get(['latitud']).value" [longitud]="this.formEstudiosConexion.get(['longitud']).value"></app-mapa-conozco-sector>
            </div>

        </div>
    </div>
</div>
