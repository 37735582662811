<app-header></app-header>

<div class="container-fluid p-0">
    <div class="row">
        <div class="col-lg-12 pt-5">
            <div id="container" class="mapa-estilos" style="width: auto; height: 50%"></div>
        </div>
    </div>
</div>
<div id="myModal2" class="modal fade " tabindex="-1">
    <div class="modal-dialog modal-dialog-centered animate__animated animate__fadeIn animate__faster">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{"phcMundoMapa.titulo" | translate }}</h5>
                <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <p class="descripcion-phcmodal"></p>
            </div>
        </div>
    </div>
</div>

<app-footer #footer></app-footer>