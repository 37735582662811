import { Component, OnInit } from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { HomeService } from 'src/app/services/home.service';
import { Proyecto } from '../../models/proyectos.models';
import { environment } from '../../../environments/environment';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {
    hola = "hola";
    proyectosDetalleModal = {
      titulo: '',
      descripcion: '',
      areaDesempeno: '',
      nombreParticipantes: ''
    }
    closeResult: string;
    ngbModalRef: NgbModalRef;
    customOptions: OwlOptions = {
      loop: false,
      items: 3,
      dotsSpeed: 500,
      dots: true,
      smartSpeed: 600,
      dragEndSpeed: 600,
      autoHeight: true,
      autoWidth:true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        900: {
          items: 1
        }
      },
      // stagePadding: 40,
      nav: false
    }

  proyectos = new Proyecto;
  objetoCompleto: Proyecto;
  arrayProyectos: Array<Proyecto> = [];
  constructor(private homeService: HomeService,private modalService: NgbModal,) {

    this.homeService.consultarProyecto( { "seccion" : "Nuestros proyectos", "estado": "Activo" } )
        .subscribe( data => {

          for (const array of data.data) {
            array.campos.forEach(campo => {
              if (campo.nombre == 'Nombre proyecto') {
                this.proyectos.nombre = campo.valor;
              }
              if (campo.nombre == 'Descripcion') {
                this.proyectos.descripcion = campo.valor;
              }
              if (campo.nombre == 'Archivo') {
                this.proyectos.archivo = campo.valor;
              }
            if (campo.nombre == 'Nombre participantes') {
              this.proyectos.nombreParticipantes = campo.valor;
            }
            if (campo.nombre == 'Area desempeno') {
              this.proyectos.areaDesempeno = campo.valor;
            }

              this.objetoCompleto = {
                nombre: this.proyectos.nombre,
                descripcion: this.proyectos.descripcion,
                archivo: this.proyectos.archivo,
                nombreParticipantes: this.proyectos.nombreParticipantes,
                areaDesempeno : this.proyectos.areaDesempeno
              }

            });

            this.arrayProyectos.push(this.objetoCompleto);
          }

        } );

  }

  modal(content,item)
  {
    this.proyectosDetalleModal.titulo = item.nombre;
    this.proyectosDetalleModal.descripcion = item.descripcion;
    this.proyectosDetalleModal.nombreParticipantes = item.nombreParticipantes;
    this.proyectosDetalleModal.areaDesempeno = item.areaDesempeno;



    this.ngbModalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static', centered: true });

    this.ngbModalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnInit(): void {
  }

  truncateHTML(text: string): string {

    let charlimit = 350;
    if(!text || text.length <= charlimit )
    {
        return text;
    }
      let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
      let shortened = without_html.substring(0, charlimit) + "...";
      return shortened;
    }

  get urlS3() {
    return environment.urlS3;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
