import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as Datamap from 'node_modules/datamaps/dist/datamaps.world.min.js';
import {HomeService} from '../../../services/home.service';
import * as d3 from 'd3';
import {COUNTRY_NAME_ESPANISH} from '../../../translate/datamap/topojson.es';
import { TranslateService } from '@ngx-translate/core';
import {SeoService} from '../../../services/seo.service';


@Component({
  selector: 'app-phc-mundo',
  templateUrl: './phc-mundo.component.html',
  styleUrls: ['./phc-mundo.component.css']
})
export class PhcMundoComponent implements OnInit {
  dataMAP1: any = {};
  dataMAP2: any = [];
  dataMAP3: any = {};

  closeResult = '';
  @ViewChild('mapaphc') message: ElementRef;
  public mapa: any;
  constructor(
    private homeService: HomeService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private seoService: SeoService
  ) {

  }

  ngOnInit(): void {
    if( localStorage.getItem('language') == 'es' ){
      Datamap.prototype.worldTopo = COUNTRY_NAME_ESPANISH;
    }else {
      Datamap.prototype.worldTopo.objects.world.geometries
    }

    this.seoService.addMetaTagsByPage('Meta tags - página PHC en el mundo');
  }

  closeModal(){
    var modal = document.getElementById("myModal2");
    if( modal.classList.contains('show') ){
      modal.style.display = "none";
      modal.style.paddingRight = "17px";
      modal.className="modal fade";
    }
  }

  mostrarMensaje(){

  }

  ngAfterContentInit(): void {
    let filtros = {
      'estado': 'Activo',
      'nombre': ''
    };


    this.homeService.consultarMapa(filtros).subscribe((response: any) => {
      let dataMap;
      let defaultFills;
      let fillDefault = {};
      fillDefault['defaultFill'] = '#606060';
      defaultFills = Object.assign(this.dataMAP3, fillDefault);
      response.forEach((element: any, index, geography) => {
        let nombre = element.pais_codigo;
        let data = {};
        let fill = {};
        data[nombre] = {fillKey: element.pais_codigo, empresas: element.empresas_pais , numberOfcities: element.numero_ciudades, numberclients: element.numero_clientes, nombrePais: element.pais_nombre, imagenPais: `assets/maps/${element.pais_codigo.toLowerCase()}.svg` };
        if( window.innerWidth < 700 ){
          fill[element.pais_codigo] = '#061E38';
        }
        let marker = {name: 'foo', iconUrl: 'assets/icono-geo.png', radius: 20, latitude: element.latitud, longitude: element.longitud, nombrePais: element.pais_nombre, codigoPais: element.pais_codigo, empresas: element.empresas_pais, numberOfcities: element.numero_ciudades, numberclients: element.numero_clientes, imagenPais: `assets/maps/${element.pais_codigo.toLowerCase()}.svg`};
        this.dataMAP2.push(marker);
        dataMap = Object.assign(this.dataMAP1, data);
        defaultFills = Object.assign(this.dataMAP3, fill);
      });
      var mapa = new Datamap({
        element: document.getElementById('container'),
        data: dataMap,
        projection: 'mercator',
        responsive: true,
        fills: defaultFills,
        geographyConfig: {
            highlightFillColor: function(code) {
              if(code.fillKey){
                return '#061E38';
              } else {
                return '#606060';
              }

          },
          // highlightFillColor: '#061E38',
            popupTemplate: function(geo, data) {
              let ciudades = 'Ciudades';
              let clientes = 'Clientes';
              if( localStorage.getItem('language') == 'es' ){
                ciudades = 'Ciudades';
                clientes = 'Clientes';
              }else {
                ciudades = 'Cities';
                clientes = 'Customers';
              }
              return ['<div style="padding: 8px !important; border-radius: 5px !important; background-color: #071e39; color:#ffffff; font-size: 15px;" class="hoverinfo"><strong><span style="color: #60E266" class="color-tooltipmap">' + geo.properties.name + '</span><br>',
              ' <span style="color: #60E266" class="color-tooltipmap">' + data.numberOfcities + '</span> ' +  ciudades  + '<br> <span style="color: #60E266" class="color-tooltipmap">' + data.numberclients,
              '</span> ' +  clientes + ' </strong></div>'].join('');
            },
        },
        done: function(datamap) {
          let data = datamap.options.data;
          datamap.svg.call(d3.behavior.zoom().on("zoom", redraw));

          function redraw() {
              let e = (<d3.ZoomEvent> d3.event);
               datamap.svg.selectAll("g").attr("transform", "translate(" + e.translate + ")scale(" + e.scale + ")");
              //  rescaleMarker(datamap);
          }

          datamap.svg.selectAll('.datamaps-subunit').on('click', function(datamap) {
            let paisInformacion;
            Object.keys(data).map( id => {
                if ( datamap && id && (id == datamap.id )){
                  paisInformacion = data[id];
                }
              }  );

            if (!paisInformacion){
               return;
            }


            var modal = document.getElementById("myModal2");
            var descripcion = document.getElementsByClassName("descripcion-phcmodal")[0];

            let empresas = '';

            paisInformacion.empresas.forEach(ciudad => {
              let ciudades = '';
              ciudad.empresas.map(empresas => {
                ciudades += '<span style="display: block;">'+ empresas.nombre + '</span>'
              })
              empresas += `
              <p style="font-size: 17px;">${ciudad.nombre}</p>
              <div class="empresasPorCiudad" style="margin-bottom: 5px;">
              ${ciudades.replace(/\s+/g, ' ')}
              </div>
              `
            });

           let contentEmpresa = '';
            let empresasLabel = 'Empresas';
              let ciudades = 'Ciudades';
              let clientes = 'Clientes';
              if( localStorage.getItem('language') == 'es' ){
                empresasLabel = 'Empresas';
                ciudades = 'Ciudades';
                clientes = 'Clientes';
              }else {
                empresasLabel = 'Companies';
                ciudades = 'Cities';
                clientes = 'Customers';
              }

           if( empresas.length > 0 ){
            contentEmpresa = `
            <div>
              <p class="title empresa"> ${empresasLabel} </p>
            </div>
             <div class="empresas-paises">
                ${ empresas }
             </div>`;
           }

            if ( !modal.classList.contains('modal fade show') ){
              modal.style.display = "block";
              modal.style.paddingRight = "17px";
              modal.className="modal fade show";
            }

            descripcion.innerHTML = `
            <div class="information-phcmundo">
                <div class="contenedor-painform">
                  <img src="${paisInformacion.imagenPais}" alt="${paisInformacion.nombrePais}" />
                      <div class="content-paises">
                      <div class="nombre-count">${paisInformacion.nombrePais}</div>
                        <div class="info-country">
                          <p> <span>${paisInformacion.numberOfcities}</span> ${ciudades} </p>
                          <p> <span>${paisInformacion.numberclients}</span> ${clientes} </p>
                        </div>
                      </div>
                </div>
                 <div>
                   ${contentEmpresa}
                 </div>
              </div>`;

          })
        }
      });

      window.addEventListener('resize', function() {
        mapa.resize();
      }, {passive: false});


      function handleMarkers(layer, data, options, geography) {
        var self = this,
          fillData = this.options.fills,
          svg = this.svg;

        if (!data || (data && !data.slice)) {
          throw 'Datamaps Error - markers must be an array';
        }

        var markers = layer.selectAll('image.datamaps-marker').data(data, JSON.stringify);

        markers
          .enter()
          .append('image')
          .attr('class', 'datamaps-marker')
          .attr('xlink:href', function(datum) {
            return datum.iconUrl || options.defaultIcon;
          })
          .attr('height', 15)
          .attr('width', 15)
          .attr('x', function(datum) {
            var latLng;
            if (datumHasCoords(datum)) {
              latLng = self.latLngToXY(datum.latitude, datum.longitude);
            } else if (datum.centered) {
              latLng = self.path.centroid(svg.select('path.' + datum.centered).data()[0]);
            }
            if (latLng) {
              return latLng[0] - 10;
            }
          })
          .attr('y', function(datum) {
            var latLng;
            if (datumHasCoords(datum)) {
              latLng = self.latLngToXY(datum.latitude, datum.longitude);
            } else if (datum.centered) {
              latLng = self.path.centroid(svg.select('path.' + datum.centered).data()[0]);
            }
            if (latLng) {
              return latLng[1] - 20;
            }
          })
          .on('mouseover', function(data) {
            var $this = d3.select(this);
            d3.selectAll('.datamaps-hoverover').style('display', 'block');
            if (options.popupOnHover) {

              self.updatePopup($this, data, options, svg);
            }
          })
          .on('click', function(datum) {

              self.mostrarMensaje();

            alert( datum + "\n Nombre"+ datum.nombrePais + "\n" + "Ciudades: " + datum.numberOfcities
                  + "\n" + "Clientes: " + datum.numberclients + "\n Custom data" + JSON.stringify(datum.Custom ));
          })
          .on("click", function(d){
            var modal = document.getElementById("myModal2");
            var descripcion = document.getElementsByClassName("descripcion-phcmodal")[0];

            let empresas = '';

            for(let empresa of d.empresas_pais){
                empresas += `<p> ${empresa} </p>`
            }

            let contentEmpresa = '';
            let empresasLabel = 'Empresas';
              let ciudades = 'Ciudades';
              let clientes = 'Clientes';
              if( localStorage.getItem('language') == 'es' ){
                empresasLabel = 'Empresas';
                ciudades = 'Ciudades';
                clientes = 'Clientes';
              }else {
                empresasLabel = 'Companies';
                ciudades = 'Cities';
                clientes = 'Customers';
              }

           if( empresas.length > 0 ){
            contentEmpresa = `
            <div>
              <p class="title empresa"> ${empresasLabel} </p>
            </div>
             <div class="empresas-paises">
               <ul>
                 <li> ${ empresas }  </li>
               </ul>
             </div>`;
           }

            if ( !modal.classList.contains('modal fade show') ){
              modal.style.display = "block";
              modal.style.paddingRight = "17px";
              modal.className="modal fade show";
            }

            descripcion.innerHTML = `
            <div class="information-phcmundo">
            <div class="contenedor-painform">
                  <img src="${d.imagenPais}"  alt="${d.nombrePais}" />
                      <div class="content-paises">
                      <div class="nombre-count">${d.nombrePais}</div>
                        <div class="info-country">
                          <p> <span>${d.numberOfcities}</span> ${ciudades} </p>
                          <p> <span>${d.numberclients}</span> ${clientes} </p>
                        </div>
                      </div>
                </div>
                 <div>
                   ${contentEmpresa}
                 </div>
              </div>`;
          })
          .on('mouseout', function(data) {
          var $this = d3.select(this);
          if (options.highlightOnHover) {
            var previousAttributes = JSON.parse($this.attr('data-previousAttributes'));
            for (var attr in previousAttributes) {
              $this.style(attr, previousAttributes[attr]);
            }
            self.updatePopup($this, data, options, svg);
          }
          d3.selectAll('.datamaps-hoverover').style('display', 'none');
        });

        markers.exit()
          .transition()
          .attr('height', 0)
          .remove();

        function datumHasCoords(datum) {
          return typeof datum !== 'undefined' && typeof datum.latitude !== 'undefined' && typeof datum.longitude !== 'undefined';
        }
      }

      mapa.addPlugin('markers', handleMarkers);
      var options = {
        popupOnHover: true,
        popupTemplate: function(geo, data) {
          return ['<div class="hoverinfo"><strong><span class="color-tooltipmap">' + geo.properties.name + '</span><br>',
            ' <span class="color-tooltipmap">' + data.numberOfcities + '</span> Ciudades <br> <span class="color-tooltipmap">' + data.numberclients,
            '</span> Clientes </strong></div>'].join('');
        }
      };
      mapa.markers(this.dataMAP2, {options});
    });
  }

  private getDismissReason(reason: any): string {
    this.ngOnInit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
