<div id="myModal" class="modal modal-datalocal">
    <div class="row mt-3 mr-3">
        <div class="col-12">
            <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal()">
        </div>
    </div>
    <div class="row modal-rps">
        <div class="col-lg-12">
            <div class="modal-content">
                <div class="header-modal">
                    <div class="logo-contenedor">
                        <img src="assets/logo_PHC_blanco.png" alt="" class="logo-header">
                    </div>
                    <h2 [innerHTML]="parrafosGenerales['texto_1']"></h2>
                </div>
                <div class="body-moudal">
                    <h4 [innerHTML]="parrafosGenerales['texto_2']"></h4>
                    <div class="controls-icons">
                        <div class="text-center">
                            <div *ngFor="let icono of iconos,  let i = index" class="icon-hover" data-aos="zoom-in">
                                <div (mouseout)='hover = -1' (mouseover)='hover = i'>
                                    <a (click)="routerOrPopup(icono.url)">
                                        <img [src]="icono.img " alt="icono.title" class="iconosService icon-each">
                                        <p [innerHTML]="icono.title"></p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-container">
                        <button type="button" (click)="closeModal()" class="btn btn-form">{{"proyeccionesForm.opcion7" | translate }} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>