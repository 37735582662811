import {Injectable} from '@angular/core';
import CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  private SECRET_KEY = '12345*aqsfCVwotiyub67890nlopsgdfPOIHGFDSB()=qw$';
  private secureStorage: SecureStorage;

  constructor() {
    this.startSecureStorage();
  }

  startSecureStorage(): void {
    this.secureStorage = new SecureStorage(localStorage, {
      hash: (key) => {
        key = CryptoJS.SHA256(key, this.SECRET_KEY);
        return key.toString();
      },
      encrypt: (data) => {
        data = CryptoJS.AES.encrypt(data, this.SECRET_KEY);
        data = data.toString();
        return data;
      },
      decrypt: (data) => {
        data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      }
    });
  }

  setItem(key: string, value: any): void {
    this.secureStorage.setItem(key, value);
  }

  getItem(key: string): any {
    return this.secureStorage.getItem(key);
  }

  removeItem(key: string): void {
    this.secureStorage.removeItem(key);
  }

  clear(): void {
    return this.secureStorage.clear();
  }
}
