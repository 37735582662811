<div *ngIf="datosServicio && datosServicio.length > 0" class="container servicios-sl">

    <div class="controls-icons">
        <div class="position-relative w-100 text-center pb-4">
            <h2 class="subtitle-raya" data-aos="zoom-in">{{"serviciosDigital.titulo" | translate }}</h2>
        </div>
        <div class="text-center">
            <div *ngFor="let icono of iconos,  let i = index" class="icon-hover" data-aos="zoom-in">
                <div (mouseover)="owlCar.to(icono.id.toString())" (mouseout)='hover = -1' (mouseover)='hover = i'>
                    <!-- <a [routerLink]="['/inicio' ,{ outlets: { inicio: ['proyecciones-de-mercado'] } } ]"> -->
                    <a (click)="routerOrPopup(icono.url)">
                        <img [src]="icono.img " alt="icono.title" class="iconosService icon-each">
                        <p>{{ icono.title }}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="all-slider" data-aos="zoom-in">
        <div class="controls-arrows">
            <p>
                <a class="btn-prev" (click)="owlCar.prev()">
                    <img src="assets/flecha-slide1.png" alt="">
                </a>
                <a class="btn-next" (click)="owlCar.next()">
                    <img src="assets/flecha-slide2.png" alt="">
                </a>
            </p>
        </div>

        <owl-carousel-o class="slider-services" [options]="customOptions" (translated)="getPassedData($event)" (initialized)="getPassedData($event)" #owlCar id="owlCar">

            <ng-container *ngFor="let item of carouselData">
                <ng-template carouselSlide [id]="item.id" [width]="item.width">
                    <!-- <div class="slider" [ngClass]=" {  } "> -->
                    <div class="slider">
                        <img [src]="item.src" alt="item.text">
                        <p class="title-derecha">{{item.text}}</p>
                        <p class="title-izquierda">{{item.text}}</p>
                        <div class="tarjeta-azul">
                            <p class="title desaparecer-active" style="cursor: pointer;" (click)="routerOrPopup(item.url)">{{item.text}}</p>

                            <div class="lineaverde desaparecer-active"></div>
                            <p class="description desaparecer-active">{{item.descripcion | cutHtmlString:350:true}}</p>
                        </div>
                    </div>
                </ng-template>
            </ng-container>

        </owl-carousel-o>
    </div>

</div>