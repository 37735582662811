import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica12/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#00AF50', '#A5A5A5', '#055F99'],
          values: data,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            position: ['50%', '50%'],
          },
          legend: {
            data: this.lenguaje == 'es' ? [
              'Existentes + CXC + CLP',
              'Expansión Adicional',
              'Demanda Energía [GWh-día]',
            ] : [              
              'Existing + CXC + CLP',
              'Additional expansion',
              'Energy Demand [GWh-day]'
            ],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          yAxis: {
            type: 'value',
          },
          xAxis: {
            /* data: [
            'existentes',
            'expansion',
            'demanda',
            'eolica',
            'solar',
            'GNL',
            'GLP',
            'retiros',
          ], */
            data: data.map((e) => this.datePipe.transform(e.fecha, 'MMM/yyyy')),
          },
          series: [
            {
              name: this.lenguaje == 'es' ? 'Existentes + CXC + CLP' : 'Existing + CXC + CLP',
              type: 'line',
              areaStyle: {color: '#00AF50'},
              lineStyle: {
                color: '#00AF50',
              },
              data: data.map((e) => parseFloat(e.existentes.toFixed(2))),
            },
            {
              name: this.lenguaje == 'es' ? 'Expansión Adicional' : 'Additional expansion',
              type: 'line',
              areaStyle: {color: '#A5A5A5'},
              lineStyle: {
                color: '#A5A5A5',
              },
              data: data.map((e) => parseFloat(e.expansion.toFixed(2))),
            },
            {
              name: this.lenguaje == 'es' ? 'Demanda Energía [GWh-día]' : 'Energy Demand [GWh-day]',
              type: 'line',
              lineStyle: {
                width: 3,
                color: '#055F99',
              },
              data: data.map((e) => parseFloat(e.demanda.toFixed(2))),
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
