import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HomeService} from '../../../services/home.service';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {DetallePagoComponent} from '../../detalle-pago/detalle-pago.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {InternasServiceService} from '../../../services/internas-service.service';
import {MatSnackBarUtil} from '../../../utils/mat-snack-bar.util';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-versiones-window',
  templateUrl: './versiones-window.component.html',
  styleUrls: ['./versiones-window.component.scss']
})
export class VersionesWindowComponent implements OnInit {

  public responseCupons = [];
  redirectUrl = environment.urlWeb;
  publicKeyWompi: String;

  constructor(
    public dialogRef: MatDialogRef<VersionesWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private homeService: HomeService,
    private authService: AuthService,
    private modalService: NgbModal,
    private internasServiceService: InternasServiceService,
    private matSnackBarUtil: MatSnackBarUtil,
    private translateService: TranslateService
  ) {


    if (this.data) {
    }

    this.getSuscripciones();


  }

  ngOnInit(): void {
    let apiKeywompi = JSON.parse(localStorage.getItem('credenciales'));
    let keyDecript = AES.decrypt(apiKeywompi.wompiKey, 'abcd@$--12');
    this.publicKeyWompi = keyDecript.toString(CryptoJS.enc.Utf8);


  }

  onNoClick() {
    this.dialogRef.close([]); // this.data.list_games
  }

    getUser(): Promise<any> {
    return new Promise(resolve => {
      resolve(this.authService.getUserData());
    });
  }

  getSuscripciones() {
  
    
    this.homeService.getSuscripciones({estado: 'activo'}).subscribe(data => {

      this.responseCupons = data;
    });
  }

  pagos(valor, id) {
    var winWidgetCheckout = window['WidgetCheckout'];
    var ref = this.generaNss();
    valor = parseInt(valor);
    valor = valor * 100;

    if (valor === 0) {
      this.closeModal();
      this.internasServiceService.setRegistroSuscripcion(id, 'version 1').subscribe(data => {

        const message = this.translateService.instant('suscripcion.gratis');
        this.matSnackBarUtil.matSnackBarSuccess(message);
      });
    }

    if (valor > 0) {

      this.getUser().then(user => {

        

        var checkout = new winWidgetCheckout({
          currency: 'COP',
          amountInCents: valor,
          reference: id + '-' + ref + '-' + user.email,
          publicKey: this.publicKeyWompi,
          redirectUrl: `${this.redirectUrl}/inicio/proyecciones-de-mercado` // Opcional
        });

        console.log({
          currency: 'COP',
          amountInCents: valor,
          reference: id + '-' + ref + '-' + user.email,
          publicKey: this.publicKeyWompi,
          redirectUrl: `${this.redirectUrl}/inicio/proyecciones-de-mercado` // Opcional
        })

        checkout.open(function(result) {
          // localStorage.setItem('useHash', '1');

          const transaction = result.transaction;

          const transactionId = transaction.id;
          localStorage.setItem('transactionId', transaction.id);

          localStorage.setItem('transactionObject', JSON.stringify(transaction));


          if (transactionId) {
            const ngbModalRef: NgbModalRef = this.modalService.open(DetallePagoComponent, {centered: true, size: 'xl'});
            ngbModalRef.componentInstance.idInput = transactionId;
            ngbModalRef.componentInstance.envInput = 'test';
            ngbModalRef.result.then(resultModal => {

            }, reason => {
              console.warn('####### checkout.open #######', reason);
            });
          }
        }.bind(this));
      });
      
    }
  }

  generaNss(): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < charactersLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;

  }

  get name(): string {
    return this.authService.getPropertyOfCurrentUserData('first_name');
  }
  closeModal() {
    this.dialogRef.close();
  }
}

