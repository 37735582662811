import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';
import { InicioSesionComponent } from '../../inicio-sesion/inicio-sesion.component';
import {TranslateService} from '@ngx-translate/core';
import { ConsultaUsuarioComponent } from "../../phc-interno/consulta-usuario/consulta-usuario.component";
import { InfoSuscripcionComponent } from "../../phc-interno/info-suscripcion/info-suscripcion.component";
import {SecureStorageService} from '../../../services/secure-storage.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { ServiciosNoDigitalService } from 'src/app/services/servicios-no-digital.service';

@Component({
  selector: 'app-header-general',
  templateUrl: './header-general.component.html',
  styleUrls: ['./header-general.component.css', './change-password.css']
})
export class HeaderGeneralComponent implements OnInit {
  logged: any;
  dataUser: any;
  modalReference: NgbModalRef;
  closeResult = '';
  loading: boolean;
  mensajeEstado: string;
  primeraVez: boolean;
  successPassword: boolean;
  firtsTimePasswordForm: FormGroup;
  contrasenaError: string;
  messajeErrorPassword: any;
  errorMensaje: boolean;
  getPassword: any;
  digitalServices = [];
  // loginForm: any;
  @ViewChild('construccion') paginaConstruccionRef: ElementRef;
  constructor(private modalService: NgbModal,
              private homeService: HomeService,
              private authService: AuthService, private _builder: FormBuilder,
              private translateService: TranslateService,
              private secureStorageService: SecureStorageService,
              private serviciosNoDigitalService: ServiciosNoDigitalService,
              private router: Router
  ) {
    this.getPassword = false;
    this.firtsTimePasswordForm = this._builder.group({
      /*Se valida que :
        - La contraseña sea obligatoria
        - Tenga minimo 8 carácteres
        - Solo permita letras y números
        */
      digitarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')])),
      confirmarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')]))
    });
  }

  ngOnInit(): void {
    this.consultaServiciosDigitales();
    if (this.secureStorageService.getItem('djangoUser')) {
      this.logged = true;
      this.dataUser = this.secureStorageService.getItem('djangoUser');
    } else {
      this.logged = false;
    }
  }

  cerrarSesion() {
    this.logged = false;
    this.dataUser = '';
    this.authService.logout();
    this.router.navigate(['/']);
  }

  consultaServiciosDigitales() {
    let objeto = {
      nombre: "",
      digital: "si",
      estado: "activo"
    }
    this.serviciosNoDigitalService.consultarServiciosNoDigitales(objeto).subscribe((data: any) => {
      for (const value of data) {
        const dataIconos = {
          id: value.id,
          url: value.url,
          title: value.nombre,
          img: this.urlS3 + "/" + value.icono,
          imgHover: this.urlS3 + "/" + value.icono
        };
        this.digitalServices.push(dataIconos);
      }
    });
  }

  get urlS3(): string {
    return environment.urlS3;
  }

  inicioSesion() {

    this.modalReference = this.modalService.open(InicioSesionComponent, { centered: true });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.authService.getEmitter()
        .subscribe(respuesta => {
          if (this.secureStorageService.getItem('djangoUser')) {
            this.logged = true;
            this.dataUser = this.secureStorageService.getItem('djangoUser');
            this.asyncPromise();
          } else {
            this.logged = false;
          }
        });
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  paginaConstruccion(content) {
    this.modalService.open(content, { centered: true, size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  consultaUser(){
    this.modalService.open(ConsultaUsuarioComponent, { centered: true, size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // private getDismissReason(reason: any): string {
  //   this.ngOnInit();
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  firstTime(value?, estado?) {
    if (this.loading == true) {
      this.asyncPromise();
    }
    else {

      if (estado == false) {

        this.mensajeEstado = this.translateService.instant('errores.login');
      }

      switch (value) {
        case undefined:
          this.loading = true;
          this.asyncPromise();
          break;
        case true:
          this.loading = false;
          this.primeraVez = true;
          break;
        case false:
          this.loading = false;
          location.reload();
          break;
      }

    }
  }


  closeModal() {
    this.modalService.dismissAll();
    this.mensajeEstado = "";
    this.errorMensaje = false;
  }

  login() {
    //debugger
     this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.logged = this.authService.isLoggedIn();
       if (this.logged) {
          this.router.navigate(['/inicio/trazabilidad-de-proyectos']);
       }
 
     });
   }

  validateLoggin(url: string){
    const arrayUrls = [];
    arrayUrls['proyecciones-de-mercado'] = 'proyecciones-de-mercado';
    arrayUrls['estudios-de-conexion'] = 'estudios-de-conexion';
    arrayUrls['asesoria-en-linea'] = 'asesoria-en-linea';
    arrayUrls['market-projections'] = 'proyecciones-de-mercado';
    arrayUrls['connection-studies'] = 'estudios-de-conexion';
    arrayUrls['online-consulting'] = 'asesoria-en-linea';
    arrayUrls['project-traceability'] = 'trazabilidad-de-proyectos';
    arrayUrls['trazabilidad-de-proyectos'] = 'trazabilidad-de-proyectos';

    const urlNext = arrayUrls[url];
    if (urlNext && urlNext !== 'trazabilidad-de-proyectos') {
      this.router.navigate(['/inicio', urlNext]);
      return;
    } else if (!urlNext) {
      this.paginaConstruccion(this.paginaConstruccionRef);
      return;
    }
    this.logged = this.authService.isLoggedIn();
    if (!this.logged) {
      this.login()
    }else{
        this.router.navigate(['/inicio/trazabilidad-de-proyectos']);
      }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.errorMensaje = false;
      this.messajeErrorPassword = '';
      this.mensajeEstado = "";
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.errorMensaje = false;
      // this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.mensajeEstado = "";
      return 'by clicking on a backdrop';
    } else {
      this.errorMensaje = false;
      // this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.mensajeEstado = "";
      return `with: ${reason}`;
    }
  }
  /*
    Método usado para volver a preguntar sí la información
    en el local host ya existe
  */
  asyncPromise() {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        this.loading = false;
        let property = "primera_vez";
        let value = this.authService.getPropertyOfCurrentUserData(property);
        let propertyEstado = "is_active";
        let valueEstado = this.authService.getPropertyOfCurrentUserData(propertyEstado);
        this.firstTime(value, valueEstado);
      }, 1000)
    })
  }
  submitChangePassword(object) {
    if (this.firtsTimePasswordForm.valid) {
      this.homeService.changePassword(object).subscribe(data => {


        switch (data.type) {
          case "danger": this.messajeErrorPassword = data.message; break;
          case "warning": this.messajeErrorPassword = data.message; break;
          default: this.messajeErrorPassword = this.translateService.instant('formularioAvisos.opcion11'); break;
        }
      }, err => {
        this.messajeErrorPassword = "Error inesperado, comunicarse con administración";
      });
    }

  }
  changePassword(status) {
    this.errorMensaje = false;
    this.getPassword = status;
    this.messajeErrorPassword = '';
  }

  consultaSuscripcion(){
    this.modalService.open(InfoSuscripcionComponent, { centered: true, size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
