import {Component, OnInit} from '@angular/core';
import {SobreNosotros} from 'src/app/models/sobrenosotros.models';
import {NuestroEquipo} from '../../../models/equipo.models';

import {tabulador} from '../../../models/tabulador.models';

import {HomeService} from 'src/app/services/home.service';
import {InternasServiceService} from '../../../services/internas-service.service';
import {environment} from '../../../../environments/environment';

import {Title} from '@angular/platform-browser';
import {SeoService} from '../../../services/seo.service';


@Component({
  selector: 'app-sobre-nosotros',
  templateUrl: './sobre-nosotros.component.html',
  styleUrls: ['./sobre-nosotros.component.css']
})
export class SobreNosotrosComponent implements OnInit {

  nosotrosParametros: any;
  nosotrosCampos = new SobreNosotros;
  misionCampos = new tabulador;
  visionCampos = new tabulador;
  valoresCampos = new tabulador;
  objetoCompleto: NuestroEquipo;
  arrayTabulador = [];
  archivoTipo: any;
  active = '1';
  equipoDescripcion: any;
  dataPrueba: any[] = [];

  constructor(private internas: InternasServiceService, private homeService: HomeService, private seo: SeoService) {
    this.nosotrosParametros = {seccion: 'Nosotros', estado: 'Activo'};
    this.arrayTabulador = [{seccion: 'Mision', estado: 'Activo'}, {seccion: 'Vision', estado: 'Activo'},
      {seccion: 'Valores', estado: 'Activo'}]
    this.homeService.consultarNosotros(this.nosotrosParametros)
      .subscribe((respuesta: any) => {
        if (respuesta.data.length === 0) {
          return;
        }
        respuesta.data[0].campos.forEach(campo => {
          if (campo.nombre == 'Titulo') {
            this.nosotrosCampos.titulo = campo.valor;
          }
          if (campo.nombre == 'Descripcion') {
            this.nosotrosCampos.descripcion = campo.valor;
          }
          if (campo.nombre == 'Subtitulo') {
            this.nosotrosCampos.subtitulo = campo.valor;
          }
          if (campo.nombre == 'Archivo') {
            this.nosotrosCampos.archivo = campo.valor;
          }
          if (campo.nombre == 'Tipo contenido') {
            this.archivoTipo = campo.valor;
          }
        });
      });

    this.arrayTabulador.forEach(item => {

      this.homeService.consultarNosotros(item)
        .subscribe((respuesta: any) => {
          if (respuesta.data.length === 0) {
            return;
          }
          respuesta.data[0].campos.forEach(campo => {
            if (item.seccion == 'Mision') {

              if (campo.nombre == 'Titulo') {
                this.misionCampos.titulo = campo.valor;
              }
              if (campo.nombre == 'Texto') {
                this.misionCampos.descripcion = campo.valor;
              }

            } else if (item.seccion == 'Vision') {

              if (campo.nombre == 'Titulo') {
                this.visionCampos.titulo = campo.valor;
              }
              if (campo.nombre == 'Texto') {
                this.visionCampos.descripcion = campo.valor;
              }

            } else if (item.seccion == 'Valores') {

              if (campo.nombre == 'Titulo') {
                this.valoresCampos.titulo = campo.valor;
              }
              if (campo.nombre == 'Texto') {
                this.valoresCampos.descripcion = campo.valor;
              }

            }


          });
        });
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.homeService.consultaEquipoGet()
      .subscribe((respuesta: any) => {

        this.dataPrueba = respuesta.data;
      });

    this.homeService.filtrarSecciones({nombre: 'Nuestro equipo'})
      .subscribe((respuesta: any) => {
        this.equipoDescripcion = respuesta[0];
      });

    this.seo.addMetaTagsByPage('Meta tags - página sobre nosotros');
  }

  get urlS3() {
    return environment.urlS3;
  }

}
