import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormControl, Validators, FormGroup} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth.service';
import {User} from '../../../models/user.models';
import {HomeService} from '../../../services/home.service';
import {ProyeccionesMercadoService} from '../../../services/proyecciones-mercado.service';
import {environment} from 'src/environments/environment';
import {MatSnackBarUtil} from '../../../utils/mat-snack-bar.util';
import {ImageSnippet} from 'src/app/models/image';
import {SecureStorageService} from '../../../services/secure-storage.service';


@Component({
  selector: 'app-consulta-usuario',
  templateUrl: './consulta-usuario.component.html',
  styleUrls: ['./consulta-usuario.component.css']
})
export class ConsultaUsuarioComponent implements OnInit {
  usuarioFormulario: FormGroup;
  User = new User;
  documentoOrNIT: boolean = true;
  parsearLocal = this.secureStorageService.getItem('djangoUser');
  fileRutCurrent: any;
  fileCameraCurrent: any;
  mensajeAfterRegistro: boolean;

  rutFile: ImageSnippet;
  camaraFile: ImageSnippet;
  fileEventRut: any;
  fileEventCamara: any;
  fileNameCurrent: string;
  formatFilerut: boolean;
  formatFilecamera: boolean;
  responseRutFile: unknown;
  responseCamaraFile: unknown;

  constructor(private _builder: FormBuilder, private modalService: NgbModal,
              private translateService: TranslateService, private AuthService: AuthService,
              private HomeService: HomeService, private notify: MatSnackBarUtil,
              private ProyeccionesMercadoService: ProyeccionesMercadoService,
              private secureStorageService: SecureStorageService
  ) {

    Promise.all([this.tipodocumento(), this.empresa()]).then(values => {

        this.usuarioFormulario = this._builder.group({
          nombre: new FormControl(this.parsearLocal.first_name, [Validators.required]),
          apellido: new FormControl(this.parsearLocal.last_name, [Validators.required]),
          correo: new FormControl(this.parsearLocal.email, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]),
          tipoDocumento: new FormControl(values[0], []),
          // tipoPersona:new FormControl(this.parsearLocal.tipo_persona,[]),
          numeroDocumento: new FormControl(this.parsearLocal.documento_usuario, [Validators.required, Validators.pattern('^[0-9]*$')]),
          celular: new FormControl(this.parsearLocal.celular, [Validators.required, Validators.pattern('^[0-9]*$')]),
          telefono: new FormControl(this.parsearLocal.telefono == 0 ? '' : this.parsearLocal.telefono, [Validators.pattern('^[0-9]*$')]),

          cargo: new FormControl(this.parsearLocal.cargo),
          empresa: new FormControl(values[1]),
          nitEmpresa: new FormControl(this.parsearLocal.empresa, []),
          // cargo: new FormControl('', [Validators.required]),
        });
        this.fileRutCurrent = this.parsearLocal.rut_file;
        this.fileCameraCurrent = this.parsearLocal.camara_file;
        this.usuarioFormulario.controls.correo.disable();
        this.usuarioFormulario.controls.tipoDocumento.disable();
        this.usuarioFormulario.controls.empresa.disable();
        this.usuarioFormulario.controls.nitEmpresa.disable();
        this.usuarioFormulario.controls.numeroDocumento.disable();

      }
    ).catch((err) => {
      this.closeModal();
      notify.matSnackBarDanger('Hubo error en el servidor, intente más tarde');
    });
  }

  get urlS3() {
    return environment.urlS3;
  }

  tipodocumento() {
    return new Promise((resolve, reject) => {
      this.HomeService.tipoDeDocumento({'id': this.parsearLocal.tipo_documento}).subscribe(data => {
          if (data[0].tipo == 'NIT') {
            this.documentoOrNIT = false;
          } else {
            this.documentoOrNIT = true;
          }
          resolve(data[0].tipo);
        },
        err => {
          reject(err);
        });
    });
  }

  empresa() {
    return new Promise((resolve, reject) => {
      this.ProyeccionesMercadoService.getNameBusiness({'nit': this.parsearLocal.empresa}).subscribe(data => {
          resolve(data);
        },
        err => {
          reject(err);
        });
    });
  }

  ngOnInit(): void {
  }

  onSubmitRegistro(formDir) {
    if (!this.usuarioFormulario.valid) {
      this.usuarioFormulario.markAllAsTouched();
      return;
    }

    if (this.documentoOrNIT) {
      this.registro();
    } else {
      if (this.rutFile && this.rutFile.file.type != 'application/pdf') {
        // this.matSnackBarUtil.matSnackBarWarning('Verifique el formato del archivo');
        this.formatFilerut = true;
        return;
      }

      if (this.camaraFile && this.camaraFile.file.type != 'application/pdf') {
        // this.matSnackBarUtil.matSnackBarWarning('Verifique el formato del archivo');
        this.formatFilecamera = true;
        return;
      }

      if (!this.camaraFile) {
        this.responseCamaraFile = '';
      }

      if (!this.rutFile) {
        this.responseRutFile = '';
      }
      Promise.all([this.uploadRutFilePromise(), this.uploadCamaraFilePromise()]).then(response => {
        this.responseRutFile = response[0];
        this.responseCamaraFile = response[1];
        this.registro();
      });

    }
  }

  registro() {
    if (this.usuarioFormulario.valid) {
      this.User.cargo = this.usuarioFormulario.get('cargo').value;
      this.User.celular = this.usuarioFormulario.get('celular').value;
      this.User.telefono = this.usuarioFormulario.get('telefono').value ? this.usuarioFormulario.get('telefono').value : 0;
      this.User.documento_usuario = this.usuarioFormulario.get('numeroDocumento').value;
      this.User.email = this.parsearLocal.email;
      this.User.empresa = this.parsearLocal.empresa;
      this.User.first_name = this.usuarioFormulario.get('nombre').value;
      this.User.last_name = this.usuarioFormulario.get('apellido').value;
      this.User.tipo_documento = this.parsearLocal.tipo_documento;
      this.User.tipo_persona = this.parsearLocal.tipo_persona;
      this.User.username = this.parsearLocal.username;
      this.User.usuario_creador = this.parsearLocal.usuario_creador;
      this.User.camara_file = this.responseCamaraFile || this.parsearLocal.camara_file;
      this.User.rut_file = this.responseRutFile || this.parsearLocal.rut_file;
      try {
        this.AuthService.updateUser(this.User).subscribe(data => {


            if (data) {
              this.AuthService.getCurrentUserData(true).subscribe(data => {
                const message = this.translateService.instant('general.actualizacion');
                this.notify.matSnackBarSuccess(message);
                this.closeModal();
              });
            }

          },
          err => {
            this.notify.matSnackBarWarning('Ha ocurrido un error en el servidor, por favor intente de nuevo');
            this.closeModal();
          }
        );
      } catch (error) {
        this.notify.matSnackBarDanger('Ha ocurrido un error en el servidor, por favor intente de nuevo más tarde');
        this.closeModal();
      }
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  uploadRutFilePromise() {
    return new Promise((resolve, reject) => {
      if (!this.rutFile) {
        resolve();
      }
      this.HomeService.guardarImg(this.rutFile.file).subscribe((data: any) => resolve(data.namefile), err => reject(err));
    });
  }

  uploadCamaraFilePromise() {
    return new Promise((resolve, reject) => {
      if (!this.camaraFile) {
        resolve();
      }
      this.HomeService.guardarImg(this.camaraFile.file).subscribe((data: any) => resolve(data.namefile), err => reject(err));
    });
  }

  processFile(imageInput: any, events, tipo) {
    const file: File = imageInput.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        if (tipo == 'rut') {
          this.rutFile = new ImageSnippet(event.target.result, file);
          this.fileEventRut = events;
          this.formatFilerut = false;

        }
        if (tipo == 'camara') {
          this.camaraFile = new ImageSnippet(event.target.result, file);
          this.fileEventCamara = events;
          this.formatFilecamera = false;
        }
      });
      reader.readAsDataURL(file);
    } else {
      if (tipo == 'rut') {
        this.rutFile = undefined;
      }
      if (tipo == 'camara') {
        this.camaraFile = undefined;
      }
    }
  }
}
