import { Component, OnInit } from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { HomeService } from '../../services/home.service';
import { Testimonio } from '../../models/testimonios.models';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-testimonios',
  templateUrl: './testimonios.component.html',
  styleUrls: ['./testimonios.component.css']
})
export class TestimoniosComponent implements OnInit {

    customOptions: OwlOptions = {
      loop: true,
      items: 3,
      dotsSpeed: 500,
      dots: true,
      smartSpeed: 600,
      dragEndSpeed: 600,
      center: true,
      autoHeight: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        900: {
          items: 1
        },
        1025: {
          items: 3
        }
      },
      // stagePadding: 40,
      nav: false
    }

  testimonios = new Testimonio;
  objetoCompleto: Testimonio;
  arrayTestimonios: Array<Testimonio> = [];
  testimoniosDescripcion: any;
  constructor(private translate: TranslateService, private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.consultarNosotros({ estado : 'activo' , seccion: 'Testimonio'  })
        .subscribe( data => {



          for (const array of data.data) {
            array.campos.forEach(campo => {
              if (campo.nombre == 'Nombre') {
                this.testimonios.nombre = campo.valor;
              }
              if (campo.nombre == 'Perfilprofesional') {
                this.testimonios.perfilprofesional = campo.valor;
              }
              if (campo.nombre == 'Descripcion') {
                this.testimonios.descripcion = campo.valor;
              }
              if (campo.nombre == 'Archivo') {
                this.testimonios.archivo = campo.valor;
              }

              this.objetoCompleto = {
                nombre: this.testimonios.nombre,
                perfilprofesional: this.testimonios.perfilprofesional,
                descripcion: this.testimonios.descripcion,
                archivo: this.testimonios.archivo
              }

            });

            this.arrayTestimonios.push(this.objetoCompleto);
          }


        } );

        this.homeService.filtrarSecciones( { nombre: 'Testimonio' } )
        .subscribe( (respuesta: any) => {
          this.testimoniosDescripcion = respuesta[0];
            } );

  }

  truncateHTML(text: string): string {

    let charlimit = 350;
    if(!text || text.length <= charlimit )
    {
        return text;
    }
      let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
      let shortened = without_html.substring(0, charlimit) + "...";
      return shortened;
    }

  get urlS3() {
    return environment.urlS3;
  }

}
