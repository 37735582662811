<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                    <h1 class="titulo-bannerinicio">
                        {{"proyeccionesBanner.tituloTrazabilidad" | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="proyectoDetalle" class="container-fluid cnt-responsive pt-5 pb-5">
        <div class="actividades-recientes">
            <button [routerLink]="'/inicio/trazabilidad-de-proyectos'" class="btn btn-secondary position-relative btn-arecientes"> <mat-icon style="vertical-align: middle;margin-left: -10px;">keyboard_arrow_left</mat-icon> <span style="display: inline-block;
                vertical-align: middle;">{{"proyeccionesBanner.atras" | translate }} </span> </button>
        </div>
        <div class="tituloProyecto position-relative">
            <div class="row align-items-center">
                <div class="col-12 col-md-8">
                    <h3> {{ proyectoDetalle.proyecto.nombre }} - {{ proyectoDetalle.proyecto.codigo }} </h3>
                </div>
                <div class="col-12 col-md-4">
                    <div class="actividades-recientes">
                        <button (click)="log()" class="btn btn-secondary btn-arecientes"> {{"proyectosDetalle.actividadesRecientes" | translate }} <span class="badge badge-light" *ngIf="notificacionNumero >= 0"> {{ notificacionNumero }} </span></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="informacion-proyecto">
            <div class="row">
                <div class="col-12">
                    <div class="row align-items progreso-indiproyect">
                        <div class="col-12 col-md-4">
                            <section class=" example-section">
                                <mat-progress-bar class="example-margin" [mode]="mode" [value]=" proyectoDetalle.proyecto.avance">
                                </mat-progress-bar>
                                <span>{{  proyectoDetalle.proyecto.avance }} % </span>
                            </section>
                        </div>

                        <div class="col-12 col-md-2">
                            <span>{{"proyectosDetalle.Tareasrealizadas" | translate }}: {{ tareasTerminadas }}/{{tareasTamano}}</span>
                        </div>
                        <div class="col-12 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                            <span>{{"proyectosDetalle.horasProyecto" | translate }}: {{ proyectoDetalle.proyecto.horas_ejecutadas }}</span>
                        </div>
                        <div class="col-12 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                            <span>{{"proyectosDetalle.horasEstimadas" | translate }}: {{ proyectoDetalle.proyecto.horas_estimadas }}</span>
                        </div>
                        <div class="col-12 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                            <span>{{"proyectosDetalle.desviacionProyecto" | translate }}: {{ proyectoDetalle.proyecto.desviacion }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4 mb-4 detalle-proyecto">
            <div class="row align-items mt-2 mb-2">
                <div class="col-12">
                    <span class="subtitle-info">{{"proyectosDetalle.descProyecto" | translate }}: </span>
                    <span class="inf-proyecto" [innerHTML]="proyectoDetalle.proyecto.descripcion_corta_negocio"> </span>
                </div>
            </div>
            <div class="row align-items mt-2 mb-2">
                <div class="col-12">
                    <span class="subtitle-info">{{"proyectosDetalle.objetivoProyecto" | translate }}:</span>
                    <span class="inf-proyecto" [innerHTML]="proyectoDetalle.proyecto.objetivo_propuesta">  </span>
                </div>
            </div>
            <div class="row align-items mt-2 mb-2">
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.Estado" | translate }}: </span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.estado['estado_proceso'] }} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.negocio" | translate }}:</span>
                    <span class="inf-proyecto" [innerHTML]="proyectoDetalle.proyecto.tipo_negocio['descripcion']">  </span>
                </div>
                <div class="col-12 col-sm-4" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                    <span class="subtitle-info">{{"proyectosDetalle.tipoProyecto" | translate }}: </span>
                    <span class="inf-proyecto" [innerHTML]="proyectoDetalle.proyecto.tipo_proyecto['descripcion']">  </span>
                </div>

            </div>
            <div class="row align-items mt-2 mb-2">
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.cierreFecha" | translate }}:</span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.fecha_cierre | date:'dd/MM/yyyy'}} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.inicioFecha" | translate }}:</span>
                    <span class="inf-proyecto">{{ proyectoDetalle.proyecto.fecha_inicio | date:'dd/MM/yyyy'}}  </span>
                </div>
                <div class="col-12 col-sm-4" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                    <span class="subtitle-info">{{"proyectosDetalle.finFecha" | translate }}: </span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.fecha_fin | date:'dd/MM/yyyy'}} </span>
                </div>
            </div>

            <div class="row align-items mt-2 mb-2">
                <div class="col-12 col-sm-4" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                    <span class="subtitle-info">{{"proyectosDetalle.moneda" | translate }}: </span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.moneda['nombre_moneda'] }} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.valorProyecto" | translate }}:</span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.valor | number : '1.2-2' }} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.responsableCliente" | translate }}: </span>
                    <span class="inf-proyecto" *ngFor="let responsables of proyectoDetalle.proyecto.Responsables_cliente"> {{ responsables }} </span>
                </div>
            </div>


            <div class="row align-items mt-2 mb-2">

                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.fechaCreacion" | translate }}: </span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.fecha_creacion | date:'dd/MM/yyyy'}} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.cliente" | translate }}: </span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.Cliente }} </span>
                </div>

            </div>

            <div class="row align-items mt-2 mb-2">
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.diasProyecto" | translate }}:</span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.plazo_ejecucion }}  </span>
                </div>

                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.responsableProyecto" | translate }}:</span>
                    <span class="inf-proyecto" *ngFor="let responsablePHC of proyectoDetalle.proyecto.Responsables_phc"> {{ responsablePHC }} </span>
                </div>
                <div class="col-12 col-sm-4">
                    <span class="subtitle-info">{{"proyectosDetalle.comercial" | translate }}:</span>
                    <span class="inf-proyecto">{{ proyectoDetalle.proyecto.Responsable_comercial}}</span>
                </div>
            </div>
            <div class="row align-items mt-2 mb-2">
                <div class="col-12">
                    <span class="subtitle-info">{{"proyectosDetalle.comentariosGenerales" | translate }}:</span>
                    <span class="inf-proyecto"> {{ proyectoDetalle.proyecto.comentarios }}  </span>
                </div>
            </div>
        </div>

        <div class="etapas-pr">
            <div>
                <div id="accordion">
                    <div class="card" *ngFor="let etapa of proyectoDetalle.fases; let i = index">
                        <div class="card-header" [id]="'heading'+i">

                            <button class="btn position-relative collapse-prooyect" data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                                <div class="info">
                                    <div class="row align-items-center">
                                        <div class="iconos-desplegable">
                                            <mat-icon *ngIf="flechaArriba">keyboard_arrow_up</mat-icon>
                                            <mat-icon *ngIf="!flechaArriba">keyboard_arrow_down</mat-icon>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2">
                                            <span class="title-top">{{"proyectosDetalle.nombreProyecto" | translate }}: </span>
                                            <span> {{ etapa.fase['nombre'] }} </span>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                            <span class="title-top">{{"proyectosDetalle.horasProyecto" | translate }}: </span>
                                            <span>{{etapa.detalle.horas_ejecutadas}}</span>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                                <span class="title-top">{{"proyectosDetalle.desviacionProyecto" | translate }}</span>
                                                <span [ngClass]="{'desviacion-roja': etapa.detalle.desviacion > 100, 'desviacion-verde': etapa.detalle.desviacion < 100 }">{{etapa.detalle.desviacion}} %</span>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2">
                                            <span class="title-top">{{"proyectosDetalle.avanceProyecto" | translate }}: </span>
                                            <section class=" example-section">
                                                <mat-progress-bar class="example-margin" [mode]="mode" [value]="etapa.detalle.avance">
                                                </mat-progress-bar>
                                                <span  class="avance-id">{{ etapa.detalle.avance }}% </span>
                                            </section>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2">
                                            <span class="title-top">{{"proyectosDetalle.Estado" | translate }}: </span>
                                            <span [ngClass]="{'estado-verde': etapa.detalle.estado['color'] == 'verde', 'estado-azul': etapa.detalle.estado['color'] == 'azul', 'estado-rojo': etapa.detalle.estado['color'] == 'rojo', 'estado-naranja': etapa.detalle.estado['color'] == 'naranja' }"> {{ etapa.detalle.estado['estado_proceso'] }} </span>
                                        </div>
                                        <div class="rp-tproyectos col-6 col-sm-4 col-md-2">
                                            <div (click)="popupEtapa(etapa)" class="ojo-information">
                                                <mat-icon>remove_red_eye</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>

                        </div>

                        <div [id]="'collapse'+i" class="collapse" [attr.aria-labelledby]="'heading'+i" data-parent="#accordion">
                            <div class="card-body">
                                <div class="example-box" *ngFor="let tarea of etapa.tareas; let j = index">
                                    <div class="row align-items-center">

                                        <div class="col-12 info-fases text-center">
                                            <div class="row align-items-center">
                                                <div class="rp-tareas col-6 col-sm-4 col-md-2 text-center nombrePlantillas">
                                                    {{tarea.tarea.nombre_tareas}}
                                                </div>
                                                <div class="rp-tareas col-6 col-sm-4 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                                    <div class="fechas">
                                                        <span class="title-top">{{"proyectosDetalle.horasProyecto" | translate }}:</span>
                                                        <span>{{tarea.detalle.horas_ejecutadas}}</span>
                                                    </div>
                                                </div>
                                                <div class="rp-tareas col-6 col-sm-4 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                                    <div class="horas">
                                                        <span class="title-top">{{"proyectosDetalle.desviacionProyecto" | translate }}: </span>
                                                        <span [ngClass]="{'desviacion-roja': tarea.detalle.desviacion > 100, 'desviacion-verde': tarea.detalle.desviacion < 100 }">{{tarea.detalle.desviacion}}%</span>
                                                    </div>
                                                </div>
                                                <div class="rp-tareas col-6 col-sm-4 col-md-2">
                                                    <span class="title-top">{{"proyectosDetalle.avanceProyecto" | translate }}: </span>
                                                    <section class=" example-section">
                                                        <mat-progress-bar class="example-margin" [mode]="mode" [value]="tarea.detalle.avance">
                                                        </mat-progress-bar>
                                                        <span class="avance-id">{{tarea.detalle.avance}} % </span>
                                                    </section>
                                                </div>
                                                <div class="rp-tareas col-6 col-sm-4 col-md-2">
                                                    <div class="responsable">
                                                        <span class="title-top">{{"proyectosDetalle.Estado" | translate }}: </span>
                                                        <span [ngClass]="{'estado-verde': tarea.detalle.estado['color'] == 'verde', 'estado-azul': tarea.detalle.estado['color'] == 'azul', 'estado-rojo': tarea.detalle.estado['color'] == 'rojo', 'estado-naranja': tarea.detalle.estado['color'] == 'naranja' }"> {{ tarea.detalle.estado['estado_proceso']}} </span>
                                                    </div>
                                                </div>

                                                <div class="rp-tareas col-6 col-sm-4 col-md-2">
                                                    <div (click)="popupTarea(tarea)" class="ojo-information">
                                                        <mat-icon>remove_red_eye</mat-icon>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="comentarios-archivos pt-5">
            <form [formGroup]="formProyecto" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">

                <div class="comentarios-section">
                    <div class="title-comments">
                        <h3> {{"proyectosInformacionPR.comentariosPopup" | translate }} </h3>
                    </div>
                    <cdk-virtual-scroll-viewport class="lista-archivos" itemSize="50" *ngIf="comentarios.length">
                        <div class="comentarios-div">
                            <div (click)="getComentariosProyectos()" style="cursor: pointer;" class="sort-comentarios">
                                <span *ngIf="!recientesComentos" style="margin-top: -5px;"> {{"proyectosInformacionPR.antiguo" | translate }} </span>
                                <span *ngIf="recientesComentos"  style="margin-top: -5px;"> {{"proyectosInformacionPR.nuevo" | translate }} </span>
                                <span *ngIf="recientesComentos">  <mat-icon>keyboard_arrow_down</mat-icon> </span>
                                <span *ngIf="!recientesComentos"> <mat-icon>keyboard_arrow_up</mat-icon> </span>
                            </div>
                            <div class="comment-person" *ngFor="let comentario of comentarios">
                                <div class="informacionUsuario">
                                    <b><span [innerHTML]="comentario.nombre_usuario"></span> <span class="fecha-comentario"> {{ (comentario.fecha_modificacion != null || comentario.fecha_modificacion != '') ?  (comentario.fecha_modificacion.split(".")[0] | date:'dd/MM/yyyy h:mm')  : (comentario.fecha_creacion.split(".")[0] | date:'dd/MM/yyyy h:mm')   }} </span> </b> <span class="editarComentario cursor-pointer" (click)="modificarComentario(comentario)"> <mat-icon>create</mat-icon> </span> <span style="color: #C71C22;vertical-align: middle;"
                                    (click)="eliminarComentario(comentario)" class="cursor-pointer"> <mat-icon>close</mat-icon> </span>
                                </div>
                                <p [innerHTML]="comentario.comentarios"></p>
                                
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                    <div class="form-group">
                        <label for="comentarios" class="labelForm"> {{"proyectosInformacionPR.comentariosAgregar" | translate }}: </label>
                        <textarea type="text" style="overflow:auto;resize:none" class="form-control" resize=false id="comentarios" formControlName="comentarios"></textarea>
                    </div>
                    <div *ngIf="editarComment" class="editComments">
                        <div class="row">
                            <div class="col-12 col-sm-6 guardarButton ">
                                <button type="button" (click)="actualizarComentario()" class="btn btn-form mt-3">{{"proyectosDetalle.guardar" | translate }} </button>
                            </div>
                            <div class="col-12 col-sm-6 cancelarButton">
                                <button type="button" (click)="cancelarComentario()" class="btn btn-form mt-3">{{"proyectosDetalle.cancelar" | translate }} </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="lineadivisora"></div>

                <div class="archivos-section">
                    <div class="title-comments">
                        <h3> {{"proyectosInformacionPR.archivosInformacion" | translate }} </h3>
                    </div>
                    <label for="archivo" class="labelForm"> {{"proyectosInformacionPR.archivosAgregar" | translate }} :</label>
                    <div class="custom-file">
                        <input type="file" id="archivo" formControlName="archivo" #valorArchivo aria-describedby="archivo" (change)="fileInputChange($event.target.files)" class="cursor-pointer custom-file-input" />
                        <label class="custom-file-label" [attr.data-after-content]="[buscarPalabra]">{{"modalCargarPlantilla.opcion2" | translate }}</label>
                    </div>
                    <span class="mensajeArchivos"> {{"proyectosInformacionPR.archivos" | translate }} </span>
                    <cdk-virtual-scroll-viewport class="lista-archivos" itemSize="50" *ngIf="adjuntos.length">
                        <div class="archivos-lista">
                            <div class="archivo-add position-relative" *ngFor="let adjunto of adjuntos">
                                <a [attr.href]="adjunto.url" target="_blank" rel="noopener noreferrer">{{adjunto.nombre}}</a>
                                <span (click)="eliminarArchivo(adjunto)" class="basura-iconos cursor-pointer"> <mat-icon>close</mat-icon> </span>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>

                <div class="row btn-container">
                    <button [disabled]="editarComment" type="submit" class="btn btn-form mt-3">{{"proyectosDetalle.guardar" | translate }} </button>
                </div>
            </form>
        </div>

    </div>

</div>
