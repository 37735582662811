import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {InternasServiceService} from 'src/app/services/internas-service.service';
import {MatSnackBarUtil} from "../../../utils/mat-snack-bar.util";

@Component({
  selector: 'app-formulario-encuesta',
  templateUrl: './formulario-encuesta.component.html',
  styleUrls: ['./formulario-encuesta.component.css']
})
export class FormularioEncuestaComponent implements OnInit {

  fechaToday: any;
  encuestaId = '';
  formularioEncuesta = new FormGroup({});
  fields: FormlyFieldConfig[];

  constructor(
    private _builder: FormBuilder, private translateService: TranslateService,
    private internaService: InternasServiceService, private route: ActivatedRoute,
    private matSnackBarUtil: MatSnackBarUtil,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    //Traer toda la encuesta
    this.traerInformacion();

    //Dia de hoy
    this.fechaToday = new Date();
    this.encuestaSolucionada();
  }

  traerInformacion() {

    //Tomar id
    const queryParams = this.route.snapshot.queryParams;
    if ('id' in queryParams) {
      this.encuestaId = queryParams.id;
    }

    this.internaService.traerEncuesta(this.encuestaId)
      .subscribe(respuesta => this.fields = respuesta.campos);
  }

  onSubmit(event, formDirective: FormGroupDirective): void {

    const queryParams = this.route.snapshot.queryParams;
    let token = '';
    if ('token' in queryParams) {
      token = queryParams.token;
    }
    if (this.formularioEncuesta.valid) {
      const formData = JSON.stringify(event.form.value);
      const obj = {
        id_encuesta: this.encuestaId,
        form_data: formData,
        token
      };
      this.internaService.encuestaSolucion(obj).subscribe(response => {
        let message: string;
        let type: string;
        if (response && ('message' in response && 'type' in response)) {
          message = response.message;
          type = response.type;
        }
        if (type && message) {
          this.matSnackBarUtil.getSnackByType(type, message);
        }
        if (type && type === 'success') {
          this.formularioEncuesta.reset();
          this.router.navigate(['/']);
        }
      });
    } else {
      const message = this.translateService.instant('errores.requerido');
      this.matSnackBarUtil.matSnackBarDanger(message);
    }
  }

  encuestaSolucionada(): void {
    const queryParams = this.route.snapshot.queryParams;
    let token = '';
    if ('token' in queryParams) {
      token = queryParams.token;
    }

    if (token.length === 0) {
      this.router.navigate(['/']);
    }

    const obj = {
      token
    };

    this.internaService.encuestaSolucionada(obj).subscribe(response => {
      if (response) {
        this.router.navigate(['/']);
      }
    });
  }
}
