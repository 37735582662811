import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProyeccionesMercadoService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  constructor(private _http: HttpClient) { }

  correoSinAdjunto(objeto): any {
    return this._http.post(`${environment.url}send-mail/`, objeto, this.httpOptions);
  }

  downloadFile(): any {
    return this._http.get(`${environment.url}plantilla-proyeciones-download`);
  }

  guardarArchivo(objeto): any{
    const formData = new FormData();
    formData.append('nombres', objeto.nombres);
    formData.append('apellidos', objeto.apellidos);
    formData.append('empresa', objeto.empresa);
    formData.append('contacto', objeto.contacto);
    formData.append('mail', objeto.mail);
    formData.append('attach', objeto.attach);
    return this._http.post(`${environment.url}send-mail/`, formData);
  }

  getNameBusiness(nit):any {
    return this._http.post(`${environment.url}empresa/`, nit, this.httpOptions)
  }

  consultaSuscripcionUsuario(usuario):any {
    return this._http.post(`${environment.url}consulta/suscripcion/user/`, {usuario:usuario})
  }
  
  consultaSuscripcion(usuario):any {
    return this._http.post(`${environment.url}consulta/suscripcion/`, {usuario:usuario})
  }

  consultaContenido(usuario): Observable<any> {
    return this._http.post(`${environment.url}consulta/candado/`, {usuario}, this.httpOptions);
  }

  consultaIndicadores(escenario):any {
    return this._http.post(`${environment.url}consulta-tabla-indicadores/`, {escenario:escenario})
  }

}
