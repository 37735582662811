<div class="container">
    <div class="row">
        <div class="col-lg-12 text-mapas pt-2">
            <h1 *ngIf="mapaDescripcion" class="text-center text-main" data-aos="zoom-in"> {{ "phcMundoMapa.titulo" | translate }} </h1>
            <div class="linea"></div>
            <p *ngIf="mapaDescripcion" class="pt-3 text-center description" data-aos="zoom-in" [innerHTML]="mapaDescripcion.descripcion | dompurify">
            </p>
            <div class="button-link text-center  mb-3">
                <a [routerLink]="['/PHC-en-el-mundo']" class="btn btn-light " data-aos="zoom-in">{{"linkVerMas.link" | translate }}</a>
            </div>
        </div>
        <div class="col-lg-12 p-3">
            <div id="container" class="mapa-estilos" style="width: auto; height: 50%"></div>
        </div>
    </div>
</div>