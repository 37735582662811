import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CotizacionPDFService {

  url = environment.url;
  urlS3 = environment.urlS3;
  urlCreatePDF = environment.urlCreatePDF;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  generatePDF(params: object): Observable<any> {
    return this.httpClient.post(`${this.urlCreatePDF}/download/estudios-de-conexion`, params, this.httpOptions);
  }

  getParametros(objReq): Promise<any> {
    const obj: any = [];
    return new Promise(resolve => {
      this.httpClient.post(`${this.url}parametros/pdf/`, objReq).subscribe((parameters: any) => {
        const parametros = parameters;
        if (!obj) {
          return;
        }

        obj.nombre_cliente = parametros.nombre_cliente;
        obj.codigo_propuesta = parametros.codigo_propuesta
        obj.fecha = parametros.fecha_propuesta;
        obj.fechaCreacion = parametros.fecha_creacion;
        obj.titulo_proyecto = parametros.titulo_proyecto;

      });

      resolve(obj);

    });
  }

  getContentTablePDF(id): Promise<any> {
    const contentTable = [];
    return new Promise(resolve => {
      this.httpClient.post(`${this.url}parametros/pdf/`, {id}).subscribe((parameters: any) => {
        const pages = parameters.paginas;
        if (!pages) {
          return;
        }
        for (const [index, page] of pages.entries()) {
          for (const pageContent of page) {
            const obj: any = {};
            obj.title = pageContent.titulo;
            obj.order = pageContent.orden;
            obj.pageNumber = index + 2;
            contentTable.push(obj);
          }
        }
      });
      resolve(contentTable);
    });
  }

  getPagesPDF(obj): Promise<any> {
    let contentTable = [];
    const allPages = [];
    return new Promise(resolve => {
      this.httpClient.post(`${this.url}parametros/pdf/`, obj).subscribe((parameters: any) => {
        const pages = parameters.paginas;
        if (!pages) {
          return;
        }
        for (const [index, page] of pages.entries()) {
          for (const pageContent of page) {
            const obj: any = {};
            obj.title = pageContent.titulo;
            obj.order = pageContent.orden;
            obj.paragraph = pageContent.parrafo;
            obj.pageNumber = index + 1;
            contentTable.push(obj);
          }
          allPages.push(contentTable);
          contentTable = [];
        }
      });
      resolve(allPages);
    });
  }

  getHeaderAndFooterForPDF(id): Promise<any> {
    const obj: any = {};
    return new Promise(resolve => {
      this.httpClient.post(`${this.url}parametros/pdf/`, {id}).subscribe((parameters: any) => {
        const headerAndFooter = parameters.encabezado;

        if (!headerAndFooter) {
          return;
        }
        for (const headerFooter of headerAndFooter) {
          obj.header = headerFooter.encabezado;
          obj.footerImage = `${this.urlS3}/static/${headerFooter.imagen}`;
          obj.footerText = headerFooter.texto;
          obj.imagen_banner = `${this.urlS3}/static/${headerFooter.imagen_banner}`;
          obj.imagen_logo_phc = `${this.urlS3}/static/${headerFooter.imagen_logo_phc}`;
          obj.datos_contacto = headerFooter.datos_contacto;
        }

      });


      resolve(obj);
    });
  }
}
