import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { ChartService } from '../../../services/charts/chart15.service';

@Component({
  selector: 'app-chart15',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class Chart15Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  title: any;

  chartOption: EChartOption = {};
  xAxis = [];
  constructor(private service: ChartService) {}

  ngOnInit(): void {
    let historico = this.historico;
    let data = { escenario: this.escenario, historico };
    this.service.getData(data).then((data: any) => {
      this.chartOption = data;
      this.xAxis = data.xAxis.data;
    });

    let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.nombre_grafica_15;
  }
  mapArray(key) {
    if (this.chartOption.values) {
      return this.chartOption.values.map((e) => e[key]);
    }
  }
}
