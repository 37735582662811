import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ProyeccionesMercadoService} from '../../../services/proyecciones-mercado.service';

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {VersionesWindowComponent} from '../../windows/versiones-window/versiones-window.component';
import {InicioSesionComponent} from '../../inicio-sesion/inicio-sesion.component';
import {DetallePagoComponent} from '../../detalle-pago/detalle-pago.component';
import {AuthService} from '../../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/services/home.service';
import {SecureStorageService} from '../../../services/secure-storage.service';


@Component({
  selector: 'app-proyecciones-mercado',
  templateUrl: './proyecciones-mercado.component.html',
  styleUrls: ['./proyecciones-mercado.component.css']
})

export class ProyeccionesMercadoComponent implements OnInit {
  @ViewChild('message') message: ElementRef;
  logged: any;
  tomaDeDatosON = false;
  datosPersonalizado: any;
  getDatosPersonalizados: any;
  dataUser: any;
  dataform: any;
  registerForm: FormGroup;
  uploadFile: FormGroup;
  closeResult = '';
  nombreUsuarioSoporte = '';
  modalReference: NgbModalRef;
  uploadFileEvent: any;
  urlFileDownload: any;
  fileParaSubir: any;
  buscarPalabra: any;
  indicadores: any;
  id: string;
  queryParams: any;
  fullView: string;


  // NUEVA IMPLEMENTACION
  selectorActual;

  constructor(private modalService: NgbModal, private _builder: FormBuilder,
              private proyeccionService: ProyeccionesMercadoService,
              private router: Router, private dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private _snackBar: MatSnackBar,
              private translateService: TranslateService,
              private homeService:HomeService,
              private secureStorageService: SecureStorageService
  ) {

    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.id = this.queryParams.id;

    this.registerForm = this._builder.group({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      empresa: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      correo: new FormControl('', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]),
    });

    this.uploadFile = this._builder.group({
      archivo: new FormControl('', [Validators.required]),
    });

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 300) {
      document.querySelector('#scrollBtn').classList.add('ocultarInforme');
    }else{
      document.querySelector('#scrollBtn').classList.remove('ocultarInforme');
    }

  }

  hoverMe(){
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (document.querySelector('#scrollBtn').classList.contains('ocultarInforme') && number > 300) {
      document.querySelector('#scrollBtn').classList.remove('ocultarInforme');
    }else{
      return;
    }
  }

  onSubmit(formDir): void {
    if (!this.registerForm.valid) {
      this.registerForm.markAllAsTouched();
      return;
    }



    this.datosPersonalizado = {
      nombre: this.registerForm.get('nombre').value,
      apellido: this.registerForm.get('apellido').value,
      empresa: this.registerForm.get('empresa').value,
      telefono: this.registerForm.get('telefono').value,
      correo: this.registerForm.get('correo').value,
    };

    localStorage.setItem('tomaDeDatos', JSON.stringify(this.datosPersonalizado));
    this.tomaDeDatosON = true;
    this.modalReference.close();
  }

  resetTomaDatos(): void {
    this.tomaDeDatosON = false;
    this.registerForm.reset();
    // this.nombreUsuarioSoporte = '';
    localStorage.removeItem('tomaDeDatos');
  }

  onTabClick(event, content): void {

    if (event !== 3) {
      this.cargaIndicadores(event);
    }

    if (!this.logged) {
      if (event === 3) {
        this.resetTomaDatos();

        this.modalReference = this.modalService.open(content, {centered: true});
        this.modalReference.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }

  }

  openDatos(content): void {
    this.modalReference = this.modalService.open(content, {centered: true});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cargaIndicadores(escenario: any = 0): void {

    if (escenario === 0) {
      escenario = 'pesimista';
    }
    if (escenario === 1) {
      escenario = 'moderado';
    }
    if (escenario === 2) {
      escenario = 'optimista';
    }
    this.proyeccionService.consultaIndicadores(escenario).subscribe(indicadores => {

      this.indicadores = indicadores;
    });

  }

  private async dataUserLogged() {
    let resp: any;
    let promise = this.proyeccionService.getNameBusiness({nit: this.dataUser.empresa}).toPromise();
    await promise.then((data) => {
      resp = {
        nombres: this.dataUser.first_name,
        apellidos: this.dataUser.last_name,
        empresa: data,
        contacto: this.dataUser.celular,
        mail: this.dataUser.email
      };
    });
    return resp;
  }

  async openSoporte(content) {

    let correoUsuario: any;
    if (this.logged == true) {
      this.dataUser = this.secureStorageService.getItem('djangoUser');
      this.nombreUsuarioSoporte = this.dataUser.first_name;
      correoUsuario = await this.dataUserLogged();

    } else if (this.tomaDeDatosON == true) {
      this.getDatosPersonalizados = JSON.parse(localStorage.getItem('tomaDeDatos'));
      this.nombreUsuarioSoporte = this.getDatosPersonalizados.nombre;

      correoUsuario = {
        nombres: this.getDatosPersonalizados.nombre,
        apellidos: this.getDatosPersonalizados.apellido,
        empresa: this.getDatosPersonalizados.empresa,
        contacto: this.getDatosPersonalizados.telefono,
        mail: this.getDatosPersonalizados.correo
      };

    }
    this.proyeccionService.correoSinAdjunto(correoUsuario).toPromise();

    this.modalService.open(content, {centered: true, size: 'sm'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.resetTomaDatos();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  openUploadFile(content) {
    this.modalReference = this.modalService.open(content, {centered: true, size: 'sm'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.resetTomaDatos();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  async onSubmitFile(form) {
    if (!this.uploadFile.valid) {


      if (this.uploadFile.hasError('required', 'archivo')) {
        this._snackBar.open( this.translateService.instant('proyeccionesPersonalizado.obligatorio'), '' , { duration: 3000,
          panelClass: ['alert', 'alert-danger'] }
          );
          return;
      }

      this._snackBar.open( this.translateService.instant('proyeccionesPersonalizado.extensiones'), '' , { duration: 3000,
        panelClass: ['alert', 'alert-danger'] }
        );
      this.uploadFile.markAllAsTouched();
      return;
    }


    let correoUsuario: any;



    if (this.logged == true) {
      this.dataUser = this.secureStorageService.getItem('djangoUser');
      this.nombreUsuarioSoporte = this.dataUser.first_name;
      correoUsuario = await this.dataUserLogged();
      correoUsuario.attach = this.fileParaSubir;
    } else if (this.tomaDeDatosON == true) {
      this.getDatosPersonalizados = JSON.parse(localStorage.getItem('tomaDeDatos'));
      this.nombreUsuarioSoporte = this.getDatosPersonalizados.nombre;

      correoUsuario = {
        nombres: this.getDatosPersonalizados.nombre,
        apellidos: this.getDatosPersonalizados.apellido,
        empresa: this.getDatosPersonalizados.empresa,
        contacto: this.getDatosPersonalizados.telefono,
        mail: this.getDatosPersonalizados.correo,
        attach: this.fileParaSubir
      };

    }

    this.proyeccionService.guardarArchivo(correoUsuario).toPromise()
      .then(() => {
        this.modalReference.close();
        this.modalService.open(this.message);
        this.fileParaSubir = '';
        this.uploadFile.get('archivo').reset();
      });

  }

  fileInputChange(evento, valorArchivo) {
    this.fileParaSubir = evento[0];
    document.querySelector('.custom-file-label').innerHTML = this.fileParaSubir.name;

    let validExts = new Array('.xlsx', '.xls');
    if (!evento) {
      return;
    }

    let fileExt = evento[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.uploadFileEvent = true;
      this._snackBar.open( this.translateService.instant('proyeccionesPersonalizado.extensiones'), '' , { duration: 3000,
        panelClass: ['alert', 'alert-danger'] }
        );
      this.uploadFile.setErrors({'invalid': true});

    } else {
      this.uploadFileEvent = false;
    }
  }

  descargarPlantilla() {
    this.proyeccionService.downloadFile().subscribe((file: any) => {
      this.urlFileDownload = file.url;
    });
  }


  private getDismissReason(reason: any): string {
    this.ngOnInit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateProyeccion(proyeccion) {
    // //debugger

    let user: any = this.secureStorageService.getItem('djangoUser');
    let accessToken = this.secureStorageService.getItem('accessToken');
    let refreshToken = this.secureStorageService.getItem('refreshToken');
    const report = proyeccion.replaceAll(' ', '-');
    let trigerContenido = false;

    if (user && accessToken && refreshToken) { //validacion de login

      this.proyeccionService.consultaContenido(user.email).subscribe(contenido => { // valicacion de contenido

        if (!contenido) { // si no tiene registro en contenido valida suscripcion
          this.validateSuscripciones(user, proyeccion);
        } else {
          contenido.forEach(element => {
            if (element.contenido__nombre.indexOf(report) > -1) {
              trigerContenido = true;
              let date = new Date();
              let fecha_inicio = new Date(element.inicio_vigencia);
              let fecha_fin = new Date(element.fin_vigencia);
              if ((date >= fecha_inicio) && (date <= fecha_fin)) {
                let baseUrl = window.location.href.replace(this.router.url, '');
                this.router.navigate([]).then(result => {
                  window.open(baseUrl + '/componente-informe?nombre='+proyeccion, '_blank');
                });
              } else {
                this.validateSuscripciones(user, proyeccion);
              }
            }
          });
          if (trigerContenido == false) {
            this.validateSuscripciones(user, proyeccion, contenido);
          }
        }
      });
    } else {
      const modalRef = this.modalService.open(InicioSesionComponent, {centered: true});
      modalRef.componentInstance.tituloInforme = this.translateService.instant('proyeccionesPersonalizado.tituloInforme');
      // this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
      //   this.closeResult = `Closed with: ${result}`;
      // }, (reason) => {
      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // });
    }
  }

  validateSuscripciones(user, proyeccion, contenido?) {
    this.proyeccionService.consultaSuscripcion(user.email).subscribe((data: any) => {

      if (!data) {
        this.openWindow();
      } else {
        data.forEach(element => {
          let date = new Date();
          let fecha_inicio = new Date(element.created_at);
          let fecha_fin = new Date(element.created_at);
          fecha_fin.setDate(fecha_fin.getDate() + (element.suscripcion__meses_vigencia * 30));

          if ((date >= fecha_inicio) && (date <= fecha_fin)) {
            // if (!contenido) {
            //   this.openWindow();
            // } else {
              let baseUrl = window.location.href.replace(this.router.url, '');

              this.router.navigate([]).then(result => {
                window.open(baseUrl + '/componente-informe?nombre='+proyeccion, '_blank');
              });
            // }
          } else {
            this.openWindow();
          }
        });
      }
    });
  }

  openWindow() {
    const that = this;
    const config = new MatDialogConfig();

    config.disableClose = false;
    config.autoFocus = true;
    config.data = {
      list_teams: [],
      teams: [],
    };
    const dialogRef = this.dialog.open(VersionesWindowComponent, {
      panelClass: 'custom-dialog-container',
      width: '100%',
      maxWidth: '90vw',
      height: '90%'
    });
  }

  ngOnInit(): void {
    this.botonFullView();

    this.selectTab({index: 0});

    if (this.id) {
      this.modalService.open(DetallePagoComponent, {centered: true, size: 'xl'}).result.then((result) => {
        delete this.id;
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        delete this.id;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    this.resetTomaDatos();
    this.descargarPlantilla();
    this.cargaIndicadores();
    this.fileParaSubir = '';

    if (localStorage.getItem('language') == 'en') {
      this.buscarPalabra = 'Search';
    } else {
      this.buscarPalabra = 'Buscar';
    }
  }

  selectTab($event, content?): void {
    this.selectorActual = $event.index;
    this.logged = this.authService.isLoggedIn();
    if (this.logged) {
      this.dataUser = this.secureStorageService.getItem('djangoUser');
    }
    this.onTabClick(this.selectorActual, content);
  }

 private async botonFullView(){
  let promise = this.homeService.botonProyeccionesMercado().toPromise();
  await promise.then((data:any)=>{
    this.fullView = data.message;
  });
  }
}
