import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Momentos } from '../../../models/momentos.models';
import { HomeService } from 'src/app/services/home.service';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';
import {SeoService} from '../../../services/seo.service';

@Component({
  selector: 'app-momentos-phc',
  templateUrl: './momentos-phc.component.html',
  styleUrls: ['./momentos-phc.component.css']
})
export class MomentosPHCComponent implements OnInit {
  selected = '';
  momentosCampos = new Momentos;
  objetoCompleto: Momentos;
  arrayMomentos: Array<Momentos> = [];
  arrayMomentosNuevo: Array<Momentos> = [];
  arrayMomentosFiltro: Array<Momentos> = [];
  archivoTipo: any;
  arrayPrueba: any[];
  page = 1;
  pageSize = 10;
  active = '1';
  loading: boolean = false;
  filtroMomentos: string;
  existeTab: boolean;
  momentosDescripcion: any;
  pdfmomento: boolean = true;
  categorias: any[];
  constructor(
    config: NgbCarouselConfig,
    private translate: TranslateService,
    private homeService: HomeService,
    private datePipe: DatePipe,
    private seoService: SeoService
    ) {

  }

  tamanoPaginador() {
    if (window.innerWidth < 480) {
      return 'sm';
    } else {
      return 'md';
    }
  }

  ngOnInit(): void {

    this.loading = true;
    this.homeService.categoriaMomentos().subscribe(
      (data) => {
        this.categorias = data;
      }
    );

    this.homeService.consultarNosotros({ seccion: 'Momentos phc', estado: 'activo' })
      .subscribe(data => {

        const tmpArrayMomentos = [];

        for (const array of data.data) {
          array.campos.forEach(campo => {
            if (campo.nombre == 'Subtitulo') {
              this.momentosCampos.subtitulo = campo.valor;
            }
            if (campo.nombre == 'Descripcion') {
              this.momentosCampos.descripcion = campo.valor;
            }
            if (campo.nombre == 'Archivo') {
              this.momentosCampos.archivo = campo.valor;
            }
            if (campo.nombre == 'Tipo momento') {
              this.momentosCampos.tipo = campo.valor;
            }
            if (campo.nombre == 'Categoria') {
              this.momentosCampos.categoria = campo.valor;
            }
            if (campo.nombre.toLowerCase() === 'titulo') {
              this.momentosCampos.titulo = campo.valor.toLowerCase().replaceAll(' ', '-');
            }

            this.objetoCompleto = {
              subtitulo: this.momentosCampos.subtitulo,
              descripcion: this.momentosCampos.descripcion,
              archivo: this.momentosCampos.archivo,
              tipo: this.momentosCampos.tipo,
              categoria: this.momentosCampos.categoria,
              fechaCreacion: array.created_at,
              fechaModificacion: array.updated_at,
              // titulo: this.momentosCampos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            };

            if (this.momentosCampos.titulo) {
              this.objetoCompleto.titulo = this.momentosCampos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
          });
          this.objetoCompleto.id = array.id;
          tmpArrayMomentos.push(this.objetoCompleto);
        }
        this.arrayMomentos = tmpArrayMomentos;
        this.arrayMomentos.sort( (a: any,b: any) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime() )
        this.loading = false;
      });


    this.homeService.filtrarSecciones({ nombre: 'Momentos phc' })
      .subscribe((respuesta: any) => {
        this.momentosDescripcion = respuesta[0];
      });

    this.seoService.addMetaTagsByPage('Meta tags - momentos PHC');
  }
  console(peticion) {
    this.loading = true;
    this.filtroMomentos = this.translate.instant(peticion);
    this.homeService.filtroMomento({ "parametro": this.filtroMomentos })
      .subscribe(respuesta => {

        const tmpArrayMomentos = [];

        for (const array of respuesta.data) {
          array.campos.forEach(campo => {
            if (campo.nombre == 'Subtitulo') {
              this.momentosCampos.subtitulo = campo.valor;
            }
            if (campo.nombre == 'Descripcion') {
              this.momentosCampos.descripcion = campo.valor;
            }
            if (campo.nombre == 'Archivo') {
              this.momentosCampos.archivo = campo.valor;
            }
            if (campo.nombre == 'Tipo momento') {
              this.momentosCampos.tipo = campo.valor;
            }
            if (campo.nombre == 'Categoria') {
              this.momentosCampos.categoria = campo.valor;
            }
            if (campo.nombre.toLowerCase() === 'titulo') {
              this.momentosCampos.titulo = campo.valor.toLowerCase().replaceAll(' ', '-');
            }

            this.objetoCompleto = {
              subtitulo: this.momentosCampos.subtitulo,
              descripcion: this.momentosCampos.descripcion,
              archivo: this.momentosCampos.archivo,
              tipo: this.momentosCampos.tipo,
              categoria: this.momentosCampos.categoria,
              fechaCreacion: array.created_at,
              fechaModificacion: array.updated_at,
              // titulo: this.momentosCampos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            };
            if (this.momentosCampos.titulo) {
              this.objetoCompleto.titulo = this.momentosCampos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
          });
          this.objetoCompleto.id = array.id;
          tmpArrayMomentos.push(this.objetoCompleto);
        }
        this.arrayMomentosFiltro = tmpArrayMomentos;
        this.arrayMomentosFiltro.sort( (a: any,b: any) => new Date(b.fechaCreacion).getTime() - new Date(a.fechaCreacion).getTime() )
        this.loading = false;
      }); //fin subcribe

  }

  get urlS3() {
    return environment.urlS3;
  }

  truncateHTML(text: string): string {
    let charlimit = 350;
    if (!text || text.length <= charlimit) {
      return text;
    }
    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + '...';
    return shortened;
  }
}
