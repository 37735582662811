<div class="table-responsive">
    <table class="table borderless  inform-cabecera table-striped">
        <thead>
            <tr>
                <th scope="col">{{"tabla5.planta" | translate}}</th>
                <th scope="col">{{"tabla5.tipo" | translate}}</th>
                <th scope="col">{{"tabla5.potencia" | translate}}</th>
                <th scope="col">{{"tabla5.posible_fecha_de_entrada" | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of response">
                <td>{{data.planta}}</td>
                <td class="text-center">{{data.tipo}}</td>
                <td class="text-center">{{data.potencia | customDecimal}}</td>
                <td class="text-center"> {{data.posible_fecha_de_entrada | date:"MM/dd/yy"}}</td>
            </tr>

        </tbody>
    </table>
</div>