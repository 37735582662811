import {DatePipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InternasServiceService} from '../../services/internas-service.service';
import {PagosService} from '../../services/pagos.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-detalle-pago',
  templateUrl: './detalle-pago.component.html',
  styleUrls: ['./detalle-pago.component.css']
})
export class DetallePagoComponent implements OnInit {

  queryParams: any;
  @Input() idInput: string;
  @Input() envInput: string;
  id: string;
  env: string;
  transactionData: any;
  loading: boolean = false;
  traduccionEstado: string;
  pagoEfectuadoA: string;
  fechaTransaccion: any;

  constructor(private activatedRoute: ActivatedRoute,
              private pagoService: PagosService,
              private internasServiceService: InternasServiceService,
              private datepipe: DatePipe, public activeModal: NgbActiveModal,
              private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParams;

    this.id = this.queryParams.id || this.idInput;
    this.env = this.queryParams.env || this.envInput;


    this.loading = true;

    this.getTransaction().then(data => {

      this.registerSubscription('version 1');
    }).then(data => {
      this.registerPayment();
    }).catch(reason => console.error('Error transaction: ', reason));
  }

  getTransaction(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pagoService.getTransactionById(this.id, this.env).subscribe(transaction => {
        this.transactionData = transaction;
        const status = this.transactionData.data.status;
        this.traduccionEstado = this.translateService.instant(`detallePago.estados.${status}`);
        this.pagoEfectuadoA = `<b>${this.transactionData.data.merchant.name}</b> - ( ${this.transactionData.data.merchant.legal_name} ${this.transactionData.data.merchant.legal_id_type} ${this.transactionData.data.merchant.legal_id}  )`;
        this.fechaTransaccion = this.datepipe.transform(this.transactionData.data.created_at, 'medium');
        resolve(this.transactionData);
      }, error => reject(error));
    });
  }

  registerSubscription(version): void {
    const transactionStatus = this.getTransactionProperty('status');
    const subscriptionId = this.getSubscriptionId();
    if (transactionStatus === 'APPROVED') {
      this.internasServiceService.setRegistroSuscripcion(subscriptionId, version).subscribe(data => {

      });
    }
  }

  getTransactionProperty(property): string {
    if (this.transactionData.data[property] !== undefined) {
      return this.transactionData.data[property];
    }
  }

  getSubscriptionId(): string {
    const reference = this.getTransactionProperty('reference');
    return reference.split('-', 1)[0];
  }

  registerPayment(): void {
    const data = this.transactionData;
    this.pagoService.createPayment({data}).subscribe(data => {

      this.loading = false;
     });
    localStorage.removeItem('transactionId');
    localStorage.removeItem('transactionObject');
  }
}
