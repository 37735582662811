import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {CutHtmlStringPipe} from '../../pipes/cut-html-string.pipe';

const url = `${environment.url}grafica16/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  renderer: Renderer2;
  title: string;
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.lenguaje = localStorage.getItem('language');
    this.getTitle();
  }

  getTitle(): void {
    const parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.leyenda_grafico_16;
    const cutHtmlStringPipe = new CutHtmlStringPipe(this.renderer);
    this.title = cutHtmlStringPipe.transform(this.title, 0);
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#1162A0', '#38A42B'],
          values: data,
          tooltip: {
            trigger: 'axis',
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) =>
              this.datePipe.transform(el.fecha, 'MMM-yyyy')
            ),
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            data: this.lenguaje == "es" ? ['MM y MC', 'PB'] : ['MM y MC', 'SP'],
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'MM y MC' : 'MM y MC',
              data: data.map((el) => parseFloat(el.mm_mc.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#1162A0',
              },
            },
            {
              name: this.lenguaje == "es" ? 'PB' : 'SP',
              data: data.map((el) => parseFloat(el.pb.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#38A42B',
              },
              markPoint: {
                symbol: false,
                symbolSize: 1,
                label: {
                  formatter: this.title,
                  color: 'gray',
                  type: 'solid',
                },
                itemStyle: {
                  color: 'red',
                  type: 'solid',
                },
                data: [
                  {x: '75%', y: '80%'},
                ],
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
