import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';
import {CutHtmlStringPipe} from '../../pipes/cut-html-string.pipe';

const url = `${environment.url}grafica18/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  title: string;
  private renderer: Renderer2;
  lenguaje = "";

  constructor(private _http: HttpClient, private datePipe: DatePipe, private rendererFactory: RendererFactory2) {
    this.lenguaje = localStorage.getItem('language');
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.getTitle();
  }

  getTitle(): void {
    const parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.leyenda_grafico_18;
    const cutHtmlStringPipe = new CutHtmlStringPipe(this.renderer);
    this.title = cutHtmlStringPipe.transform(this.title, 0);
  }

  getData(params) {
    return new Promise((resolve) => {
      let markLine = [];
      markLine.push([
        {
          name: this.title,
          coord: [90, 570],
          label: {
            formatter: this.title,
            position: 'end',
          },
        },
        {
          coord: [180, 600],
        },
      ]);
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#0D569A', '#28AE4C', '#ED7A2D'],
          values: data,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              'type': 'cross',
              'label': {
                'backgroundColor': '#6a7985'
              }
            },
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) =>
              this.datePipe.transform(el.fecha, 'MM/yyyy')
            ),
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            data: this.lenguaje == 'es' ? ['PMG', 'P. Escasez 071', 'P. Escasez Act'] : ['Marginal Scarcity Price', 'P. Scarcity 071', 'P. Scarcity Act',],
          },
          series: [
            {
              name: this.lenguaje == 'es' ? 'PMG' : 'Marginal Scarcity Price',
              data: data.map((el) => el.pme != null ? parseFloat(el.pme.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#0D569A',
              },
            },
            {
              name: this.lenguaje == 'es' ? 'P. Escasez 071' : 'P. Scarcity 071',
              data: data.map((el) => el.p_esc_071 != null ? parseFloat(el.p_esc_071.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#28AE4C',
              },
            },
            {
              name: this.lenguaje == 'es' ? 'P. Escasez Act' : 'P. Scarcity Act',
              data: data.map((el) => el.p_esc_act != null ? parseFloat(el.p_esc_act.toFixed(2)) : 'null'),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#ED7A2D',
              },
              markLine: {
                data: markLine,
                lineStyle: {
                  color: 'red',
                  type: 'solid',
                },
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
