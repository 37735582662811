import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {graphicsColor} from '../../app.config';

const url = `${environment.url}grafica10/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let charts = [];
        data.forEach((info) => {
          let chart = {
            name: info.tipo,
            tooltip: {
              formatter: '{b}: {c} ({d}%)',
              trigger: 'item',
              axisPointer: {
                type: 'shadow',
              },
              position: ['50%', '50%'],
            },
            grid: {
              left: '0%',
              bottom: '0%',
              right: '0%',
              top: '0%'
            },
            // legend: {
            //   orient: 'vertical',
            //   left: 10,
            //   top: 0,
            // },
            series: [
              {
                type: 'pie',
                height: 250,
                radius: ['50%', '90%'],
                avoidLabelOverlap: false,
                // label: {
                //   show: true,
                //   position: 'insideTop',
                //   normal: {
                //     formatter: '{c}',
                //     position: 'inside',
                //   },
                // },
                label: {
                  backgroundColor: 'rgb(89,85,85,0.5)',
                  borderColor: 'rgb(89,85,85,0.5)',
                  borderWidth: 1,
                  borderRadius: 1,
                  position: 'inner',
                  color: '#ffffff',
                  formatter: '{b}: \n {d}%',
                  // shadowBlur:3,
                  // shadowOffsetX: 2,
                  // shadowOffsetY: 2,
                  // shadowColor: '#999',
                  // padding: [0, 7],
                  labelLine: {
                    show: false
                  }
                },
                // emphasis: {
                //   label: {
                //     show: true,
                //     fontSize: '30',
                //     fontWeight: 'bold',
                //   },
                // },
                // labelLine: {
                //   show: true,
                // },
                data: [
                  {
                    value: info.hidraulica,
                    name: this.lenguaje == "es" ? 'Hidráulico' : 'Hydraulic',
                    itemStyle: {
                      color: graphicsColor.hidraulica,
                    },
                  },
                  {
                    value: info.gas,
                    name: this.lenguaje == "es" ? 'Gas' : 'Gas',
                    itemStyle: {
                      color: graphicsColor.gas,
                    },
                  },
                  {
                    value: info.carbon,
                    name: this.lenguaje == "es" ? 'Carbón' : 'Coal',
                    itemStyle: {
                      color: graphicsColor.carbon,
                    },
                  },
                  {
                    value: info.eolica,
                    name: this.lenguaje == "es" ? 'Eólica' : 'Wind',
                    itemStyle: {
                      color: graphicsColor.eolica,
                    },
                  },
                  {
                    value: info.solar,
                    name: this.lenguaje == "es" ? 'Solar' : 'Solar',
                    itemStyle: {
                      color: graphicsColor.solar,
                    },
                  },
                  {
                    value: info.otro_termico,
                    name: this.lenguaje == "es" ? 'Otro Térmico' : 'Other Thermal',
                  },
                  {
                    value: info.ndc,
                    name: this.lenguaje == "es" ? 'NDC' : 'NDC',
                    itemStyle: {
                      color: '#F4B183',
                    },
                  },
                  {
                    value: info.total,
                    name: this.lenguaje == "es" ? 'Total' : 'Total',
                    itemStyle: {
                      color: graphicsColor.pch
                    }
                  },
                  {
                    value: info.ernc,
                    name: this.lenguaje == "es" ? 'ERNC' : 'NCRE',
                    itemStyle: {
                      color: graphicsColor.ndc_t
                    }
                  }
                  // { value: info.ernc, name: 'ERNC' },
                ],
              },
            ],
          };
          charts.push(chart);
        });
        resolve(charts);
      });
    });
  }
}
