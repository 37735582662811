<div *ngIf="arrayTestimonios && arrayTestimonios.length > 0" class="bg-azul testimonio-section">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center text-main" data-aos="zoom-in">{{"testimonios.titulo" | translate }}</h1>
                <div class="linea"></div>
                <p *ngIf="testimoniosDescripcion" class="pt-3 text-center description" data-aos="zoom-in" [innerHTML]="testimoniosDescripcion.descripcion | dompurify">
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="all-slider" data-aos="zoom-in">
                    <div class="controls-arrows">
                        <p>
                            <a class="btn-prev" (click)="testimonio.prev()">
                                <img src="assets/flecha-slide1.png" alt="">
                            </a>
                            <a class=" btn-next" (click)="testimonio.next()">
                                <img src="assets/flecha-slide2.png" alt="">
                            </a>
                        </p>
                    </div>

                    <owl-carousel-o [options]="customOptions" #testimonio id="testimonio">

                        <ng-container *ngFor="let item of arrayTestimonios">
                            <ng-template carouselSlide [id]="item.id" [width]="item.width">
                                <div class="slider">
                                    <div class="testi-tarjeta text-center">
                                        <img src="assets/comilla1.png" alt="comilla1" class="comillas1">
                                        <img src="assets/comilla2.png" alt="comilla2" class="comillas2">
                                        <img [src]="urlS3+'/'+item.archivo" alt="item.text" class="foto-testimonio">
                                        <p class="title">{{item.nombre}}</p>
                                        <p class="subtitle">{{item.perfilprofesional}}</p>
                                        <div class="description" [innerHTML]="item.descripcion | cutHtmlString:350:true"></div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>

                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>