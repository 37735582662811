import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica14/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {

        let chart = {
          color: ['#BDA1D4', '#6CBC64', '#F69A4F', '#7BACCE'],
          tooltip: {
            trigger: 'axis',
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) => this.datePipe.transform(el.etapa, 'MMM/yyyy')),
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            data: this.lenguaje == "es" ? [
              'Valor esperado con Niño y Niña',
              'Hidrología Alta',
              'Hidrología Baja',
              'Valor Esperado (100 Series)',
            ] : [
              'Expected value with Niño and Niña',
              'High Hydrology',
              'Low Hydrology',
              'Expected Value (100 Series)',
            ] ,
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'Valor esperado con Niño y Niña'  : 'Expected value with Niño and Niña',
              data: data.map((el) => parseFloat(el.valor_esperado.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'dotted',
                color: '#BDA1D4',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Hidrología Alta' : 'High Hydrology',
              data: data.map((el) => parseFloat(el.hidrologia_alta.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#6CBC64',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Hidrología Baja' : 'Low Hydrology',
              data: data.map((el) => parseFloat(el.hidrologia_baja.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#F69A4F',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Valor Esperado (100 Series)' : 'Expected Value (100 Series)',
              data: data.map((el) => parseFloat(el.valor_promedio.toFixed(2))),
              type: 'line',
              lineStyle: {
                width: 3,
                type: 'solid',
                color: '#7BACCE',
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
