import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';
import { ChartService } from '../../../services/charts/chart9.service';

@Component({
  selector: 'app-chart9',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
})
export class Chart9Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  title: any;

  chartOption: EChartOption = {};
  constructor(private service: ChartService) {}

  ngOnInit(): void {
    let historico = this.historico;
    let data = { escenario: this.escenario, historico };
    this.service.getData(data).then((data: any) => {
      this.chartOption = data;
    });
    let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.nombre_grafica_9;
  }
}
