<div class="table-responsive">
    <table class="table  table-striped">
        <thead>
            <tr>
                <th colspan="12" class="text-center" [innerHTML]="title">
                </th>
            </tr>
            <tr>
                <th scope="col">{{"tabla3.actividad" | translate}}</th>
                <th scope="col">{{"tabla3.costo" | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of response;  let i = index;">
                <td>{{data.actividad}}</td>
                <td class="text-center">{{data.costo | customDecimal}}</td>
            </tr>
        </tbody>
    </table>
</div>