import {Component, OnInit} from '@angular/core';
import {HomeService} from '../../services/home.service';
import {Nosotros} from '../../models/nosotros.models';
import {environment} from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.css']
})
export class NosotrosComponent implements OnInit {

  nosotrosParametros: any;
  nosotrosCampos = new Nosotros;
  archivoTipo: any;

  constructor(
    private translate: TranslateService,private homeService: HomeService,
  ) {
  }

  ngOnInit(): void {
    this.nosotrosParametros = {seccion: 'Nosotros', estado: 'Activo'};
    this.homeService.consultarNosotros(this.nosotrosParametros)
      .subscribe((respuesta: any) => {

        if (respuesta.data.length === 0) {
          return;
        }
        respuesta.data[0].campos.forEach(campo => {
          if (campo.nombre == 'Titulo') {
            this.nosotrosCampos.titulo = campo.valor;
          }
          if (campo.nombre == 'Descripcion') {
            this.nosotrosCampos.descripcion = campo.valor;
          }
          if (campo.nombre == 'Archivo') {
            this.nosotrosCampos.archivo = campo.valor;
          }
          if (campo.nombre == 'Tipo contenido') {
            this.archivoTipo = campo.valor;
          }
        });
      });

  }

  get urlS3() {
    return environment.urlS3;
  }
}
