import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GraficasService } from "../../../services/graficas.service";

@Component({
    selector: 'app-tabla3',
    templateUrl: './tabla3.component.html',
    styleUrls: ['./tabla3.component.scss'],
    providers: []
})
export class Tabla3Component implements OnInit, AfterViewInit, OnDestroy {
    // Parametros para buscador
  @Input()
  escenario;
  @Input()
  historico = false;
  title: any
    response: any;
    constructor(
        private graficasService: GraficasService
    ) { }


    ngOnInit() {
        this.getTabla3();
        let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
        this.title = parsearLocal.titulo_tabla_3;
    }

    ngAfterViewInit() { }

    ngOnDestroy() { }

    getTabla3() {

        this.graficasService.consultatablas(this.escenario, 'tabla_3').subscribe(data => {
            if (data) {
                this.response = data
            } else {
                alert("Consulta sin resultados");
            }
        });

    }
}
