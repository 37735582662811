<app-header></app-header>

<div class="banner-ppal">
    <div class="archivo-banner">
        <img data-aos="zoom-in" *ngIf="archivoTipo == 'Imagen' || archivoTipo == 'Infografia'" [src]="urlS3+ '/' + nosotrosCampos.archivo" alt="" class="w-100 pt-1">
        <video data-aos="zoom-in" *ngIf="archivoTipo == 'Video'" width=" 100% " height=" auto " loop="true" data-autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" controls controlsList="nodownload">
            <source [src]="urlS3+ '/' + nosotrosCampos.archivo " type="video/mp4 ">
        </video>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 text-center align-self-center">
                <h1> {{ nosotrosCampos.titulo }}</h1>
                <h3> {{ nosotrosCampos.subtitulo }}</h3>
            </div>
        </div>
    </div>
</div>

<div class="tabuladores-nosotros">
    <div class="container">
        <div class="row">
            <div class="d-flex pass-block">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-pills" orientation="vertical">
                    <li ngbNavItem="1">
                        <a ngbNavLink>{{ "sobrenosotrosTabs.opcion1" | translate }}</a>
                        <ng-template ngbNavContent>
                            <h3 class="title">{{ nosotrosCampos.titulo }}</h3>
                            <div class="linea-verdetab"></div>
                            <p [innerHTML]='nosotrosCampos.descripcion | dompurify'></p>
                        </ng-template>
                    </li>
                    <li ngbNavItem="2">
                        <a ngbNavLink>{{ "sobrenosotrosTabs.opcion2" | translate }}</a>
                        <ng-template ngbNavContent>
                            <h3 class="title">{{ misionCampos.titulo }}</h3>
                            <div class="linea-verdetab"></div>
                            <p [innerHTML]='misionCampos.descripcion | dompurify'></p>
                        </ng-template>
                    </li>
                    <li ngbNavItem="3">
                        <a ngbNavLink>{{ "sobrenosotrosTabs.opcion3" | translate }}</a>
                        <ng-template ngbNavContent>
                            <h3 class="title">{{ visionCampos.titulo }}</h3>
                            <div class="linea-verdetab"></div>
                            <p [innerHTML]='visionCampos.descripcion | dompurify'></p>
                        </ng-template>
                    </li>
                    <li ngbNavItem="4">
                        <a ngbNavLink>{{ "sobrenosotrosTabs.opcion4" | translate }}</a>
                        <ng-template ngbNavContent>
                            <h3 class="title">{{ valoresCampos.titulo }}</h3>
                            <div class="linea-verdetab"></div>
                            <p [innerHTML]='valoresCampos.descripcion | dompurify'></p>
                        </ng-template>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="ml-4"></div>
            </div>
        </div>
    </div>
</div>

<div class="equipo">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-equipo pt-2 pb-4">
                <h1 class="text-center text-main"> {{ "nuestroEquipoNosotros.titulo" | translate }} </h1>
                <div class="linea"></div>
                <p *ngIf="equipoDescripcion" class="pt-3 text-center description" [innerHTML]="equipoDescripcion.descripcion">
                </p>
            </div>
        </div>

        <!--  PRUEBA PARA LA CREACIÓN DINAMICA DE NUESTRO EQUIPO  -->

        <div *ngFor="let dataP of dataPrueba" class="row responsive-arreglo">
            <div class="col-lg-12 text-equipo pt-4 pb-4">
                <h2 class="text-center text-main" style="text-transform: uppercase;"> {{ dataP.titulo }} </h2>
            </div>
            <div *ngFor="let equipo of dataP.equipo" class="col-lg-4 col-md-6 col-xs-12 equipo-items">

                <div class="container-img position-relative">
                    <img [src]="urlS3+'/'+equipo.archivo" alt="equipo.nombre" class="foto-equipo">
                    <div class="overlay text-center">
                        <div class="text">
                            <h3> {{ equipo.nombre }} </h3>
                            <p class="cargo"> {{ equipo.seccion }}</p>
                            <p class="cargo cargo-nuevo"> {{ equipo.cargo }}</p>
                            <p class="correo"> {{ equipo.correo }} </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>

<app-footer #footer></app-footer>
