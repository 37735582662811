<app-header></app-header>

<!-- <div id="sections-nav" class="sections-right">
  <ul>
    <li>
      <a href="#banner" id="sections-nav-item-banner" (click)="addClassActive('a[href=&quot;#banner&quot;]')" class="sections-nav-item active">
        <span class="sections-sr-only">
          Section 1
        </span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#sliderservicio" id="sections-nav-item-sliderservicio" (click)="addClassActive('a[href=&quot;#sliderservicio&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">
          Section 2
        </span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#nosotros" id="sections-nav-item-nosotros" (click)="addClassActive('a[href=&quot;#nosotros&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 3</span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#serviciosNoDigitales" id="sections-nav-item-serviciosNoDigitales" (click)="addClassActive('a[href=&quot;#serviciosNoDigitales&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 4</span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#PHCMundo" id="sections-nav-item-PHCMundo" (click)="addClassActive('a[href=&quot;#PHCMundo&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 5</span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#testimonios" id="sections-nav-item-testimonios" (click)="addClassActive('a[href=&quot;#testimonios&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 6</span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#proyectos" id="sections-nav-item-proyectos" (click)="addClassActive('a[href=&quot;#proyectos&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 7</span>
        <span></span>
      </a>
    </li>
    <li>
      <a href="#footer" id="sections-nav-item-footer" (click)="addClassActive('a[href=&quot;#footer&quot;]')" class="sections-nav-item">
        <span class="sections-sr-only">Section 8</span>
        <span></span>
      </a>
    </li>
  </ul>
</div> -->

<div>
  <div id="banner" class="section over_banner">
    <app-banner></app-banner>
  </div>
  <div id="sliderservicio" class="section">
    <app-slider></app-slider>
  </div>
  <div id="nosotros" class="section">
    <app-nosotros></app-nosotros>
  </div>
  <div id="serviciosNoDigitales" class="section">
    <app-servicios-no-digital></app-servicios-no-digital>
  </div>
  <div id="PHCMundo" class="section">
    <app-mapa></app-mapa>
  </div>
  <div id="testimonios" class="section">
    <app-testimonios></app-testimonios>
  </div>
  <div id="proyectos" class="section">
    <app-proyectos></app-proyectos>
  </div>
  <div id="footer" class="section">
    <app-footer></app-footer>
  </div>
</div>
