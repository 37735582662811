import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InternasServiceService } from 'src/app/services/internas-service.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-modal-asesoria',
  templateUrl: './modal-asesoria.component.html',
  styleUrls: ['./modal-asesoria.component.css']
})
export class ModalAsesoriaComponent implements OnInit {

  usuarioAsesoria: FormGroup;
  asesores: any[] = [];
  nameUser: any = '';
  urlAsesoria: any = '';
  @Input() servicio: any;
  constructor(private ngbActiveModal: NgbActiveModal, private _builder: FormBuilder,
    private internaService: InternasServiceService, private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {


    //Traer los usuarios
    this.internaService.traerAsesores({tema_asesoria: this.servicio})
        .subscribe( (respuesta: any) =>  {

          if( respuesta.type == "warning" || respuesta.type == "danger"){
            this._snackBar.open( respuesta.message, '' , { duration: 3000,
             panelClass: ['alert', 'alert-danger'] }
             );
             return;
           }

           this.asesores = respuesta;
        });

    this.usuarioAsesoria = this._builder.group({
      usuario: new FormControl('', [Validators.required]),
    });

  }

  obtenerUserHubspot(username){
    this.nameUser = username;
    this.generateSafeImageUrl();
  }

  generateSafeImageUrl() {
    this.urlAsesoria = this.sanitizer.bypassSecurityTrustResourceUrl(`https://meetings.hubspot.com/${this.nameUser}?embed=true`);
  }

  closeModal(instancia){
    this.ngbActiveModal.close(instancia);
  }

  onSubmit(values){

  }

}
