import {Component, Input, OnInit} from '@angular/core';
import {EChartOption} from 'echarts';
import {GraficasService} from '../../../services/graficas.service';

@Component({
  selector: 'app-grafica4',
  templateUrl: './grafica4.component.html',
  styleUrls: ['./grafica4.component.css']
})
export class Grafica4Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  data = [];
  title: any;
  chartOption: EChartOption;
  lenguaje = "";
  constructor(private graficasService: GraficasService) {
    this.lenguaje = localStorage.getItem('language');
  }

  ngOnInit() {
    let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.nombre_grafica_4;

    this.graficasService.consultarGrafica4(this.escenario).subscribe((Response: any) => {


      this.chartOption = {
        // title: {
        //     text: 'Precio promedio de Contratos COP/kWh'
        // },
        color: ['#2c7eb0', '#48a043', '#f5994c'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false
          }
        },
        legend: {
          //   icon: 'rectangle',
          //   center: ['10%', '10%'],
          //   orient: 'line',
          //   textStyle: {
          //       fontSize: '10',
          //   },
          // x : 'left',
          y: '-3px',
          // left:'28%',
          data: this.lenguaje == "es" ? ['Precio Promedio Contrato', 'Precio Promedio Contratos Regulados', 'Precio Promedio Contratos No Regulados'] : ['Average Contract Price', 'Average Price Regulated Contracts', 'Average Price of Non Regulated Contracts']
        },
        xAxis: {
          type: 'category',
          rotate: 90,
          splitLine: {
            show: false
          },
          data: Response.headers
        },
        yAxis: {
          type: 'value',

        },
        series: [
          {
            name: this.lenguaje == "es" ? 'Precio Promedio Contrato' : 'Average Contract Price',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: Response.precio_promedio_contrato,
            lineStyle: {color: '#2c7eb0'}
          },
          {
            name: this.lenguaje == "es" ? 'Precio Promedio Contratos Regulados' : 'Average Price Regulated Contracts',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: Response.precio_promedio_contratos_regulados,
            lineStyle: {color: '#48a043'}
          }
          ,
          {
            name: this.lenguaje == "es" ? 'Precio Promedio Contratos No Regulados' : 'Average Price of Non Regulated Contracts',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: Response.precio_promedio_contratos_no_regulados,
            lineStyle: {color: '#f5994c'}
          }
        ],
        dataZoom: [
          {
            start: 0,
            type: 'inside'
          },
          {
            start: 0
          }
        ]
      };

    });
  }

  randomData(now, oneDay, value) {
    let now2 = new Date(+now + oneDay);
    let value2 = value + Math.random() * 21 - 10;
    return {
      value: [
        [now2.getFullYear(), now2.getMonth() + 1, now2.getDate()].join('/'),
        Math.round(value2)
      ]
    };
  }
}
