import {Injectable, Injector} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {publicUrls} from '../app.config';
import {SecureStorageService} from '../services/secure-storage.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private injector: Injector,
        private router: Router,
    ) {
    }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({setHeaders: {Authorization: 'JWT ' + token}});
    }

    getAccessToken() {
      const secureStorageService = this.injector.get(SecureStorageService);
      return secureStorageService.getItem('accessToken');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        // const authService = this.injector.get(AuthService);
        const token = this.getAccessToken();

        let isPublic = false;
        for (const url of publicUrls) {
            if (req.url.search(url) !== -1) {
                isPublic = true;
                break;
            }
        }

        if (token && !isPublic) {
            return next.handle(this.addToken(req, token)).pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        if (error && error.error && error.error.code === 'token_not_valid') {
                            // Handle Access token expired
                            if (error.error.detail === 'Given token not valid for any token type') {
                                return this.handleTokenRefresh(req, next);
                            } else if (error.error.detail === 'Token is invalid or expired') { // Handle Request token expired

                                return this.logoutUser();
                            } else {

                                return throwError(error);
                            }
                        } else if (error.error instanceof Blob && error.error.type === 'application/json') {
                            return this.handleTokenRefresh(req, next);

                        } else {

                            return throwError(error);
                        }
                    } else {

                        return throwError(error);
                    }
                }));
        }
        return next.handle(req);
    }

    handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(AuthService);

            return authService.refreshAccessToken().pipe(
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.

                    return this.logoutUser();
                }),
                switchMap((newToken: string) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        return next.handle(this.addToken(req, newToken));
                    }
                    // If we don't get a new token, we are in trouble so logout.

                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }));
        }
    }

    logoutUser() {
        const authService = this.injector.get(AuthService);
        authService.logout();
        this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
        // Route to the login page (implementation up to you)
        return throwError('');
    }
}
