<div class="table-responsive">
  <table class="table table-striped">
    <thead>
    <tr>
      <th class="w-auto-custom">{{"tabla6.supuesto" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.natural_gas_ccgt" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.natural_gas_ocgt" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.carbon" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.eolica" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.solar" | translate}}</th>
      <th class="w-auto-custom">{{"tabla6.hidro" | translate}}</th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let data of response;  let i = index;">
      <td class="text-center">{{data.supuesto}}</td>
      <td class="text-center">{{data.natural_gas_ccgt }}</td>
      <td class="text-center">{{data.natural_gas_ocgt }}</td>
      <td class="text-center">{{data.carbon }}</td>
      <td class="text-center">{{data.eolica }}</td>
      <td class="text-center">{{data.solar }}</td>
      <td class="text-center">{{data.hidro }}</td>
    </tr>

    </tbody>
  </table>
</div>
