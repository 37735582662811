import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/services/home.service';
import {ServiciosNoDigitalService} from 'src/app/services/servicios-no-digital.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-modal-estudios-conexion',
  templateUrl: './modal-estudios-conexion.component.html',
  styleUrls: ['./modal-estudios-conexion.component.css']
})
export class ModalEstudiosConexionComponent implements OnInit {

  iconos: any[] = [];
  parrafosGenerales = {};
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
              private _ServiciosNoDigitalService: ServiciosNoDigitalService, private router: Router,
              private homeService: HomeService) {
  }

  ngOnInit(): void {

    let objeto = {
      nombre: "",
      digital: "si",
      estado: "activo"
    }

    this._ServiciosNoDigitalService.consultarServiciosNoDigitales(objeto).subscribe((data: any) => {
      for (const value of data) {
        const dataIconos = {
          id: value.id,
          url: value.url,
          title: value.nombre,
          img: this.urlS3 + "/" + value.icono,
          imgHover: this.urlS3 + "/" + value.icono
        };
        this.iconos.push(dataIconos);
      }
    });

    this.homeService.obtenerParametrosGenerales()
        .subscribe( (respuesta) => {
            this.parrafosGenerales = respuesta[0];
        });

  }

  closeModal() {
    this.activeModal.close('Succesful');
  }

  routerOrPopup(url) {
    const arrayUrls = [];
    arrayUrls['proyecciones-de-mercado'] = 'proyecciones-de-mercado';
    arrayUrls['estudios-de-conexion'] = 'estudios-de-conexion';
    arrayUrls['asesoria-en-linea'] = 'asesoria-en-linea';
    arrayUrls['market-projections'] = 'proyecciones-de-mercado';
    arrayUrls['connection-studies'] = 'estudios-de-conexion';
    arrayUrls['online-counseling'] = 'asesoria-en-linea';
    arrayUrls['trazabilidad-de-proyectos'] = 'trazabilidad-de-proyectos';

    const urlNext = arrayUrls[url];

    if (urlNext) {
      this.router.navigate(['/inicio', urlNext]);
      this.closeModal();
    } else {
      return;
    }

  }

  get urlS3() {
    return environment.urlS3;
  }


}
