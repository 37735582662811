import {Component, OnInit} from '@angular/core';
import {ServiciosNoDigitalService} from '../../services/servicios-no-digital.service';
import {environment} from '../../../environments/environment';
import {nosotrosDetalle} from '../../models/nosotrosdetalle.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-servicios-no-digital',
  templateUrl: './servicios-no-digital.component.html',
  styleUrls: ['./servicios-no-digital.component.css']
})
export class ServiciosNoDigitalComponent implements OnInit {
  datosServicio: any;
  nosotrosDetalleModal = new nosotrosDetalle;

  constructor(private translate: TranslateService,private _ServiciosNoDigitalService: ServiciosNoDigitalService) {
    this.consultaServicios();

  }

  ngOnInit(): void {
  }

  consultaServicios() {
    let objeto = {
      nombre: '',
      digital: false,
      estado: 'activo'
    };
    this._ServiciosNoDigitalService.consultarServiciosNoDigitales(objeto).subscribe((data: any) => {
      this.datosServicio = data;

    });
  }

  get urlS3() {
    return environment.urlS3;
  }

  modal(data) {
    this.nosotrosDetalleModal.titulo = data.nombre;
    this.nosotrosDetalleModal.descripcion_larga = data.descripcion_larga;
  }

}
