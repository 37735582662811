import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica6/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let res = {
          color: ['#39C2F3', '#195D9F', '#2EB256', '#EC7B2C'],
          tooltip: {
            trigger: 'axis',
            // formatter: '{a} <br/>{b}: {c}',
          },
          xAxis: {
            type: 'category',
            data: data.map((el) =>
              this.datePipe.transform(el.fecha, 'MMM-yyyy')
            ),
          },
          yAxis: {
            type: 'value',
            min: this.getMin(data),
            name: 'GWh',
            nameTextStyle: {
              padding: [0, 70, 0, 0]
            },
          },
          legend: {
            data: this.lenguaje == "es" ? ['Escenario Bajo', 'Escenario Medio', 'Escenario Alto', 'Real'] :  ['Low Scenario', 'Medium Scenario', 'High Scenario', 'Real'],
          },
          series: [
            {
              name: this.lenguaje == "es" ? 'Escenario Bajo' : 'Low Scenario',
              data: data.map((el) => parseFloat(el.bajo.toFixed(2))),
              type: 'line',
              lineStyle: {
                type: 'dotted',
                color: '#39C2F3',
                width: 3,
              },
            },
            {
              name: this.lenguaje == "es" ? 'Escenario Medio' : 'Medium Scenario',
              data: data.map((el) => parseFloat(el.medio.toFixed(2))),
              type: 'line',
              lineStyle: {
                type: 'dotted',
                color: '#195D9F',
                width: 3,
              },
            },
            {
              name: this.lenguaje == "es" ? 'Escenario Alto' : 'High Scenario',
              data: data.map((el) => parseFloat(el.alto.toFixed(2))),
              type: 'line',
              lineStyle: {
                type: 'dotted',
                color: '#2EB256',
                width: 3,
              },
            },
            {
              name: this.lenguaje == "es" ? 'Real' : 'Real',
              data: data.map((el) => parseFloat(el.real.toFixed(2))),
              type: 'line',
              lineStyle: {
                type: 'solid',
                color: '#EC7B2C',
                width: 3,
              },
            },
            {
              name: this.lenguaje == "es" ? 'Actualización 1' : 'Update 1',
              data: data.map((el) => el.actualizacion1),
              type: 'bar',
              tooltip: {
                show: false
              },

              label: {
                show: true,
                position: 'top',
                formatter: `{a} \n {b}`,
                color: '#000',
                rotate: 25
              },
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
            {
              name: this.lenguaje == "es" ? 'Actualización 2' : 'Update 2',
              data: data.map((el) => el.actualizacion2),
              type: 'bar',
              tooltip: {
                show: false
              },
              label: {
                show: true,
                position: 'top',
                formatter: `{a} \n {b}`,
                color: '#000',
                rotate: 25
              },
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(res);
      });
    });
  }

  getMin(data): number {
    const bajo = data.map((el) => el.bajo);
    const medio = data.map((el) => el.medio);
    const alto = data.map((el) => el.alto);
    const real = data.map((el) => el.real);
    const actualizacion1 = data.map((el) => el.actualizacion1);
    const actualizacion2 = data.map((el) => el.actualizacion2);
    let values = [...bajo, ...medio, ...alto, ...real, ...actualizacion1, ...actualizacion2];
    values = values.filter(value => value);
    values = values.map(value => parseFloat(value));
    const min = Math.min(...values);
    return min;
  }
}
