
   
   <div id="myModal" class="modal md-registro">
        <div class="row mt-3 mr-3 ">
            <div class="col-12">
               <!---<img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click'); resetDatosRegistro()">-->
            </div>
        </div>
        <div class="row modal-rps">
            <div class="col-lg-12 col-sm-12 ">
                <div class="modal-content">
                    <form *ngIf="!mensajeAfterRegistro" [formGroup]="registerFormulario" (ngSubmit)="onSubmitRegistro(formDir)" #formDir="ngForm" autocomplete="off">
                        <div class="row formularioRegistro">
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="nombre" class="labelForm"> {{"registroForm.opcion1" | translate }} </label>
                                <input type="text" class="form-control" id="nombre" formControlName="nombre">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="apellido" class="labelForm">{{"registroForm.opcion2" | translate }}</label>
                                <input type="text" class="form-control" id="apellido" formControlName="apellido">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'apellido') && formDir.submitted">
                                    {{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="correo" class="labelForm"> {{"registroForm.opcion3" | translate }}</label>
                                <input type="email" class="form-control" id="correo" formControlName="correo">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'correo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.get('correo').invalid && formDir.submitted ">{{"formularioAvisos.opcion3" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label class="labelForm"><b>{{"registroForm.opcion4" | translate }}</b></label>
                                <select id="inputState" class="form-control" (change)="cambiarTexto($event.target.value)"  formControlName="tipoPersona">
                                    <option value="null" disabled>{{"formularioAvisos.opcion6" | translate }}</option>
                                    <option *ngFor="let tipoPersona of tiposPersona" value="{{tipoPersona.id}}">{{tipoPersona.tipo}}</option>
                                </select>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'tipoPersona') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="numeroDocumento" *ngIf="documentoOrNIT" class="labelForm">{{"registroForm.opcion5" | translate }}</label>
                                <label for="numeroDocumento" *ngIf="!documentoOrNIT" class="labelForm">{{"registroForm.opcion6" | translate }}</label>
                                <input type="text" class="form-control" id="numeroDocumento" formControlName="numeroDocumento">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'numeroDocumento') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'numeroDocumento') && formDir.submitted">
                                    {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="celular" class="labelForm">{{"registroForm.opcion7" | translate }}</label>
                                <input type="text" class="form-control" id="celular" formControlName="celular">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'celular') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'celular') && formDir.submitted">
                                    {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="telefono" class="labelForm">{{"registroForm.opcion8" | translate }}</label>
                                <input type="text" class="form-control" id="telefono" formControlName="telefono">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'telefono') && formDir.submitted">
                                    {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="cargo" class="labelForm">{{"registroForm.opcion9" | translate }}</label>
                                <input type="text" class="form-control" id="cargo" formControlName="cargo">
                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('required', 'cargo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="empresa" class="labelForm">{{"registroForm.opcion10" | translate }}</label>
                                <input type="text" class="form-control" id="empresa" formControlName="empresa">

                            </div>
                            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                                <label for="nitEmpresa" class="labelForm"> {{"registroForm.opcion11" | translate }}</label>
                                <input *ngIf="documentoOrNIT" type="text" class="form-control" id="nitEmpresa" formControlName="nitEmpresa">
                                <input *ngIf="!documentoOrNIT" type="text" class="form-control" id="nitEmpresa" formControlName="nitEmpresa" [value]="registerFormulario.controls['numeroDocumento'].value">

                                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;" *ngIf="registerFormulario.hasError('pattern', 'nitEmpresa') && formDir.submitted">
                                    {{"formularioAvisos.opcion2" | translate }}</label>
                            </div>
                            <div class="col-lg-12 col-12 form-check">
                                <input class="form-check-input" type="checkbox" (change)="checkHabeasData($event.target.checked)" value="" formControlName="habeasData" id="habeasData">
                                <label class="form-check-label" for="habeasData">
                                    {{"registroForm.opcion12" | translate }}
                                </label>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="this.checkedHabeasData == false || (registerFormulario.hasError('required', 'habeasData') && formDir.submitted)">
                                    {{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="col-lg-12">
                                <div class="btn-container">
                                    <button type="submit" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="mensajeAfterRegistro" class="mensaje-registro">
                        <div *ngIf="!mensajeExitoRegistro && !mensaje_back" class="mensaje-existente">
                            <label style="font-size: 16px;">{{"registroForm.opcion14" | translate }}</label>
                            <div class="col-lg-12">
                                <div class="btn-container">
                                    <button type="button" (click)="cerrarModal" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="mensaje_back && !mensajeExitoRegistro" class="mensaje-existente">
                            <label style="font-size: 16px;">{{mensaje_back}}</label>
                            <div class="col-lg-12">
                                <div class="btn-container">
                                    <button type="button" (click)="cerrarModal" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="mensajeExitoRegistro" class="mensaje-exitoso">
                            <label style="font-size: 16px;">{{"registroForm.opcion15" | translate }}</label>
                            <div class="col-lg-12">
                                <div class="btn-container">
                                    <button type="button" (click)="cerrarModal()" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>