import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HomeService } from 'src/app/services/home.service';
import { InternasServiceService } from 'src/app/services/internas-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';

import { Vacantes } from '../../../models/vacantes.models';
import { TranslateService } from '@ngx-translate/core';
import { removeSpaces } from '../../../utils/validation-trim';
import { environment } from 'src/environments/environment';
import {SeoService} from '../../../services/seo.service';

@Component({
  selector: 'app-trabajacon-nosotros',
  templateUrl: './trabajacon-nosotros.component.html',
  styleUrls: ['./trabajacon-nosotros.component.css']
})
export class TrabajaconNosotrosComponent implements OnInit {

  vacantesCampos = new Vacantes;
  objetoCompleto: Vacantes;
  arrayVacantes: Array<Vacantes> = [];
  vacantesForm: FormGroup;
  fileParaSubir: any;
  buscarPalabra: any;
  politicasTratamiento: any;
  terminosTitulo: any;
  checkedHabeasData: boolean;
  trabajaConNosotrosDescripcion: any;
  uploadFileEvent: boolean;
  habeasData: any;
  constructor(
    private _builder: FormBuilder,
    private homeService: HomeService,
    private translate: TranslateService,
    private internasService: InternasServiceService,
    private _snackBar: MatSnackBar,
    private seoService: SeoService
  ) {

    this.homeService.consultarNosotros({seccion: 'Trabaja con nosotros vacantes' , estado : 'activo'})
      .subscribe( data => {

        for (const array of data.data) {
          array.campos.forEach(campo => {
            if (campo.nombre == 'Titulo') {
              this.vacantesCampos.titulo = campo.valor;
            }
            if (campo.nombre == 'Descripcion') {
              this.vacantesCampos.descripcion = campo.valor;
            }
            if (campo.nombre == 'Nombre cargo') {
              this.vacantesCampos.cargo = campo.valor;
            }

            this.objetoCompleto = {
              titulo: this.vacantesCampos.titulo,
              descripcion: this.vacantesCampos.descripcion,
              cargo: this.vacantesCampos.cargo
            }

          });

          this.arrayVacantes.push(this.objetoCompleto);
        }

      } );

   }

  ngOnInit(): void {
    this.vacantesForm = this._builder.group({
      nombres: new FormControl('', [Validators.required,removeSpaces]),
      apellidos: new FormControl('', [Validators.required,removeSpaces]),
      telefono: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      correo: new FormControl('', [Validators.required, Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$")]),
      cargo: new FormControl('', [Validators.required,removeSpaces]),
      terminos: new FormControl('', [Validators.required]),
      HV: new FormControl('', [Validators.required])
    });

    this.vacantesForm.reset();

    this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        // //debugger
        const lenguaje = this.translate.getDefaultLang().valueOf();
        if (lenguaje == 'es') {
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 1);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 2);
        } else{
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 3);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 4);
        }
      });

    if( localStorage.getItem('language') == 'en' ){
      this.buscarPalabra = 'Search';
    }else {
      this.buscarPalabra = 'Buscar';
    }

    this.homeService.filtrarSecciones( { nombre: 'Trabaja con nosotros vacantes' } )
    .subscribe( (respuesta: any) => {
      this.trabajaConNosotrosDescripcion = respuesta[0];
        } );
    this.gethabeasData();
    this.seoService.addMetaTagsByPage('Meta tags - página trabaja con nosotros');
  }

  checkHabeasData(event) {
    if (event == false) {
      this.checkedHabeasData = false;
    } else {
      this.checkedHabeasData = true;
    }
  }

  fileInputChange(evento){
    this.fileParaSubir = evento[0];
    document.querySelector('.custom-file-label').innerHTML = this.fileParaSubir.name;

    let validExts = new Array('.docx', '.doc', '.pdf');
    if (!evento) {
      return;
    }

    let fileExt = evento[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.uploadFileEvent = true;
      this.vacantesForm.setErrors({'invalid': true});

    } else {
      this.uploadFileEvent = false;
    }


  }

  onSubmit(event, formDirective: FormGroupDirective){
    let message:string;
    if( !this.vacantesForm.valid || this.vacantesForm.controls['terminos'].value == false  ){
      this.vacantesForm.markAllAsTouched();
      this.translate.get('errores').subscribe((res:any)=>{
        message = res.requerido;
      });

      this._snackBar.open( message, '' , { duration: 3000,
        panelClass: ['alert', 'alert-danger'] }
     );
      return;
    }

    if( this.vacantesForm.valid && this.uploadFileEvent ){
      this.vacantesForm.markAllAsTouched();
      this.translate.get('errores').subscribe((res:any)=>{
        message = res.extensiones;
      });
      this._snackBar.open( message , '' , { duration: 3000,
        panelClass: ['alert', 'alert-danger'] });
      return;
    }

    let correoUsuario = {
      nombres: this.vacantesForm.controls['nombres'].value,
      apellidos: this.vacantesForm.controls['apellidos'].value,
      correo: this.vacantesForm.controls['correo'].value,
      telefono: this.vacantesForm.controls['telefono'].value,
      cargo: this.vacantesForm.controls['cargo'].value,
      terminos: this.vacantesForm.controls['terminos'].value,
      HV: this.fileParaSubir,
      tipo: 'Trabaja con nosotros'
    }

    this.internasService.vacantesFormulario(correoUsuario).subscribe(( respuesta ) => {
      if( respuesta.type == 'danger' ){

          this._snackBar.open( respuesta.message, '' , { duration: 3000,
            panelClass: ['alert', 'alert-danger'] }
         );
          return;
        }else {
          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );
         formDirective.resetForm();
         this.vacantesForm.reset();
         document.querySelector('.custom-file-label').innerHTML = 'Selecciona un archivo';
      }
    });

  }

  gethabeasData(): void {
    this.homeService.consultaTerminosPolticas().subscribe(response => {
      const lenguaje = this.translate.getDefaultLang().valueOf();
      if (lenguaje == 'es') {
        const data = response.filter(habeasdata => habeasdata.id === 1);
      this.habeasData = data[0];
      } else{
        const data = response.filter(habeasdata => habeasdata.id === 3);
      this.habeasData = data[0];
      }
    });
  }

  navigate(event,id){
    window.open(environment.urlWeb+'/terminos-y-condiciones/'+id,'_blank')
    event.preventdefault();
  }

}
