import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {InternasServiceService} from '../../../services/internas-service.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HomeService} from 'src/app/services/home.service';
import {TranslateService} from '@ngx-translate/core';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from 'src/environments/environment';
import {SeoService} from '../../../services/seo.service';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  title = 'Mapa contacto';
  // Configuración de Google Maps
  center = {lat: 6.2128125, lng: -75.5595065};
  zoom = 15;
  display?: google.maps.LatLngLiteral;
  contactoForm: FormGroup;
  markers: any[];
  urlTwitter: string;
  urlLinkedin: string;
  urlYoutube: string;
  urlFacebook: string;
  urlInstagram: string;
  urlTiktok: string;
  politicasTratamiento: any;
  terminosTitulo: any;
  checkedHabeasData: boolean;
  // options?: google.maps.MapOptions = {
  //   zoomControl: false,
  //   scrollwheel: false,
  //   disableDoubleClickZoom: true,
  // }
  mapps: any;
  contactoDescripcion: any;
  habeasData: any;
  // apiLoaded: Observable<boolean>;
  apiLoaded: boolean = false;
  apiKeyMapa: String;
  showMap = false;
  map: google.maps.Map;
  constructor(
    private _builder: FormBuilder,
    private translate: TranslateService,
    private homeService: HomeService,
    private internasService: InternasServiceService,
    private _snackBar: MatSnackBar,
    private seoService: SeoService,
    private changeDetectorRef: ChangeDetectorRef
  ) {

    let apiKeyMap = JSON.parse(localStorage.getItem('credenciales'));
    let keyDecript = AES.decrypt(apiKeyMap.mapKey, 'abcd@$--12');
    this.apiKeyMapa = keyDecript.toString(CryptoJS.enc.Utf8);

  }
  ngAfterViewInit() {
    console.log("ngAfterViewInit")
    const loader = new Loader({
      apiKey: this.apiKeyMapa.toString(),
      version: 'weekly',
    });
    loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map'),{
            center: {lat: 6.2128125, lng: -75.5595065},
            zoom: 8,
            mapTypeId: 'roadmap',
      });
      // Añadir un marcador en la ubicación del centro del mapa
      new google.maps.Marker({
            position: {lat: 6.2128125, lng: -75.5595065},
            title: "PHC",
            map: this.map
      })
      
    });
    this.showMap = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    console.log("ngOnInit")
        
    this.contactoForm = this._builder.group({
      nombres: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      correo: new FormControl('', [Validators.required, Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$")]),
      mensaje: new FormControl('', [Validators.required]),
      terminos: new FormControl('', [Validators.required]),
    });



    this.homeService.obtenerRedesSociales()
    .subscribe( respuesta => {

      respuesta.forEach( item => {
        if ( item.name == "twitter" ){
          this.urlTwitter = item.link;
       } else if ( item.name == "linkedin" ){
          this.urlLinkedin = item.link;
       } else if ( item.name == "youtube" ) {
          this.urlYoutube = item.link;
       } else if ( item.name == "facebook" ) {
          this.urlFacebook = item.link;
       } else if ( item.name == "instagram" ){
          this.urlInstagram = item.link;
       } else if ( item.name == "tiktok" ){
          this.urlTiktok = item.link;
       }
      } );

     } );


     this.homeService.filtrarSecciones( { nombre: 'Comunícate con nosotros' } )
    .subscribe( (respuesta: any) => {
      this.contactoDescripcion = respuesta[0];
        } );


        this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        //debugger
        const lenguaje = this.translate.getDefaultLang().valueOf();
        if (lenguaje == 'es') {
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 1);
        } else{
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 3);
        }


      });

    this.contactoForm.reset();
    this.gethabeasData();
    this.seoService.addMetaTagsByPage('Meta tags - página contacto');

  }

  checkHabeasData(event) {
    if (event == false) {
      this.checkedHabeasData = false;
    } else {
      this.checkedHabeasData = true;
    }
  }

  onSubmit(event, formDirective: FormGroupDirective){
    let message:string;
    if(!this.contactoForm.valid || this.checkedHabeasData == false ){
      this.translate.get('errores').subscribe((res:any)=>{
        message = res.requerido;
      });


      this.contactoForm.markAllAsTouched();
      this._snackBar.open( message, '' , { duration: 3000,
        panelClass: ['alert', 'alert-danger'] }
     );
      return;
    }

    var objeto = Object.assign(this.contactoForm.value, { tipo: 'Contáctanos' });

    this.internasService.enviarContacto(objeto).subscribe((respuesta) => {
        if( respuesta.type == 'danger' ){
          this._snackBar.open( respuesta.message, '' , { duration: 3000,
            panelClass: ['alert', 'alert-danger'] }
         );
          return;
        }else {
          this._snackBar.open( respuesta.message , '' , { duration: 3000,
            panelClass: ['alert', 'alert-success'] }
         );
         formDirective.resetForm();
         this.contactoForm.reset();
      }
    });
    
  }

  gethabeasData(): void {
    this.homeService.consultaTerminosPolticas().subscribe(response => {
      const data = response.filter(habeasdata => habeasdata.id === 1);
      this.habeasData = data[0];

      const lenguaje = this.translate.getDefaultLang().valueOf();
      if (lenguaje == 'es') {
        const data = response.filter(habeasdata => habeasdata.id === 1);
      this.habeasData = data[0];
      } else{
        const data = response.filter(habeasdata => habeasdata.id === 3);
      this.habeasData = data[0];
      }

    });


  }

  navigate(event,id){

    window.open(environment.urlWeb+'/terminos-y-condiciones/'+id,'_blank')
    event.preventdefault();
  }

}
