
<div class="modal-dialog-lg" role="document">

  
  <div style="background-color:#60e266;  text-align:center;">
   
       <button type="button" class="close close-dialog" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="titulo-vrs font-weight-bold; p-3">{{"versionesModal.titulo" | translate }} {{name}}!</h2>
   
    


  </div>
</div>
<div class="modal-body modal-versiones" *ngIf="responseCupons.length >0">
  <div class="row">
    <div class="card text-center shadow p-3 mb-2 bg-white rounded col" *ngFor="let cupon of responseCupons">
      <br />
      <h3 class="font-weight-bold;">
        {{cupon.nombre}}
      </h3>
      <div class="card-body">
        <h5 class="card-title">{{"versionesModal.precio" | translate }}: {{cupon.valor}}</h5>
        <p class="card-text">{{"versionesModal.vigencia" | translate }}: {{cupon.meses_vigencia}}
          {{"versionesModal.meses" | translate }}</p>
        <p class="card-text"><b>{{"versionesModal.condiciones" | translate }}:</b></p>
        <p class="card-text" [innerHTML]="cupon.condiciones" style="margin-bottom: 40px"> </p>
        <button class="btn rounded-pill col"
          style="margin: auto;background-color:#60e266; width: 200px; font-size: 20px; color:#606060; bottom: 0; position: absolute; left: 0; right: 0"
          (click)="pagos(cupon.valor, cupon.id)">{{"versionesModal.aceptar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>