import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, Validators, FormGroup} from '@angular/forms';
import {HomeService} from '../../services/home.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ChangePasswordComponent} from '../../modals/change-password/change-password.component';
import {ImageSnippet} from '../../models/image';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {environment} from '../../../environments/environment';
import {SecureStorageService} from '../../services/secure-storage.service';

@Component({
  selector: 'app-inicio-sesion',
  templateUrl: './inicio-sesion.component.html',
  styleUrls: ['./inicio-sesion.component.css', './change-password.css']
})
export class InicioSesionComponent implements OnInit {
  responseCaptcha: boolean;
  lenguaje: string;
  logged: any;
  closeResult = '';
  loginForm: FormGroup;
  passwordForm: FormGroup;
  firtsTimePasswordForm: FormGroup;
  contrasenaError: String = '';
  messajeError = '';
  messajeErrorPassword = '';
  getPassword: boolean = false;
  primeraVez: boolean = false;
  successPassword: boolean = false;
  documentoOrNIT: boolean = true;
  tipoDocumento: any;
  registerFormulario: FormGroup;
  mensajeAfterRegistro: boolean = false;
  mensajeExitoRegistro: boolean = false;
  checkedHabeasData: boolean;
  loading: boolean = false;
  tiposPersona = [];
  imageLenguaje: string;
  keyCaptcha: string;
  errorMensaje: boolean = false;
  mensajeEstado: String = '';
  checkedTerminos: boolean;
  politicasTratamiento: any;
  terminosTitulo: any;
  rutFile: ImageSnippet;
  camaraFile: ImageSnippet;
  fileEventRut: any;
  fileEventCamara: any;
  fileNameCurrent: string;
  formatFilerunt: boolean;
  fileRutCurrent: boolean;
  formatFilecamera: boolean;
  fileCameraCurrent: boolean;
  responseRutFile: unknown;
  responseCamaraFile: unknown;
  fieldTextType: boolean = true;
  messageError = {message: null, type: null};
  mensaje_back:string = '';
  @Input() tituloInforme: any;
  errorEmail;
  errorEmpresa;
  constructor(private homeService: HomeService, private _render: Renderer2, private translate: TranslateService,
              private modalService: NgbModal, private _builder: FormBuilder, private authService: AuthService,
              private router: Router, private secureStorageService: SecureStorageService) {
    let script = this._render.createElement('script');
    script.defer = true;
    script.async = true;
    script.src = 'https://www.google.com/recaptcha/api.js';
    this._render.appendChild(document.body, script);

    this.loginForm = this._builder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      /*Se valida que :
      - La contraseña sea obligatoria
      - Tenga minimo 8 carácteres
      */
    });

    this.registerFormulario = this._builder.group({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      tipoPersona: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]),
      empresa: new FormControl(''),
      numeroDocumento: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      nitEmpresa: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      celular: new FormControl('', [Validators.required,Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
      telefono: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      cargo: new FormControl('', []),
      habeasData: new FormControl('', [Validators.required]),
      terminos: new FormControl('', [Validators.required]),
    });


    this.firtsTimePasswordForm = this._builder.group({
      /*Se valida que :
        - La contraseña sea obligatoria
        - Tenga minimo 8 carácteres
        - Solo permita letras y números
        */
      digitarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')])),
      confirmarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')]))
    });
    this.passwordForm = this._builder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email]))
    });
  }

  navigate(event,id){

    window.open(environment.urlWeb+'/terminos-y-condiciones/'+id,'_blank')
    event.preventdefault();
  }
  changeLenguaje() {
    this.lenguaje = this.translate.getDefaultLang().valueOf();
    if (this.lenguaje == 'es') {
      this.translate.setDefaultLang('en');
      localStorage.setItem('language', 'en');
      this.imageLenguaje = 'bandera-espanol.png';
    } else {
      this.translate.setDefaultLang('es');
      localStorage.setItem('language', 'es');
      this.imageLenguaje = 'bandera-ingles.png';
    }
    window.location.reload();
  }

  ngOnInit(): void {

    let apiKeyCaptcha = JSON.parse(localStorage.getItem('credenciales'));
    let keyDecript = AES.decrypt(apiKeyCaptcha.captchaKey, 'abcd@$--12');
    this.keyCaptcha = keyDecript.toString(CryptoJS.enc.Utf8);

    if (this.secureStorageService.getItem('djangoUser')) {
      this.logged = true;
    } else {
      this.logged = false;
    }

    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.registerFormulario.reset();

    this.lenguaje = this.getLanguage();
    this.imageLenguaje = (this.lenguaje === 'es') ? 'bandera-ingles.png' : 'bandera-espanol.png';

    this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        // //debugger
        const lenguaje = this.translate.getDefaultLang().valueOf();
        if (lenguaje == 'es') {
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 1);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 2);
        } else{
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 3);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 4);
        }
      });

  }

  cerrarSesion() {
    delete this.logged;
    localStorage.clear();
  }

  openRegistro(content) {
    this.mensajeAfterRegistro = false;
    this.homeService.traerTipoPersona()
      .subscribe(resp => {

        this.tiposPersona = resp;
      });

    this.modalService.open(content, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /*
  Método usado para el volver atrás de olvidó su contraseña
  */
  changePassword(status) {
    this.errorMensaje = false;
    this.getPassword = status;
    this.messajeErrorPassword = '';
    this.passwordForm.reset();
  }


  /*
    Método usado para volver a preguntar sí la información
    en el local host ya existe
  */
  asyncPromise() {
    this.getUser().then(user => {
      this.loading = false;
      const value = user.primera_vez;
      const valueEstado = user.is_active;
      this.firstTime(value, valueEstado);
    });
  }

  /*
    Primera vez contraseña, es cuando el usuario inicia sesión
    por primera vez
  */
  firstTime(value?, estado?) {
    if (this.loading == true) {
      this.asyncPromise();
    } else {

      if (estado == false) {

        this.mensajeEstado = this.translate.instant('errores.login');
      }

      switch (value) {
        case undefined:
          this.loading = true;
          this.asyncPromise();
          break;
        case true:
          this.loading = false;
          this.primeraVez = true;
          this.openChangePassword();
          break;
        case false:
          this.loading = false;
          location.reload();
          break;
      }
      /*
         let estado = "is_active";
    let estadoActivo = this.authService.getPropertyOfCurrentUserData(estado);
    if (value == undefined)
    { this.loading = true;


      this.asyncPromise();
    }
    else if (value == true) {

      this.primeraVez = true;
    }
    else if (value == false)
    {
      this.loading = false;
    }
      */
    }
  }


  checkHabeasData(event) {
    if (event == false) {
      this.checkedHabeasData = false;
    } else {
      this.checkedHabeasData = true;
    }
  }

  checkTerminos(event) {
    if (event == false) {
      this.checkedTerminos = false;
    } else {
      this.checkedTerminos = true;
    }
  }

  cambiarTexto(evento) {

    this.homeService.tipoDeDocumento({id: evento})
      .subscribe((resp: any) => {
        if (resp[0].tipo == 'NIT') {
          this.documentoOrNIT = false;
        } else {
          this.documentoOrNIT = true;
        }

        this.tipoDocumento = resp[0].id;

      });

  }

  resolved(token) {
    if (token) {
      this.responseCaptcha = true;
    } else {
      this.responseCaptcha = false;
    }


  }


  onSubmitRegistro(formDir) {

    if (!this.registerFormulario.valid || this.checkedHabeasData == false || !this.responseCaptcha) {
      this.registerFormulario.markAllAsTouched();
      return;
    }

    if (this.documentoOrNIT) {
      this.guardarRegistro();
    } else {
      if (this.rutFile && this.rutFile.file.type != 'application/pdf') {
        // this.matSnackBarUtil.matSnackBarWarning('Verifique el formato del archivo');
        this.formatFilerunt = true;
        return;
      }

      if (this.camaraFile && this.camaraFile.file.type != 'application/pdf') {
        // this.matSnackBarUtil.matSnackBarWarning('Verifique el formato del archivo');
        this.formatFilecamera = true;
        return;
      }

      if (!this.camaraFile) {
        this.responseCamaraFile = '';
      }

      if (!this.rutFile) {
        this.responseRutFile = '';
      }

      Promise.all([this.uploadRutFilePromise(), this.uploadCamaraFilePromise()]).then(response => {
        this.responseRutFile = response[0];
        this.responseCamaraFile = response[1];
        this.guardarRegistro();
      });
    }


  }

  guardarRegistro() {


    this.homeService.crearEmpresa({
      nitEmpresa: !this.documentoOrNIT ? this.registerFormulario.controls['numeroDocumento'].value : this.registerFormulario.controls['nitEmpresa'].value,
      empresa: this.registerFormulario.controls['empresa'].value
    })
      .subscribe(respuesta => {
        let nitEmpresa = respuesta;

        let username = '';
        this.homeService.userName({username: this.registerFormulario.get('correo').value.toString().split('@', 1)[0]})
          .subscribe(data => {
            username = data;

            let objeto = {
              'first_name': this.registerFormulario.get('nombre').value,
              'last_name': this.registerFormulario.get('apellido').value,
              'username': username,
              'email': this.registerFormulario.get('correo').value,
              'celular': this.registerFormulario.get('celular').value,
              'telefono': this.registerFormulario.get('telefono').value,
              'cargo': this.registerFormulario.get('cargo').value || '',
              'documento_usuario': this.registerFormulario.get('numeroDocumento').value,
              'empresa': nitEmpresa || '',
              'usuario_creador': 'admin@phcportal.com',
              'tipo_documento': this.tipoDocumento,
              'tipo_persona': this.registerFormulario.get('tipoPersona').value,
              'habeas_data': this.checkedHabeasData,
              'terminos': this.checkedTerminos,
              'rut_file': this.responseRutFile || '',
              'camara_file': this.responseCamaraFile || '',
            };


            this.homeService.registrarUsuario(objeto)
              .subscribe({
                next: (respuesta) => {

                  this.resetDatosRegistro();
                  this.mensajeAfterRegistro = true;
                  this.mensajeExitoRegistro = true;
                  // this.cloneUser(respuesta.email);
                  return;
                },
                error: (err) => {

                  this.registerFormulario.get('correo').reset();
                  this.mensajeExitoRegistro = false;
                  this.mensajeAfterRegistro = true;

                  if ('documento_usuario' in err.error) {
                    this.mensaje_back = err.error.documento_usuario[0];
                  }
                  if ('email' in err.error) {
                    this.errorEmail = err.error.email[0];
                  }
                  if ('empresa' in err.error) {
                    this.errorEmpresa = err.error.empresa[0];
                  }
                  return;
                }
              });
          });
      });
  }

  resetDatosRegistro() {
    this.registerFormulario.reset();
    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.tipoDocumento = '';
  }

  //Método usado en el submit del loginForm
  onSubmit(formDir) {
    this.errorMensaje = true;
    //Se valida que la info sea válida
    // localStorage.setItem('Validador', 'false');
    if (this.loginForm.valid) {
      //Se envía información del login al servicio
      this.authService.login(this.loginForm.value).subscribe(data => {
        this.logged = true;
        this.loginForm.reset();
        this.modalService.dismissAll();
        this.loading = true;
        this.firstTime();
      }, err => {
        this.messajeError = this.translate.instant('errores.login');
      });


    }
  }

  submitChangePassword(object) {
    if (this.passwordForm.valid) {
      this.homeService.changePassword(object).subscribe(data => {
        this.messageError.message = data.message;
        this.messageError.type = data.type;
        this.passwordForm.reset();
      }, err => {
        this.messajeErrorPassword = 'Error inesperado, comunicarse con administración';
      });
    }

  }

  //ocultar y mostrar contraseña
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  closeModal() {
    this.modalService.dismissAll();
    this.mensajeEstado = '';
    this.errorMensaje = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return 'by clicking on a backdrop';
    } else {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return `with: ${reason}`;
    }
  }

  getLanguage(): string {
    return localStorage.getItem('language');
  }

  getUser(): Promise<any> {
    return new Promise(resolve => {
      resolve(this.authService.getUserData());
    });
  }

  openChangePassword(): void {
    this.modalService.open(ChangePasswordComponent, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  cloneUser(email: string): void {
    this.homeService.cloneUser(email).toPromise();
  }

  get urlS3() {
    return environment.urlS3;
  }

  uploadRutFilePromise() {
    return new Promise((resolve, reject) => {
      if (!this.rutFile) {
        resolve();
      }
      this.homeService.guardarImg(this.rutFile.file).subscribe((data: any) => resolve(data.namefile), err => reject(err));
    });
  }

  uploadCamaraFilePromise() {
    return new Promise((resolve, reject) => {
      if (!this.camaraFile) {
        resolve();
      }
      this.homeService.guardarImg(this.camaraFile.file).subscribe((data: any) => resolve(data.namefile), err => reject(err));
    });
  }

  processFile(imageInput: any, events, tipo) {
    const file: File = imageInput.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        if (tipo == 'rut') {
          this.rutFile = new ImageSnippet(event.target.result, file);
          this.fileEventRut = events;
          this.formatFilerunt = false;

        }
        if (tipo == 'camara') {
          this.camaraFile = new ImageSnippet(event.target.result, file);
          this.fileEventCamara = events;
          this.formatFilecamera = false;
        }
      });
      reader.readAsDataURL(file);
    } else {
      if (tipo == 'rut') {
        this.rutFile = undefined;
      }
      if (tipo == 'camara') {
        this.camaraFile = undefined;
      }
    }
  }


}
