<div class="background-footer" style="margin-top: 0px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 responsive-foot">
                <h4 class="title-footer" data-aos="zoom-in">{{ "titulosFooter.titulo1" | translate }}</h4>
                <ul class="p-0" data-aos="zoom-in">
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion1" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion2" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion3" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion4" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion5" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion6" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{ "serviciosDigitales.opcion7" | translate }}</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 responsive-foot">
                <h4 class="title-footer" data-aos="zoom-in">{{ "titulosFooter.titulo2" | translate }}</h4>
                <ul class="p-0" data-aos="zoom-in">
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion1" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion2" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion3" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion4" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion5" | translate }}</a></li>
                    <li>
                        <a [routerLink]="['']" [fragment]="'serviciosNoDigitales'">{{ "serviciosNODigitales.opcion6" | translate }}</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 responsive-foot col-xs-6">

                <h4 class="title-footer" data-aos="zoom-in">{{ "titulosFooter.titulo3" | translate }}</h4>
                <ul *ngIf="datosContacto" class="p-0" data-aos="zoom-in">
                    <li>
                        <span [innerHTML]="datosContacto.description | dompurify"></span>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 responsive-foot col-xs-6">
                <div class="logo-footer">
                    <img data-aos="zoom-in" src="assets/logo_PHC_blanco.png" alt="logo-phc">
                </div>
                <div class="iconosociales-footer" data-aos="zoom-in">
                    <div class="row icons">
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlTwitter"> <img src="assets/icono-twitter.png" alt="" class="w-100"> </a>
                        </div>
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlFacebook"> <img src="assets/icon-facebook.png" alt="" class="w-100"> </a>
                        </div>
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlLinkedin"> <img src="assets/icono-Linkedin.png" alt="" class="w-100"> </a>
                        </div>
                    </div>
                    <div class="row icons">
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlYoutube"> <img src="assets/icono-Yotube.png" alt="" class="w-100"> </a>
                        </div>
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlInstagram"> <img src="assets/icono-instagram.png" alt="" class="w-100"> </a>
                        </div>
                        <div class="col icon-padding">
                            <a target="_blank" [attr.href]="urlTiktok"> <img src="assets/icono-tiktok.png" alt="" class="w-100"> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="row">
        <div class="col text-center">
          <img data-aos="zoom-in" src="assets/logo_PHC_blanco.png" alt="logo-phc" width="50"
               class="aos-init aos-animate">&nbsp;<span>{{ "titulosFooter.copyright" | translate }}</span>
        </div>
      </div>
    </div>
</div>
