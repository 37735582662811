import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InternasServiceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient, private authService: AuthService) {
  }

  consultarNosotros(objeto): any {
    return this._http.post(`${environment.url}servicio_consulta/`, objeto, this.httpOptions);
  }

  enviarContacto(objeto): any {
    return this._http.post(`${environment.url}send-mail/phc-interno/`, objeto, this.httpOptions);
  }

  proyectoDetalle(objeto): any {
    return this._http.post(`${environment.url}proyecto-detalle/`, objeto, this.httpOptions);
  }

  obtenerEstadosProyecto(){
    return this._http.get(`${environment.url}estados-proyecto`, this.httpOptions);
  }

  obtenerEstadosPorEstadoActualProyecto(estadoId): Observable<any> {
    return this._http.get(`${environment.url}estados-proyecto/${estadoId}`, this.httpOptions);
  }

  getProyectos(objeto): any {
    return this._http.post(`${environment.url}proyecto-users`, objeto, this.httpOptions);
  }

  actualizarComentsProyecto(objeto): any {
    return this._http.post(`${environment.url}proyecto/update/`, objeto);
  }

  vacantesFormulario(objeto): any {
    const formData = new FormData();
    formData.append('name', objeto.nombres);
    formData.append('last_name', objeto.apellidos);
    formData.append('email', objeto.correo);
    formData.append('phone', objeto.telefono);
    formData.append('terms_and_coditions', objeto.terminos);
    formData.append('position', objeto.cargo);
    formData.append('HV', objeto.HV);
    formData.append('tipo', objeto.tipo);
    return this._http.post(`${environment.url}send-mail/phc-interno/`, formData);
  }

  setRegistroSuscripcion(suscripcionId, version): Observable<any> {

    const usuarioId = this.authService.getPropertyOfCurrentUserData('email');

    return this._http.post(`${environment.url}registo/suscripcion/`, {
      estado: 'activo',
      suscripcion: suscripcionId,
      usuario: usuarioId,
      version
    }, this.httpOptions);
  }

  updateFaseTarea(objeto): any {

    return this._http.post(`${environment.url}tareas/detalle/`, objeto);
  }

  getComentariosProyectos(proyectoId): Observable<any> {
    return this._http.get(`${environment.url}comentarios-proyectos/list/${proyectoId}`, this.httpOptions);
  }

  getComentariosEtapasDetalle(etapaDetalleId): Observable<any> {
    return this._http.get(`${environment.url}comentarios-etapas-detalle/list/${etapaDetalleId}`, this.httpOptions);
  }

  getComentariosTareasDetalle(tareaDetalleId): Observable<any> {
    return this._http.get(`${environment.url}comentarios-tareas-detalle/list/${tareaDetalleId}`, this.httpOptions);
  }

  getLogActividades(idProyecto, pagina): Observable<any> {
    return this._http.get(`${environment.url}proyecto-log/list/${idProyecto}?page=${pagina}`, this.httpOptions);
  }

  getAdjuntosProyectos(obj): Observable<any> {
    return this._http.post(`${environment.url}adjuntos/list/`, obj, this.httpOptions);
  }

  obtenerFacturas(obj): Observable<any> {
    return this._http.post(`${environment.url}proyectos/obtener-factura`, obj, this.httpOptions);
  }

  editarComentario(obj): Observable<any> {
    return this._http.post(`${environment.url}comentarios/update/`, obj, this.httpOptions);
  }

  borrarArchivosPorProyecto(obj): Observable<any> {
    return this._http.post(`${environment.url}adjuntos/delete`, obj, this.httpOptions);
  }

  borrarComentariosProyecto(obj): Observable<any> {
    return this._http.post(`${environment.url}comentarios/delete/`, obj, this.httpOptions);
  }

  getNotificacionesProyectos(idProyecto): Observable<any> {
    return this._http.get(`${environment.url}nuevos-logs-proyecto/${idProyecto}`, this.httpOptions);
  }

  logActividadesUsuario(idProyecto): Observable<any> {
    return this._http.get(`${environment.url}visitas-logs-proyecto/${idProyecto}`, this.httpOptions);
  }

  traerEncuesta(idEncuesta): Observable<any> {
    return this._http.get(`${environment.url}encuesta/generar/${idEncuesta}`, this.httpOptions);
  }
  
  traerAsesores(idServicio): Observable<any> {
    return this._http.post(`${environment.url}asesoria/users/`, idServicio, this.httpOptions);
  }

  encuestaSolucion(obj): Observable<any> {
    return this._http.post(`${environment.url}encuesta/solucion`, obj, this.httpOptions);
  }
  encuestaSolucionada(obj): Observable<any> {
    return this._http.post(`${environment.url}encuesta/solucionada`, obj, this.httpOptions);
  }


}
