<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th colspan="12" class="text-center" [innerHTML]="title">
                </th>
            </tr>
            <tr>
                <th scope="col">{{"tabla2.ano" | translate}}</th>
                <th scope="col">{{"tabla2.vegetativa" | translate}}</th>
                <th scope="col">{{"tabla2.grandes_consumidores" | translate}}</th>
                <th scope="col">{{"tabla2.vehiculos_electricos" | translate}}</th>
                <th scope="col">{{"tabla2.metro_de_bogota" | translate}}</th>
                <th scope="col">{{"tabla2.exportaciones_hacia_panama" | translate}}</th>
                <th scope="col">{{"tabla2.generacion_distribuida" | translate}}</th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let data of response;  let i = index;">
                <td>{{data.ano }}</td>
                <td>{{data.vegetativa}}</td>
                <td>{{data.grandes_consumidores}}</td>
                <td>{{data.vehiculos_electricos | customDecimal}}</td>
                <td>{{data.metro_de_bogota | customDecimal}}</td>
                <td>{{data.exportaciones_hacia_panama | customDecimal}}</td>
                <td>{{data.generacion_distribuida | customDecimal}}</td>
            </tr>

        </tbody>
    </table>
</div>
<!--<app-tabla1 [escenario]="escenario" [tabla]="tabla"></app-tabla1>-->