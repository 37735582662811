import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {environment} from '../../../environments/environment';

const url = `${environment.url}grafica13/`;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  lenguaje = "";
  constructor(private _http: HttpClient, private datePipe: DatePipe) {
    this.lenguaje = localStorage.getItem('language');
  }

  getData(params) {
    return new Promise((resolve) => {
      this._http.post(url, params).subscribe((data: any) => {
        let chart = {
          color: ['#4473C4', '#ED7E31', '#A5A5A5', '#FAC004', '#5899D6'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map((e) => e.anio),
          },
          yAxis: {
            type: 'value',
          },
          legend: {
            data: this.lenguaje == 'es' ? ['CEE', 'FAZNI', 'LEY 99', 'AGC', 'DELTAI'] : ['CEE', 'FAZNI', 'LAW 99', 'AGC', 'DELTAI'],
          },
          series: [
            {
              name: 'CEE',
              data: data.map((e) => parseFloat(e.cee.toFixed(2))),
              stack: 'total',
              type: 'line',
              areaStyle: {color: '#4473C4'},
            },
            {
              name: 'FAZNI',
              data: data.map((e) => parseFloat(e.fazni.toFixed(2))),
              stack: 'total',
              type: 'line',
              areaStyle: {color: '#ED7E31'},
            },
            {
              name: this.lenguaje == 'es' ? 'LEY 99' : 'LAW 99',
              data: data.map((e) => parseFloat(e.ley_99.toFixed(2))),
              stack: 'total',
              type: 'line',
              areaStyle: {color: '#A5A5A5'},
            },
            {
              name: 'AGC',
              data: data.map((e) => parseFloat(e.agc.toFixed(2))),
              stack: 'total',
              type: 'line',
              areaStyle: {color: '#FAC004'},
            },
            {
              name: 'DELTAI',
              data: data.map((e) => parseFloat(e.deltai.toFixed(2))),
              stack: 'total',
              type: 'line',
              areaStyle: {color: '#5899D6'},
            },
          ],
          dataZoom: [
            {
              start: 0,
              type: 'inside'
            },
            {
              start: 0
            }
          ]
        };
        resolve(chart);
      });
    });
  }
}
