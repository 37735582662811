import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { InicioSesionComponent } from '../../inicio-sesion/inicio-sesion.component';
import {SecureStorageService} from '../../../services/secure-storage.service';
import {ServiciosNoDigitalService} from '../../../services/servicios-no-digital.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  modalReference: NgbModalRef;
  closeResult = '';
  logueado: boolean;
  logged: any;
  digitalServices = [];
  @ViewChild('construccion') paginaConstruccionRef: ElementRef;

  constructor(
    private modalService: NgbModal,
    private authservice: AuthService,
    private router: Router,
    private secureStorageService: SecureStorageService,
    private serviciosNoDigitalService: ServiciosNoDigitalService
  ) { }

  ngOnInit(): void {
    this.secureStorageService.getItem('djangoUser') ? this.logueado = true : this.logueado = false;
    this.consultaServiciosDigitales();
  }

  openSidebar(){
    document.getElementById("sidebar").classList.add('open-sidebar');
  }
  closeSidebar(){
    document.getElementById("sidebar").classList.remove('open-sidebar');
  }

  paginaConstruccion(content){
    this.modalService.open(content, { centered: true, size: 'sm'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.digitalServices = [];
    this.ngOnInit();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateLoggin(url: string){
    const arrayUrls = [];
    arrayUrls['proyecciones-de-mercado'] = 'proyecciones-de-mercado';
    arrayUrls['estudios-de-conexion'] = 'estudios-de-conexion';
    arrayUrls['asesoria-en-linea'] = 'asesoria-en-linea';
    arrayUrls['market-projections'] = 'proyecciones-de-mercado';
    arrayUrls['connection-studies'] = 'estudios-de-conexion';
    arrayUrls['online-consulting'] = 'asesoria-en-linea';
    arrayUrls['project-traceability'] = 'trazabilidad-de-proyectos';
    arrayUrls['trazabilidad-de-proyectos'] = 'trazabilidad-de-proyectos';

    const urlNext = arrayUrls[url];
    if (urlNext && urlNext !== 'trazabilidad-de-proyectos') {
      this.router.navigate(['/inicio', urlNext]);
      return;
    } else if (!urlNext) {
      this.paginaConstruccion(this.paginaConstruccionRef);
      return;
    }
    this.logged = this.authservice.isLoggedIn();
    if (!this.logged) {
      this.login()
    }else{
        this.router.navigate(['/inicio/trazabilidad-de-proyectos']);
      }
  }
 login() {
   //debugger
    this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.logged = this.authservice.isLoggedIn();
      if (this.logged) {
         this.router.navigate(['/inicio/trazabilidad-de-proyectos']);
      }

    });
  }

  consultaServiciosDigitales() {
    let objeto = {
      nombre: "",
      digital: "si",
      estado: "activo"
    }
    this.serviciosNoDigitalService.consultarServiciosNoDigitales(objeto).subscribe((data: any) => {
      for (const value of data) {
        const dataIconos = {
          id: value.id,
          url: value.url,
          title: value.nombre,
          img: this.urlS3 + "/" + value.icono,
          imgHover: this.urlS3 + "/" + value.icono
        };
        this.digitalServices.push(dataIconos);
      }
    });
  }

  get urlS3(): string {
    return environment.urlS3;
  }


}
