import {Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {GraficasService} from '../../../services/graficas.service';

@Component({
  selector: 'app-tabla2',
  templateUrl: './tabla2.component.html',
  styleUrls: ['./tabla2.component.scss'],
  providers: []
})
export class Tabla2Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  response: any;
  tabla = 'tabla_2';
  title: any 

  constructor(private graficasService: GraficasService) {
  }

    ngOnInit(): void {
    this.graficasService.consultatablas(this.escenario, this.tabla).subscribe(data => {
      this.response = data;
    });

    let parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.titulo_tabla_2_2;
  }
}
