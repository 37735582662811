<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-4">
                    <h1 class="titulo-bannerinicio">
                        {{"proyeccionesBanner.tituloppal" | translate }}
                    </h1>
                </div>
                <div class="col-lg-8">
                    <div class="estadisticas" *ngIf="selectorActual != 3">
                        <div class="row">

                            <div class="col-lg-6 col-sm-6 cajon-indicadores text-center" *ngFor="let item of indicadores">
                                <p class="parrafo">{{item.parametros}}</p>
                                <span class="number" *ngIf="item.pesimista">{{item.pesimista}}</span>
                                <span class="number" *ngIf="item.moderado">{{item.moderado}}</span>
                                <span class="number" *ngIf="item.optimista">{{item.optimista}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contenido">
      <div class="container">

        <mat-tab-group (selectedTabChange)="selectTab($event, content)"  mat-align-tabs="center" class="tabuladores-proyeccion" mat-stretch-tabs>

                <mat-tab label='{{"proyeccionesTabulador.titulo1" | translate }}'>
                    <button id="scrollBtn" (mouseenter)="hoverMe()" type="button" class="btn btn-secondary btn-fixd button-flvw btn-full" (click)="validateProyeccion('Informe escenario pesimista')"> <mat-icon> article</mat-icon> {{"proyeccionesTabulador.fullView" | translate }}</button>
                    <app-componente-informe [escenario]="'pesimista'" *ngIf="selectorActual == 0"></app-componente-informe>
                </mat-tab>

                <mat-tab label='{{"proyeccionesTabulador.titulo2" | translate }}'>
                    <button id="scrollBtn" (mouseenter)="hoverMe()" type="button" class="btn btn-secondary btn-fixd button-flvw btn-full" (click)="validateProyeccion('Informe escenario moderado')"> <mat-icon> article</mat-icon> {{"proyeccionesTabulador.fullView" | translate }}</button>
                    <app-componente-informe [escenario]="'moderado'" *ngIf="selectorActual == 1"></app-componente-informe>
                </mat-tab>

                <mat-tab label='{{"proyeccionesTabulador.titulo3" | translate }}'>
                    <button id="scrollBtn" (mouseenter)="hoverMe()" type="button" class="btn btn-secondary btn-fixd button-flvw btn-full" (click)="validateProyeccion('Informe escenario optimista')"> <mat-icon> article</mat-icon> {{"proyeccionesTabulador.fullView" | translate }}</button>
                    <app-componente-informe [escenario]="'optimista'" *ngIf="selectorActual == 2"></app-componente-informe>
                </mat-tab>

          <mat-tab label='{{"proyeccionesTabulador.titulo4" | translate }}'>
            <div class="tab-personalizado">
              <h2> {{"proyeccionesPersonalizado.titulo1" | translate }}</h2>
              <div class="logueado" *ngIf="logged || tomaDeDatosON">
                <p>
                  {{"proyeccionesPersonalizado.descriplogged" | translate }}
                </p>
                <div class="botones-tabpersonalizado">
                  <a [attr.href]="urlFileDownload" target="_blank">{{"proyeccionesPersonalizado.boton1" | translate }}</a>
                  <a (click)="openUploadFile(fileupload)">{{"proyeccionesPersonalizado.boton2" | translate }}</a>
                  <a (click)="openSoporte(message)">{{"proyeccionesPersonalizado.boton3" | translate }}</a>
                </div>
              </div>
              <div class="Nologueado" *ngIf="!logged && !tomaDeDatosON">
                <p>
                  {{"proyeccionesPersonalizado.tituloNoLogged" | translate }}
                </p>
                <div class="botones-tabpersonalizado">
                  <a (click)="openDatos(content)"> {{"proyeccionesPersonalizado.tituloButton" | translate }}</a>
                </div>
              </div>
            </div>
          </mat-tab>



        </mat-tab-group>

      </div>
    </div>


    <ng-template #content let-modal>
        <div id="myModal" class="modal modal-datalocal">
            <div class="row mt-3 mr-3">
                <div class="col-12">
                    <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.close(false)">
                </div>
            </div>
            <div class="row modal-rps">
                <div class="col-lg-6 col-sm-12">
                    <div class="modal-content">
                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">
                            <div class="form-group">
                                <label for="nombre" class="labelForm"> {{"proyeccionesForm.opcion1" | translate }} </label>
                                <input type="text" class="form-control" id="nombre" formControlName="nombre">
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="form-group">
                                <label for="apellido" class="labelForm">{{"proyeccionesForm.opcion2" | translate }} </label>
                                <input type="text" class="form-control" id="apellido" formControlName="apellido">
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'apellido') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="form-group">
                                <label for="empresa" class="labelForm">{{"proyeccionesForm.opcion3" | translate }} </label>
                                <input type="text" class="form-control" id="empresa" formControlName="empresa">
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'empresa') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                            </div>
                            <div class="form-group">
                                <label for="telefono" class="labelForm">{{"proyeccionesForm.opcion4" | translate }} </label>
                                <input type="text" class="form-control" id="telefono" formControlName="telefono">
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'telefono') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('pattern', 'telefono') && formDir.submitted">{{"formularioAvisos.opcion2" | translate }}</p>
                            </div>
                            <div class="form-group">
                                <label for="correo" class="labelForm">{{"proyeccionesForm.opcion5" | translate }} </label>
                                <input type="email" class="form-control" id="correo" formControlName="correo">
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.hasError('required', 'correo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p>
                                <p class="text-danger p-messaje errorMessaje" *ngIf="registerForm.get('correo').invalid && formDir.submitted ">{{"formularioAvisos.opcion3" | translate }}</p>
                            </div>
                            <div class="row btn-container">
                                <button type="submit" class="btn btn-form">{{"proyeccionesForm.opcion6" | translate }} </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 align-self-center text-center desaparecer-image">
                    <img src="assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
                </div>
            </div>

        </div>
    </ng-template>

    <ng-template #message let-modal>
        <div id="myModal" class="modal modal-soportes modal-dialog-centered">
            <div class="row mt-3 mr-3 w-100">
                <div class="col-12">
                    <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click')">
                </div>
            </div>
            <div class="row modal-middle">
                <div class="col-lg-12">
                    <div class="modal-content-modify">
                        <div class="cabecera-modal">
                            {{"modalSoporte.opcion1" | translate }} {{ nombreUsuarioSoporte }}!
                        </div>
                        <div class="cuerpo-modal">
                            <p> {{"modalSoporte.opcion2" | translate }}
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn-container-modify">
                        <button type="button" (click)="modal.close('Close click')" class=" btn btn-form ">{{"modalSoporte.opcion3" | translate }} </button>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>

    <ng-template #fileupload let-modal>
        <div id="myModal" class="modal modal-dialog-centered subir-archivo">
            <div class="row mt-3 mr-3 w-100">
                <div class="col-12">
                    <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click')">
                </div>
            </div>
            <div class="row modal-middle">
                <div class="col-lg-12">
                    <div class="modal-content-modify archivo-modal">
                        <div class="cuerpo-modal">
                            <p><strong>{{"modalCargarPlantilla.opcion1" | translate }}</strong>
                            </p>
                            <form [formGroup]="uploadFile" (ngSubmit)="onSubmitFile(formDir)" #formDir="ngForm" autocomplete="off">
                                <div class="custom-file">
                                    <input type="file" id="archivo" formControlName="archivo" #valorArchivo aria-describedby="archivo" (change)="fileInputChange($event.target.files,valorArchivo)" accept=".xls, .xlsx" class="cursor-pointer custom-file-input" />
                                    <label class="custom-file-label" [attr.data-after-content]="[buscarPalabra]">{{"modalCargarPlantilla.opcion2" | translate }}</label>
                                    <!-- <p class="text-danger p-messaje errorMessaje" *ngIf="uploadFile.hasError('required', 'archivo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</p> -->
                                    <!-- <p class="text-danger p-messaje errorMessaje" *ngIf="uploadFileEvent">{{"proyeccionesPersonalizado.extensiones" | translate }}</p> -->
                                </div>
                                <div class="row btn-container">
                                    <button type="submit" class="btn btn-form">{{"modalCargarPlantilla.opcion3" | translate }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>

</div>
