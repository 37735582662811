import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-mapa',
  templateUrl: './modal-mapa.component.html',
  styleUrls: ['./modal-mapa.component.css']
})
export class ModalMapaComponent implements OnInit {

  @Input() dataPais: any
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
