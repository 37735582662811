import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Banner } from '../../models/banner.models';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VersionesWindowComponent } from "../windows/versiones-window/versiones-window.component";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  nosotrosParametros: any;
  bannerCampos = new Banner;
    constructor(
        private homeService: HomeService,
        private dialog: MatDialog ) { }

  ngOnInit(): void {

    this.nosotrosParametros = {seccion: "Banner principal", estado: "Activo"};
    this.homeService.consultarNosotros(this.nosotrosParametros)
        .subscribe((respuesta: any) => {

          if (respuesta.data.length === 0) {
            return;
          }
           respuesta.data[0].campos.forEach(campo => {
            if (campo.nombre == "Titulo") {
              this.bannerCampos.titulo = campo.valor;
            }
            if (campo.nombre == "Descripcion") {
              this.bannerCampos.descripcion = campo.valor;
            }
            if (campo.nombre == "Archivo") {
              this.bannerCampos.archivo= campo.valor;
              this.bannerCampos.tipo = campo.tipo;
            }


           });


        });


  }

  get urlS3() {
    return environment.urlS3;
    }

    openWindow() {
        const that = this;
        const config = new MatDialogConfig();

        config.disableClose = true;
        config.autoFocus = true;
        config.data = {
            list_teams: [],
            teams: [],
        };
        const dialogRef = this.dialog.open(VersionesWindowComponent, { panelClass: 'custom-dialog-container' });
    }
}
