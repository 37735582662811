<div id="myModal" class="modal md-registro" *ngIf="usuarioFormulario">
  <div class="row mt-3 mr-3 ">
    <div class="col-12">
      <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal()">
    </div>
  </div>
  <div class="row modal-rps">
    <div class="col-lg-12 col-sm-12 ">
      <div class="modal-content">
        <form [formGroup]="usuarioFormulario" (ngSubmit)="onSubmitRegistro(formDir)" #formDir="ngForm"
          autocomplete="off">
          <div class="row formularioRegistro">
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="nombre" class="labelForm"> {{"registroForm.opcion1" | translate }} </label>
              <input type="text" class="form-control" id="nombre" formControlName="nombre">
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1"
                | translate }}</label>
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="apellido" class="labelForm">{{"registroForm.opcion2" | translate }}</label>
              <input type="text" class="form-control" id="apellido" formControlName="apellido">
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('required', 'apellido') && formDir.submitted">{{"formularioAvisos.opcion1"
                | translate }}</label>
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="correo" class="labelForm"> {{"registroForm.opcion3" | translate }}</label>
              <input type="email" class="form-control" id="correo" formControlName="correo">
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="correo" class="labelForm"> {{"registroForm.opcion4" | translate }}</label>
              <input type="email" class="form-control" id="tipoDocumento" formControlName="tipoDocumento">
            </div>

            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="numeroDocumento" *ngIf="documentoOrNIT " class="labelForm">{{"registroForm.opcion5" |
                translate }}</label>
              <label for="numeroDocumento" *ngIf="!documentoOrNIT" class="labelForm">{{"registroForm.opcion6" |
                translate }}</label>
              <input type="text" class="form-control" id="numeroDocumento" formControlName="numeroDocumento">
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('required', 'numeroDocumento') && formDir.submitted">
                {{"formularioAvisos.opcion1" | translate }}</label>
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('pattern', 'numeroDocumento') && formDir.submitted">{{"formularioAvisos.opcion2"
                | translate }}</label>
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="celular" class="labelForm">{{"registroForm.opcion7" | translate }}</label>
              <input type="text" class="form-control" id="celular" formControlName="celular">
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('required', 'celular') && formDir.submitted">
                {{"formularioAvisos.opcion1" | translate }}</label>
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('pattern', 'celular') && formDir.submitted">
                {{"formularioAvisos.opcion2" | translate }}</label>
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
              <label for="telefono" class="labelForm">{{"registroForm.opcion8" | translate }}</label>
              <input type="text" class="form-control" id="telefono" formControlName="telefono">
              <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                *ngIf="usuarioFormulario.hasError('pattern', 'telefono') && formDir.submitted">
                {{"formularioAvisos.opcion2" | translate }}</label>
            </div>

            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
              <label for="cargo" class="labelForm">{{"registroForm.opcion9" | translate }}</label>
              <input type="text" class="form-control" id="cargo" formControlName="cargo">
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
              <label for="empresa" class="labelForm">{{"registroForm.opcion10" | translate }}</label>
              <input type="text" class="form-control" id="empresa" formControlName="empresa">

            </div>
            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
              <label for="nitEmpresa" class="labelForm"> {{"registroForm.opcion11" | translate }}</label>

              <input type="text" class="form-control" id="nitEmpresa" formControlName="nitEmpresa">
            </div>

            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
              <label for="archivo" class="labelForm">{{"registroForm.opcion17" | translate }}</label>
              <input *ngIf="!fileRutCurrent" type="file" class="form-control-file" id="archivo" #imageInput
                (change)="processFile(imageInput,$event,'rut')">
              <span class="text-danger errorMessaje"  *ngIf="formatFilerut && !fileRutCurrent">{{"formularioAvisos.opcion15" | translate }}</span>
              <p *ngIf="fileRutCurrent"><a [attr.href]="urlS3 + '/' + fileRutCurrent"
                  target="_blank">{{fileRutCurrent}}</a></p>
            </div>

            <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group" *ngIf="!documentoOrNIT">
              <label for="archivo" class="labelForm">{{"registroForm.opcion18" | translate }}</label>
              <input *ngIf="!fileCameraCurrent" type="file" class="form-control-file" id="archivo" #imageInput
                (change)="processFile(imageInput,$event,'camara')">
              <span class="text-danger errorMessaje"  *ngIf="formatFilecamera && !fileCameraCurrent">{{"formularioAvisos.opcion15" | translate }}</span>

              <p *ngIf="fileCameraCurrent"><a [attr.href]="urlS3 + '/' + fileCameraCurrent"
                  target="_blank">{{fileCameraCurrent}}</a></p>
            </div>


            <div class="col-lg-12">
              <div class="btn-container">
                <button type="submit" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>