import {Component, OnInit} from '@angular/core';
import {CotizacionPDFService} from '../../services/cotizacion-pdf.service';
import {ActivatedRoute} from '@angular/router';
import AES from 'crypto-js/aes';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalEstudiosConexionComponent} from '../modal-estudios-conexion/modal-estudios-conexion.component';
import {AuthService} from '../../services/auth.service';
import {ViewportScroller} from '@angular/common';
import {SecureStorageService} from '../../services/secure-storage.service';

@Component({
  selector: 'app-informe-propuesta',
  templateUrl: './informe-propuesta.component.html',
  styleUrls: ['./informe-propuesta.component.css']
})
export class InformePropuestaComponent implements OnInit {

  contentTable: any;
  pages: any;
  headerAndFooter: any;
  parametrosInforme: any;
  id:any = '';
  constructor(
    private cotizacionPDFService: CotizacionPDFService,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private authService: AuthService,
    private viewportScroller: ViewportScroller,
    private secureStorageService: SecureStorageService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {

      this.id= params.id;
      });
    this.getContentTable(this.id);
    this.getPages(this.id);
    this.getHeaderAndFooter(this.id);
    this.getParametros(this.id);
  }

  revisar(anchorID) {
    if (anchorID) {
      this.viewportScroller.scrollToAnchor(anchorID);
    } else {
      return;
    }
  }

  get idReport(): string {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  encryptUrl(url: string): string {
    return AES.encrypt(url, 'abcd@$--12').toString();
  }

  generatePDF(): void {
    const url = `${this.urlWeb}/informe-propuesta?id=${this.id}`;
    const language = localStorage.getItem('language');
    const name = 'solicitud-cotizacion';
    const djangoUser = this.secureStorageService.getItem('djangoUser');
    const user = !!djangoUser ? this.authService.getPropertyOfCurrentUserData('first_name') : this.propertyUserOfData('nombre');
    const email = !!djangoUser ? this.authService.getPropertyOfCurrentUserData('email') : this.propertyUserOfData('correo');
    this.cotizacionPDFService.generatePDF({url, name, language, user, email, djangoUser}).subscribe(
      response => {

        if (response && response.status === 'OK') {
          this.ngbModal.open(ModalEstudiosConexionComponent);
          // this.matSnackBarUtil.matSnackBarSuccess(`PDF "${name}" generado exitosamente`);
        }
      }, error => {
        console.error(error);
        // this.matSnackBarUtil.matSnackBarDanger(`Error en el servicio PDF`);
      });
  }

  getContentTable(id): void {
    this.cotizacionPDFService.getContentTablePDF(id).then(data => {
      this.contentTable = data;
    });
  }

  generateIdPage(index): string {
    const variable = index + 3;
    return `pagina${variable}`;
  }

  getPages(id): void {
    const empresaNombre = this.empresaNombre;
    this.cotizacionPDFService.getPagesPDF({id, empresaNombre}).then(data => {
      this.pages = data;
    });
  }

  getHeaderAndFooter(id): void {
    this.cotizacionPDFService.getHeaderAndFooterForPDF(id).then(data => {
      this.headerAndFooter = data;
    });
  }

  getParametros(id): void {
    const empresaNombre = this.empresaNombre;
    this.cotizacionPDFService.getParametros({id, empresaNombre}).then(data => {
      this.parametrosInforme = data;
    });
  }

  isInteger(digit: string): boolean {
    const validDigit = parseFloat(digit);
    return Number.isInteger(validDigit);
  }

  calculateTextIndent(digit: string): string {
    const amountPoints = (digit.match(/[.]/g) || []).length * 3;
    const textIndentValue = `${amountPoints}%`;
    return textIndentValue;
  }

  generatePageNumber(i: number): number {
    return i + 2;
  }

  get urlCreatePDF(): string {
    return environment.urlCreatePDF;
  }

  get urlWeb(): string {
    return environment.urlWeb;
  }

  get userData(): object {
    return JSON.parse(localStorage.getItem('tomaDeDatos'));
  }

  propertyUserOfData(property: string): string {
    const userData = this.userData;
    if (userData && property && property in userData) {
      return userData[property];
    }
  }

  get empresaNombre(): string {
    const djangoUser = this.secureStorageService.getItem('djangoUser');
    return !!djangoUser ? this.authService.getPropertyOfCurrentUserData('empresa_nombre') : null;
  }
}
