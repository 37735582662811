import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MatSnackBarUtil {
  constructor(
    private matSnackBar: MatSnackBar
  ) {
  }

  matSnackBarWarning(message: string): void {
    this.matSnackBar.open(message, null, {
      duration: 2000,
      horizontalPosition: 'right',
      panelClass: ['alert', 'alert-warning']
    });
  }

  matSnackBarSuccess(message: string): void {
    this.matSnackBar.open(message, null, {
      duration: 2000,
      horizontalPosition: 'right',
      panelClass: ['alert', 'alert-success']
    });
  }

  matSnackBarDanger(message: string): void {
    this.matSnackBar.open(message, null, {
      duration: 2000,
      horizontalPosition: 'right',
      panelClass: ['alert', 'alert-danger']
    });
  }

  getSnackByType(type: string, message: string): void {
    if (type === 'danger') {
      this.matSnackBarDanger(message);
    }
    if (type === 'warning') {
      this.matSnackBarWarning(message);
    }
    if (type === 'success') {
      this.matSnackBarSuccess(message);
    }
  }
}
