<ng-template #mapaphc let-modal>
    <div id="myModal" class="modal modal-construccion modal-dialog-centered">
        <div class="row mt-3 mr-3 w-100">
            <div class="col-12">
                <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click')">
            </div>
        </div>
        <div class="row modal-middle">
            <div class="col-lg-12">
                <div class="modal-content-modify">
                    <div class="cabecera-modal">
                        Datapais
                    </div>
                    <div class="cuerpo-modal">
                        <p> {{ dataPais | JSON }}
                        </p>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 text-center">
                <div class="btn-container-modify">
                    <button type="button" (click)="modal.close('Close click')" class=" btn btn-form ">ACEPTAR</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>