<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>{{"tabla7.variable" | translate}}</th>
                <th>{{"tabla7.usd_mwh" | translate}}</th>
                <th>{{"tabla7.cop_kwh" | translate}}</th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let data of response;">
                <td>{{data.variable}}</td>
                <td>{{data.usd_mwh | customDecimal}}</td>
                <td>{{data.cop_kwh | customDecimal}}</td>
            </tr>

        </tbody>
    </table>
</div>
