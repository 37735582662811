<div *ngIf="datosServicio && datosServicio.length > 0" class="pagina-completa">
    <app-header-general></app-header-general>
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                    <h1 class="titulo-bannerinicio">
                        {{"proyeccionesBanner.tituloAsesoriaLinea" | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showSeconds" class="container-fluid contenedor-customizable">

        <div class="controls-icons">
            <div class="controls-arrows">
                <p>
                    <a class="btn-prev" (click)="owlCarIcons.prev()">
                        <img src="assets/flecha-slide1.png" alt="">
                    </a>
                    <a class="btn-next" (click)="owlCarIcons.next()">
                        <img src="assets/flecha-slide2.png" alt="">
                    </a>
                </p>
            </div>

            <div class="text-center">
                <owl-carousel-o [options]="customOptions2" #owlCarIcons>
                    <ng-container *ngFor="let icono of datosServicio,  let i = index" data-aos="zoom-in">
                        <ng-template carouselSlide class="icon-hover">
                            <div (mouseover)="owlCar.to(icono.id.toString())" (mouseout)='hover = -1' (mouseover)='hover = i'>
                                <!-- <a [routerLink]="['/inicio' ,{ outlets: { inicio: ['proyecciones-de-mercado'] } } ]"> -->
                                <a>
                                    <img [src]="urlS3+ '/static/' + icono.icono " alt="icono.nombre" class="iconosService icon-each">
                                    <p>{{ icono.nombre }}</p>
                                </a>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>

        <div class="all-slider" data-aos="zoom-in">
            <div class="controls-arrows">
                <p>
                    <a class="btn-prev" (click)="funcionPrev(owlCar)">
                        <img src="assets/flecha-slide1.png" alt="">
                    </a>
                    <a class="btn-next" (click)="funcionNext(owlCar)">
                        <img src="assets/flecha-slide2.png" alt="">
                    </a>
                </p>
            </div>

            <owl-carousel-o class="slider-services slide-asesoria owl-refreshed" [options]="customOptions" #owlCar id="owlCar">

                <ng-container *ngFor="let item of datosServicio">
                    <ng-template carouselSlide [id]="item.id" [width]="item.width">
                        <!-- <div class="slider" [ngClass]=" {  } "> -->
                        <div class="slider">
                            <video [id]="'videoPlayer'+item.id" #video height=" auto " donotallowfullscreen data-setup='{"userActions": {"doubleClick": false }}' controls controlsList="nodownload">
                                <source [src]="urlS3+ '/static/' + item.video" type="video/mp4 ">
                            </video>
                            <p class="title desaparecer-active">{{item.nombre}}</p>
                            <div class="descripcion-slider desaparecer-active">
                                <p class="description ">{{item.descripcion | cutHtmlString:350:true}}</p>
                                <button type="button" (click)="asesoriaLinea(item.id)" class="button-link"> {{asesoria}} </button>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>

            </owl-carousel-o>
        </div>

    </div>

</div>