export class User {
    cargo: string
    celular: number
    telefono:number
    documento_usuario: string
    email: string
    empresa: string
    first_name:string
    last_name:string
    tipo_documento:string
    tipo_persona:string
    username:string
    usuario_creador:string
    camara_file: string
    rut_file:string
  
}