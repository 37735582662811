export const publicUrls = [
  'wompi.co/v1',
  'maps.googleapis.com'
];

export const graphicsColor = {
  hidraulica: 'rgb(2, 97, 161)',
  liquidos: 'rgb(228, 120, 47)',
  carbon: 'rgb(165, 165, 165)',
  gas: 'rgb(255, 192, 0)',
  glp: 'rgb(112, 48, 160)',
  pch: 'rgb(0, 176, 240)',
  ndc_t: 'rgb(244, 177, 131)',
  solar: 'rgb(255, 255, 0)',
  eolica: 'rgb(0, 176, 80)',
};
