<div id="myModal" class="modal modal-datalocal">
    <div class="row mt-3 mr-3">
        <div class="col-12">
            <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal(false)">
        </div>
    </div>
    <div class="row modal-rps">
        <div class="col-12">
            <div class="modal-content">
                <form [formGroup]="popupInformacion" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm" autocomplete="off">

                    <div class="section-informativos">
                        <div class="title-comments">
                            <h3 *ngIf="!mostrarTareasCampos"> {{"proyectosDetalle.tituloEtapas" | translate }} </h3>
                            <h3 *ngIf="mostrarTareasCampos"> {{"proyectosDetalle.tituloTareas" | translate }} </h3>
                        </div>
                        <div class="informacion-especial">
                            <div class="row align-items-center">
                                <div *ngIf="!mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.descEtapa" | translate }}: </span>
                                    <span> {{ objetoCompleto.fase.nombre }} </span>
                                </div>
                                <div *ngIf="mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.nombreTarea" | translate }}: </span>
                                    <span> {{ objetoCompleto.tarea.nombre_tareas }} </span>
                                </div>
                                <div *ngIf="!mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.responsableEtapa" | translate }}: </span>
                                    <span class="breakword-responsables"> {{ objetoCompleto.detalle.responsable['email'] }} </span>
                                </div>
                                <div *ngIf="mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.responsableEtapa" | translate }}: </span>
                                    <span class="breakword-responsables"> {{ objetoCompleto.detalle.responsable.join(', ') }} </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.inicioFecha" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.fecha_inicio | date:'dd/MM/yyyy'}}  </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.finFecha" | translate }}: </span>
                                    <span>  {{ objetoCompleto.detalle.fecha_fin | date:'dd/MM/yyyy'}}  </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                    <span class="title-top">{{"proyectosDetalle.horasProyecto" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.horas_ejecutadas }} </span>
                                </div>
                                <div *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                    <div *ngIf="!mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                        <span class="title-top">{{"proyectosDetalle.horasEstimadas" | translate }}: </span>
                                        <span> {{ objetoCompleto.detalle.horas_etapa }} </span>
                                    </div>
                                </div>
                                <div *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                    <div *ngIf="mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                        <span class="title-top">{{"proyectosDetalle.horasEstimadas" | translate }}: </span>
                                        <span> {{ objetoCompleto.detalle.horas_estimadas }} </span>
                                    </div>
                                </div>


                            </div>
                            <div class="row align-items-center">
                                <div *ngIf="!mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.Estado" | translate }}: </span>
                                    <span class="breakword-responsables"> {{ objetoCompleto.detalle.estado['estado_proceso'] }} </span>
                                </div>
                                <div *ngIf="mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.Estado" | translate }}: </span>
                                    <span class="breakword-responsables"> {{ objetoCompleto.detalle.estado['estado_proceso'] }} </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.entregable" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.entregable == true ? trueLenguage : falseLenguage }} </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2" *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                    <span class="title-top">{{"proyectosDetalle.desviacionProyecto" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.desviacion }}% </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.avanceProyecto" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.avance }}% </span>
                                </div>
                                <div *ngIf="!mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.peso" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.peso }}% </span>
                                </div>
                                <div *ngIf="mostrarTareasCampos" class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.peso" | translate }}: </span>
                                    <span> {{ objetoCompleto.detalle.peso_tarea }}% </span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-2">
                                    <span class="title-top">{{"proyectosDetalle.facturacion" | translate }}: </span>
                                    <span *ngIf="popUp === 'etapas'"> {{ objetoCompleto.detalle.realiza_facturacion == true ? trueLenguage : falseLenguage }} </span>
                                    <span *ngIf="popUp === 'tareas'"> {{ objetoCompleto.detalle.realizar_facturacion == true ? trueLenguage : falseLenguage }} </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="mostrarTareasCampos" class="form-tareas pt-4">
                            <div *ngIf="hasPermission('gestor_proyectos') || hasPermission('comercial_proyectos') ">
                                <div class="form-group">
                                    <label for="horasEjecutadas" class="labelForm">{{"proyectosDetalle.horasProyecto" | translate }}:  </label>
                                    <input type="text" class="form-control" id="horasEjecutadas" formControlName="horasEjecutadas">
                                    <p class="text-danger p-messaje errorMessaje" *ngIf="popupInformacion.hasError('min', 'horasEjecutadas') && formDir.submitted">{{ "formularioAvisos.opcion17" | translate }}</p>
                                    <p class="text-danger p-messaje errorMessaje" *ngIf="popupInformacion.hasError('pattern', 'horasEjecutadas') && formDir.submitted">{{ "formularioAvisos.opcion2" | translate }}</p>
                                </div>
                                <div class="form-group">
                                    <label class="labelForm">{{"proyectosDetalle.Estado" | translate }}:  </label>
                                    <select id="inputState" class="form-control" formControlName="estadoTarea">
                                    <option value="null" disabled="true">{{"formularioAvisos.opcion6" | translate }}</option>
                                    <option *ngFor="let estado of estadosArray" value="{{estado.id}}">{{estado.estado_proceso}}</option>
                                </select>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="lineadivisora"></div>

                    <div class="comentarios-section">
                        <div class="title-comments">
                            <h3> {{"proyectosInformacionPR.comentariosPopup" | translate }} </h3>
                        </div>
                        <cdk-virtual-scroll-viewport class="lista-archivos" itemSize="50" *ngIf="comentarios.length">
                            <div class="comentarios-div">
                                <div (click)="getComentariosAndAdjuntos()" style="cursor: pointer;" class="sort-comentarios">
                                    <span *ngIf="!recientesComentos" style="margin-top: -5px;"> {{"proyectosInformacionPR.antiguo" | translate }} </span>
                                    <span *ngIf="recientesComentos"  style="margin-top: -5px;"> {{"proyectosInformacionPR.nuevo" | translate }} </span>
                                    <span *ngIf="recientesComentos">  <mat-icon>keyboard_arrow_down</mat-icon> </span>
                                    <span *ngIf="!recientesComentos"> <mat-icon>keyboard_arrow_up</mat-icon> </span>
                                </div>
                                <div class="comment-person" *ngFor="let comentario of comentarios">
                                    <div class="informacionUsuario">
                                        <b><span [innerHTML]="comentario.nombre_usuario"></span> <span class="fecha-comentario"> {{ (comentario.fecha_modificacion != null || comentario.fecha_modificacion != '') ?  (comentario.fecha_modificacion.split(".")[0] | date:'dd/MM/yyyy h:mm')  : (comentario.fecha_creacion.split(".")[0] | date:'dd/MM/yyyy h:mm')   }} </span> </b> <span  class="editarComentario cursor-pointer"
                                            (click)="modificarComentario(comentario)"> <mat-icon>create</mat-icon> </span> <span style="color: #C71C22;vertical-align: middle;" 
                                            (click)="eliminarComentario(comentario)" class="cursor-pointer"> <mat-icon>close</mat-icon> </span>
                                    </div>
                                    <p [innerHTML]="comentario.comentarios"></p>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                        <div class="form-group">
                            <label for="comentarios" class="labelForm"> {{"proyectosInformacionPR.comentariosAgregar" | translate }} </label>
                            <textarea type="text" style="overflow:auto;resize:none" class="form-control" resize=false id="comentarios" formControlName="comentarios"></textarea>
                        </div>
                        <div *ngIf="editarComment" class="editComments">
                            <div class="row">
                                <div class="col-12 col-sm-6 guardarButton ">
                                    <button type="button" (click)="actualizarComentario()" class="btn btn-form mt-3">{{"proyectosDetalle.guardar" | translate }} </button>
                                </div>
                                <div class="col-12 col-sm-6 cancelarButton">
                                    <button type="button" (click)="cancelarComentario()" class="btn btn-form mt-3">{{"proyectosDetalle.cancelar" | translate }} </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lineadivisora"></div>

                    <div class="archivos-section">
                        <div class="title-comments">
                            <h3> {{"proyectosInformacionPR.archivosInformacion" | translate }} </h3>
                        </div>
                        <label for="archivo" class="labelForm"> {{"proyectosInformacionPR.archivosAgregar" | translate }} </label>
                        <div class="custom-file">
                            <input type="file" id="archivo" formControlName="archivo" #valorArchivo aria-describedby="archivo" (change)="fileInputChange($event.target.files)" class="cursor-pointer custom-file-input" />
                            <label class="custom-file-label label-new" [attr.data-after-content]="[buscarPalabra]">{{"modalCargarPlantilla.opcion2" | translate }}</label>
                        </div>
                        <span class="mensajeArchivos"> {{"proyectosInformacionPR.archivos" | translate }} </span>
                        <cdk-virtual-scroll-viewport class="lista-archivos" itemSize="50" *ngIf="adjuntos.length">
                            <div class="archivos-lista">
                                <div class="archivo-add position-relative" *ngFor="let adjunto of adjuntos">
                                    <a [attr.href]="adjunto.url" target="_blank" rel="noopener noreferrer">{{adjunto.nombre}}</a>
                                    <span (click)="eliminarArchivo(adjunto)" class="basura-iconos cursor-pointer"> <mat-icon>close</mat-icon> </span>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>

                    <div *ngIf="facturas.length" class="lineadivisora"></div>

                    <div *ngIf="facturas.length" class="archivos-section">
                        <div class="title-comments">
                            <h3> {{"proyectosInformacionPR.facturas" | translate }} </h3>
                        </div>
                        <!-- <label for="archivo" class="labelForm"> {{"proyectosInformacionPR.archivosAgregar" | translate }} </label>
                        <div class="custom-file">
                            <input type="file" id="archivo" formControlName="archivo" #valorArchivo aria-describedby="archivo" (change)="fileInputChange($event.target.files)" class="cursor-pointer custom-file-input" />
                            <label class="custom-file-label" [attr.data-after-content]="[buscarPalabra]">{{"modalCargarPlantilla.opcion2" | translate }}</label>
                        </div> -->
                        <cdk-virtual-scroll-viewport class="lista-archivos" itemSize="50">
                            <div class="archivos-lista">
                                <div class="archivo-add position-relative" *ngIf="facturas.length">
                                    <a [attr.href]="facturas[0].url" target="_blank" rel="noopener noreferrer">{{facturas[0].nombre}}</a>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>


                    <div class="row btn-container">
                        <button type="submit" [disabled]="deshabilitarSubmit || editarComment" class="btn btn-form mt-3">{{"proyectosDetalle.guardar" | translate }} </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
