<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-5 mb-5">
        <div class="borderBottom-title">
            <div class="row align-items-center">
                <div class="col-12 col-md-8">
                    <h1 class="title-activity">{{"proyectosDetalle.actividad" | translate }}</h1>
                </div>
                <div class="col-12 col-md-4">
                    <div class="actividades-recientes">
                        <button (click)="redirectToProjectDetail()" class="btn btn-secondary btn-arecientes"> {{"proyectosDetalle.volver" | translate }} </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="registrosLog" class="log-actividades">
            <cdk-virtual-scroll-viewport (scrolledIndexChange)="onScroll($event)" class="lista-archivos" itemSize="50">
                <div *ngFor="let registro of registrosLog">
                    <div  class="log-individual">
                        <span class="fechas">  {{ registro.action_time | date:'dd/MM/yyyy' }} </span> <span class="horas"> | {{ registro.action_time | date:'shortTime' }}</span>
                        <div class="texto-actividad">
                            <div class="icono-log">
                                <mat-icon> message</mat-icon>
                            </div>
                            <span [innerHTML]="registro.change_message"></span>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>

    </div>

</div>
