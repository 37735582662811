<app-header-general></app-header-general>
<div class="pagina-completa">
    <div class="banner-proyeccion">
        <div class="contenedor-personalizado">
            <div class="row m-0 align-items-center">
                <div class="col-lg-12">
                </div>
            </div>
        </div>
    </div>

    <div class="container encuestaContainer mt-5 mb-5">
        <div class="row align-items-center">
            <div class="col-12 col-sm-6">
                <h2> {{"formularioEncuesta.tituloForm" | translate }} </h2>
            </div>
            <div class=" fecha-encuesta col-12 col-sm-6">
                <b>{{ fechaToday | date:'dd/MM/yyyy' }}</b>
            </div>
        </div>

        <div class="linea-divisora"></div>

        <div class="row section-vacantes  pd-contenido">
            <div class="col-12">
                <form [formGroup]="formularioEncuesta" (ngSubmit)="onSubmit(formDir, formDirective)" #formDir="ngForm" #formDirective="ngForm" autocomplete="off">
                    <formly-form [form]="formularioEncuesta" [fields]="fields"></formly-form>
                    <div class="row btn-container">
                        <button type="submit" class="btn btn-form">{{ "trabajaNosotrosForm.opcion8" | translate }}</button>
                    </div>
                </form>
            </div>
        </div>

    </div>

</div>