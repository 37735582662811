import {Component, OnInit, Renderer2, Inject, Injector} from '@angular/core';
import * as AOS from 'aos';
import {TranslateService} from '@ngx-translate/core';
import { HomeService } from './services/home.service';
import AES from 'crypto-js/aes';
import { DOCUMENT } from '@angular/common';
import {AuthService} from './services/auth.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import {SecureStorageService} from './services/secure-storage.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'web-phc';
  mensajeCargador: any;

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,private translate: TranslateService, private homeService: HomeService,
    private injector: Injector, private router: Router, private bnIdle: BnNgIdleService,
    private dateAdapter: DateAdapter<Date>) {
    const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'es';
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang(language);
    localStorage.setItem('language', language);
    const lenguaje = this.translate.getDefaultLang().valueOf();
    lenguaje == 'es' ? this.dateAdapter.setLocale('es-co') : this.dateAdapter.setLocale('en');  
  }

  ngOnInit(): void {
    AOS.init();

    //cerrarSesion automáticamente
    const token = this.getAccessToken();
    if(token != null || token != '' || token != undefined ){
      //Start watching for user inactivity.
      this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
        if (isTimedOut) {
          this.logoutUser();
        }
      });
    }

    this.homeService.obtenerCredenciales()
        .subscribe( (respuesta) => {

            let objetoEncriptadoCredenciales = {
              mapKey: AES.encrypt(respuesta.key_google_maps, 'abcd@$--12').toString(),
              captchaKey: AES.encrypt(respuesta.key_google_captcha, 'abcd@$--12').toString(),
              wompiKey: AES.encrypt(respuesta.key_wompi, 'abcd@$--12').toString(),
            }

            localStorage.setItem( 'credenciales', JSON.stringify( objetoEncriptadoCredenciales ));

        });

        this.homeService.mensajeCargador()
            .subscribe( (respuesta: any) => {
              this.mensajeCargador = respuesta.message;
            });

  }

  getAccessToken() {
    const secureStorageService = this.injector.get(SecureStorageService);
    return secureStorageService.getItem('accessToken');
  }

  logoutUser() {
    const authService = this.injector.get(AuthService);
    authService.logout();
    this.router.navigateByUrl('/');
    // Route to the login page (implementation up to you)
    return throwError('');
}

}
