<div id="sidebar" class="sidebar" (mouseover)='openSidebar()' (mouseout)='closeSidebar()'>
    <a class="logo-phc" href="https://www.phc.com.co/">
        <img src="assets/logo_PHC_blanco.png" alt="logo-phcblanco">
    </a>
    <div class="menu">
        <ul>
          <li *ngFor="let digitalService of digitalServices">
            <div class="row align-items-center lineaverde-hover">
              <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">
                <a (click)="validateLoggin(digitalService.url)"><img [src]="digitalService.img" alt=""
                                                                       style="filter: brightness(0) invert(1); cursor: pointer;"></a>
              </div>
              <div  style="cursor: pointer;" class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">
                <a (click)="validateLoggin(digitalService.url)">{{ digitalService.title }}</a>
              </div>
            </div>
          </li>
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a [routerLink]="['/inicio/proyecciones-de-mercado']"><img src="assets/4v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a [routerLink]="['/inicio/proyecciones-de-mercado']">{{"serviciosDigitales.opcion1" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" (click)="validateLoggin()"><img src="assets/1v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" (click)="validateLoggin()">{{"serviciosDigitales.opcion4" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)"><img src="assets/2v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)">{{"serviciosDigitales.opcion2" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)"><img src="assets/3v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)">{{"serviciosDigitales.opcion3" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" routerLink="/inicio/asesoria-en-linea"><img src="assets/5v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" routerLink="/inicio/asesoria-en-linea">{{"serviciosDigitales.opcion7" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" routerLink="/inicio/estudios-de-conexion"><img src="assets/6v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" routerLink="/inicio/estudios-de-conexion">{{"serviciosDigitales.opcion5" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li>-->
<!--                <div class="row align-items-center lineaverde-hover">-->
<!--                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 icono-rps">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)"><img src="assets/7v2x(blanco).png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 texto-menu">-->
<!--                        <a class="cursor-pointer" (click)="paginaConstruccion(construccion)">{{"serviciosDigitales.opcion6" | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </li>-->
        </ul>
    </div>

    <ng-template #construccion let-modal>
        <div id="myModal" class="modal modal-construccion modal-dialog-centered">
            <div class="row mt-3 mr-3 w-100">
                <div class="col-12">
                    <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="modal.dismiss('Cross click')">
                </div>
            </div>
            <div class="row modal-middle">
                <div class="col-lg-12">
                    <div class="modal-content-modify">
                        <div class="cabecera-modal">
                            {{"paginaConstruccion.opcion1" | translate }}
                        </div>
                        <div class="cuerpo-modal">
                            <p>{{"paginaConstruccion.opcion2" | translate }}
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="btn-container-modify">
                        <button type="button" (click)="modal.close('Close click')" class=" btn btn-form ">{{"paginaConstruccion.opcion3" | translate }}</button>
                    </div>
                </div>
            </div>

        </div>
    </ng-template>

</div>
