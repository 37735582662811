import {Component, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, Validators, FormGroup} from '@angular/forms';
import {HomeService} from '../../services/home.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ConsultaUsuarioComponent} from '../phc-interno/consulta-usuario/consulta-usuario.component';
import {InfoSuscripcionComponent} from '../phc-interno/info-suscripcion/info-suscripcion.component';
import {InicioSesionComponent} from '../inicio-sesion/inicio-sesion.component';
import { environment } from 'src/environments/environment';
import {SecureStorageService} from '../../services/secure-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  lenguaje: string;
  logged: any;
  closeResult = '';
  loginForm: FormGroup;
  passwordForm: FormGroup;
  firtsTimePasswordForm: FormGroup;
  contrasenaError: string = '';
  messajeError = '';
  messajeErrorPassword = '';
  getPassword: boolean = false;
  primeraVez: boolean = false;
  successPassword: boolean = false;
  documentoOrNIT: boolean = true;
  tipoDocumento: any;
  registerFormulario: FormGroup;
  mensajeAfterRegistro: boolean = false;
  mensajeExitoRegistro: boolean = false;
  checkedHabeasData: boolean;
  checkedTerminos: boolean;
  loading: boolean = false;
  tiposPersona = [];
  imageLenguaje: string;
  errorMensaje: boolean = false;
  mensajeEstado: String = '';
  politicasTratamiento: any;
  terminosTitulo: any;
  messageError = {message: null, type: null};

  constructor(private homeService: HomeService, private translate: TranslateService, private modalService: NgbModal,
              private _builder: FormBuilder, private authService: AuthService, private router: Router,
              private secureStorageService: SecureStorageService
  ) {

    this.loginForm = this._builder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      /*Se valida que :
      - La contraseña sea obligatoria
      - Tenga minimo 8 carácteres
      */
    });

    this.registerFormulario = this._builder.group({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      tipoPersona: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]),
      empresa: new FormControl(''),
      numeroDocumento: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      nitEmpresa: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      celular: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      telefono: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      cargo: new FormControl('', [Validators.required]),
      habeasData: new FormControl('', [Validators.required]),
      terminos: new FormControl('', [Validators.required]),
    });


    this.firtsTimePasswordForm = this._builder.group({
      /*Se valida que :
        - La contraseña sea obligatoria
        - Tenga minimo 8 carácteres
        - Solo permita letras y números
        */
      digitarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      confirmarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)]))

    });
    this.passwordForm = this._builder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email]))
    });
  }

  changeLenguaje() {
    this.lenguaje = this.translate.getDefaultLang().valueOf();
    if (this.lenguaje == 'es') {
      this.translate.setDefaultLang('en');
      localStorage.setItem('language', 'en');
      this.imageLenguaje = 'bandera-espanol.png';
    } else {
      this.translate.setDefaultLang('es');
      localStorage.setItem('language', 'es');
      this.imageLenguaje = 'bandera-ingles.png';
    }
    window.location.reload();
  }

  addClassActive(selector: string, scroll): void {
    
    
    if (selector) {
      const sectionActive = document.querySelector('#sections-nav a.active');
      if (sectionActive) {
        sectionActive.classList.remove('active');
      }
      var section = document.querySelector(selector);
      section.classList.add('active');
    }
    
    // const elmnt = document.getElementById(scroll);
    // elmnt.scrollIntoView({behavior: "smooth"});
    window['scroll_star'] = false;

  }

  ngOnInit(): void {
    if (this.secureStorageService.getItem('djangoUser')) {
      this.logged = true;
    } else {
      this.logged = false;
    }

    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.registerFormulario.reset();

    this.lenguaje = this.getLanguage();
    this.imageLenguaje = (this.lenguaje === 'es') ? 'bandera-ingles.png' : 'bandera-espanol.png';

    this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        // //debugger
        const lenguaje = this.translate.getDefaultLang().valueOf();
        if (lenguaje == 'es') {
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 1);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 2);
        } else{
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 3);
          this.terminosTitulo = respuesta.find(elemento => elemento.id == 4);
        }
      });

  }

  cerrarSesion() {

    delete this.logged;
    let remove = ['accessToken', 'refreshToken', 'djangoUser'];
    remove.forEach(key => {
      this.secureStorageService.removeItem(key);
    });
    this.authService.logout();
    this.router.navigate(['/']);
  }

  login(content) {
    this.modalService.open(InicioSesionComponent, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openRegistro(content) {
    this.mensajeAfterRegistro = false;
    this.homeService.traerTipoPersona()
      .subscribe(resp => {

        this.tiposPersona = resp;
      });

    this.modalService.open(content, {centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /*
  Método usado para el volver atrás de olvidó su contraseña
  */
  changePassword(status) {
    this.errorMensaje = false;
    this.getPassword = status;
    this.messajeErrorPassword = '';
    this.passwordForm.reset();
  }


  /*
    Método usado para volver a preguntar sí la información
    en el local host ya existe
  */
  asyncPromise() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        this.loading = false;
        let property = 'primera_vez';
        let value = this.authService.getPropertyOfCurrentUserData(property);
        let propertyEstado = 'is_active';
        let valueEstado = this.authService.getPropertyOfCurrentUserData(propertyEstado);
        this.firstTime(value, valueEstado);
      }, 5000);
    });
  }

  /*
    Primera vez contraseña, es cuando el usuario inicia sesión
    por primera vez
  */
  firstTime(value?, estado?) {
    if (this.loading == true) {
      this.asyncPromise();
    } else {

      if (estado == false) {

        this.mensajeEstado = this.translate.instant('errores.login');
      }

      switch (value) {
        case undefined :
          this.loading = true;
          this.asyncPromise();
          break;
        case true :
          this.loading = false;
          this.primeraVez = true;
          break;
        case false :
          this.loading = false;
          location.reload();
          break;
      }
    }
  }


  checkHabeasData(event) {
    if (event == false) {
      this.checkedHabeasData = false;
    } else {
      this.checkedHabeasData = true;
    }
  }

  checkTerminos(event) {
    if (event == false) {
      this.checkedTerminos = false;
    } else {
      this.checkedTerminos = true;
    }
  }

  cambiarTexto(evento) {

    this.homeService.tipoDeDocumento({id: evento})
      .subscribe((resp: any) => {
        if (resp[0].tipo == 'NIT') {
          this.documentoOrNIT = false;
        } else {
          this.documentoOrNIT = true;
        }

        this.tipoDocumento = resp[0].id;

      });

  }
  mensaje_back:string;
  onSubmitRegistro(formDir) {

    if (!this.registerFormulario.valid || this.checkedHabeasData == false) {
      this.registerFormulario.markAllAsTouched();
      return;
    }

    this.homeService.crearEmpresa({
      nitEmpresa: this.registerFormulario.controls['nitEmpresa'].value,
      empresa: this.registerFormulario.controls['empresa'].value
    })
      .subscribe(respuesta => {
        let nitEmpresa = respuesta;
        let username = '';
        this.homeService.userName({username: this.registerFormulario.get('correo').value.toString().split('@', 1)[0]})
          .subscribe(data => {
            username = data;


            let objeto = {
              'first_name': this.registerFormulario.get('nombre').value,
              'last_name': this.registerFormulario.get('apellido').value,
              'username': username,
              'email': this.registerFormulario.get('correo').value,
              'celular': this.registerFormulario.get('celular').value,
              'cargo': this.registerFormulario.get('cargo').value,
              'documento_usuario': this.registerFormulario.get('numeroDocumento').value,
              'empresa': nitEmpresa,
              'usuario_creador': 'admin@phcportal.com',
              'tipo_documento': this.tipoDocumento,
              'tipo_persona': this.registerFormulario.get('tipoPersona').value,
              'habeas_data': this.checkedHabeasData,
              'terminos': this.checkedTerminos
            };



            this.homeService.registrarUsuario(objeto)
              .subscribe({
                next: (respuesta) => {

                  this.resetDatosRegistro();
                  this.mensajeAfterRegistro = true;
                  this.mensajeExitoRegistro = true;
                  // this.cloneUser(respuesta.email);
                  return;
                },
                error: (err) => {

                  this.registerFormulario.get('correo').reset();
                  this.mensajeExitoRegistro = false;
                  this.mensajeAfterRegistro = true;

                  this.mensaje_back=err.error.documento_usuario[0];
                  return;
                }
              });
          });
      });
  }

  resetDatosRegistro() {
    this.registerFormulario.reset();
    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.tipoDocumento = '';
  }

//Método usado en el submit del loginForm
  onSubmit(formDir) {
    this.errorMensaje = true;
    //Se valida que la info sea válida
    // localStorage.setItem('Validador','false');
    if (this.loginForm.valid) {
      //Se envía información del login al servicio
      this.authService.login(this.loginForm.value).subscribe(data => {
        this.logged = true;
        this.loginForm.reset();
        this.modalService.dismissAll();
        this.loading = true;
        this.firstTime();
      }, err => {
        this.messajeError = this.translate.instant('errores.login');
      });
    }
  }

  changePasswordFirtTime(objeto) {
    let property = 'username';
    let correctPassword;
    let value = this.authService.getPropertyOfCurrentUserData(property);
    if (objeto == 'CerrarModal') {
      this.successPassword = false;
    } else {
      if (this.firtsTimePasswordForm.valid) {
        let digitada = this.firtsTimePasswordForm.value.digitarContrasena;
        let confirmada = this.firtsTimePasswordForm.value.confirmarContrasena;
        if (digitada == confirmada) {
          correctPassword = this.validatePassword(this.firtsTimePasswordForm.get('digitarContrasena'));
        } else {
          this.contrasenaError = this.translate.instant('formularioAvisos.opcion15');
        }

        if (this.firtsTimePasswordForm.valid && correctPassword) {
          let usuario = value;
          let objeto = {
            password: confirmada,
            usuario: usuario
          };
          this.homeService.firtsTimePassword(objeto).subscribe(data => {
            this.primeraVez = false;
            if (data.type == 'success') {
              this.successPassword = true;
            }
            this.firtsTimePasswordForm.reset();
          }, err => {
          });
        }

      }
    }
  }

  validatePassword(password) {
    // //debugger
    let hasUpper = /[A-Z]/.test(password.value);
    let hasLower = /[a-z]/.test(password.value);
    let hasNumber = /[0-9]/.test(password.value);
    var patt = new RegExp('[!@#$%^&*()_+.,-/]');
    let hasComodin = patt.test(password.value);
    //
    const valid = hasNumber && hasUpper && hasLower && hasComodin;
    if (!valid) {
      // return what´s not valid
      return false;
    }
    return true;
  }


  submitChangePassword(object) {
    if (this.passwordForm.valid) {
      this.homeService.changePassword(object).subscribe(data => {

        this.messageError.message = data.message;
        this.messageError.type = data.type;
        this.passwordForm.reset();
      }, err => {

        this.messajeErrorPassword = '3';
      });
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.mensajeEstado = '';
    this.errorMensaje = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return 'by clicking on a backdrop';
    } else {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = '';
      return `with: ${reason}`;
    }
  }

  getLanguage(): string {
    return localStorage.getItem('language');
  }

  cloneUser(email: string): void {
    this.homeService.cloneUser(email).toPromise();
  }

  consultaUser() {
    this.modalService.open(ConsultaUsuarioComponent, {centered: true, size: 'sm'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  consultaSuscripcion() {
    this.modalService.open(InfoSuscripcionComponent, {centered: true, size: 'sm'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  navigate(event,id){

    window.open(environment.urlWeb+'/terminos-y-condiciones/'+id,'_blank')
    event.preventdefault();
  }
}
