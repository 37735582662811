import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { HomeService } from 'src/app/services/home.service';
import { ProyeccionesMercadoService } from 'src/app/services/proyecciones-mercado.service';
import {SecureStorageService} from '../../../services/secure-storage.service';

@Component({
  selector: 'app-info-suscripcion',
  templateUrl: './info-suscripcion.component.html',
  styleUrls: ['./info-suscripcion.component.css']
})
export class InfoSuscripcionComponent implements OnInit {

  userEmail: String;
  suscripcionInfo: any[] = [];
  page = 1;
  pageSize = 5;
  active = '1';
  loading: boolean = false;
  suscripcion: boolean = false;
  constructor(private modalService: NgbModal, private datepipe: DatePipe ,
              private ProyeccionesMercadoService: ProyeccionesMercadoService,
              private secureStorageService: SecureStorageService
  ) { }

  ngOnInit( ): void {

    this.loading = true;
    this.userEmail = this.secureStorageService.getItem('djangoUser').email;
    this.ProyeccionesMercadoService.consultaSuscripcionUsuario( this.userEmail )
        .subscribe( ( respuesta ) => {



          if( !respuesta ){
              this.suscripcion = false;
              this.loading = false;
              return;
          }

          this.suscripcion = true;
          respuesta.forEach(element => {
            Object.assign( element, { email: this.userEmail } );
          });
          this.loading = false;
          this.suscripcionInfo = respuesta;

        } );

  }

  tamanoPaginador() {
    if (window.innerWidth < 480) {
      return 'sm';
    } else {
      return 'md';
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
