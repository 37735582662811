// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
 url: 'https://api.phc.com.co/api/',
  urlS3: 'https://phcstorageprod.s3.us-east-1.amazonaws.com',
  urlS3pdf: 'http://docs.google.com/gview?url=https://phcprueba.s3.us-east-2.amazonaws.com',
  urlCreatePDF: 'https://pdf.phc.com.co',
	urlWeb: 'https://phc.com.co',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
