import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiciosNoDigitalService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private _http: HttpClient) { }

  
  consultarServiciosNoDigitales(objeto): any {
    return this._http.post(`${environment.url}services/`, objeto, this.httpOptions);
  }
  
  consultarAsesoria(): any {
    return this._http.get(`${environment.url}asesoria/temas`, this.httpOptions);
  }
}
