import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HomeService } from '../../services/home.service';
import { AuthService } from '../../services/auth.service';
// import {MatSnackBarUtil} from '../utils/mat-snack-bar.util';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, interval } from 'rxjs';
import {SecureStorageService} from '../../services/secure-storage.service';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})
export class RegistrarseComponent implements OnInit {
  lenguaje: string;
  logged: any;
  closeResult = '';
  loginForm: FormGroup;
  passwordForm: FormGroup;
  firtsTimePasswordForm: FormGroup;
  contrasenaError: String = '';
  messajeError = '';
  messajeErrorPassword = '';
  getPassword: boolean = false;
  primeraVez: boolean = false;
  successPassword: boolean = false;
  documentoOrNIT: boolean = true;
  tipoDocumento: any;
  registerFormulario: FormGroup;
  mensajeAfterRegistro: boolean = false;
  mensajeExitoRegistro: boolean = false;
  checkedHabeasData: boolean;
  loading: boolean = false;
  tiposPersona = [];
  imageLenguaje: string;
  errorMensaje: boolean = false;
  mensajeEstado: String = "";
  constructor(private homeService: HomeService, private translate: TranslateService, private modalService: NgbModal,
              private _builder: FormBuilder, private authService: AuthService, private router: Router,
              private secureStorageService: SecureStorageService
  ) {

    this.loginForm = this._builder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      /*Se valida que :
      - La contraseña sea obligatoria
      - Tenga minimo 8 carácteres
      */
    });

    this.registerFormulario = this._builder.group({
      nombre: new FormControl('', [Validators.required]),
      apellido: new FormControl('', [Validators.required]),
      tipoPersona: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$")]),
      empresa: new FormControl(''),
      numeroDocumento: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      nitEmpresa: new FormControl('', [Validators.pattern("^[0-9]*$")]),
      celular: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      telefono: new FormControl('', [Validators.pattern("^[0-9]*$")]),
      cargo: new FormControl('', [Validators.required]),
      habeasData: new FormControl('', [Validators.required]),
    });


    this.firtsTimePasswordForm = this._builder.group({
      /*Se valida que :
        - La contraseña sea obligatoria
        - Tenga minimo 8 carácteres
        - Solo permita letras y números
        */
      digitarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')])),
      confirmarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{8,}')]))
    });
    this.passwordForm = this._builder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email]))
    });
  }

  changeLenguaje() {
    this.lenguaje = this.translate.getDefaultLang().valueOf();
    if (this.lenguaje == 'es') {
      this.translate.setDefaultLang('en');
      localStorage.setItem('language', 'en');
      this.imageLenguaje = 'bandera-espanol.png';
    } else {
      this.translate.setDefaultLang('es');
      localStorage.setItem('language', 'es');
      this.imageLenguaje = 'bandera-ingles.png';
    }
    window.location.reload();
  }

  ngOnInit(): void {
    this.mensajeAfterRegistro = false;
    this.homeService.traerTipoPersona()
      .subscribe(resp => {

        this.tiposPersona = resp;
      });


    if (this.secureStorageService.getItem('djangoUser')) {
      this.logged = true;
    } else {
      this.logged = false;
    }

    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.registerFormulario.reset();

    this.lenguaje = this.getLanguage();
    this.imageLenguaje = (this.lenguaje === 'es') ? 'bandera-ingles.png' : 'bandera-espanol.png';
  }

  cerrarSesion() {
    this.authService.logout();
    delete this.logged
    localStorage.clear();
  }

  login(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openRegistro(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /*
  Método usado para el volver atrás de olvidó su contraseña
  */
  changePassword(status) {
    this.errorMensaje = false;
    this.getPassword = status;
    this.messajeErrorPassword = '';
    this.passwordForm.reset();
  }


  /*
    Método usado para volver a preguntar sí la información
    en el local host ya existe
  */
  asyncPromise() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        this.loading = false;
        let property = "primera_vez";
        let value = this.authService.getPropertyOfCurrentUserData(property);
        let propertyEstado = "is_active";
        let valueEstado = this.authService.getPropertyOfCurrentUserData(propertyEstado);
        this.firstTime(value, valueEstado);
      }, 5000)
    })
  }

  /*
    Primera vez contraseña, es cuando el usuario inicia sesión
    por primera vez
  */
  firstTime(value?, estado?) {
    if (this.loading == true) {
      this.asyncPromise();
    }
    else {

      if (estado == false) {

        this.mensajeEstado = this.translate.instant('errores.login');
      }

      switch (value) {
        case undefined:
          this.loading = true;
          this.asyncPromise();
          break;
        case true:
          this.loading = false;
          this.primeraVez = true;
          break;
        case false:
          this.loading = false;
          location.reload();
          break;
      }
      /*
         let estado = "is_active";
    let estadoActivo = this.authService.getPropertyOfCurrentUserData(estado);
    if (value == undefined)
    { this.loading = true;


      this.asyncPromise();
    }
    else if (value == true) {

      this.primeraVez = true;
    }
    else if (value == false)
    {
      this.loading = false;
    }
      */
    }
  }


  checkHabeasData(event) {
    if (event == false) {
      this.checkedHabeasData = false;
    } else {
      this.checkedHabeasData = true;
    }
  }

  cambiarTexto(evento) {

    this.homeService.tipoDeDocumento({ id: evento })
      .subscribe((resp: any) => {
        if (resp[0].tipo == 'NIT') {
          this.documentoOrNIT = false;
        } else {
          this.documentoOrNIT = true;
        }
        this.tipoDocumento = resp[0].id;
      });
  }
  mensaje_back:string='';
  onSubmitRegistro(formDir) {

    if (!this.registerFormulario.valid || this.checkedHabeasData == false) {
      this.registerFormulario.markAllAsTouched();
      return;
    }

    this.homeService.crearEmpresa({
      nitEmpresa: this.registerFormulario.controls['nitEmpresa'].value,
      empresa: this.registerFormulario.controls['empresa'].value
    })
      .subscribe(respuesta => {
        let nitEmpresa = respuesta;
        let user = '';
        this.homeService.userName({ username: this.registerFormulario.get('correo').value.toString().split('@', 1)[0] })
          .subscribe(data => {
            user = data;

            let objeto = {
              "first_name": this.registerFormulario.get('nombre').value,
              "last_name": this.registerFormulario.get('apellido').value,
              "username": user,
              "email": this.registerFormulario.get('correo').value,
              "celular": this.registerFormulario.get('celular').value,
              "telefono":this.registerFormulario.get('telefono').value,
              "cargo": this.registerFormulario.get('cargo').value,
              "documento_usuario": this.registerFormulario.get('numeroDocumento').value,
              "empresa": nitEmpresa,
              "usuario_creador": 'admin@phcportal.com',
              "tipo_documento": this.tipoDocumento,
              "tipo_persona": this.registerFormulario.get('tipoPersona').value,
              "habeas_data": this.checkedHabeasData
            }


            this.homeService.registrarUsuario(objeto)
              .subscribe({
                next: (respuesta) => {

                  this.resetDatosRegistro();
                  this.mensajeAfterRegistro = true;
                  this.mensajeExitoRegistro = true;
                  return;
                },
                error: (err) => {

                  this.registerFormulario.get('correo').reset();
                  this.mensajeExitoRegistro = false;
                  this.mensajeAfterRegistro = true;
                  this.mensaje_back = err.error.documento_usuario[0];
                  return;
                }
              });
          });

      });
  }

  resetDatosRegistro() {
    this.registerFormulario.reset();
    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
    this.tipoDocumento = '';
  }

  //Método usado en el submit del loginForm
  onSubmit(formDir) {
    this.errorMensaje = true;
    //Se valida que la info sea válida
    // localStorage.setItem('Validador','false');
    if (this.loginForm.valid) {
      //Se envía información del login al servicio
      this.authService.login(this.loginForm.value).subscribe(data => {
        this.logged = true;
        this.loginForm.reset();
        this.modalService.dismissAll();
        this.loading = true;
        this.firstTime();
      }, err => {
        this.messajeError = this.translate.instant('errores.login');
      });
    }
  }

  changePasswordFirtTime(objeto) {
    let property = "username";
    let value = this.authService.getPropertyOfCurrentUserData(property);
    if (objeto == 'CerrarModal') {
      this.successPassword = false;
    }
    else {
      if (this.firtsTimePasswordForm.valid) {
        let digitada = this.firtsTimePasswordForm.value.digitarContrasena;
        let confirmada = this.firtsTimePasswordForm.value.confirmarContrasena;
        let usuario = value;
        if (digitada == confirmada) {
          let objeto = {
            password: confirmada,
            usuario: usuario
          }
          this.homeService.firtsTimePassword(objeto).subscribe(data => {
            this.primeraVez = false;
            if (data.type == "success") {
              this.successPassword = true;
            }
            this.firtsTimePasswordForm.reset();
          }, err => {
          })
        }
        else {
          this.contrasenaError = 'Las contraseñas no coinciden';
        }
      }
    }
  }

  cerrarModal() {
    this.mensajeAfterRegistro = false;
    this.mensajeExitoRegistro = false;
  }


  submitChangePassword(object) {
    if (this.passwordForm.valid) {
      this.homeService.changePassword(object).subscribe(data => {
        switch (data.type) {
          case "danger": this.messajeErrorPassword = data.message; break;
          default: this.messajeErrorPassword = this.translate.instant('formularioAvisos.opcion11'); break;
        }
        this.passwordForm.reset();
      }, err => {
        this.messajeErrorPassword = "Error inesperado, comunicarse con administración";
      });
    }

  }

  closeModal() {
    this.modalService.dismissAll();
    this.mensajeEstado = "";
    this.errorMensaje = false;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = "";
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = "";
      return 'by clicking on a backdrop';
    } else {
      this.errorMensaje = false;
      this.loginForm.reset();
      this.messajeErrorPassword = '';
      this.passwordForm.reset();
      this.mensajeEstado = "";
      return `with: ${reason}`;
    }
  }

  getLanguage(): string {
    return localStorage.getItem('language');
  }
}
