import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import {Loader} from "@googlemaps/js-api-loader";

@Component({
  selector: 'app-mapa-conozco-sector',
  templateUrl: './mapa-conozco-sector.component.html',
  styleUrls: ['./mapa-conozco-sector.component.css']
})
export class MapaConozcoSectorComponent implements OnInit, OnChanges {
  @Input() latitud: any;
  @Input() longitud: any;

  center: any;
  zoom = 11;
  apiLoaded: boolean;
  apiKeyMapa: string;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  }
  markers: any[];

  constructor() {
    let apiKeyMap = JSON.parse(localStorage.getItem('credenciales'));
    let keyDecript = AES.decrypt(apiKeyMap.mapKey, 'abcd@$--12');
    this.apiKeyMapa = keyDecript.toString(CryptoJS.enc.Utf8);


  }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes.latitud) {
      this.latitud = changes.latitud.currentValue;
    }
    if (changes.longitud) {
      this.longitud = changes.longitud.currentValue
    }
    if (!this.latitud && !this.longitud) {
      this.latitud = 6.2443382;
      this.longitud = -75.573553;
    }

    this.caragarMapa();

  }

  // click(event: google.maps.MouseEvent) {
  //   // console.log(event)
  //   this.latitud = event.latLng.lat()
  //   this.longitud = event.latLng.lng()
  //   this.caragarMapa()
  // }

  ngOnInit(): void {


    if (!this.latitud && !this.longitud) {
      this.latitud = 6.2443382;
      this.longitud = -75.573553;
    }
    this.caragarMapa();

  }

  caragarMapa() {
    this.center = {lat: parseFloat(this.latitud), lng: parseFloat(this.longitud)};
    this.markers = [{
      position: {
        lat: this.center.lat,
        lng: this.center.lng,
      },
      title: 'PHC'
    }
    ];
    
    const loader = new Loader({
      apiKey: this.apiKeyMapa.toString(),
      version: "weekly",
    }).load().then(() => this.apiLoaded = true);

    // loader.load().then(() => {
    //   this.apiLoaded = true;
    // });
  }

}
