import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  url = environment.url;

  constructor(
    private meta: Meta,
    private httpClient: HttpClient,
    private title: Title
  ) { }

  generateTagConfig(config): void {
    config = {
      title: 'PHC – Servicios Integrados',
      description: 'PHC – Servicios Integrados',
      image: '',
      slug: '',
      ...config
    };

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
      this.meta.updateTag({ name: key, content: config[key] });
      }
    }

    this.title.setTitle(config.title);
  }

  getMetaTagsByPage(page: string): Observable<any> {
    return this.httpClient.get(`${this.url}phc-web/seo/${page}`);
  }

  addMetaTagsByPage(page): void {
    this.getMetaTagsByPage(page).subscribe(tags => this.generateTagConfig(tags));
  }
}
