<div class="table-responsive">
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">{{"tabla4.proyecto_planta_unidad" | translate}}</th>
      <th scope="col">{{"tabla4.clasificacion_planta" | translate}}</th>
      <th scope="col">{{"tabla4.tipo_de_tecnologia" | translate}}</th>
      <th scope="col">{{"tabla4.posible_fecha_de_entrada" | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of response">
      <td class="text-center">{{data.proyecto_planta_unidad}}</td>
      <td class="text-center">{{data.clasificacion_planta}}</td>
      <td class="text-center">{{data.tipo_de_tecnologia}}</td>
      <td class="text-center">{{data.posible_fecha_de_entrada}}</td>
    </tr>
    </tbody>
  </table>
</div>
