<div class="completo-page">
  <app-header-general></app-header-general>
  <div class="pagina-completa">
    <div class="banner-proyeccion">
      <div class="contenedor-personalizado">
        <div class="row m-0 align-items-center">
          <div class="col-lg-12">
            <h1 class="titulo-bannerinicio">
              {{"proyeccionesBanner.tituloEstudiosMercado" | translate }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="cuerpo-pagina">
        <div *ngIf="appear && estudiosComplete[0]" class="cajones-conexion caja-right">
          <a class="link-estudios" [routerLink]="['/inicio/conozco-el-sector']">
            <div class="row align-items-center contenedor-imagen">
              <img class="volador-image" src="assets/card 1@2x.png" alt="image-contenedor1">
              <div *ngIf="estudiosComplete[0]['imagen']"
                   class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6 noneResponsive-block position-relative">
                <img class="img-fluid right-imgContent" [src]="urlS3+ '/' + estudiosComplete[0]['imagen'] "
                     alt="image-conexion">
              </div>
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-12 expandirBloque position-relative">
                <div class="text-conexion">
                  <h2 class="titulos-ppales"
                      *ngIf="estudiosComplete[0]['titulo']"> {{ estudiosComplete[0]['titulo'] }} </h2>
                  <p class="descripciones-ppales" *ngIf="estudiosComplete[0]['descripcion']"
                     [innerHTML]="estudiosComplete[0]['descripcion'] | dompurify"></p>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div *ngIf="appear && estudiosComplete[1]" class="cajones-conexion caja-left">
          <a class="link-estudios" [routerLink]="['/inicio/asesoria-en-linea']">
            <div class="row align-items-center">
              <img class="volador-image2" src="assets/card 2@2x.png" alt="image-contenedor2">
              <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-12  expandirBloque position-relative">
                <div class="text-conexion">
                  <h2 class="titulos-ppales"
                      *ngIf="estudiosComplete[1]['titulo']"> {{ estudiosComplete[1]['titulo'] }} </h2>
                  <p class="descripciones-ppales" *ngIf="estudiosComplete[1]['descripcion']"
                     [innerHTML]="estudiosComplete[1]['descripcion'] | dompurify"></p>
                </div>
              </div>
              <div *ngIf="estudiosComplete[1]['imagen']"
                   class="col-lg-6 col-md-6 col-xs-6 col-sm-6 col-6 noneResponsive-block position-relative">
                <img class="img-fluid right-imgContent" [src]="urlS3+ '/' + estudiosComplete[1]['imagen'] "
                     alt="image-conexion">
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="boton-asesor">
        <a [routerLink]="['/inicio/asesoria-en-linea']">
          <img src="assets/5v2x(blanco).png" alt="5v2x(blanco)">
          <span>{{"serviciosDigitales.opcion7" | translate }}</span>
        </a>
      </div>
    </div>

  </div>
</div>
