import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {

  transform(value?: any): any {
    let data = parseFloat(value.toFixed(2));
    return data;
  }

}
