import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {HomeService} from '../../services/home.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  firtsTimePasswordForm: FormGroup;
  getPassword = false;
  contrasenaError = '';
  successPassword = false;
  primeraVez = false;
  loading = false;
  fieldTextType: boolean = true;
  fieldTextType2: boolean = true;


  constructor(
    private ngbActiveModal: NgbActiveModal,
    private authService: AuthService,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {

    const pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+.,-/])[A-Za-z\d!@#$%^&*()_+].{7,}/;

    this.firtsTimePasswordForm = this.formBuilder.group({
      digitarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(pattern)])),
      confirmarContrasena: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(pattern)]))
    });
  }

  //ocultar y mostrar contraseña
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  changePasswordFirtTime(objeto): void {
    let property = 'username';
    this.loading = true;
    let value = this.authService.getPropertyOfCurrentUserData(property);
    if (objeto == 'CerrarModal') {
      this.successPassword = false;
      this.ngbActiveModal.close(true);
      location.reload();
    } else {
      if (!this.firtsTimePasswordForm.valid) {
        this.loading = false;
      }
      if (this.firtsTimePasswordForm.valid) {
        let digitada = this.firtsTimePasswordForm.value.digitarContrasena;
        let confirmada = this.firtsTimePasswordForm.value.confirmarContrasena;
        let usuario = value;
        if (digitada == confirmada) {
          let objeto = {
            password: confirmada,
            usuario: usuario
          };
          this.homeService.firtsTimePassword(objeto).subscribe(data => {
            this.primeraVez = false;
            this.loading = false;
            if (data.type == 'success') {
              this.successPassword = true;
            }
            this.firtsTimePasswordForm.reset();
          }, err => {
            this.loading = false;
          });
        } else {
          this.contrasenaError = this.translateService.instant('formularioAvisos.opcion15');
          this.loading = false;
        }
      }
    }
  }

}
