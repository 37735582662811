import {Component, OnInit} from '@angular/core';
import {HomeService} from '../../../../services/home.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {SeoService} from '../../../../services/seo.service';

@Component({
  selector: 'app-detalle-momento-phc',
  templateUrl: './detalle-momento-phc.component.html',
  styleUrls: ['./detalle-momento-phc.component.css']
})
export class DetalleMomentoPhcComponent implements OnInit {

  webContent;

  constructor(
    private homeService: HomeService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService
  ) {
  }

  ngOnInit(): void {
    this.getDetailWebContent();
  }

  getDetailWebContent(): void {
    const id = this.id;
    this.homeService.detailWebContent(id).subscribe(webContent => {
      this.webContent = webContent;
      this.addMetaTags();
    });
  }

  get id(): string {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  get urlS3(): string {
    return environment.urlS3;
  }

  addMetaTags(): void {
    if (this.webContent && 'metatags' in this.webContent) {
      this.seoService.generateTagConfig(JSON.parse(this.webContent.metatags));
    }
  }
}
