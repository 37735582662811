import { Component, OnInit } from '@angular/core';
import { OwlOptions, CarouselComponent } from 'ngx-owl-carousel-o';
import { HomeService } from 'src/app/services/home.service';
import { NuestrosProyectos } from '../../../models/nuestros-proyectos.model'; //Se extrae modelo
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {SeoService} from '../../../services/seo.service';
@Component({
  selector: 'app-nuestros-proyectos-phc',
  templateUrl: './nuestros-proyectos-phc.component.html',
  styleUrls: ['./nuestros-proyectos-phc.component.css']
})
export class NuestrosProyectosPHCComponent implements OnInit {
  /*datosServicio: any;
  nosotrosDetalleModal = new nosotrosDetalle;*/
  nosotrosDetalleModal = {
    titulo : "",
    descripcion : "",
    nombreParticipantes : "",
    areaDesempeno : ""
  };
  calcularDimensiones: any = "";
  count:number = 0;
  posicion:string = '';
  tipoCount:string = '';
  colorBackgroung: string = '';
  loading:boolean = true;
  customOptions: OwlOptions = {
    loop: false,
    items: 3,
    dotsSpeed: 500,
    dots: true,
    smartSpeed: 600,
    dragEndSpeed: 600,
    autoHeight: true,
    autoWidth:true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    // stagePadding: 40,
    nav: false
  }

proyectos = new NuestrosProyectos;
objetoCompleto: NuestrosProyectos;
arrayProyectos: Array<NuestrosProyectos> = [];
arrayProyectosMovil: Array<NuestrosProyectos> = [];
proyectosDescripcion: any;
constructor(
  private translate: TranslateService,
  private homeService: HomeService,
  private seoService: SeoService
) {

  this.homeService.consultarProyecto( { "seccion" : "Nuestros proyectos", "estado": "Activo" } )
      .subscribe( data => {

        for (const array of data.data) {
          this.count = this.count + 1;

          //Se establece las direccipnes para los móviles
          if(this.calcularDimensiones == "rgb(96, 226, 103)"){
            switch (this.posicion){
              case '' : this.posicion ='derecha' ; break;
              case 'derecha' :this.posicion = 'izquierda' ; break;
              case 'izquierda' :this.posicion = 'derecha' ; break;
            }
          } else if (this.count%2==0) //Se establece los colores para pantalla grande
          {
            switch (this.colorBackgroung)
            {
              case '': this.colorBackgroung = 'blanco'; break;
              case 'blanco' : this.colorBackgroung = 'azulClaro'; break;
              case 'azulClaro' :  this.colorBackgroung = 'blanco'; break;
            }
            this.tipoCount = "par";
          }
          else
          {
            //Se revisa a que orientación deben ir en pantalla grande
            this.tipoCount = "impar";
              if (this.posicion == '' && this.count != 1)
              {
                this.posicion = "derecha";
              }else if (this.posicion == "derecha")
              {
                this.posicion = "izquierda";
              }
              else if(this.posicion == "izquierda")
              {
                this.posicion = "derecha"
              }
            }





          array.campos.forEach(campo => {
            if (campo.nombre == 'Nombre proyecto') {
              this.proyectos.nombre = campo.valor;
            }
            if (campo.nombre == 'Descripcion') {
              this.proyectos.descripcion = campo.valor;
            }
            if (campo.nombre == 'Archivo') {
              this.proyectos.archivo = campo.valor;
            }
            if (campo.nombre == 'Logo') {
              this.proyectos.logo = campo.valor;
            }
            if (campo.nombre == 'Nombre participantes') {
              this.proyectos.nombreParticipantes = campo.valor;
            }
            if (campo.nombre == 'Area desempeno') {
              this.proyectos.areaDesempeno = campo.valor;
            }
            if (campo.nombre === 'Titulo') {
              this.proyectos.titulo = campo.valor.toLowerCase().replaceAll(' ', '-');
            }

            this.objetoCompleto = {
              nombre: this.proyectos.nombre,
              descripcion: this.proyectos.descripcion,
              archivo: this.proyectos.archivo,
              logo: this.proyectos.logo ? this.proyectos.logo : '',
              nombreParticipantes : this.proyectos.nombreParticipantes,
              areaDesempeno : this.proyectos.areaDesempeno,
              cantidad: this.count,
              posicion: this.posicion,
              color: this.colorBackgroung,
              id: array.id,
              // titulo: this.proyectos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            };
            if (this.proyectos.titulo) {
              this.objetoCompleto.titulo = this.proyectos.titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }
          });

          if(this.calcularDimensiones == "rgb(96, 226, 103)")
          {
            this.arrayProyectosMovil.push(this.objetoCompleto);
          }
          else {
            this.arrayProyectos.push(this.objetoCompleto);
          }

          //Borrar o limpiar todos los datos del objeto
          delete this.proyectos.nombre;
          delete this.proyectos.descripcion;
          delete this.proyectos.archivo;
          delete this.proyectos.logo;
          delete this.proyectos.nombreParticipantes;
          delete this.proyectos.areaDesempeno;

        }
        this.loading = false;
      } );

}

ngOnInit(): void {
    //   let element = document.getElementById('titulo');
    // let elementStyle = window.getComputedStyle(element);
    // let elementColor = elementStyle.getPropertyValue('color');
    // this.calcularDimensiones = elementColor;
    // if (this.calcularDimensiones == "rgb(96, 226, 103)")
    // {

    // }


    this.homeService.filtrarSecciones( { nombre: 'Nuestros proyectos' } )
    .subscribe( (respuesta: any) => {
      this.proyectosDescripcion = respuesta[0];
        } );

    this.seoService.addMetaTagsByPage('Meta tags - página nuestros proyectos PHC');

}

truncateHTML(text: string): string {

  let charlimit = 150;
  if(!text || text.length <= charlimit )
  {
      return text;
  }
    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
  }

get urlS3() {
  return environment.urlS3;
}

 modal(data) {
  this.nosotrosDetalleModal.titulo = data.nombre;
  this.nosotrosDetalleModal.descripcion = data.descripcion;
  this.nosotrosDetalleModal.nombreParticipantes = data.nombreParticipantes.split(",").join("<br />");
  this.nosotrosDetalleModal.areaDesempeno = data.areaDesempeno;
}



}
