<app-header></app-header>

<div class="banner-politicas">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 text-center align-self-center">
                <h1> {{ politicasCampos.titulo }} </h1>
                <h3 *ngIf="politicasDescripcion" [innerHTML]="politicasDescripcion.descripcion | dompurify"></h3>
            </div>
        </div>
    </div>
</div>

<div class="mision-politicas">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img class="img-mision" *ngIf="misionCampos.archivo" [src]="urlS3+'/'+misionCampos.archivo" alt="politics_misionimg">
            </div>
            <div class="col-lg-6">
                <div class="tarjeta">
                    <h3> {{ misionCampos.titulo }} </h3>
                    <div class="linea linea-politicas"></div>
                    <p [innerHTML]="misionCampos.descripcion | dompurify"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="politicas-texto">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 cajon-politica">
                <p [innerHTML]="idiomaTexto == 'es' ? (politicasCampos.descripcion | slice:0:2475) : (politicasCampos.descripcion | slice:0:2100) | dompurify">
                </p>
            </div>
            <div class="col-lg-6 cajon-politica politicas-fondoverde">
                <p [innerHTML]="idiomaTexto == 'es' ? (politicasCampos.descripcion | slice:2475:5140) : (politicasCampos.descripcion | slice:2100:4250) | dompurify"></p>
            </div>
            <!-- <div class="col-lg-12">
                <p [innerHTML]="politicasCampos.descripcion"></p>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-12 mt-5 mb-5 text-center">
                <button class="button-politics" data-toggle="modal" data-target="#exampleModal"> {{ "verPoliticas.titulo" | translate }} </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade modal-custom" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="cerrar-moda">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row align-items-center p-3">
                <div class="titulo-modal col-lg-12">
                    <h5 class="modal-title text-center" id="exampleModalLabel" data-aos="zoom-in">{{ politicasCampos.titulo }}</h5>
                </div>
                <div class=" descripcion-modal col-lg-12">
                    <div class="modal-body text-justify">
                        <p [innerHTML]="politicasCampos.descripcion | dompurify" data-aos="zoom-in"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer #footer></app-footer>