import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {MatSliderChange} from '@angular/material/slider';
import {HomeService} from 'src/app/services/home.service';
import {CountryService} from "../../../services/country.service";
import {environment} from 'src/environments/environment';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ModalRegistroDatosComponent} from '../../modal-registro-datos/modal-registro-datos.component'
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from 'src/app/services/auth.service';
import {solicitudCotizacion} from '../../../models/conozcoSector.models';
import {SecureStorageService} from '../../../services/secure-storage.service';


@Component({
  selector: 'app-conozco-sector',
  templateUrl: './conozco-sector.component.html',
  styleUrls: ['./conozco-sector.component.css']
})
export class ConozcoSectorComponent implements OnInit {

// Configuración de Google Maps

  markers: any[];
  formEstudiosConexion: FormGroup;
  display?: google.maps.LatLngLiteral;
  isChecked = false;
  //Variables
  // apiLoaded: Observable<boolean>;
  apiLoaded: boolean;
  unidadInformacion: String;
  apiKeyMapa: String;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  }
  checkedMoneda: boolean = false;
  activeCamposPotencia: boolean = true;
  activaCampoOperador: boolean = false;
  politicasTratamiento: any;
  mapaState = false;
  //Rango máximo minimo
  max = 1000;
  step = 0.5;
  min = 0;
  value = 0;

  //Servicios
  operadores: any[] = [];
  estudios: any[] = [];
  alternativas: any[] = [];
  monedas: any[] = [];
  unidadesPotencia: any[] = [];
  tecnologias: any[] = [];
  closeResult = '';
  tooltips = {};
  fechaHoy: any;
  @ViewChild('formDir') myForm: NgForm;
  @ViewChild('unidadPotenciaInfo') unidadPotenciaInfo: ElementRef;
  constructor(private _snackBar: MatSnackBar, private _builder: FormBuilder, private router: Router, private translate: TranslateService,
              httpClient: HttpClient, private homeService: HomeService, private authService: AuthService,
              private countryService: CountryService, private modalService: NgbModal,
              private secureStorageService: SecureStorageService) {

  }

  ngOnInit(): void {

    this.fechaHoy = new Date();

    this.getTooltips();


    this.formEstudiosConexion = this._builder.group({
      nombreProyecto: new FormControl('', [Validators.required]),
      tipoProyecto: new FormControl('', [Validators.required]),
      coordProtecciones: new FormControl(false),
      potencia: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,1})?$')]),
      unidadPotencia: new FormControl('', [Validators.required]),
      fechaInicioOperacion: new FormControl(''),
      latitud: new FormControl(''),
      longitud: new FormControl(''),
      ubicacion: new FormControl('', [Validators.maxLength(100)]),
      operadorRed: new FormControl('', [Validators.required]),
      operadorRedOtros: new FormControl(''),
      alternativas: new FormControl(''),
      moneda: new FormControl('', [Validators.required]),
      tecnologia: new FormControl('', [Validators.required]),
      terminosCondiciones: new FormControl('', [Validators.required]),
    });

    this.homeService.obtenerOperadores()
      .subscribe((respuesta) => {
        this.operadores = respuesta;
      });


      this.homeService.obtenerEstudiosConexion()
      .subscribe((respuesta) => {
        respuesta.forEach( dato => this.estudios.push(dato.tipo_estudio.toLowerCase()) );

      });

    this.homeService.obtenerMonedas()
      .subscribe((respuesta) => {
        if (respuesta.length) {
          this.monedas = respuesta;
          this.monedas.sort( (a,b) => a.id-b.id );
          let monedaCOP = this.monedas.filter( (monedaID) => monedaID.id == 3 );
          //Añadiendo COP como primer parametro en moneda - Por defecto
          if (monedaCOP.length) {
            monedaCOP[0]['isChecked'] = true;
          }
          this.formEstudiosConexion.controls['moneda'].setValue(3);
        }
      });

    this.homeService.obtenerTecnologias()
      .subscribe((respuesta) => {
        this.tecnologias = respuesta;
      });

    this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        const lenguaje = this.translate.getDefaultLang().valueOf();
        if (lenguaje == 'es') {
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 2);

        } else{
          this.politicasTratamiento = respuesta.find(elemento => elemento.id == 4);

        }
      });


  }

  closeModal() {
    this.modalService.dismissAll();
  }

  getTooltips(){
    this.homeService.tooltips().subscribe((data)=>{
      this.tooltips = data;
    });
  }

  envioTipoProyecto(tipo) {

    this.activeCamposPotencia = false;

    this.homeService.obtenerPotenciasPromise({"tipoEstudio": tipo})
      .then((respuesta) => {

        if( respuesta.type == "danger" || respuesta.type == "warning" ){
          this._snackBar.open( respuesta.message, '' , { duration: 3000,
            panelClass: ['alert', 'alert-danger'] }
         );
          this.unidadesPotencia = [];
          return;
        }

        this.unidadesPotencia = respuesta;
      });

    this.homeService.obtenerAlternativas({"tipoEstudio": tipo})
      .subscribe((respuesta) => {
        if (respuesta.length) {
          this.alternativas = respuesta;
          if (tipo == 'convencional') {
            this.formEstudiosConexion.controls['alternativas'].setValue(2);
          } else if (tipo == 'simplificado'){
            this.formEstudiosConexion.controls['alternativas'].setValue(1);
          } else if (tipo == 'simplificado con coordinación de protecciones'){
            this.formEstudiosConexion.controls['alternativas'].setValue(1);
          }
        }
      });

      //Reseteando información de Unidad de potencia.
      this.formEstudiosConexion.controls['unidadPotencia'].reset();
      this.formEstudiosConexion.controls['unidadPotencia'].setValue('');
      this.unidadInformacion = '';
      this.formEstudiosConexion.controls['potencia'].setValue(0);
      this.inputChangeSlider(0);
    }

  obtenerRangos(valor) {

    if(valor && this.formEstudiosConexion.controls['unidadPotencia'].value != '' ){
      this.unidadInformacion = valor;
    }

    let obtenerInfo = this.unidadesPotencia.filter(unidad => unidad.unidad_potencia == valor);
    this.min = obtenerInfo[0].rango_minimo || 0;
    this.max = obtenerInfo[0].rango_maximo || 0;
    this.formEstudiosConexion.controls['potencia'].setValidators([Validators.min(this.min), Validators.max(this.max)])
  }

  onSubmit() {

    if (!this.formEstudiosConexion.valid) {
      this._snackBar.open(this.translate.instant('errores.requerido'), '', {
          duration: 3000,
          panelClass: ['alert', 'alert-danger']
        }
      );
      return;
    }

    let user: any = this.secureStorageService.getItem('djangoUser');

    let objetoEnvio: solicitudCotizacion = {
      nombre_proyecto: this.formEstudiosConexion.controls['nombreProyecto'].value,
      tipo_proyecto: this.formEstudiosConexion.controls['tipoProyecto'].value,
      coordProtecciones: this.formEstudiosConexion.controls['coordProtecciones'].value || null,
      unidad: this.formEstudiosConexion.controls['unidadPotencia'].value,
      potencia_valor: this.formEstudiosConexion.controls['potencia'].value,
      fecha_operacion: this.formEstudiosConexion.controls['fechaInicioOperacion'].value || null,
      latitud: this.formEstudiosConexion.controls['latitud'].value || null,
      longitud: this.formEstudiosConexion.controls['longitud'].value || null,
      ubicacion: this.formEstudiosConexion.controls['ubicacion'].value || null,
      operador: this.formEstudiosConexion.controls['operadorRed'].value == "otros" ? null : this.formEstudiosConexion.controls['operadorRed'].value,
      otro_operador: this.formEstudiosConexion.controls['operadorRedOtros'].value || null,
      alternativa: this.formEstudiosConexion.controls['alternativas'].value || null,
      moneda: this.formEstudiosConexion.controls['moneda'].value,
      tecnologia: this.formEstudiosConexion.controls['tecnologia'].value,
      terminos: this.formEstudiosConexion.controls['terminosCondiciones'].value,
      nombre_cliente: '',
      usuario_de_creacion: user ? user.email : null
    }

    if (this.authService.isLoggedIn()) {
      this.guardarInformacion(objetoEnvio);
    } else {
      this.modalService.open(ModalRegistroDatosComponent, {centered: true}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        // pasar parametros al pdf
        let parserDatos = JSON.parse(localStorage.getItem('tomaDeDatos'));
        objetoEnvio['nombre_cliente'] = parserDatos.empresa;
        this.guardarInformacion(objetoEnvio);
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  guardarInformacion(objetoGuardar) {
    this.homeService.solicitarCotizacion(objetoGuardar)
      .subscribe((respuesta) => {

        this.router.navigate(['/informe-propuesta'], { queryParams: { id: respuesta.id } });
        this.resetForm();
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checked(i) {
    this.monedas.forEach(x => x.isChecked = false);
    this.monedas[i]['isChecked'] = !this.monedas[i]['isChecked'];
  }

  checkedTecnologia(i) {
    this.tecnologias.forEach(x => x.isChecked = false);
    this.tecnologias[i]['isChecked'] = !this.tecnologias[i]['isChecked'];
  }

  cleanUbicacion() {
    this.formEstudiosConexion.controls['ubicacion'].setValue(null);
  }

  onInputChange(event: MatSliderChange) {
    this.formEstudiosConexion.controls['potencia'].setValue(event.value);
  }

  inputChangeSlider(valor) {
    this.value = valor;
  }

  resetForm(){
    this.formEstudiosConexion.reset();
    this.myForm.resetForm();
    this.formEstudiosConexion.markAsPristine();
    this.formEstudiosConexion.markAsUntouched();
    this.formEstudiosConexion.updateValueAndValidity();
  }

  // operadorOtros(valor) {
  //   if (valor == "otros") {
  //     this.activaCampoOperador = true;
  //     this.formEstudiosConexion.controls['operadorRedOtros'].setValidators([Validators.required]);
  //   } else {
  //     this.activaCampoOperador = false;
  //     this.formEstudiosConexion.controls['operadorRedOtros'].setValidators(null);
  //   }
  // }

  get urlS3() {
    return environment.urlS3;
  }

  navigate(event,id){

    window.open(environment.urlWeb+'/terminos-y-condiciones/'+id,'_blank')
    event.preventdefault();
  }

  getTooltip(key: string): string {
    if (key in this.tooltips) {
      return this.tooltips[key];
    }
    return '';
  }

  getLatitudeAndLongitudeByAddress(address: string): void {
    if (address.length) {
      address = encodeURIComponent(address);
      this.countryService.getLatitudeAndLongitudeByAddress(address).subscribe(latiteAndLongitude => {
        if (latiteAndLongitude && 'lat' in latiteAndLongitude && 'lng' in latiteAndLongitude) {
          const latitud = latiteAndLongitude.lat;
          const longitud = latiteAndLongitude.lng;
          this.formEstudiosConexion.controls['latitud'].setValue(latitud);
          this.formEstudiosConexion.controls['longitud'].setValue(longitud);
        }
      });
    }
  }

  getAddressByLatitudeAndLongitude(): void {
    const latitud = this.formEstudiosConexion.controls['latitud'].value;
    const longitud = this.formEstudiosConexion.controls['longitud'].value;
    if (latitud && longitud) {
      const latitudeAndLongitude = latitud + ',' + longitud;
      this.countryService.getAddressByLatitudeAndLongitude(latitudeAndLongitude).subscribe(address => {
        if (address.length) {
          this.formEstudiosConexion.controls['ubicacion'].setValue(address);
        }
      });
    }
  }
}
