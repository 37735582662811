export class Momentos{
    subtitulo: string
    descripcion: string
    archivo: string
    tipo: string;
    categoria: string;
    fechaCreacion: any;
    fechaModificacion: any;
    id ?: number;
    titulo ?: string;
}
