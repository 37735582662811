<div class="nav-main mt-3 animate__animated animate__fast" id="navbar">
  <nav class="navbar  navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="https://www.phc.com.co/">
      <img src="assets/logo-phc.png" alt="" class="img-nav ml-4">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse w-100 mr-5" id="navbarSupportedContent">
      <ul class="navbar-nav mr-custom mt-2 mini-menu" style="align-items: center;">
        <li class="nav-item dropdown">
          <a class="nav-link" class="nav-link dropdown-toggle" id="navbarDropdownContacto" role="button"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{"header.opcion1" | translate }} </a>
          <div class="dropdown-menu animate__animated animate__fast animate__fadeIn" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" style="cursor: pointer;"
            [routerLink]="['']" [fragment]="'sliderservicio'" (click)="addClassActive('a[href=&quot;#sliderservicio&quot;]', 'sliderservicio')"> {{ "serviciosHeader.opcion1" | translate }} </a>
            <a class="dropdown-item" style="cursor: pointer;"
            [routerLink]="['']" [fragment]="'serviciosNoDigitales'" (click)="addClassActive('a[href=&quot;#serviciosNoDigitales&quot;]', 'serviciosNoDigitales')"> {{ "serviciosHeader.opcion2" | translate }} </a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" class="nav-link dropdown-toggle" id="navbarDropdownNosotros" role="button"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{"header.opcion2" | translate }} </a>
          <div class="dropdown-menu animate__animated animate__fast animate__fadeIn" aria-labelledby="navbarDropdown">
            <!--Cambiar--><a class="dropdown-item"
                             [routerLink]="['/sobre-nosotros']"> {{ "nosotrosHeader.opcion1" | translate }} </a>
            <a class="dropdown-item" [routerLink]="['/momentosPHC']"> {{ "nosotrosHeader.opcion2" | translate }} </a>
          </div>
        </li>
        <li class="nav-item ">
          <a class="nav-link" style="cursor: pointer;" [routerLink]="['']" [fragment]="'PHCMundo'" (click)="addClassActive('a[href=&quot;#PHCMundo&quot;]', 'PHCMundo')">{{"header.opcion3" | translate }}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" [routerLink]="['/nuestrosProyectosPHC']">{{"header.opcion4" | translate }}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" [routerLink]="['/politicas']">{{"header.opcion5" | translate }}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" class="nav-link dropdown-toggle" id="navbarDropdownContacto" role="button"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{"header.opcion6" | translate }}</a>
          <div class="dropdown-menu animate__fast animate__animated animate__fadeIn" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/contacto']"> {{ "contactoHeader.opcion1" | translate }} </a>
            <a class="dropdown-item"
               [routerLink]="['/trabaja-con-nosotros']"> {{ "contactoHeader.opcion2" | translate }} </a>
          </div>
        </li>
        <li class="nav-item ">


          <!-- <button *ngIf="!logged" type="button" (click)="login(content)" class="btn btn-custom" data-toggle="collapse" data-target="#navbarSupportedContent">
              <p>{{"header.login" | translate }}</p>
          </button>
          <button *ngIf="logged" type="button" (click)="cerrarSesion()" class="btn btn-custom" data-toggle="collapse" data-target="#navbarSupportedContent">
              <p>{{"header.cerrarSeccion" | translate }}</p>
          </button> -->
          <div class="dropdown" *ngIf="logged">
            <button class="btn btn-custom dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <b>
                {{"header.micuenta" | translate }}
              </b>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a type="button" class="dropdown-item" (click)="consultaUser()"> {{"header.perfil" | translate }} </a>
              <a type="button" class="dropdown-item"
                 (click)="consultaSuscripcion()">{{"header.suscripcion" | translate }}</a>
              <a type="button" class="dropdown-item"
                 (click)="cerrarSesion()"> {{"header.cerrarSeccion" | translate }} </a>
            </div>
          </div>
          <div class="iniciar-s" *ngIf="!logged">
            <!-- <a type="button" (click)="inicioSesion()"> {{"header.Inicio" | translate }} </a> -->

            <button class="btn btn-custom dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" (click)="login(content)">
              {{"header.Inicio" | translate }}
            </button>
          </div>

        </li>
        <div class="idioma">
          <a class="cursor-pointer btn-lenguajes" *ngIf="lenguaje == 'en'"
             (click)="changeLenguaje()"> {{ "lenguajesbtn.ingles" | translate }} </a>
          <a class="cursor-pointer btn-lenguajes" *ngIf="lenguaje == 'es'"
             (click)="changeLenguaje()"> {{ "lenguajesbtn.espanol" | translate }}  </a>
        </div>
      </ul>
    </div>
  </nav>

</div>

<!--
    Modal en donde se muestra el inicio de sesión del usuario
-->
<ng-template #content let-modal>
  <div>
    <div id="myModal" class="modal login-md">
      <div class="row mt-3 mr-3">
        <!--<div class="col-12">
            <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close"
                (click)="modal.dismiss('Cross click')">
        </div>-->
      </div>
      <div class="row modal-rps">
        <div class="col-lg-6 center col-sm-12 desaparecer" style="margin: auto;">

          <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close boton-cerrar"
               (click)="modal.dismiss('Cross click')">
          <br>

          <img src="assets/logo-sobre-azul.png" class="img-logo-proyect desaparecer-image" alt="">

        </div>

        <div class="col-lg-6 col-sm-12">
          <div class="modal-content">
            <form *ngIf="getPassword == false" [formGroup]="loginForm" (ngSubmit)="onSubmit(formDir)" #formDir="ngForm"
                  autocomplete="off">
              <div class="form-group">
                <!--<div *ngIf="localstorage.getItem('Validador') == 'false'">
                    <mat-spinner></mat-spinner>
                </div>-->
                <label for="exampleInputUser" class="labelForm">{{"loginForm.opcion1" | translate }}</label>
                <input type="text" class="form-control" value="" id="login-name" formControlName="username">
                <label for="login-name"></label>
                <br>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="loginForm.hasError('required', 'username') && errorMensaje == true">{{"formularioAvisos.opcion1" | translate }}</label>
              </div>

              <div class="form-group">
                <label for="exampleInputPass" class="labelForm">{{"loginForm.opcion2" | translate }}</label>
                <input type="password" minlength="8" class="form-control" value="" id="login-pass"
                       formControlName="password">
                <label for="login-pass"></label>
                <br>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="loginForm.hasError('required', 'password') && errorMensaje == true">{{"formularioAvisos.opcion1" | translate }}</label>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="loginForm.hasError('minlength', 'password') && errorMensaje == true">{{"formularioAvisos.opcion4" | translate }}</label>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{mensajeEstado}}</label>
              </div>
              <label class="contra-label cursor-pointer"
                     (click)="changePassword(true)">{{"loginForm.opcion3" | translate }}</label>
              <label class="contra-label cursor-pointer subrayado-registro"
                     (click)="modal.dismiss('Cross click');openRegistro(registro)"> {{"loginForm.opcion4" | translate }} </label>
              <p class="text-danger text-center errorMessaje" *ngIf="errorMensaje == true">{{messajeError}}</p>
              <div class="row btn-container">
                <button type="submit" class="btn btn-form">{{"loginForm.opcion5" | translate }} </button>
              </div>
            </form>

            <!--
                Sección de
                ¿olvidó su contraseña?
            -->
            <form *ngIf="getPassword == true" [formGroup]="passwordForm"
                  (ngSubmit)="submitChangePassword(passwordForm.value)" #formDir="ngForm" autocomplete="off">
              <div class="form-group">
                <label for="exampleInputUser" class="labelForm">{{"olvidoContra.opcion1" | translate }}</label>
                <input type="text" class="form-control" value="" id="login-name" formControlName="email">
                <label for="login-name"></label>
                <br>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="passwordForm.hasError('required', 'email') && formDir.submitted && messajeErrorPassword == ''">{{"formularioAvisos.opcion1" | translate }}</label>
                <br>
                <label class="labelForm text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="passwordForm.hasError('email', 'email') && formDir.submitted">{{"formularioAvisos.opcion5" | translate }} </label>
                <br>
                <label class="labelForm p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="this.messageError.message" class="text-{{this.messageError.type}}">
                  {{ this.messageError.message }}
                </label>
                <br>
                <label class="labelForm p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf=" messajeErrorPassword == '3'" [ngClass]="{'text-danger' : messajeErrorPassword == '3'}">
                  {{"formularioAvisos.opcion13" | translate }}
                </label>
              </div>
              <label (click)="changePassword(false)">{{"olvidoContra.opcion2" | translate }}</label>
              <div class="row btn-container">
                <button type="submit" class="btn btn-form">{{"olvidoContra.opcion3" | translate }}</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6 center col-sm-12" style="margin: auto;">

          <img src="assets/x-cerrar.png" alt="" class="cursor-pointer img-close boton-cerrar desaparecer-image"
               (click)="modal.dismiss('Cross click')">
          <br>

          <img src="assets/logo-sobre-azul.png" class="img-logo-proyect desaparecer-image" alt="">

        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--
    Modal que se muestra el registro para un nuevo usuario
-->
<ng-template #registro let-modal>
  <div id="myModal" class="modal md-registro">
    <div class="row mt-3 mr-3 ">
      <div class="col-12">
        <img src="assets/x-cerrar.png" alt="" class="close img-close"
             (click)="modal.dismiss('Cross click'); resetDatosRegistro()">
      </div>
    </div>
    <div class="row modal-rps">
      <div class="col-lg-12 col-sm-12 ">
        <div class="modal-content">
          <form *ngIf="!mensajeAfterRegistro" [formGroup]="registerFormulario" (ngSubmit)="onSubmitRegistro(formDir)"
                #formDir="ngForm" autocomplete="off">
            <div class="row formularioRegistro">
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="nombre" class="labelForm"> {{"registroForm.opcion1" | translate }} </label>
                <input type="text" class="form-control" id="nombre" formControlName="nombre">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'nombre') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="apellido" class="labelForm">{{"registroForm.opcion2" | translate }}</label>
                <input type="text" class="form-control" id="apellido" formControlName="apellido">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'apellido') && formDir.submitted">
                  {{"formularioAvisos.opcion1" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="correo" class="labelForm"> {{"registroForm.opcion3" | translate }}</label>
                <input type="email" class="form-control" id="correo" formControlName="correo">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'correo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.get('correo').invalid && formDir.submitted ">{{"formularioAvisos.opcion3" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label class="labelForm"><b>{{"registroForm.opcion4" | translate }}</b></label>
                <select id="inputState" class="form-control" (change)="cambiarTexto($event.target.value)"
                        formControlName="tipoPersona">
                  <option value="null" disabled>{{"formularioAvisos.opcion6" | translate }}</option>
                  <option *ngFor="let tipoPersona of tiposPersona"
                          value="{{tipoPersona.id}}">{{tipoPersona.tipo}}</option>
                </select>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'tipoPersona') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="numeroDocumento" *ngIf="documentoOrNIT"
                       class="labelForm">{{"registroForm.opcion5" | translate }}</label>
                <label for="numeroDocumento" *ngIf="!documentoOrNIT"
                       class="labelForm">{{"registroForm.opcion6" | translate }}</label>
                <input type="text" class="form-control" id="numeroDocumento" formControlName="numeroDocumento">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'numeroDocumento') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('pattern', 'numeroDocumento') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="celular" class="labelForm">{{"registroForm.opcion7" | translate }}</label>
                <input type="text" class="form-control" id="celular" formControlName="celular">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'celular') && formDir.submitted"> {{"formularioAvisos.opcion1" | translate }}</label>
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('pattern', 'celular') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="telefono" class="labelForm">{{"registroForm.opcion8" | translate }}</label>
                <input type="text" class="form-control" id="telefono" formControlName="telefono">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('pattern', 'telefono') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="cargo" class="labelForm">{{"registroForm.opcion9" | translate }}</label>
                <input type="text" class="form-control" id="cargo" formControlName="cargo">
                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('required', 'cargo') && formDir.submitted">{{"formularioAvisos.opcion1" | translate }}</label>
              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="empresa" class="labelForm">{{"registroForm.opcion10" | translate }}</label>
                <input type="text" class="form-control" id="empresa" formControlName="empresa">

              </div>
              <div class="col-lg-6 col-sm-6 col-xs-6 col-6 form-group">
                <label for="nitEmpresa" class="labelForm"> {{"registroForm.opcion11" | translate }}</label>
                <input *ngIf="documentoOrNIT" type="text" class="form-control" id="nitEmpresa"
                       formControlName="nitEmpresa">
                <input *ngIf="!documentoOrNIT" type="text" class="form-control" id="nitEmpresa"
                       formControlName="nitEmpresa" [value]="registerFormulario.controls['numeroDocumento'].value">

                <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                       *ngIf="registerFormulario.hasError('pattern', 'nitEmpresa') && formDir.submitted">
                  {{"formularioAvisos.opcion2" | translate }}</label>
              </div>
              <div class="col-lg-12 col-12 form-check">
                <input class="form-check-input" type="checkbox" (change)="checkHabeasData($event.target.checked)"
                       value="" formControlName="habeasData" id="habeasData">
                <label class="form-check-label" for="habeasData" *ngIf="politicasTratamiento.titulo">
                  <a class="link-terminos" (click)="navigate($event,politicasTratamiento.id)"
                     target="_blank"> {{politicasTratamiento.titulo}} </a>
                </label>
                <p class="text-danger p-messaje errorMessaje"
                   *ngIf="this.checkedHabeasData == false || (registerFormulario.hasError('required', 'habeasData') && formDir.submitted)">
                  {{"formularioAvisos.opcion1" | translate }}</p>
              </div>
              <div class="col-lg-12 col-12 form-check">
                <input class="form-check-input" type="checkbox" (change)="checkTerminos($event.target.checked)" value=""
                       formControlName="terminos" id="terminos">
                <label class="form-check-label" for="terminos">
                  <a class="link-terminos" (click)="navigate($event,terminosTitulo.id)"
                     target="_blank"> {{terminosTitulo.titulo}} </a>
                </label>
                <p class="text-danger p-messaje errorMessaje"
                   *ngIf="this.checkedTerminos == false || (registerFormulario.hasError('required', 'terminos') && formDir.submitted)">
                  {{"formularioAvisos.opcion1" | translate }}</p>
              </div>
              <div class="col-lg-12">
                <div class="btn-container">
                  <button type="submit" class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                </div>
              </div>
            </div>
          </form>
          <div *ngIf="mensajeAfterRegistro" class="mensaje-registro">
            <div *ngIf="!mensajeExitoRegistro && !mensaje_back" class="mensaje-existente">
              <label style="font-size: 16px;">{{"registroForm.opcion14" | translate }}</label>
              <div class="col-lg-12">
                <div class="btn-container">
                  <button type="button" (click)="modal.dismiss('Cross click')"
                          class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                </div>
              </div>
            </div>
            <div *ngIf="mensaje_back && !mensajeExitoRegistro" class="mensaje-existente">
              <label style="font-size: 16px;">{{mensaje_back}}</label>
              <div class="col-lg-12">
                <div class="btn-container">
                  <button type="button" (click)="modal.dismiss('Cross click')"
                          class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                </div>
              </div>
            </div>
            <div *ngIf="mensajeExitoRegistro" class="mensaje-exitoso">
              <label style="font-size: 16px;">{{"registroForm.opcion15" | translate }}</label>
              <div class="col-lg-12">
                <div class="btn-container">
                  <button type="button" (click)="modal.dismiss('Cross click')"
                          class="btn btn-form">{{"registroForm.opcion13" | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<!--Este div se dispara según lo que venga del localstorage
    key : djangoUser
    value : primera_vez
-->
<div id="myModal" class="modal" *ngIf="primeraVez == true">
  <div class="row modal-rps changepass-modal">
    <div class="col-lg-6 col-sm-12">
      <div class="modal-content">
        <form *ngIf="getPassword == false" [formGroup]="firtsTimePasswordForm"
              (ngSubmit)="changePasswordFirtTime(formDir)" #formDir="ngForm" autocomplete="off">
          <div class="form-group">
            <label for="exampleInputUser" class="labelForm"> {{"modificarContrasenaForm.opcion1" | translate }}</label>
            <br>
            <label for="exampleInputUser" class="labelForm"
                   style="margin-top: 12px;"> {{"modificarContrasenaForm.opcion2" | translate }}</label>
            <input type="password" class="form-control" value="" id="login-name" formControlName="digitarContrasena"
                   minlength="8">
            <label for="login-name"></label>
            <br>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="firtsTimePasswordForm.hasError('required', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion1" | translate }}</label>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="firtsTimePasswordForm.hasError('minlength', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion7" | translate }}</label>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="firtsTimePasswordForm.hasError('pattern', 'digitarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion8" | translate }}</label>
          </div>
          <div class="form-group">
            <label for="exampleInputPass" class="labelForm">{{"modificarContrasenaForm.opcion3" | translate }}</label>
            <input type="password" class="form-control" value="" id="login-pass" formControlName="confirmarContrasena"
                   minlength="8">
            <label for="login-pass"></label>
            <br>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="firtsTimePasswordForm.hasError('required', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion1" | translate }}</label>
            <br>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="firtsTimePasswordForm.hasError('minlength', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion7" | translate }}</label>
            <br>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;"
                   *ngIf="!correctPassword && !contrasenaError && !firtsTimePasswordForm.hasError('required', 'confirmarContrasena') && !firtsTimePasswordForm.hasError('minlength', 'confirmarContrasena') && formDir.submitted">
              {{"formularioAvisos.opcion8" | translate }}</label>
            <br>
            <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{contrasenaError}}</label>
            <!-- <label class="text-danger p-messaje errorMessaje" style="font-size: 16px;">{{contrasenaExpresionRegular}}</label> -->
          </div>
          <div class="row btn-container">
            <button type="submit" class="btn btn-form">{{"modificarContrasenaForm.opcion4" | translate }}</button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-lg-6 align-self-center text-center desaparecer-image">
      <img src="/assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
    </div>
  </div>
</div>

<!--
    div que confirma que el usuario ha cambiado su contraseña
-->
<div id="myModal" class="modal" *ngIf="successPassword == true">
  <div class="row modal-rps">
    <div class="col-lg-6 col-sm-12">
      <div class="modal-content" style="justify-content: center;align-items: center;text-align: center;">
        <h3>{{"modificarContrasenaForm.opcion5" | translate }}</h3>
        <button type="submit" class="btn btn-form" style="color: white;"
                (click)="changePasswordFirtTime('CerrarModal')">{{"modificarContrasenaForm.opcion7" | translate }}</button>
      </div>
      <div class="row btn-container">
      </div>
    </div>
    <div class="col-lg-6 align-self-center text-center desaparecer-image">
      <img src="/assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
    </div>
  </div>
</div>

<!--
    Loading
    La información del usuario se esta cargando
-->
<div id="myModal" class="modal" *ngIf="loading == true">
  <div class="row modal-rps">
    <div class="col-lg-6 col-sm-12">
      <div class="modal-content" style="justify-content: center;align-items: center;text-align: center;">
        <mat-spinner></mat-spinner>
        <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
      </div>
      <div class="row btn-container">
      </div>
    </div>
    <div class="col-lg-6 align-self-center text-center desaparecer-image">
      <img src="/assets/logo-sobre-azul.png" class="img-logo-proyect" alt="">
    </div>
  </div>
</div>
