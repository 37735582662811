<app-header></app-header>

<div class="banner-terminos">

    <div *ngIf="loading" class="pt-5 cargador text-center">
        <mat-spinner class="text-center"></mat-spinner>
        <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
    </div>

    <div *ngIf="!loading && terminosCondiciones" class="container">
        <div class="row banner-ppal align-items-center">
            <div class="col-lg-12 text-center align-self-center">
                <h1> {{ terminosCondiciones.titulo }} </h1>

            </div>
        </div>

        <div class="row subir-terminos">
            <div class="col-lg-12">
                <p [innerHTML]="terminosCondiciones.descripcion | dompurify"></p>
            </div>
        </div>
    </div>


</div>

<app-footer #footer></app-footer>
