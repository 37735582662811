import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraficasService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient) {
  }

  consultarGrafica1(escenario): any {
    return this._http.post(`${environment.url}consulta-grafica1`, {
      escenario: escenario
    });
  }

  consultarGrafica2(params): any {
    return this._http.post(`${environment.url}consulta-grafica-2`, params);
  }

  datosInforme(params): any {
    return this._http.post(`${environment.url}consulta-parametros-informe`, params);
  }

  consultarGrafica4(escenario) {
    return this._http.post(`${environment.url}consulta-grafica4`, {
      escenario: escenario
    });
  }

  consultarGrafica3(escenario) {
    return this._http.post(`${environment.url}consulta-grafica3`, {
      escenario: escenario
    });
  }

  consultatablas(escenario, tabla) {
    return this._http.post(`${environment.url}consulta-tabla/`, {
      escenario: escenario,
      tabla: tabla
    });
  }

  downloadPDF(params: object): Observable<any> {
    return this._http.post(`${environment.url}content/download-pdf/`, params, this.httpOptions);
  }

}
