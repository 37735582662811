<div id="myModal" class="modal md-registro">
    <div class="row mt-3 mr-3 ">
        <div class="col-12">
            <img src="assets/x-cerrar.png" alt="" class="close img-close" (click)="closeModal()">
        </div>
    </div>

    <div class="row modal-rps">
        <div class="col-lg-12 col-sm-12 ">
            <div class="modal-content">
                <div *ngIf="loading" class="cargador text-center">
                    <mat-spinner class="text-center"></mat-spinner>
                    <label>{{"modificarContrasenaForm.opcion6" | translate }}</label>
                </div>
                <div *ngIf="!loading" class="row">
                    <div class="col-lg-12">
                        <h3 class="text-center d-block"> {{"suscripcion.titulo" | translate }} </h3>
                    </div>

                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-12" *ngIf="suscripcion">
                                <div class="tabla-suscripcion">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="align-middle" scope="col"> {{"suscripcion.plan" | translate }} </th>
                                                    <th class="align-middle" scope="col"> {{"suscripcion.usuario" | translate }} </th>
                                                    <th class="align-middle" scope="col"> {{"suscripcion.inicioplan" | translate }} </th>
                                                    <th class="align-middle" scope="col"> {{"suscripcion.finplan" | translate }} </th>
                                                    <th class="align-middle" scope="col"> {{"suscripcion.estado" | translate }} </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let subsItem of suscripcionInfo | slice: (page-1) * pageSize : page * pageSize">
                                                    <td class="align-middle"> {{ subsItem.suscripcion__nombre }} </td>
                                                    <td class="align-middle"> {{ subsItem.email }} </td>
                                                    <td class="align-middle"> {{ subsItem.created_at | date: 'yyyy-MM-dd' }} </td>
                                                    <td class="align-middle"> {{ subsItem.vigencia | date: 'yyyy-MM-dd' }} </td>
                                                    <td class="align-middle"> {{ subsItem.estado }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="text-center paginador-suscripcion">
                                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [size]="tamanoPaginador()" [collectionSize]="suscripcionInfo.length">
                                        <ng-template ngbPaginationPrevious> {{"paginador.anterior" | translate }} </ng-template>
                                        <ng-template ngbPaginationNext> {{"paginador.siguiente" | translate }} </ng-template>
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div *ngIf="!suscripcion" class="col-lg-12">
                                <div class="text-center">
                                    <p> {{"suscripcion.noSuscripcion" | translate }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
