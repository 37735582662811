<app-header></app-header>

<div class="section-contacto">

    <div class="container-fluid">
        <div class="row pd-contenido">
            <div class="col-lg-7 fondo-formulario">
                <form [formGroup]="contactoForm" (ngSubmit)="onSubmit(formDir, formDirective)" #formDir="ngForm" #formDirective="ngForm" autocomplete="off">
                    <div class="form-group">
                        <label for="nombres" class="labelForm"> {{ "contactoForm.opcion1" | translate }}</label>
                        <input type="text" class="form-control" id="nombres" formControlName="nombres">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('required', 'nombres') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                    </div>
                    <div class="form-group">
                        <label for="telefono" class="labelForm">{{ "contactoForm.opcion2" | translate }}</label>
                        <input type="text" class="form-control" id="telefono" formControlName="telefono">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('required', 'telefono') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('pattern', 'telefono') && formDir.submitted">{{ "formularioAvisos.opcion2" | translate }}</p>
                    </div>
                    <div class="form-group">
                        <label for="correo" class="labelForm">{{ "contactoForm.opcion3" | translate }}</label>
                        <input type="email" class="form-control" id="correo" formControlName="correo">
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('required', 'correo') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('pattern', 'correo') && formDir.submitted">{{ "formularioAvisos.opcion3" | translate }}</p>
                    </div>
                    <div class="form-group">
                        <label for="mensaje" class="labelForm">{{ "contactoForm.opcion4" | translate }}</label>
                        <textarea type="text" class="form-control" id="mensaje" formControlName="mensaje"></textarea>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="contactoForm.hasError('required', 'mensaje') && formDir.submitted">{{ "formularioAvisos.opcion1" | translate }}</p>
                    </div>
                    <div class="col-lg-12 col-12 form-check">
                        <input class="form-check-input" type="checkbox" (change)="checkHabeasData($event.target.checked)" value="" formControlName="terminos" id="terminos">
                        <label class="form-check-label" for="terminos" *ngIf="habeasData">
                            <a *ngIf="politicasTratamiento" class="link-terminos"  (click)="navigate($event,politicasTratamiento.id)" target="_blank" > {{politicasTratamiento.titulo}} </a>
                        </label>
                        <p class="text-danger p-messaje errorMessaje" *ngIf="this.checkedHabeasData == false || (contactoForm.hasError('required', 'terminos') && formDir.submitted)">
                            {{"formularioAvisos.opcion1" | translate }}</p>
                    </div>
                    <div class="row btn-container pt-4">
                        <button type="submit" class="btn btn-form">{{ "contactoForm.opcion5" | translate }}</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-5">
                <div class="row fifty-row">
                    <div class="mapa-google position-relative" *ngIf="showMap" id="map"></div>
                    <!-- <div class="mapa-google position-relative" #map id="map"> -->
                        <!-- lala
                        <google-map>
                            <map-marker *ngFor="let marker of markers" [position]="marker.position" [title]="marker.title">
                            </map-marker>
                        </google-map> -->
                    <!-- </div> -->
                </div>
                <div class="row fifty-row">
                    <div class="informacion-contacto">
                        <h3 class="text-center text-main"> {{ "informacionContacto.titulo" | translate }} </h3>
                        <div class="linea linea-azul"></div>
                        <p *ngIf="contactoDescripcion" class="descripcion-contacto" [innerHTML]="contactoDescripcion.descripcion">
                        </p>
                        <div class="redes-sociales">
                            <ul>
                                <li>
                                    <a target="_blank" [attr.href]="urlTwitter"><img src="assets/iconotV.png" alt="logo_twitter"></a>
                                </li>
                                <li>
                                    <a target="_blank" [attr.href]="urlLinkedin"><img src="assets/iconoinV.png" alt="logo_linkedin"></a>
                                </li>
                                <li>
                                    <a target="_blank" [attr.href]="urlYoutube"><img src="assets/icono youtubeV.png" alt="logo_youtube"></a>
                                </li>
                                <li>
                                    <a target="_blank" [attr.href]="urlFacebook"> <img src="assets/iconofbV.png" alt="logo_facebook"> </a>
                                </li>
                                <li>
                                    <a target="_blank" [attr.href]="urlInstagram"> <img src="assets/iconiV.png" alt="logo_instagram"> </a>
                                </li>
                                <li>
                                    <a target="_blank" [attr.href]="urlTiktok"> <img src="assets/icontikV.png" alt="logo_tiktok"> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<app-footer #footer></app-footer>