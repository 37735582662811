import {Component, OnInit} from '@angular/core';
import {HomeService} from 'src/app/services/home.service';
import {ActivatedRoute} from '@angular/router';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-terminos-y-condiciones',
  templateUrl: './terminos-y-condiciones.component.html',
  styleUrls: ['./terminos-y-condiciones.component.css']
})
export class TerminosYCondicionesComponent implements OnInit {

  terminosCondiciones: any;
  loading: boolean;

  constructor(
    private homeService: HomeService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.homeService.consultaTerminosPolticas()
      .subscribe((respuesta: any) => {
        this.terminosCondiciones = respuesta.find(elemento => elemento.id == this.id || elemento.resource_translated_id == this.id);
        this.loading = false;
        this.addMetaTags();
      });
  }

  get id(): string {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  addMetaTags(): void {
    if (this.terminosCondiciones && 'meta_tags' in this.terminosCondiciones) {
      this.seoService.generateTagConfig((this.terminosCondiciones.meta_tags));
    }
  }

}
