import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  fragment: string;
  // this_window: any;

  constructor(
    private route: ActivatedRoute, private title: Title, private seo: SeoService,
    private renderer2: Renderer2,
    public elementRef: ElementRef,
  ) {
    let t:string = 'Inicio';
    this.title.setTitle(t);
    this.seo.generateTagConfig({
      title: 'Inicio',
      description: 'Inicio'
    });
    this.fragment = route.snapshot.fragment;
    // this.this_window = window;
  }

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   if (this.fragment) {
  //     this.scrollToAnchor(this.fragment, 1500);
  //   }
  // }

  /**
   * Scroll to anchor
   *
   * @param {string} location Element id
   * @param {string} wait     Wait time in milliseconds
   */
  // scrollToAnchor(location: string, wait: number): void {
  //   const element = document.querySelector('#' + location);
  //   if (element) {
  //     setTimeout(() => {
  //       element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  //     }, wait);
  //   }
  // }

  // addClassActive(selector: string): void {

  //   /**
  //    * Asigna 'scroll_star' como falso para impedir que el scroll se ejecute.
  //    */
  //   window['scroll_star'] = false;

  //   if (selector) {
  //     const sectionActive = this.elementRef.nativeElement.querySelector('#sections-nav a.active');
  //     if (sectionActive) {
  //       this.renderer2.removeClass(sectionActive, 'active');
  //     }
  //     const section = this.elementRef.nativeElement.querySelector(selector);
  //     this.renderer2.addClass(section, 'active');
  //   }
  // }

}
