import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {EChartOption} from 'echarts';
import {GraficasService} from '../../../services/graficas.service';
import {CutHtmlStringPipe} from '../../../pipes/cut-html-string.pipe';
import {graphicsColor} from '../../../app.config';

@Component({
  selector: 'app-grafica1',
  templateUrl: './grafica1.component.html',
  styleUrls: ['./grafica1.component.css']
})
export class Grafica1Component implements OnInit {
  @Input()
  escenario;
  @Input()
  historico = false;
  dataHeaders: any;
  contenidoGrafico: any;
  title: any;
  chartOption: EChartOption;
  lenguaje = "";
  private cutHtmlStringPipe = new CutHtmlStringPipe(this.renderer2);

  constructor(private graficasService: GraficasService,
              private renderer2: Renderer2
  ) {
    this.lenguaje = localStorage.getItem('language');

  }

  ngOnInit(): void {
    const parsearLocal = JSON.parse(localStorage.getItem('datainforme'));
    this.title = parsearLocal.nombre_grafica_1;

    this.graficasService.consultarGrafica1(this.escenario).subscribe((Response: any) => {


      this.contenidoGrafico = Response.data;
      this.dataHeaders = Response.header;
      this.chartOption = {
        title: {
          text: this.cutHtmlStringPipe.transform(this.title, 0),
          top: 30,
          right: 'center'
        },
        color: [
          graphicsColor.hidraulica,
          graphicsColor.gas,
          graphicsColor.carbon,
          graphicsColor.liquidos,
          '#569cdc',
          graphicsColor.ndc_t,
          '#214580',
          '#dd8b52',
          graphicsColor.solar,
          graphicsColor.eolica
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          x: 'right',
          y: '35%',
          data: this.dataHeaders
        },
        series: [
          {
            name: this.lenguaje == 'es' ? 'Capacidad efectiva' : 'Effective capacity',
            type: 'pie',
            radius: ['30%', '60%'],
            label: {
              backgroundColor: '#595555',
              borderColor: '#595555',
              borderWidth: 8,
              borderRadius: 1,
              position: 'inner',
              color: '#ffffff',
              formatter: '{d}%',
              // shadowBlur:3,
              // shadowOffsetX: 2,
              // shadowOffsetY: 2,
              // shadowColor: '#999',
              // padding: [0, 7],
              labelLine: {
                show: false
              }

            },
            data: this.contenidoGrafico,
            center: ['30%', '50%'],
          }
        ]
      };
    });
  }

}
